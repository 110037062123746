import React, { Fragment } from "react";
import Loading from "../components/Loading";
import db from "../utils/db";

class Velocity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      favrocollections: [],
      estByColumn: null,
      user: "",
      pass: "",
      collectionSelected: null,
      okTags: [
        "3D",
        "Level",
        "Code",
        "Concept",
        "Animation",
        "Estimation",
        "Game Design",
        "Level Design",
      ],
      velocityEst: null,
      velocity: null,
      fetchFail: false,
    };

    this.importFavroColls();
  }

  async importFavroColls() {
    const res = await db.request("/auth/importfavrocolls", "POST", {
      user: this.state.user,
      pass: this.state.pass,
    });

    var str = this.props.auth.getProjName();
    if (res.status === 200) {
      this.setState({
        favrocollections: res.data.filter((val) => {
          return val.name.replace(/[\W_]+/g, "").match(new RegExp(str, "i"));
        }),
      });
    } else {
      this.setState({ fetchFail: true });
    }
  }

  async getFavro(collectionId) {
    const res = await db.request("/auth/getfavro", "POST", {
      org: collectionId,
      user: this.state.user,
      pass: this.state.pass,
    });

    let widgetsById = {};
    let columnsById = {};
    let tagsById = {};
    let fieldsById = {};

    for (var i = 0; i < res.widgets.length; i++) {
      widgetsById[res.widgets[i].widgetCommonId] = res.widgets[i];
    }
    for (var j = 0; j < res.columns.length; j++) {
      columnsById[res.columns[j].columnId] = res.columns[j];
    }
    for (var k = 0; k < res.tags.length; k++) {
      tagsById[res.tags[k].tagId] = res.tags[k];
    }
    for (var m = 0; m < res.customfields.length; m++) {
      fieldsById[res.customfields[m].customFieldId] = res.customfields[m];
    }

    let estByColumn = {};
    let card = null;
    let colName = null;
    let custField = null;
    let custFieldId = null;
    let doneEst = {};
    let notdoneEst = {};
    let done = {};
    let notdone = {};
    let velocityEst = {};
    let velocity = {};

    for (var l = 0; l < res.cards.length; l++) {
      card = res.cards[l];

      if (card.columnId) {
        colName = columnsById[card.columnId].name;
        if (!estByColumn[colName]) estByColumn[colName] = {};

        if (card.customFields) {
          for (var n = 0; n < card.customFields.length; n++) {
            custField = card.customFields[n];
            custFieldId = custField.customFieldId;

            // if (!this.state.okTags.includes(fieldsById[custFieldId].name))
            //   continue;

            if (!estByColumn[colName][fieldsById[custFieldId].name])
              estByColumn[colName][fieldsById[custFieldId].name] = 0;

            if (custField.total && typeof custField.total === "number")
              estByColumn[colName][fieldsById[custFieldId].name] += Math.round(
                custField.total
              );

            // Also calc doneEst and notdone
            switch (colName.toUpperCase()) {
              case "REVIEW":
              case "COMPLETED":
                if (!doneEst[fieldsById[custFieldId].name])
                  doneEst[fieldsById[custFieldId].name] = 0;
                if (custField.total && typeof custField.total === "number")
                  doneEst[fieldsById[custFieldId].name] += custField.total;

                break;
              case "PLANNED":
              case "IN PROGRESS":
                if (!notdoneEst[fieldsById[custFieldId].name])
                  notdoneEst[fieldsById[custFieldId].name] = 0;
                if (custField.total && typeof custField.total === "number")
                  notdoneEst[fieldsById[custFieldId].name] += Math.round(
                    custField.total
                  );

                break;
              default:
            }
          }
        }

        if (card.tags) {
          for (var z = 0; z < card.tags.length; z++) {
            var tagId = card.tags[z];
            var tagName = tagsById[tagId].name
              .replace(/[^a-zA-Z\d\s:]+/g, "")
              .trim()
              .toLowerCase();

            // Also calc doneEst and notdone
            switch (colName.toUpperCase()) {
              case "REVIEW":
              case "COMPLETED":
                if (!done[tagName]) done[tagName] = 0;
                done[tagName] += 1;

                break;
              case "PLANNED":
              case "IN PROGRESS":
                if (!notdone[tagName]) notdone[tagName] = 0;
                notdone[tagName] += 1;

                break;
              default:
            }
          }
        }
      }
    }

    for (var y = 0; y < this.state.okTags.length; y++) {
      var tag = this.state.okTags[y];
      var tagLow = tag.toLowerCase();

      if (doneEst[tag] && notdoneEst[tag]) {
        if (!velocityEst[tag]) velocityEst[tag] = 0;

        velocityEst[tag] = Math.round(
          (doneEst[tag] / (doneEst[tag] + notdoneEst[tag])) * 100
        );
      }

      if (done[tagLow] && notdone[tagLow]) {
        if (!velocity[tag]) velocity[tag] = 0;

        velocity[tag] = Math.round(
          (done[tagLow] / (done[tagLow] + notdone[tagLow])) * 100
        );
      }
    }

    // Save velocity to DB
    await db.request("/auth/setvelocity", "POST", {
      proj: this.props.auth.getProjId(),
      org: this.props.auth.getOrgId(),
      velocity: velocity,
    });

    this.setState({
      estByColumn: estByColumn,
      velocityEst: velocityEst,
      velocity: velocity,
    });
  }

  render() {
    var planned = null;
    var inprogress = null;
    var review = null;
    var completed = null;

    if (this.state.estByColumn) {
      planned = Object.keys(this.state.estByColumn["PLANNED"]).map((key, i) => {
        return (
          <div key={"planned" + i} style={{ display: "block" }}>
            {key + ": " + this.state.estByColumn["PLANNED"][key] + "d"}
          </div>
        );
      });
      inprogress = Object.keys(this.state.estByColumn["IN PROGRESS"]).map(
        (key, i) => {
          return (
            <div key={"inprogress" + i} style={{ display: "block" }}>
              {key + ": " + this.state.estByColumn["IN PROGRESS"][key] + "d"}
            </div>
          );
        }
      );
      review = Object.keys(this.state.estByColumn["REVIEW"]).map((key, i) => {
        return (
          <div key={"review" + i} style={{ display: "block" }}>
            {key + ": " + this.state.estByColumn["REVIEW"][key] + "d"}
          </div>
        );
      });
      completed = Object.keys(this.state.estByColumn["COMPLETED"]).map(
        (key, i) => {
          return (
            <div key={"completed" + i} style={{ display: "block" }}>
              {key + ": " + this.state.estByColumn["COMPLETED"][key] + "d"}
            </div>
          );
        }
      );
    }

    return !this.state.fetchFail ? (
      <div style={{ padding: 20 }}>
        <Loading isLoading={this.state.favrocollections.length === 0} />
        <center>
          {this.state.collectionSelected === null && (
            <h4>Get velocity for Favro collection:</h4>
          )}
          {this.state.collectionSelected === null &&
            this.state.favrocollections.map((val, i) => {
              return (
                <button
                  key={i}
                  className="btn btn-sm btn-secondary"
                  style={{ margin: 4 }}
                  onClick={() => {
                    this.setState({ collectionSelected: i });
                    this.getFavro(val.collectionId);
                  }}
                >
                  {val.name}
                </button>
              );
            })}

          {this.state.collectionSelected !== null && (
            <div
              style={{
                display: "block",
                marginBottom: 10,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {"Historical velocity of " +
                this.state.favrocollections[this.state.collectionSelected].name}
            </div>
          )}
          <Loading
            isLoading={
              !this.state.estByColumn &&
              this.state.favrocollections.length > 0 &&
              this.state.collectionSelected !== null
            }
          />
          {this.state.estByColumn && (
            <Fragment>
              <div
                style={{
                  display: "inline-block",
                  marginRight: 50,
                  textAlign: "left",
                }}
              >
                <div style={{ display: "block" }}>
                  <b>PLANNED</b>
                  <br />
                  <div style={{ paddingLeft: 20, fontSize: 12 }}>{planned}</div>
                </div>
                <div style={{ display: "block" }}>
                  <b>IN PROGRESS</b>
                  <br />
                  <div style={{ paddingLeft: 20, fontSize: 12 }}>
                    {inprogress}
                  </div>
                </div>
                <div style={{ display: "block" }}>
                  <b>REVIEW</b>
                  <br />
                  <div style={{ paddingLeft: 20, fontSize: 12 }}>{review}</div>
                </div>
                <div style={{ display: "block" }}>
                  <b>COMPLETED</b>
                  <br />
                  <div style={{ paddingLeft: 20, fontSize: 12 }}>
                    {completed}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  padding: 40,
                  fontSize: 18,
                }}
              >
                <b>by Estimates</b>
                <br />
                {Object.keys(this.state.velocityEst).map((key, i) => {
                  return (
                    <div key={"veloEst" + i} style={{ display: "block" }}>
                      {key}: {this.state.velocityEst[key]}%
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  padding: 40,
                  fontSize: 18,
                }}
              >
                <b>by Tags</b>
                <br />
                {Object.keys(this.state.velocity).map((key, i) => {
                  return (
                    <div key={"velo" + i} style={{ display: "block" }}>
                      {key}: {this.state.velocity[key]}%
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </center>
      </div>
    ) : (
      <center>
        <h4 style={{ width: 400, marginTop: 150 }}>
          Favro says NO! Too many calls per hour, try again soon.
        </h4>
      </center>
    );
  }
}

export default Velocity;
