import React, { useState } from "react";
import { useSpring, a } from "react-spring/three";

const Box = args => {
  const epicSelected =
    args.epicSelected !== undefined && args.epicSelected !== null
      ? args.epicSelected
      : -1;
  var [hovered, setHovered] = useState(false);
  const mult = hovered ? 1.2 : 1;
  const pos = 0.25;
  const extra = epicSelected > -1 ? 1.6 : 0;
  const target = mult * pos;
  var posArr = [
    [-target, target, target],
    [target, target, target],
    [-target, -target, target],
    [target, -target, target],
    [-target, target, -target],
    [target, target, -target],
    [-target, -target, -target],
    [target, -target, -target]
  ];
  const selIdx = epicSelected % 8;
  if (epicSelected > -1) {
    posArr[selIdx][0] *= extra;
    posArr[selIdx][1] *= extra;
    posArr[selIdx][2] *= extra;
  }
  const props = useSpring({
    scale: hovered ? [1.2, 1.2, 1.2] : [1, 1, 1]
  });
  const color = "#493778";
  const rotX = args.hasControls ? 0 : 0.2;

  return (
    <a.mesh
      onPointerOver={() => {
        if (!args.isSolid) setHovered(true);
      }}
      onPointerOut={() => {
        if (!args.isSolid) setHovered(false);
      }}
      scale={props.scale}
      rotation={[rotX, 1.3, 0]}
    >
      <a.mesh position={posArr[0]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 0 ? "#888" : color}
        />
      </a.mesh>
      <a.mesh position={posArr[1]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 1 ? "#888" : color}
        />
      </a.mesh>

      <a.mesh position={posArr[2]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 2 ? "#888" : color}
        />
      </a.mesh>
      <a.mesh position={posArr[3]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 3 ? "#888" : color}
        />
      </a.mesh>

      <a.mesh position={posArr[4]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 4 ? "#888" : color}
        />
      </a.mesh>
      <a.mesh position={posArr[5]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 5 ? "#888" : color}
        />
      </a.mesh>

      <a.mesh position={posArr[6]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 6 ? "#888" : color}
        />
      </a.mesh>
      <a.mesh position={posArr[7]}>
        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
        <meshPhysicalMaterial
          attach="material"
          color={epicSelected === 7 ? "#888" : color}
        />
      </a.mesh>
    </a.mesh>
  );
};

export default Box;
