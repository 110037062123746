import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Column from "./Column";
import Button from "./Button";
import ButtonDropdown from "./ButtonDropdown";
import history from "../utils/history";
import Colors from "../utils/colors";

export default function SprintGoal(props) {
  const [isDone, setIsDone] = useState(false);
  const [border, setBorder] = useState("3px solid transparent");
  const [background, setBackground] = useState("transparent");
  const [isArchived, setIsArchived] = useState(props.data.card_status === "-1");
  const [hovering, setHovering] = useState(-1);
  const [storyOutOfScope, setStoryOutOfScope] = useState(false);

  const prioTypes = [
    { name: "Very Low", color: "green" },
    { name: "Low", color: "lightgreen" },
    { name: "Medium", color: "yellow" },
    { name: "High", color: "orange" },
    { name: "Very High", color: "red" },
  ];

  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "sprintgoal",
    item: {
      id: props.id,
      index: props.index,
      priority: props.data.card_priority,
    },
    canDrag: (prps) => {
      return !prps.isCustom;
    },
    beginDrag: (props) => {
      if (props.startDrag) props.startDrag(props);
      return true;
    },
    endDrag: (props, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        props.submitDragSprintgoal(monitor.getItem().index);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "sprintgoal",
    hover: (dragged, monitor) => {
      if (!ref.current) return;

      const dragIndex = dragged.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (dragged.priority !== props.data.card_priority) return;
      // Time to actually perform the action
      props.dragSprintgoal(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragged.index = hoverIndex;
    },
  });

  drag(drop(ref));

  useEffect(() => {
    var totalCards = 0;
    for (var i = 0; i < props.data.children.length; i++) {
      totalCards += props.data.children[i].length;
    }
    setIsDone(
      totalCards > 0 &&
        totalCards ===
          props.data.children[props.data.children.length - 1].length
    );
    // Check if completely out of scope
    setStoryOutOfScope(
      props.data.children[0].length > 0 &&
        props.data.children[0].find((v) => v.outOfScope === true) &&
        props.data.children[1].length === 0
    );
  }, [props.data.children]);

  useEffect(() => {
    var _border = "3px solid transparent";
    if (props.highlight && props.highlightCard === -1)
      _border = "3px dotted orange";
    else if (isArchived) _border = "3px solid #674ea7";

    var bg = "transparent";
    if (isArchived) bg = "#674ea799";
    else if (isDone) bg = "#88ff8855";

    setBorder(_border);
    setBackground(bg);
  }, [props.highlight, props.highlightCard, isDone, isArchived]);

  useEffect(() => {
    setIsArchived(props.data.card_status === "-1");
  }, [props.data.card_status]);

  // RENDER XXXXXXXXXXXXXXXXXXXXXX

  var titleColor = "#fff";
  if (props.customColor) titleColor = props.customColor;
  else if (isDone && !isArchived) titleColor = "limegreen";

  const prio =
    props.data.card_priority === null ? -1 : props.data.card_priority - 1;
  const prioType = prio > -1 ? prioTypes[prio] : null;

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        width: "100%",
        position: "relative",
        paddingTop: isArchived ? 10 : 0,
      }}
    >
      {isArchived && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
            fontSize: 100,
            opacity: 0.4,
          }}
        >
          <i className="fas fa-lock" />
        </div>
      )}
      <div
        style={{
          display: "block",
          width: "100%",
          background: background,
          borderRadius: 10,
          minHeight: 80,
          border: border,
          transition: "none",
          minWidth: 500,
          zIndex: 2,
          opacity: isDragging ? 0 : 1,
        }}
      >
        <div
          style={{
            width: "100%",
            minWidth: 500,
            paddingBottom: 6,
            minHeight: 170,
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginLeft: 8,
            }}
          >
            <div
              className="fancy"
              style={{
                fontSize: 33,
                fontWeight: 600,
                color: titleColor,
                textShadow: "1px 1px 3px #000",
                cursor: props.isCustom ? "default" : "pointer",
                lineHeight: 1.6,
                marginRight: 10,
                opacity: !isArchived && storyOutOfScope ? 0.4 : 1,
                pointerEvents: !props.isCustom,
                zIndex: 10,
              }}
              onClick={() => {
                if (!props.isCustom) props.editSprintGoal(props.data.card_id);
              }}
            >
              {props.title ? props.title : "no name"}
            </div>

            {props.showPriority && prioType && (
              <div
                style={{
                  position: "absolute",
                  bottom: 1,
                  left: 4,
                  fontSize: 9,
                  textTransform: "uppercase",
                  color: prioType.color,
                  minWidth: 27,
                  textAlign: "left",
                  lineHeight: "9px",
                  fontWeight: 600,
                }}
              >
                {prioType.name}
              </div>
            )}

            {!isDone && !isArchived && !props.isCustom && (
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  top: 26,
                  zIndex: 100,
                }}
              >
                <ButtonDropdown type="ellipsis" value="..." nocaret right naked>
                  <div style={{ display: "inline-block", width: 140 }}>
                    <Button
                      value={isArchived ? "Unarchive" : "Archive"}
                      type={isArchived ? "red" : "green"}
                      onClick={() => {
                        props.editTheCard({
                          id: props.data.card_id,
                          status: isArchived ? 1 : -1,
                        });
                        setIsArchived(!isArchived);
                      }}
                      fontawesome={
                        isArchived ? "fas fa-file-upload" : "fas fa-archive"
                      }
                    />
                    <Button
                      value={"Push to next goal"}
                      style={{ marginTop: 6 }}
                      onClick={() => {
                        props.pushCard(props.data.card_id);
                      }}
                      fontawesome={"fas fa-arrow-right"}
                    />
                    <Button
                      value={"Delete"}
                      type={"red"}
                      style={{ marginTop: 6 }}
                      onClick={() => {
                        props.deleteCard(props.data.card_id);
                      }}
                      fontawesome={"fas fa-trash"}
                    />
                  </div>
                </ButtonDropdown>
              </div>
            )}
            {(isDone || isArchived) && (
              <Button
                value={isArchived ? "Unarchive" : "Archive"}
                style={{
                  position: "absolute",
                  right: 6,
                  top: 6,
                  background: isArchived ? "red" : "limegreen",
                }}
                onClick={() => {
                  props.editTheCard({
                    id: props.data.card_id,
                    status: isArchived ? 1 : -1,
                  });
                  setIsArchived(!isArchived);
                }}
                fontawesome={
                  isArchived ? "fas fa-file-upload" : "fas fa-archive"
                }
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "stretch",
              color: "#666",
            }}
          >
            {props.data.children.map((col, k) => {
              return (
                <Column
                  key={"col" + k}
                  idx={k}
                  colname={props.columns[k]}
                  maxcols={props.columns.length}
                  id={props.data.card_id}
                  data={col}
                  isLast={k === props.data.children.length - 1}
                  isDone={isDone && !isArchived}
                  dropCallback={props.dropCallback}
                  editCard={props.editSprintGoal}
                  updateCard={props.editTheCard}
                  users={props.users}
                  disciplines={props.disciplines}
                  idToIdxUsers={props.idToIdxUsers}
                  idToIdxDiscs={props.idToIdxDiscs}
                  highlight={props.highlight && props.highlightCol === k}
                  highlightCard={props.highlightCard}
                  cloudinary={props.cloudinary}
                  addTask={props.addTask}
                  isArchived={isArchived}
                  showMyCards={props.showMyCards}
                  showDiscipline={props.showDiscipline}
                  showPriority={props.showPriority}
                  auth={props.auth}
                  isCustom={props.isCustom}
                  customColor={props.customColor}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
