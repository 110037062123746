import React, { useImperativeHandle, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export default function Pill(props) {
  const ref = useRef(null);
  const { id, index, group } = props;

  const [{ isDragging }, drag] = useDrag({
    type: "pill",
    item: {
      id: id,
      index: index,
      group: group,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "pill",
    hover: (dragged, monitor) => {
      if (!ref.current) return;

      const dragIndex = dragged.index;
      const dragGroup = dragged.group;
      const hoverIndex = props.index;
      const hoverGroup = props.group;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex || dragGroup !== hoverGroup) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      props.movePill(dragIndex, hoverIndex, dragGroup);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragged.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  var cursor = "default";
  if (props.onClick) cursor = "pointer";
  else if (props.movePill) cursor = "move";

  var bg = "orange";
  if (props.showEnabled) bg = props.enabled ? "green" : "red";

  return (
    <div
      ref={ref}
      style={Object.assign(
        {},
        {
          display: "inline-flex",
          fontSize: 11,
          padding: "2px 5px",
          background: bg,
          borderRadius: 4,
          cursor: cursor,
          verticalAlign: "middle",
          marginLeft: 4,
          transition: "none",
          color: "#000",
        },
        { opacity }
      )}
      onClick={props.onClick}
    >
      {props.name}
      {props.showDelete && (
        <div
          className="far fa-trash-alt"
          onClick={props.clickDelete}
          style={{
            cursor: "pointer",
            verticalAlign: "middle",
            marginLeft: 4,
            marginTop: 2,
          }}
        />
      )}
      {props.showEnabled && (
        <div
          className={props.enabled ? "fas fa-toggle-on" : "fas fa-toggle-off"}
          onClick={props.clickEnable}
          style={{
            cursor: "pointer",
            verticalAlign: "middle",
            marginLeft: 4,
            marginTop: 2,
            color: "#000",
          }}
        />
      )}
    </div>
  );
}
