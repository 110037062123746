import React, { Component } from "react";
import db from "../utils/db";
import history from "../utils/history";
import PassValidator from "password-validator";
import validator from "validator";
import TextInput from "../components/TextInput";
import { toast } from "react-toastify";

class NewUser extends Component {
  constructor(props) {
    super(props);

    var key = this.props.location.pathname;
    key =
      key.search("newuser/") !== -1
        ? key.slice(key.search("newuser/") + 8)
        : undefined;

    this.state = {
      userKey: key,
      password: "",
      disable: true,
      email: "",
      norender: true,
    };

    this.checkKey = this.checkKey.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirm = this.validateConfirm.bind(this);
    this.submitProfile = this.submitProfile.bind(this);

    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.password = React.createRef();
    this.confirm = React.createRef();

    this.checkKey();
  }

  async checkKey() {
    if (this.state.userKey !== undefined) {
      const checkKey = await db.request("/newuser", "POST", {
        action: "check_key",
        key: this.state.userKey,
      });
      if (checkKey && checkKey.status === 200) {
        if (checkKey.isActive) {
          // User is not new to the system, ask for login
          toast.success("Please login to goto invitation!");
          localStorage.setItem("orgid_redirect", checkKey.orgid);
          localStorage.setItem("invite_email", checkKey.email);
          history.push("/login");
        } else {
          // User is new to system, get info from user
          var state = this.state;
          state.email = checkKey.email;
          state.norender = false;
          state.disable = false;
          this.setState(state);
        }
      } else {
        toast.warn(() => (
          <div style={{ verticalAlign: "middle" }}>
            <i
              className="fas fa-exclamation-triangle"
              style={{ paddingRight: "5px", fontSize: "1.2rem" }}
            />
            <span>The user key is no longer valid.</span>
          </div>
        ));

        history.push("/peepz");
      }
    }
  }

  validateName(value) {
    var msg = "";
    const regex = new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-]+$/u
    );

    if (validator.trim(value) === "") msg = "This cannot be empty, yo..";
    else if (!regex.test(value)) msg = "Non-valid characters in name";

    return msg;
  }

  validatePassword(value) {
    var state = this.state;
    state.password = value;
    this.setState(state);

    var schema = new PassValidator();
    schema
      .is()
      .min(8)
      .is()
      .max(25)
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces();

    var messages = {
      min: "At least 8 characters",
      uppercase: "A capital letter",
      lowercase: "A lower case letter",
      digits: "At least a number",
      spaces: "No spaces",
      empty: "Is not empty",
    };

    var errors = schema.validate(value, { list: true });
    if (value === "") errors.push("empty");

    var msg = [];
    Object.keys(messages).map((key) =>
      msg.push({
        valid: !errors.includes(key),
        message: messages[key],
      })
    );

    return msg;
  }

  validateConfirm(value) {
    return value !== "" &&
      this.state.password !== "" &&
      value === this.state.password
      ? ""
      : "Password does not match";
  }

  async submitProfile(e) {
    e.preventDefault();

    var submitSuccess = false;
    var state = this.state;
    var hasErrors =
      this.firstname.current.hasErrors() ||
      this.lastname.current.hasErrors() ||
      this.password.current.hasErrors() ||
      this.confirm.current.hasErrors();

    if (!hasErrors) {
      // Add user to db
      const response = await db.request("/newuser", "POST", {
        key: this.state.userKey,
        pass: this.confirm.current.value(),
        firstname: this.firstname.current.value(),
        lastname: this.lastname.current.value(),
      });

      if (response.status === 200) {
        submitSuccess = true;
      } else {
        state.disable = true;
        toast.warn(
          () => (
            <div style={{ verticalAlign: "middle" }}>
              <i
                className="fas fa-exclamation-triangle"
                style={{ paddingRight: "5px", fontSize: "1.2rem" }}
              />
              <span>The user key is no longer valid.</span>
            </div>
          ),
          {
            onClose: () => {
              var state = this.state;
              state.disable = false;
              this.setState(state);
            },
          }
        );
      }
    }

    if (!submitSuccess) {
      this.firstname.current.validate(true);
      this.lastname.current.validate(true);
      this.password.current.validate(true);
      this.confirm.current.validate(true);
      this.setState(state);
    } else {
      // Log new owner user in
      const resLogin = await db.request("/login", "POST", {
        email: this.state.email,
        password: this.confirm.current.value(),
      });

      this.props.auth.login(resLogin.token, resLogin.expires);
      this.props.auth.setUser(
        resLogin.firstname,
        resLogin.lastname,
        resLogin.email,
        resLogin.userid
      );
      this.props.auth.setOrgs(resLogin.org, resLogin.orgs);
      this.props.auth.setProjs(JSON.parse(resLogin.projs), 0);
      this.props.dispatch.fire("orgchange");
      this.props.dispatch.fire("projrefresh", resLogin.proj);
      this.props.dispatch.fire("userupdate");

      history.push("/overview-project");
    }
  }

  render() {
    var disabled = this.state.disable;
    var norender = this.state.norender;

    return (
      <div>
        {!norender && (
          <div className="form-wrapper">
            <center>
              <h1>Welcome, ninja!</h1>
              Some quick info, then get in there.
            </center>
            <br />
            <form>
              <fieldset disabled={disabled}>
                <TextInput
                  validateCallback={this.validateName}
                  ref={this.firstname}
                  placeholder="First name"
                  maxlength="20"
                  autofocus
                />
                <TextInput
                  validateCallback={this.validateName}
                  ref={this.lastname}
                  placeholder="Last name"
                  maxlength="20"
                />
                <TextInput
                  placeholder="Password"
                  maxlength="25"
                  validateCallback={this.validatePassword}
                  ref={this.password}
                  password
                />
                <TextInput
                  placeholder="Confirm password"
                  maxlength="25"
                  validateCallback={this.validateConfirm}
                  ref={this.confirm}
                  password
                />
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                  onClick={this.submitProfile}
                >
                  Submit
                </button>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default NewUser;
