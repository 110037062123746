import React, { useState } from "react";

/*
PROPS
- value
- small
- large
- type
- disabled
- right
*/

export default function ButtonDropdown(props) {
  const [hovering, setHovering] = useState(false);
  const [active, setActive] = useState(props.state);

  // Render ---------------------------------------------------------------

  var sizeAdj = 0;
  if (props.small) sizeAdj -= 2;
  else if (props.large) sizeAdj += 2;

  const base = !props.right
    ? {
        marginLeft: hovering ? -20 : -23,
        padding: "4px 8px",
        width: 50,
        borderRadius: 6,
        textAlign: "right",
        color: active ? "green" : "#111",
        fontSize: 11 + sizeAdj,
        cursor: "pointer",
      }
    : {
        marginRight: hovering ? -20 : -23,
        padding: "4px 8px",
        width: 50,
        borderRadius: 6,
        textAlign: "left",
        color: active ? "green" : "#111",
        fontSize: 11 + sizeAdj,
        cursor: "pointer",
      };

  const types = {};
  types.default = {
    background: active ? "#cfc" : "#674ea7",
  };
  types.outline = {
    fontWeight: 500,
    background: hovering ? "#ffffff22" : "transparent",
    border: "1px solid " + (hovering ? "#fff" : "#ffffff88"),
  };

  const selStyle = props.type ? types[props.type] : types.default;

  return (
    <div
      style={{ position: "relative", float: props.right ? "right" : "none" }}
    >
      <div
        style={
          props.disabled
            ? { opacity: "0.4", pointerEvents: "none", ...base, ...selStyle }
            : { ...base, ...selStyle }
        }
        onClick={(e) => {
          if (!props.disabled) {
            setActive((prev) => !prev);
            if (props.onClick) props.onClick(!active);

            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onMouseOver={(e) => setHovering(true)}
        onMouseOut={(e) => setHovering(false)}
        onMouseLeave={(e) => {
          setHovering(false);
        }}
      >
        <i
          className={props.fontawesome ? props.fontawesome : "fas fa-filter"}
        />
      </div>
      <div
        style={
          !props.right
            ? {
                position: "absolute",
                left: hovering ? 40 : 20,
                width: 200,
                textAlign: "left",
                top: "50%",
                transform: "translateY(-50%)",
                opacity: hovering ? 1 : 0,
                fontSize: 12,
                color: "#7b5ec4",
                userSelect: "none",
              }
            : {
                position: "absolute",
                right: hovering ? 40 : 20,
                textAlign: "right",
                width: 200,
                top: "50%",
                transform: "translateY(-50%)",
                opacity: hovering ? 1 : 0,
                fontSize: 12,
                color: "#7b5ec4",
                userSelect: "none",
              }
        }
      >
        {props.value && props.value}
      </div>
    </div>
  );
}
