import React from "react";

export default function ButtonAdd(props) {
  var extra = "";
  if (props.large) extra += " large";
  if (props.small) extra += " small";

  return (
    <div
      className={props.lightMode ? "btn-custom-light" : "btn-custom"}
      onClick={e => {
        if (!props.disabled) props.onClick(e);
      }}
      style={
        props.disabled
          ? { opacity: "0.4", pointerEvents: "none" }
          : { opacity: 1 }
      }
    >
      <i
        className={"fas fa-plus-circle" + extra}
        style={{
          borderRadius: "50%",
          background: "transparent",
          boxShadow: props.lightMode ? "1px 1px 3px #222" : "none"
        }}
      />
    </div>
  );
}
