import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import gsap, { TweenMax, Sine, Elastic } from "gsap";
import iconNeg from "../imgs/critical.png";
import iconNeutral from "../imgs/negative.png";
import iconPos from "../imgs/positive.png";
import iconLoader from "../imgs/loaderbars.svg";

export default forwardRef(function TextInput(props, ref) {
  const [value, setValueInternal] = useState(
    props.default ? props.default.toString() : ""
  );
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [didLeave, setDidLeave] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMultiReq, setIsMultiReq] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitStatus, setSubmitStatus] = useState("nonvalidated");
  const [requireSubmit] = useState(props.requireSubmit);

  const wrapper = useRef();
  const inputRef = useRef();

  useEffect(() => {
    return () => {
      gsap.globalTimeline.clear();
    };
  }, []);

  useEffect(() => {
    if (submitError !== "") validateInternal(true);
  }, [submitError]);

  useImperativeHandle(ref, () => ({
    select: () => {
      inputRef.current.select();
    },
    value: () => {
      return value;
    },
    setValue: (val) => {
      setValue(val);
    },
    validate: (val) => {
      validateInternal(val);
    },
    hasErrors: () => {
      return hasErrors;
    },
    submit: (val) => {
      setSubmitError(val);
      setSubmitStatus(val !== "" ? "error" : "valid");
    },
    blur: () => {
      inputRef.current.blur();
    },
  }));

  const onLeaveField = (e) => {
    if (
      e.relatedTarget &&
      ["INPUT", "BUTTON"].includes(e.relatedTarget.nodeName)
    ) {
      setDidLeave(true);
      setShowError(true);
      setIsLeaving(true);

      validateInternal(false, value, true);
    }
  };

  const onInputChange = (e) => {
    setValueInternal(e.target.value);
    setIsLeaving(false);
    setSubmitError("");
    setSubmitStatus("changed");
    validateInternal(false, e.target.value, false);
    if (props.changeCallback) props.changeCallback(e.target.value, e);
  };

  function validateInternal(submit = false, _val, _leaving) {
    var _msg = props.validateCallback
      ? props.validateCallback(
          _val !== null && _val !== undefined ? _val : value
        )
      : message;
    if (props.validateCallback) setMessage(_msg);
    if (!_leaving) _leaving = isLeaving;

    setIsMultiReq(Array.isArray(_msg));
    setHasErrors(false);
    setShowError(false);
    if (submit) setDidLeave(true);

    if (isMultiReq) {
      // Text field has multiple validations
      // format: [{valid: Boolean, message: String}, ...]
      var _hasErrors = false;
      _msg.forEach(function (obj) {
        if (obj.valid === false) {
          _hasErrors = true;
          setHasErrors(true);
        }
      });

      setShowError(_hasErrors && didLeave);

      if (_hasErrors && (_leaving || submit)) {
        shakeAnim();
        setIsLeaving(false);
      }
    } else if (_msg.waiting) {
      // Text field validation is asynchronous and requires waiting
      // format: [{waiting: String}]
      const waiting = _msg.waiting;
      setShowError(false);
      setTimeout((text) => showWaiting(text), 2000, waiting);
      setMessage("");
    } else {
      // Text field has a single validation = string
      if (_msg === "") {
        if (submitError && submitError !== "") {
          setMessage(submitError);
          _msg = submitError;
        }
      }

      setHasErrors(_msg !== "");

      if (_msg !== "" && (_leaving || submit)) {
        setShowError(true);
        shakeAnim();
      }
    }
  }

  function showWaiting(text) {
    setMessage({ waiting: text });
    setShowError(true);
    setIsWaiting(true);
  }

  function shakeAnim() {
    if (isAnimating) return;

    setIsAnimating(true);

    TweenMax.fromTo(
      wrapper.current,
      0.08,
      { x: -20 },
      {
        x: 20,
        repeat: 3,
        yoyo: true,
        ease: Sine.easeInOut,
        onComplete: function (animCallback) {
          TweenMax.to(this.target, 0.7, {
            x: 0,
            ease: Elastic.easeOut,
            onComplete: function (callback) {
              callback(false);
              this.kill();
            },
            onCompleteParams: [animCallback],
          });
        },
        onCompleteParams: [setIsAnimating],
      }
    );
  }

  function setValue(value) {
    setValueInternal(value);
  }

  function onKeyPress(key) {
    if (props.keypressCallback) props.keypressCallback(key);
  }

  // Render xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  var inputStyle = "form-control form-control-lg fancy";
  var errorClass = "input-error-hidden";
  var validationState = "form-group";

  if (hasErrors) {
    if (showError) {
      inputStyle += " is-invalid";
    }
  } else {
    if (requireSubmit) {
      if (submitStatus === "valid") {
        inputStyle += " is-valid";
      }
    } else {
      if (didLeave) {
        inputStyle += " is-valid";
      }
    }
  }

  if (showError) {
    errorClass = "input-error";
  }

  var _message = "";
  if (isMultiReq) {
    _message = message.map((err, id) => {
      var errorWrapper = "";
      var icon = iconNeutral;

      if (!didLeave) {
        errorWrapper = err.valid
          ? "error-wrapper good"
          : "error-wrapper neutral";
        icon = err.valid ? iconPos : iconNeutral;
      } else {
        errorWrapper = err.valid ? "error-wrapper good" : "error-wrapper bad";
        icon = err.valid ? iconPos : iconNeg;
      }

      return (
        <div className={errorWrapper} key={id}>
          <img src={icon} alt="img" />
          {err.message}
        </div>
      );
    });
  } else if (message.waiting && isWaiting) {
    _message = (
      <div>
        <img src={iconLoader} alt="loading" />
        <span>fetching {message.waiting}...</span>
      </div>
    );
  } else {
    _message = (
      <div className="error-wrapper bad">
        <img src={iconNeg} alt="img" />
        {message}
      </div>
    );
  }

  return (
    <div className={validationState}>
      <div ref={wrapper}>
        <input
          ref={inputRef}
          className={inputStyle}
          type={props.password ? "password" : "text"}
          value={value}
          onChange={onInputChange}
          onBlur={onLeaveField}
          autoFocus={props.autofocus}
          placeholder={props.placeholder}
          maxLength={props.maxlength}
          name={props.name}
          disabled={props.disabled}
          onKeyPress={onKeyPress}
          style={{
            textAlign: props.centered ? "center" : "left",
            fontWeight: 600,
          }}
        />
      </div>
      <center>
        <div className={errorClass}>{_message}</div>
      </center>
    </div>
  );
});
