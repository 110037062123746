import React from "react";

export default class Helper extends React.Component {
  constructor(props) {
    super(props);

    this.listeners = [];
  }

  addListener(key, cb) {
    if (!cb) console.error("Undefined callback registered");
    var arr = this.listeners[key] === undefined ? [] : this.listeners[key];
    arr.push(cb);
    this.listeners[key] = arr;
  }

  removeListener(key, val) {
    var arr = this.listeners[key];
    if (arr && arr !== null && arr !== undefined) {
      arr.splice(arr.indexOf(val), 1);
      if (arr.length === 0) {
        this.listeners.splice(this.listeners.indexOf("key"), 1);
      }
    }
  }

  fire(key, arg) {
    if (this.listeners[key]) {
      this.listeners[key].forEach(val => {
        val(arg);
      });
    }
  }
}
