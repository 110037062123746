import React, { useState, useEffect, useRef } from "react";
import Toggle from "./Toggle";
import useMousedown from "./useMousedown";
import useKey from "./useKey";

/*
PROPS
- text
- data {text, value, checked}
- state
- small
- large
*/

export default function ToggleDrop(props) {
  const [hovering, setHovering] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleClick);
    };
  }, []);

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  function handleClick(e) {
    // e.preventDefault();
    // e.stopPropagation();
    //
    // if (dropRef.current && !dropRef.current.contains(e.target)) {
    //   document.removeEventListener("click", handleClick);
    //   document.removeEventListener("keydown", handleClick);
    //   setOpen(false);
    // }
  }

  // Render  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const hasSelection = props.data.find((x) => x.checked);

  var sizeAdj = 0;
  if (props.small) sizeAdj -= 2;
  else if (props.large) sizeAdj += 2;

  var bg = hovering ? "#ffffff11" : "transparent";
  if (!open && hasSelection) {
    bg = hovering ? "#ffffff11" : "transparent";
    bg = "#cfc";
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "baseline",
        padding: "2px 8px",
        margin: 2,
        borderRadius: 6 + sizeAdj,
        fontSize: 11 + sizeAdj,
        color: !open && hasSelection ? "#111" : "#674ea7",
        border: !open && hasSelection ? "1px solid #cfc" : "1px solid #674ea7",
        background: bg,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => {
        setOpen(true);
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {props.fontawesome && (
        <i className={props.fontawesome} style={{ marginRight: 4 }} />
      )}
      {props.text}
      {open && (
        <Drop
          data={props.data}
          onClick={(i) => {
            var newData = props.data.slice();
            newData[i].checked = !newData[i].checked;
            props.onClick(newData);
          }}
          onBlur={() => setOpen(false)}
        />
      )}
    </div>
  );
}

const Drop = function (props) {
  const dropRef = useRef(null);

  useMousedown((e) => {
    if (
      props.onBlur &&
      dropRef.current &&
      !dropRef.current.contains(e.target)
    ) {
      props.onBlur();
    }
  });

  useKey(() => {
    if (props.onBlur) props.onBlur();
  });

  return (
    <div
      ref={dropRef}
      style={{
        position: "absolute",
        top: "101%",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "2px 8px",
        zIndex: 10,
        background: "transparent",
        borderRadius: 5,
      }}
    >
      {props.data.map((val, i) => {
        return (
          <Toggle
            key={"role" + i}
            text={val.text}
            state={val.checked}
            onClick={() => {
              if (props.onClick) props.onClick(i);
            }}
          />
        );
      })}
    </div>
  );
};
