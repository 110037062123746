import React, { useState, useEffect, useRef } from "react";
import db from "../utils/db";

export default function Analytics(props) {
  const [velocity, setVelocity] = useState(null);
  const [cards, setCards] = useState(null);
  const [words, setWords] = useState(null);
  const [wordsArr, setWordsArr] = useState(null);
  const [sentence, setSentence] = useState(null);
  const [estimate, setEstimate] = useState(null);

  const inputWords = useRef(null);

  useEffect(() => {
    fetchVelocity();
  }, []);

  // Functions ---------------------------------------------

  async function fetchVelocity() {
    // ---------------
    // 1. Get velocities per discipline

    const resVelo = await db.request("/auth/getanalytics", "POST", {
      project: props.auth.getProjId(),
    });
    var resArr = {};
    var totalArr = [];
    var totalEst = [];

    Object.keys(resVelo.data).forEach((disc, i) => {
      resArr[disc] = {};
      resArr[disc].id = resVelo.data[disc].id;

      Object.keys(resVelo.data[disc]).forEach((stat, j) => {
        if (stat === "id") return;

        resArr[disc][stat] = {};
        const arr = resVelo.data[disc][stat];
        const arrSort = arr.slice();
        arrSort.sort((a, b) => a - b);
        const mid = Math.ceil(arrSort.length / 2);

        resArr[disc][stat].average =
          Math.round((arr.reduce((a, b) => a + b) / arr.length) * 100) / 100;

        resArr[disc][stat].medianRaw =
          Math.round(
            (arrSort.length % 2 === 0
              ? (arrSort[mid] + arrSort[mid - 1]) / 2
              : arrSort[mid - 1]) * 100
          ) / 100;

        resArr[disc][stat].deviation =
          Math.round(
            Math.sqrt(
              arrSort
                .map((x) => Math.pow(x - resArr[disc][stat].average, 2))
                .reduce((a, b) => a + b) / arrSort.length
            ) * 100
          ) / 100;

        if (!["estimated", "id"].includes(stat)) {
          resArr[disc][stat].average = (
            resArr[disc][stat].average /
            (1000 * 60)
          ).convertMinutes();
          resArr[disc][stat].median = (
            resArr[disc][stat].medianRaw /
            (1000 * 60)
          ).convertMinutes();
          resArr[disc][stat].deviation = (
            resArr[disc][stat].deviation /
            (1000 * 60)
          ).convertMinutes();
        }
      });

      if (resVelo.data[disc]["1"] && resVelo.data[disc]["estimated"]) {
        resArr[disc].amountEstimated = resVelo.data[disc]["estimated"].length;
        resArr[disc].amountInProgress = resVelo.data[disc]["1"].length;
        totalArr = totalArr.concat(resVelo.data[disc]["1"]);
      }

      if (resVelo.data[disc]["estimated"]) {
        totalEst = totalEst.concat(resVelo.data[disc]["estimated"]);
      }
    });

    // One more time for the all disciplines combined
    resArr.total = {};
    const totalSort = totalArr.slice();
    totalSort.sort((a, b) => a - b);
    const totalMid = Math.ceil(totalSort.length / 2);

    if (totalArr.length > 0) {
      resArr.total.average =
        Math.round((totalArr.reduce((a, b) => a + b) / totalArr.length) * 100) /
        100;
      resArr.total.median =
        Math.round(
          (totalSort.length % 2 === 0
            ? (totalSort[totalMid] + totalSort[totalMid - 1]) / 2
            : totalSort[totalMid - 1]) * 100
        ) / 100;
      resArr.total.deviation =
        Math.round(
          Math.sqrt(
            totalSort
              .map((x) => Math.pow(x - resArr.total.average, 2))
              .reduce((a, b) => a + b) / totalSort.length
          ) * 100
        ) / 100;
    }

    // Last time for the all disciplines estimations combined
    const estSort = totalEst.slice();
    estSort.sort((a, b) => a - b);
    const estMid = Math.ceil(estSort.length / 2);
    resArr.total.velocity =
      Math.round(
        (estSort.length % 2 === 0
          ? (estSort[estMid] + estSort[estMid - 1]) / 2
          : estSort[estMid - 1]) * 100
      ) / 100;
    resArr.total.amountEst = estSort.length;

    setVelocity(resArr);

    const veloFormat = {};
    Object.keys(resArr).forEach((disc, k) => {
      if (disc === "total" || !resArr[disc].estimated) return;
      veloFormat[resArr[disc].id + "_velocity"] =
        resArr[disc].estimated.medianRaw;
      if (resArr[disc]["1"])
        veloFormat[resArr[disc].id + "_median"] = resArr[disc]["1"].medianRaw;
    });

    //----------------------
    // 2. Get general averages for cards and pillars etc.

    var _cards = [];
    var _pillars = [];
    var _epics = [];
    var _stories = [];
    var idToIdx = {};
    var _tshirtSizes = {};
    var _words = {};
    var _wordsArr = [];

    const resCards = await db.request("/auth/getcards", "POST", {
      project: props.auth.getProjId(),
      type: "all",
    });

    if (
      resCards &&
      resCards.status === 200 &&
      resCards.data &&
      resCards.data.length > 0
    ) {
      for (var i = 0; i < resCards.data.length; i++) {
        var card = resCards.data[i];
        card.children = [];
        idToIdx[card.card_id] = i;

        if (card.card_parent && idToIdx[card.card_parent] !== undefined) {
          _cards[idToIdx[card.card_parent]].children.push(card);
          card.parent = _cards[idToIdx[card.card_parent]].card_name;
        }

        _cards.push(card);

        if (card.card_type === "pillar") _pillars.push(card);
        else if (card.card_type === "epic") _epics.push(card);
        else if (card.card_type === "story") _stories.push(card);
        else if (card.card_type === "task") {
          if (card.card_estimate) {
            // Start collecting stats for every word used in card titles
            var entries = card.card_name.split(/\W|[0-9]/g);
            entries = entries.filter((v) => v.match(/[A-Za-z]+/g));
            for (var j = 0; j < entries.length; j++) {
              const word = entries[j].toLowerCase();
              if (!_words[word]) _words[word] = { count: 0, estimates: [] };
              _words[word].count += 1;
              _words[word].estimates.push(card.card_estimate);
            }
          }
        }
      }

      // Correlate words used in task titles to estimates
      _wordsArr = Object.entries(_words).sort(
        ([, a], [, b]) => b.count - a.count
      );
      for (var k = 0; k < _wordsArr.length; k++) {
        _wordsArr[k][1].estimates.sort((a, b) => a - b);
        var wordEstSort = _wordsArr[k][1].estimates;
        const wordEstMid = Math.ceil(wordEstSort.length / 2);

        _wordsArr[k].average =
          Math.round(
            (wordEstSort.reduce((a, b) => a + b) / wordEstSort.length) * 100
          ) / 100;
        _wordsArr[k].median =
          Math.round(
            (wordEstSort.length % 2 === 0
              ? (wordEstSort[wordEstMid] + wordEstSort[wordEstMid - 1]) / 2
              : wordEstSort[wordEstMid - 1]) * 100
          ) / 100;
        _wordsArr[k].irrelevant = wordEstSort.length <= 3;

        _words[_wordsArr[k][0]].word = _wordsArr[k][0];
        _words[_wordsArr[k][0]].count = _wordsArr[k][1].count;
        _words[_wordsArr[k][0]].estimates = _wordsArr[k][1].estimates;
        _words[_wordsArr[k][0]].irrelevant = _wordsArr[k].irrelevant;
        _words[_wordsArr[k][0]].average = _wordsArr[k].average;
        _words[_wordsArr[k][0]].median = _wordsArr[k].median;
      }
    }
    _wordsArr.sort((a, b) => b.average - a.average);
    setWordsArr(_wordsArr);
    setWords(_words);

    // Calculate average amount of Epics per Pillar
    var amountEpics = [];
    _pillars.forEach((pillar) => {
      amountEpics.push(pillar.children.length);
    });
    const avgEpics = amountEpics.reduce((a, b) => a + b) / amountEpics.length;

    // Calculate average amount of Stories per Epic
    var amountStories = [];
    _epics.forEach((epic) => {
      amountStories.push(epic.children.length);
    });
    const avgStories =
      amountStories.reduce((a, b) => a + b) / amountStories.length;

    // Calculate average amount of Tasks per Story
    var amountTasks = [];
    _stories.forEach((story) => {
      amountTasks.push(story.children.length);

      // ALSO: get averages for Stories tshirt estimates
      if (story.card_estimate && story.card_estimate > -1) {
        if (!_tshirtSizes[story.card_estimate])
          _tshirtSizes[story.card_estimate] = {};
        _tshirtSizes[story.card_estimate].amountTasks = story.children.length;
      }
    });
    const avgTasks = amountTasks.reduce((a, b) => a + b) / amountTasks.length;

    veloFormat.avgEpics = Math.round(avgEpics * 10) / 10;
    veloFormat.avgStories = Math.max(1, Math.round(avgStories * 10) / 10);
    veloFormat.avgTasks = Math.round(avgTasks * 10) / 10;
    veloFormat.avgCardTime = resArr.total.median;

    setCards({
      avgEpics: veloFormat.avgEpics,
      avgStories: veloFormat.avgStories,
      avgTasks: veloFormat.avgTasks,
      tshirtSizes: _tshirtSizes,
    });
  }

  function setWordValid(idx) {
    var _wordsArr = wordsArr.slice();
    var _words = Object.assign({}, words);
    _wordsArr[idx].irrelevant = !_wordsArr[idx].irrelevant;
    _words[_wordsArr[idx][0]].irrelevant = !_words[_wordsArr[idx][0]]
      .irrelevant;
    setWordsArr(_wordsArr);
    setWords(_words);
    updateEstimate();
  }

  function updateEstimate() {
    const text = inputWords.current.value;
    const _words = text.split(/\W|[0-9]/g);
    var res = [];
    var sum = 0;
    var amount = 0;

    for (var i = 0; i < _words.length; i++) {
      const _word = words[_words[i].toLowerCase()];
      if (_word && !_word.irrelevant) {
        res.push(_word);
        sum += _word.estimates.reduce((acc, curr) => acc + curr);
        amount += _word.estimates.length;
      }
    }

    setSentence(res);
    setEstimate(Math.round((sum / amount) * velocity.total.velocity));
  }

  // ---------

  return (
    <div style={{ padding: 20 }}>
      <h1>Analytics</h1>
      <div style={{ display: "inline-flex" }}>
        {velocity && (
          <Box>
            <center>
              <b style={{ display: "block", fontSize: 20, marginBottom: 6 }}>
                Disciplines
              </b>
            </center>
            <div style={{ fontSize: 12, lineHeight: 1.3, marginBottom: 20 }}>
              <div style={{ color: "#f00", display: "inline-block" }}>
                Velocity
              </div>{" "}
              is calculated by a task's estimate compared to its actual time
              spent In Progress.{" "}
              <div style={{ color: "#f90", display: "inline-block" }}>
                Average/median
              </div>{" "}
              is only based on actual time In Progress. Everything in 8h
              workdays only.
            </div>

            <b style={{ fontSize: 20, textAlign: "left" }}>All</b>

            <div style={{ display: "block", textAlign: "left" }}>
              <span style={{ color: "#f00" }}>Velocity:</span>{" "}
              <span style={{ fontSize: 21 }}>
                {velocity.total.velocity ? velocity.total.velocity : "-"}
              </span>{" "}
              <span style={{ fontSize: 12 }}>
                ({velocity.total.amountEst} cards)
              </span>
              <br />
              <span style={{ color: "#ff9900" }}>Average:</span>{" "}
              {(velocity.total.average / (1000 * 60)).convertMinutes().short}
              <br />
              <span style={{ color: "#ff9900" }}>Median:</span>{" "}
              {(velocity.total.median / (1000 * 60)).convertMinutes().short}
            </div>

            <br />

            {Object.keys(velocity).map((val, i) => {
              if (val === "total") return null;

              const velo = velocity[val].estimated;
              const avg = velocity[val]["1"] ? velocity[val]["1"].average : 0;
              const median = velocity[val]["1"] ? velocity[val]["1"].median : 0;
              return (
                <div
                  key={"disc" + i}
                  style={{
                    display: "block",
                    marginTop: i > 0 ? 20 : 0,
                  }}
                >
                  <b style={{ fontSize: 20 }}>{val}</b>
                  <div style={{ display: "block", marginLeft: 10 }}>
                    <span style={{ color: "#f00" }}>Velocity:</span>{" "}
                    <span style={{ fontSize: 21 }}>
                      {velo ? velo.medianRaw : 0}
                    </span>{" "}
                    <span style={{ fontSize: 12 }}>
                      ({velocity[val].amountEstimated} cards estimated)
                    </span>
                    <br />
                    <span style={{ color: "#ff9900" }}>Average:</span>{" "}
                    {avg.short}
                    <br />
                    <span style={{ color: "#ff9900" }}>Median:</span>{" "}
                    {median.short}
                    <br />
                    <span style={{ fontSize: 12 }}>
                      ({velocity[val].amountInProgress} cards completed ever)
                    </span>
                  </div>
                </div>
              );
            })}
          </Box>
        )}

        {cards && (
          <Box>
            <center>
              <b style={{ display: "block", fontSize: 20 }}>Cards</b>

              <div style={{ display: "inline-block", textAlign: "left" }}>
                <span style={{ color: "#ff9900" }}>Epics per Pillar:</span>{" "}
                <span style={{ fontSize: 21 }}>{cards.avgEpics}</span> <br />
                <span style={{ color: "#ff9900" }}>Stories per Epic:</span>{" "}
                <span style={{ fontSize: 21 }}>{cards.avgStories}</span> <br />
                <span style={{ color: "#ff9900" }}>Tasks per Story:</span>{" "}
                <span style={{ fontSize: 21 }}>{cards.avgTasks}</span>{" "}
              </div>

              <br />
              <br />
              <br />
              {words && (
                <>
                  <b style={{ display: "block", fontSize: 20 }}>Words</b>

                  <div
                    style={{ fontSize: 12, lineHeight: 1.3, marginBottom: 20 }}
                  >
                    Words from Task's titles, correlated to the Task's
                    historical estimate. Used to suggest estimates based on
                    typed title of the Task. Relevant words has min 3 estimates.
                  </div>

                  <input
                    ref={inputWords}
                    style={{
                      fontSize: 13,
                      width: "100%",
                    }}
                    onChange={() => {
                      updateEstimate(words);
                    }}
                  />
                  {sentence && sentence.length > 0 && (
                    <div>Sounds like a {estimate} hour task.</div>
                  )}

                  <br />
                  <br />

                  <b
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "#f90",
                      fontSize: 12,
                    }}
                  >
                    Relevant
                  </b>

                  {wordsArr.map((word, i) => {
                    return !word.irrelevant ? (
                      <Pill
                        key={"word" + i}
                        idx={i}
                        name={word[0]}
                        clickCb={setWordValid}
                      />
                    ) : null;
                  })}

                  <br />
                  <br />

                  <b
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "#f90",
                      fontSize: 12,
                    }}
                  >
                    Irrelevant
                  </b>

                  {wordsArr.map((word, i) => {
                    return word.irrelevant ? (
                      <Pill
                        key={"word" + i}
                        idx={i}
                        name={word[0]}
                        clickCb={setWordValid}
                      />
                    ) : null;
                  })}
                </>
              )}
            </center>
          </Box>
        )}
      </div>
    </div>
  );
}

const Box = (props) => {
  return (
    <div
      style={{
        display: "inline-block",
        borderRadius: 10,
        padding: 30,
        margin: 20,
        background: "#ffffff11",
        maxWidth: 400,
      }}
    >
      {props.children}
    </div>
  );
};

const Pill = (props) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        fontSize: 12,
        color: "#f90",
        padding: "1px 6px",
        margin: 2,
        borderRadius: 6,
        cursor: "pointer",
      }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      onClick={() => {
        if (props.clickCb) props.clickCb(props.idx);
      }}
    >
      {props.name}
      {hovering && (
        <div
          className="fa fa-times"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            color: "#f00",
            fontSize: 17,
            fontWeight: "bold",
          }}
        />
      )}
    </div>
  );
};
