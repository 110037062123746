import { css } from "glamor";

export default {
  base: css({
    width: "450px",
    marginLeft: "-225px"
  }),
  toastClass: css({
    padding: "0px",
    minHeight: "0px",
    borderRadius: "0.2rem",
    // background: "#fff3cd",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05)"
  }),
  bodyClass: css({
    // color: "#856404",
    // background: "#fff3cd",
    padding: "10px",
    fontSize: "0.8rem",
    textAlign: "center"
  }),
  progressClass: css({
    // background: "#856404",
    height: "2px"
  })
};
