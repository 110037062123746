import React, { useState, useRef, useEffect, useCallback } from "react";
import db from "../utils/db";
import history from "../utils/history";
import validator from "validator";
import EditCard from "../components/EditCard";
import ButtonAdd from "../components/ButtonAdd";
import Box from "../components/Box";
import TextInput from "../components/TextInput";
import { Canvas, extend, useThree, useRender } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import socket from "../utils/socket";

extend({ OrbitControls });

const Controls = () => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();

  useRender(() => {
    orbitRef.current.update();
  });

  return (
    <orbitControls
      autoRotate
      maxPolarAngle={Math.PI / 3}
      minPolarAngle={Math.PI / 3}
      args={[camera, gl.domElement]}
      ref={orbitRef}
    />
  );
};

export default function Product(props) {
  const [cards, setCards] = useState(null);
  const [idToIdx, setIdToIdx] = useState(null);
  const [newCard, setNewCard] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const editCardRef = useRef(editCard);
  const [pillars, setPillars] = useState([]);
  const [epics, setEpics] = useState([]);
  const [discDods, setDiscDods] = useState(null);
  const [amountDods, setAmountDods] = useState({});
  const [hoveringCol, setHoveringCol] = useState(null);
  const [hovering, setHovering] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [newCardName, setNewCardName] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const projectNameFullRef = useRef();
  const useLocal = useRef();

  const productUpdatedEventCb = useCallback(productUpdatedEvent);
  const cardDeletedEventCb = useCallback(cardDeletedEvent);

  useEffect(() => {
    document.addEventListener("keydown", handleKey, false);
    props.dispatch.addListener("orgchange", refresh);
    props.dispatch.addListener("projchange", refresh);
    useLocal.current = !props.auth.isAuthenticated();

    refresh();

    // Connect to socket room
    const room = props.auth.getOrgId() + "product";
    socket.emit("joinroom", {
      room: room,
      user: props.auth.getUserName(),
    });
    socket.on("productupdated", productUpdatedEventCb);
    socket.on("carddelete", cardDeletedEventCb);

    return () => {
      const room = props.auth.getOrgId() + "product";
      socket.emit("leaveroom", {
        room: room,
        user: props.auth.getUserName(),
      });
      socket.removeListener("productupdated", productUpdatedEventCb);
      socket.removeListener("carddelete", cardDeletedEventCb);

      document.removeEventListener("keydown", handleKey, false);
      props.dispatch.removeListener("orgchange", refresh);
      props.dispatch.removeListener("projchange", refresh);
    };
  }, []);

  useEffect(() => {
    editCardRef.current = editCard;
  }, [editCard]);

  //////////////////////////////////////////
  // Functions

  function refresh() {
    fetchData();
    getDiscAndDods();
  }

  async function fetchData(cb) {
    var _cards = [];
    var _pillars = [];
    var _epics = [];
    var idToIdx = {};
    var resCards = {};

    if (useLocal.current) {
      resCards.status = 200;
      resCards.data = JSON.parse(localStorage.getItem("product"));
    } else {
      // Get all cards, in order pillar, epic & story
      resCards = await db.request("/auth/getcards", "POST", {
        project: props.auth.getProjId(),
        type: "product",
      });
    }

    if (resCards.status === 200 && resCards.data && resCards.data.length > 0) {
      for (var i = 0; i < resCards.data.length; i++) {
        var card = resCards.data[i];
        card.children = [];
        idToIdx[card.card_id] = i;

        if (card.card_parent) {
          _cards[idToIdx[card.card_parent]].children.push(card);
          card.parent = _cards[idToIdx[card.card_parent]].card_name;
          card.parentId = _cards[idToIdx[card.card_parent]].card_id;
        }

        _cards.push(card);

        if (card.card_type === "pillar") _pillars.push(card);
        else if (card.card_type === "epic") _epics.push(card);
      }
    }

    var _projectName = -1;
    const project = JSON.parse(localStorage.getItem("project"));
    if (props.auth.getProjName() !== -1)
      _projectName = props.auth.getProjName();
    else if (project) _projectName = project.name;

    setCards(_cards);
    setIdToIdx(idToIdx);
    setPillars(_pillars);
    setEpics(_epics);
    setLoading(false);
    setProjectName(_projectName);
    if (cb) cb();
  }

  async function getDiscAndDods() {
    var discs = {};

    if (useLocal.current) {
      discs.status = 200;
      discs.data = [{ set_id: 1, set_type: "discipline", set_relation: null }];
    } else {
      discs = await db.request("/auth/getdisciplines", "POST", {
        org: props.auth.getOrgId(),
        dods: true,
      });
    }

    if (discs.status === 200) {
      var disciplines = [];
      var disciplinesById = {};
      var amountDods = {};
      var idToIdx = {};
      for (var i = 0; i < discs.data.length; i++) {
        var val = discs.data[i];
        idToIdx[val.set_id] = i;
        disciplines.push(val);
        disciplinesById[val.set_id] = val;
        if (val.set_type === "discipline") {
          disciplines[i].dods = [];
          amountDods[val.set_id] = 0;
        } else {
          disciplines[idToIdx[val.set_relation]].dods.push(val);
          amountDods[val.set_relation] += 1;
        }
      }

      // Finally sort the Dods
      for (var j = 0; j < disciplines.length; j++) {
        if (disciplines[j].dods && disciplines[j].dods.length > 0)
          if (disciplines[j].set_type === "discipline") {
            if (disciplines[j].dods && disciplines[j].dods.length > 0) {
              disciplines[j].dods.sort((a, b) => {
                return parseInt(a.set_value, 10) - parseInt(b.set_value, 10);
              });
            }
          } else break;
      }

      setDiscDods(disciplines);
      setAmountDods(amountDods);
    }
  }

  function handleKey(e) {
    if (!editCardRef.current && !newCard && !useLocal.current) {
      if (e.key === "1") history.push("/product");
      else if (e.key === "2") history.push("/overview-project");
      else if (e.key === "3") history.push("/sprint");
    }
  }

  async function addCard(type, parentId) {
    const data = {
      type: type,
      parent: parentId,
      project: props.auth.getProjId(),
      goal: null,
    };
    var resAdd = {};

    if (useLocal.current) {
      var product = JSON.parse(localStorage.getItem("product"));
      if (!product) product = [];
      var cardIdx = parseInt(localStorage.getItem("cardIdx"), 10);
      if (!cardIdx) cardIdx = 1;
      data.card_id = cardIdx;
      data.card_status = null;
      data.card_images = null;
      data.card_type = type;
      data.card_parent = parentId;
      product.push(data);
      localStorage.setItem("product", JSON.stringify(product));
      localStorage.setItem("cardIdx", cardIdx + 1);
      resAdd.status = 200;
      resAdd.data = [];
      resAdd.data.push({ card_id: data.card_id });
    } else {
      resAdd = await db.request("/auth/addcard", "POST", data);
    }

    if (resAdd.status === 200) {
      emitProductUpdate();
      fetchData(() => {
        setNewCard(resAdd.data[0].card_id);
      });
    }
  }

  async function submitCard(id, name, desc, goal, disc, estimate, estType) {
    if (useLocal.current) {
      const product = JSON.parse(localStorage.getItem("product"));
      var item = product[idToIdx[id]];

      if (item) {
        item.card_name = name;
        item.card_body = desc;
        item.card_estimate = estimate;
        item.card_estimate_type = estType;
        product[idToIdx[id]] = item;
        localStorage.setItem("product", JSON.stringify(product));
        fetchData();
      }
    } else {
      const resEdit = await db.request("/auth/editcard", "POST", {
        id: id,
        name: name ? name : "",
        desc: desc ? desc : "",
        goal: goal ? goal : null,
        disc: disc ? disc : null,
        estimate: estimate ? estimate : null,
        estType: estType ? estType : null,
      });

      if (resEdit.status === 200) {
        emitProductUpdate();
        fetchData();
      }
    }
  }

  async function deleteCard(id) {
    var resDel = {};
    if (useLocal.current) {
      resDel.status = 200;
      var product = JSON.parse(localStorage.getItem("product"));

      const newProduct = product.filter((val) => {
        return val.parent !== id && val.card_id !== id;
      });

      localStorage.setItem("product", JSON.stringify(newProduct));
    } else {
      resDel = await db.request("/auth/deletecard", "POST", {
        id: id,
      });
    }

    if (resDel.status === 200) {
      emitProductUpdate(id);
      setEditCard(null);
      fetchData();
    }
  }

  async function setDod(card, id, idx, max, enableMode) {
    const res = await db.request("/auth/setdod", "POST", {
      cardId: card,
      discId: id,
      dodIdx: idx,
      max: max - 1,
      enableMode: enableMode,
    });

    if (res.status === 200) {
      emitProductUpdate();
      fetchData();
    }
  }

  function clickedEpic(id) {
    setEditCard(id);
  }

  function getDodColor(val) {
    var color = "#fff";
    if (val > 99) color = "#9bff59";
    else if (val > 0) color = "#ffbf59";
    return color;
  }

  function validateName(value) {
    var msg = "";
    const regex = new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-]+$/u
    );

    if (validator.trim(value) === "") msg = "Product need name, yo..";
    else if (!regex.test(value)) msg = "Non-valid characters in name";

    return msg;
  }

  async function createProj(e, txt) {
    if (e) e.preventDefault();
    const data = {
      orgid: props.auth.getOrgId(),
      ownerid: localStorage.getItem("userid"),
      name: txt,
      start: new Date().offWeekend().subtractDays(7).format(),
      end: new Date().offWeekend(true).addMonths(6).format(),
    };

    localStorage.setItem("project", JSON.stringify(data));
    refresh();
  }

  function handleNameLocal(e) {
    var project = JSON.parse(localStorage.getItem("project"));
    if (project) project.name = e.target.value;
    localStorage.setItem("project", JSON.stringify(project));
    setProjectName(e.target.value);
  }

  async function submitProjectName() {
    if (projectNameFullRef.current) {
      const value = projectNameFullRef.current.value();
      if ((!value || value.trim() === "") && cards.length === 0) {
        localStorage.removeItem("project");
        setProjectName(-1);
      } else {
        if (value.trim() !== "" && value !== props.auth.getProjName()) {
          const res = await db.request("/auth/editproj", "POST", {
            nameOnly: true,
            name: value,
            projid: props.auth.getProjId(),
          });
          if (res.status === 200) {
            setProjectName(value);

            props.auth.refreshProjs(-1, () => {
              props.dispatch.fire("projrefresh");
            });
          }
        }
      }
    }
  }

  function emitProductUpdate(deleteId) {
    socket.emit("productupdated", {
      room: props.auth.getOrgId() + "product",
      name: props.auth.getUserName(),
      deleteId: deleteId,
    });
  }

  function productUpdatedEvent(author) {
    console.log("Product update by " + author);
    refresh();
  }

  function cardDeletedEvent(deleteId) {
    if (editCardRef.current === deleteId) {
      setEditCard(null);
    }
    refresh();
  }

  //////////////////////////////////////////
  // Render

  const hasContent = cards && cards.length > 0;
  const firstColWidth = 180;
  const regPillarWidth = 220;
  const maxPillarWidth = 320;
  const growScale = 0.35;
  const overMax =
    regPillarWidth * pillars.length > window.innerWidth - firstColWidth;
  const totalWidth = regPillarWidth * Math.max(pillars.length, 1);
  var smallPillar = overMax ? totalWidth / pillars.length : regPillarWidth;
  var hoveredPillar = overMax ? totalWidth * growScale : maxPillarWidth;

  return (
    <>
      <div style={{ height: "90vh" }}>
        {editCard && (
          <EditCard
            id={editCard}
            data={cards ? cards[idToIdx[editCard]] : null}
            dods={discDods}
            setDod={setDod}
            submitCallback={submitCard}
            closeCallback={() => {
              setEditCard(null);
            }}
            deleteCallback={deleteCard}
            parent={
              cards &&
              editCard &&
              idToIdx[editCard] &&
              cards[idToIdx[editCard]].parent
                ? cards[idToIdx[editCard]].parent
                : []
            }
            disabled={!props.auth.requiresRole("lead") && !useLocal.current}
            goals={[]}
            addCard={addCard}
            useLocal={useLocal.current}
          />
        )}
        {projectName === -1 ? (
          <center>
            <Canvas
              style={{
                height: 240,
                width: 240,
                outline: "none !important",
                marginTop: -36,
              }}
              camera={{ position: [0, 0.1, 15], fov: 10 }}
            >
              <spotLight position={[0, 3, 2]} penumbra={1} />
              <ambientLight intensity={0.6} />
              <Controls />
              <Box hasControls />
            </Canvas>
            <br />
            <div className="fancy" style={{ fontSize: 38, lineHeight: 1.2 }}>
              Hello stranger,
            </div>
            <div style={{ fontSize: 20 }}>what are we building?</div>
            <div style={{ width: 400, marginTop: 10 }}>
              <TextInput
                placeholder="Product name"
                maxlength="20"
                validateCallback={validateName}
                ref={projectNameFullRef}
                keypressCallback={(e) => {
                  if (e.key === "Enter") {
                    createProj(e, projectNameFullRef.current.value());
                  }
                }}
                centered
                autofocus
              />
            </div>
          </center>
        ) : (
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexWrap: "nowrap",
              width: "100%",
              margin: 0,
            }}
          >
            <div
              className="column"
              style={{
                position: "relative",
                width: 170,
                overflow: "visible",
                marginTop: 35,
              }}
            >
              <center>
                <input
                  ref={projectNameFullRef}
                  value={projectName}
                  placeholder={"Name"}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: -30,
                    width: "100%",
                    background: "transparent",
                    border: 0,
                    textAlign: "center",
                    fontSize: 20,
                    color: "#fff",
                    outline: "none",
                    zIndex: 200,
                  }}
                  onChange={handleNameLocal}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") projectNameFullRef.current.blur();
                  }}
                  onBlur={(e) => {
                    submitProjectName();
                  }}
                />
                <Canvas
                  style={{
                    height: 140,
                    width: 140,
                    outline: "none !important",
                    marginTop: -36,
                  }}
                  camera={{ position: [0, 0.1, 15], fov: 10 }}
                >
                  <ambientLight />
                  <spotLight position={[-2, 5, 7]} />
                  <Box epicSelected={hoveringCol} />
                </Canvas>
              </center>
            </div>

            <div className="column">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                  marginTop: 46,
                  width: totalWidth,
                }}
              >
                {!hasContent && !loading && (
                  <div style={{ marginTop: 13, width: 160, marginLeft: -200 }}>
                    <center>
                      <ButtonAdd
                        onClick={() => {
                          addCard("pillar");
                        }}
                        lightMode={true}
                        large={true}
                      />

                      <div
                        style={{
                          marginTop: 8,
                          width: 12,
                          height: 12,
                          borderLeft: "12px solid transparent",
                          borderRight: "12px solid transparent",
                          borderBottom: "12px solid #6e55ad",
                        }}
                      />
                      <div
                        style={{
                          background: "#6e55ad",
                          width: 8,
                          height: 34,
                        }}
                      />

                      <h3 style={{ marginTop: 8, marginLeft: -8 }}>
                        <b>Components</b>
                      </h3>
                      <div
                        style={{
                          width: 300,
                          marginLeft: "-42%",
                          lineHeight: "initial",
                          fontWeight: 200,
                          fontSize: 15,
                          marginTop: -6,
                        }}
                      >
                        Please break {projectName} down into its larger
                        components/categories/pillars/areas.
                      </div>
                    </center>
                  </div>
                )}

                {hasContent &&
                  pillars.map((obj, i) => {
                    const cardName = obj.card_name ? obj.card_name : "";
                    return (
                      <div
                        key={"pillar" + i}
                        style={{
                          position: "relative",
                          margin: 6,
                          maxWidth: maxPillarWidth,
                          width:
                            hoveringCol === i ? hoveredPillar : smallPillar,
                        }}
                        onMouseEnter={(e) => {
                          if (!newCard) setHoveringCol(i);
                        }}
                        onMouseLeave={(e) => {
                          if (!newCard) setHoveringCol(null);
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background:
                              hovering === obj.card_id ? "#dcdcdc" : "#ccc",
                            borderRadius: 4,
                            marginBottom: 3,
                            cursor: "pointer",
                            boxShadow: "0px 3px 4px #00000099",
                            padding: 7,
                            color: "#000",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            setEditCard(obj.card_id);
                          }}
                          onMouseEnter={() => {
                            setHovering(obj.card_id);
                          }}
                          onMouseLeave={() => {
                            setHovering(null);
                          }}
                        >
                          <input
                            value={
                              newCard === obj.card_id ? newCardName : cardName
                            }
                            onChange={(e) => {
                              const val = new RegExp(/[A-Za-z0-9 ]+/i).test(
                                e.target.value
                              );
                              const text = val
                                ? e.target.value.match(/[A-Za-z0-9 ]+/i)[0]
                                : "";
                              setNewCardName(text);
                            }}
                            onBlur={() => {
                              if (newCard && !submitted) {
                                if (
                                  newCardName &&
                                  newCardName.trim().length > 0
                                ) {
                                  submitCard(newCard, newCardName);
                                } else {
                                  deleteCard(newCard);
                                }
                                setNewCard(null);
                              }
                              setNewCardName("");
                              setSubmitted(false);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                if (
                                  newCardName &&
                                  newCardName.trim().length > 0
                                ) {
                                  setSubmitted(true);
                                  submitCard(newCard, newCardName);
                                  addCard("pillar");
                                } else {
                                  deleteCard(newCard);
                                  setNewCard(null);
                                  setNewCardName("");
                                }
                              }
                            }}
                            style={{
                              height: "100%",
                              background: "transparent",
                              overflow: "hidden",
                              border: 0,
                              outline: 0,
                              pointerEvents: "none",
                              fontSize: 13,
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                            autoFocus
                            readOnly={newCard !== obj.card_id}
                          />
                        </div>

                        <center
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {obj.children.map((epic, k) => {
                            var dod = null;
                            var donePercent = 0;
                            if (epic.card_status) {
                              dod = JSON.parse(epic.card_status);
                              var keys = Object.keys(amountDods);
                              var doneDods = 0;
                              var enabledDods = 0;
                              for (var s = 0; s < keys.length; s++) {
                                enabledDods +=
                                  dod[keys[s]] &&
                                  typeof dod[keys[s]] !== "number"
                                    ? dod[keys[s]][1] + 1
                                    : amountDods[keys[s]];

                                if (
                                  dod[keys[s]] &&
                                  typeof dod[keys[s]] !== "number"
                                ) {
                                  if (dod[keys[s]][0] > dod[keys[s]][1]) {
                                    // More dods completed than required for this Epic, cap to max enabled
                                    doneDods += dod[keys[s]][1] + 1;
                                  } else {
                                    doneDods += dod[keys[s]][0] + 1;
                                  }
                                } else {
                                  if (
                                    typeof dod[keys[s]] === "number" &&
                                    dod[keys[s]] >= 0
                                  ) {
                                    doneDods += dod[keys[s]] + 1;
                                  }
                                }
                              }

                              donePercent = Math.round(
                                (doneDods / enabledDods) * 100
                              );
                            }
                            var color = getDodColor(donePercent);
                            const epicName = epic.card_name
                              ? epic.card_name
                              : "";

                            return (
                              <div
                                key={"epic" + k}
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  background: "#fff",
                                  borderRadius: 3,
                                  padding: 3,
                                  margin: 2,
                                  fontSize: 11,
                                  cursor: "pointer",
                                  flexGrow: 1,
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  boxShadow: "0px 3px 4px #00000099",
                                }}
                                onClick={clickedEpic.bind(this, epic.card_id)}
                                onMouseEnter={() => {
                                  setHovering(epic.card_id);
                                }}
                                onMouseLeave={() => {
                                  setHovering(null);
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100%",
                                    left: 0,
                                    width: donePercent.toString() + "%",
                                    background: color,
                                  }}
                                />
                                <>
                                  <div
                                    style={{
                                      marginBottom: 2,
                                      width: 130,
                                      lineHeight: 1,
                                      color: "#000",
                                      zIndex: 1,
                                    }}
                                  >
                                    <input
                                      value={
                                        newCard === epic.card_id
                                          ? newCardName
                                          : epicName
                                      }
                                      onChange={(e) => {
                                        const val = new RegExp(
                                          /[A-Za-z0-9 ]+/i
                                        ).test(e.target.value);
                                        const text = val
                                          ? e.target.value.match(
                                              /[A-Za-z0-9 ]+/i
                                            )[0]
                                          : "";
                                        setNewCardName(text);
                                      }}
                                      onBlur={() => {
                                        if (newCard && !submitted) {
                                          if (
                                            newCardName &&
                                            newCardName.trim().length > 0
                                          ) {
                                            submitCard(newCard, newCardName);
                                          } else {
                                            deleteCard(newCard);
                                          }
                                          setNewCard(null);
                                        }
                                        setNewCardName("");
                                        setSubmitted(false);
                                      }}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          if (
                                            newCardName &&
                                            newCardName.trim().length > 0
                                          ) {
                                            setSubmitted(true);
                                            submitCard(newCard, newCardName);
                                            addCard("epic", obj.card_id);
                                          } else {
                                            deleteCard(newCard);
                                            setNewCard(null);
                                            setNewCardName("");
                                          }
                                        }
                                      }}
                                      style={{
                                        height: "100%",
                                        background: "transparent",
                                        overflow: "hidden",
                                        border: 0,
                                        outline: 0,
                                        pointerEvents: "none",
                                        fontSize: 11,
                                        textAlign: "center",
                                      }}
                                      autoFocus
                                      readOnly={newCard !== epic.card_id}
                                    />
                                  </div>
                                  {/*epic.children.length > 0 && (
                                  <center>
                                    <div
                                      style={{
                                        display: "flex"
                                      }}
                                    >
                                      {epic.children.map((story, j) => {
                                        return (
                                          <div
                                            key={"epic" + j}
                                            style={{
                                              display: "flex",
                                              background: "#8c5806",
                                              borderRadius: "50%",
                                              width: 6,
                                              height: 6,
                                              marginRight: 1,
                                              marginBottom: 1
                                            }}
                                          />
                                        );
                                      })}
                                    </div>
                                  </center>
                                )*/}
                                </>
                              </div>
                            );
                          })}

                          {pillars.length > 1 && epics.length === 0 ? (
                            <center style={{ width: "100%" }}>
                              <div style={{ width: 160 }}>
                                <ButtonAdd
                                  onClick={() => {
                                    addCard("epic", obj.card_id);
                                  }}
                                  lightMode={true}
                                />

                                {i === 0 && (
                                  <>
                                    <div
                                      style={{
                                        marginTop: 8,
                                        width: 12,
                                        height: 12,
                                        borderLeft: "12px solid transparent",
                                        borderRight: "12px solid transparent",
                                        borderBottom: "12px solid #6e55ad",
                                      }}
                                    />
                                    <div
                                      style={{
                                        background: "#6e55ad",
                                        width: 8,
                                        height: 34,
                                      }}
                                    />

                                    <h3
                                      style={{ marginTop: 8, marginLeft: -8 }}
                                    >
                                      <b>Epics</b>
                                    </h3>
                                    <div
                                      style={{
                                        width: 300,
                                        marginLeft: "-42%",
                                        lineHeight: "initial",
                                        fontWeight: 200,
                                        fontSize: 15,
                                        marginTop: -6,
                                      }}
                                    >
                                      A Component has Epics. An Epic in Scrum
                                      methodology takes longer than a sprint to
                                      finish.
                                    </div>
                                  </>
                                )}
                              </div>
                            </center>
                          ) : (
                            <>
                              {hoveringCol === i && pillars.length > 1 && (
                                <center style={{ width: "100%" }}>
                                  <ButtonAdd
                                    onClick={() => {
                                      addCard("epic", obj.card_id);
                                    }}
                                  />
                                </center>
                              )}
                            </>
                          )}
                        </center>
                      </div>
                    );
                  })}
                {hasContent && !loading && (
                  <div style={{ marginLeft: 6, marginTop: 8 }}>
                    <ButtonAdd
                      onClick={() => {
                        addCard("pillar");
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {useLocal.current && projectName !== -1 && (
          <div
            className="tinytext"
            style={{
              display: "flex",
              position: "fixed",
              bottom: 30,
              left: 10,
              cursor: "pointer",
            }}
            onClick={() => history.push("/signup")}
          >
            <div
              className="fas fa-exclamation-triangle"
              style={{ fontSize: 15, marginRight: 4 }}
            />{" "}
            <b>LOCAL MODE</b>
            <div className="tinytext" style={{ marginLeft: 3 }}>
              - Click to store in cloud
            </div>
          </div>
        )}
      </div>
    </>
  );
}
