import React, { Fragment } from "react";
import db from "../utils/db";
import Popup from "../components/Popup";
import UserOverview from "../pages/UserOverview";
import UserProjects from "../pages/UserProjects";
import UserSalary from "../pages/UserSalary";
import StatCard from "../components/StatCard";
import { toast } from "react-toastify";
import history from "../utils/history";
import image from "../imgs/img_guy_avatar.png";
import cloudinary from "cloudinary-core";

var cl = new cloudinary.Cloudinary({ cloud_name: "hcnr7jxqk" });

class User extends React.Component {
  constructor(props) {
    super(props);

    var varsFail = false;
    var vars = this.props.location.search.slice(1).split("&");
    var userid = parseInt(vars[0], 10);
    var page = vars[1] ? vars[1] : "alloc";
    varsFail = !userid || typeof userid !== "number";

    if (varsFail || !this.props.location.search) {
      userid = this.props.auth.getUserId();
      page = "alloc";
    }

    this.state = {
      confirmDelete: false,
      userid: userid,
      name: "",
      page: page,
      editing: "",
      file: null,
      showUpload: false,
      image: null,
      hoverImg: false,
      uploadProgress: 100,
      imageFail: false,
      stats: [],
    };

    this.getUser = this.getUser.bind(this);
    this.getStats = this.getStats.bind(this);
    this.onFileSubmit = this.onFileSubmit.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.deletePic = this.deletePic.bind(this);
    this.editKey = this.editKey.bind(this);
    this.handleKey = this.handleKey.bind(this);

    this.getUser();
    this.getStats(userid);

    this.file = null;
    this.fileFunc = (e) => {
      this.file = e;
    };

    this.props.dispatch.addListener("userupdate", this.getUser);
    this.props.dispatch.addListener("inspectuser", () => {
      this.setState({ userid: this.props.auth.getUserId() }, () => {
        this.getUser();
        this.getStats(this.state.userid);
      });
    });
  }

  componentWillUnmount() {
    this.props.dispatch.removeListener("userupdate");
    this.props.dispatch.removeListener("inspectuser");
  }

  UNSAFE_componentWillReceiveProps(val) {
    if (
      val.location.search.slice(1).split("&")[0] !== this.state.userid ||
      val.location.search.slice(1).split("&")[1] !== this.state.page
    ) {
      var userid = val.location.search.slice(1).split("&")[0];
      var page = val.location.search.slice(1).split("&")[1];
      if (!userid) userid = this.props.auth.getUserId();
      if (!page) page = "overview";

      this.setState({ userid: userid, page: page });
    }
  }

  async getUser() {
    const res = await db.request("/auth/getuser", "POST", {
      userid: this.state.userid,
    });

    if (res && res.status === 200) {
      var fullName = res.data.user_firstname;
      if (res.data.user_lastname) fullName += " " + res.data.user_lastname;
      this.setState({
        image: res.data.user_pic,
        name: fullName,
      });
    }
  }

  async getStats(userid) {
    const resStats = await db.request("/auth/getstats", "POST", {
      userid: userid ? userid : this.props.userid,
    });

    if (resStats && resStats.status === 200) {
      var data = resStats.data;
      var arr = [];
      arr["vacation"] = 30;
      arr["sick"] = 0;
      arr["vab"] = 0;
      arr["parental"] = 0;

      for (var i = 0; i < data.length; i++) {
        switch (data[i].dayz_status) {
          case "vacation":
            if (
              new Date(data[i].dayz_date).isAfterDay(
                new Date(new Date().getFullYear(), 3, 1)
              )
            ) {
              arr[data[i].dayz_status] -= 1;
            }
            break;
          default:
            if (
              new Date(data[i].dayz_date).getFullYear() >=
              new Date().getFullYear()
            )
              arr[data[i].dayz_status] += 1;
        }
      }
      this.setState({ stats: arr });
    } else {
      toast.error("Error getting stats for user. Please try again later!");
    }
  }

  async onFileSubmit(e) {
    var url = `https://api.cloudinary.com/v1_1/hcnr7jxqk/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener(
      "progress",
      function (e) {
        var progress = Math.round((e.loaded * 100.0) / e.total);
        this.setState({ uploadProgress: progress });
      }.bind(this)
    );

    xhr.onreadystatechange = async function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        var response = JSON.parse(xhr.responseText);
        const resUpload = await db.request("/auth/setprofilepic", "POST", {
          userid: this.state.userid,
          imgid: response.public_id,
          previmgid: this.state.image,
        });

        if (resUpload.status === 200) {
          this.setState({
            file: null,
            image: response.public_id,
          });
        }
      }
    }.bind(this);

    fd.append("upload_preset", "ircdcrqm");
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append("file", this.state.file);
    xhr.send(fd);
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  async deletePic() {
    const resDel = await db.request("/auth/setprofilepic", "POST", {
      userid: this.state.userid,
      imgid: null,
      previmgid: this.state.image,
    });

    if (resDel.status === 200) {
      this.setState({
        file: null,
        image: null,
      });
    }
  }

  editKey(key) {
    var to = this.state.editing === key ? "" : key;
    this.setState({ editing: to });
    if (to) document.addEventListener("keydown", this.handleKey, false);
    else document.removeEventListener("keydown", this.handleKey, false);
  }

  handleKey(e) {
    if (e.keyCode === 27) this.editKey(""); // Escape
  }

  render() {
    var isMe = parseInt(this.state.userid, 10) === this.props.auth.getUserId();
    var page = null;

    switch (this.state.page) {
      case "salary":
        page = (
          <UserSalary
            userid={this.state.userid}
            auth={this.props.auth}
            name={this.state.name}
          />
        );
        break;
      case "overview":
        page = (
          <UserOverview
            userid={this.state.userid}
            isMe={isMe}
            cloudinary={cl}
            name={this.state.name}
            dispatch={this.props.dispatch}
            auth={this.props.auth}
          />
        );
        break;
      case "alloc":
      default:
        page = (
          <UserProjects auth={this.props.auth} userid={this.state.userid} />
        );
        break;
    }

    return (
      <div>
        <div>
          {this.state.showUpload && (
            <Popup
              title="Upload image"
              closeCallback={() => this.setState({ showUpload: false })}
              submitCallback={() => {
                this.setState({ showUpload: false });
                this.onFileSubmit();
              }}
              negativeAction="Nevermind"
              positiveAction="YES, DO IT"
            >
              <form>
                <fieldset disabled={false}>
                  <input
                    ref={this.fileFunc}
                    type="file"
                    name="myImage"
                    accept="image/jpeg, image/png"
                    onChange={this.onChangeFile}
                  />
                </fieldset>
              </form>
              <br />
            </Popup>
          )}

          <div
            style={{
              display: "block",
              minHeight: 40,
              marginBottom: 20,
              marginLeft: -3,
            }}
          >
            <button
              className="btn btn-light"
              style={{ display: "inline-block" }}
              onClick={() => {
                history.push("/overview-project");
              }}
            >
              <i className="fas fa-angle-left" style={{ fontSize: 17 }} />
            </button>

            <div
              style={{
                position: "relative",
                width: 50,
                height: 50,
                borderRadius: "50%",
                overflow: "hidden",
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: 8,
                boxShadow: "1px 3px 3px 0px #222",
              }}
              onClick={() => {
                if (!this.state.image) this.setState({ showUpload: true });
              }}
              onMouseEnter={() => {
                this.setState({ hoverImg: true });
              }}
              onMouseLeave={() => {
                this.setState({ hoverImg: false });
              }}
            >
              {this.state.uploadProgress < 100 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    background: "#fff",
                    opacity: 0.5,
                    top: 0,
                    left: 0,
                    width: 50,
                    height: 50,
                  }}
                >
                  {this.state.uploadProgress + "%"}
                </div>
              )}
              {(this.props.auth.requiresRole("owner") || isMe) &&
                this.state.hoverImg && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      background: "#fff",
                      opacity: 0.7,
                      top: 0,
                      left: 0,
                      width: 50,
                      height: 50,
                      fontSize: 13,
                    }}
                  >
                    <i
                      className="fas fa-pencil-alt"
                      style={{
                        color: "#222",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({ showUpload: true });
                      }}
                    />
                    {this.state.image && (
                      <i
                        className="fas fa-trash-alt"
                        style={{
                          color: "#222",
                          cursor: "pointer",
                          paddingLeft: 6,
                        }}
                        onClick={this.deletePic}
                      />
                    )}
                  </div>
                )}
              <img
                src={
                  this.state.image && !this.state.imageFail
                    ? cl.url(this.state.image)
                    : image
                }
                alt="Card cap"
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                }}
                onError={() => {
                  this.setState({ imageFail: true });
                }}
              />
            </div>

            <h2
              style={{
                display: "inline-block",
                marginLeft: 8,
                marginRight: 20,
                height: "100%",
                verticalAlign: "middle",
              }}
            >
              <b style={{ verticalAlign: "middle" }}>{this.state.name}</b>{" "}
            </h2>

            {/*<div style={{ display: "inline-flex", verticalAlign: "middle" }}>
              <StatCard
                title="Vacation"
                data={this.state.stats["vacation"] + " days left"}
                type="vacation"
              />
              <StatCard
                title="Sick"
                data={this.state.stats["sick"] + " days"}
                type="sick"
              />
              <StatCard
                title="VAB"
                data={this.state.stats["vab"] + " days"}
                type="vab"
              />{" "}
              <StatCard
                title="Parental"
                data={this.state.stats["parental"] + " days"}
                type="parental"
              />
            </div>*/}
          </div>

          <div
            style={{
              display: "inline-block",
              marginLeft: 10,
              marginBottom: 6,
              width: "100%",
            }}
          >
            <button
              style={{ marginRight: 8 }}
              className={
                this.state.page === "alloc"
                  ? "btn btn-sm btn-peepz active"
                  : "btn btn-sm btn-peepz"
              }
              onClick={() => {
                history.push(
                  this.props.location.pathname +
                    "?" +
                    this.props.location.search.slice(1).split("&")[0] +
                    "&" +
                    "alloc"
                );
              }}
            >
              <div className="fas fa-home" style={{ paddingRight: 10 }} />
              Projects
            </button>
            <button
              style={{ marginRight: 8 }}
              className={
                this.state.page === "overview"
                  ? "btn btn-sm btn-peepz active"
                  : "btn btn-sm btn-peepz"
              }
              onClick={() => {
                history.push(
                  this.props.location.pathname +
                    "?" +
                    this.props.location.search.slice(1).split("&")[0] +
                    "&" +
                    "overview"
                );
              }}
            >
              <div className="fas fa-list" style={{ paddingRight: 10 }} />
              Info
            </button>
            {this.props.auth.requiresRole("owner") && (
              <Fragment>
                <button
                  className={
                    this.state.page === "salary"
                      ? "btn btn-sm btn-peepz active"
                      : "btn btn-sm btn-peepz"
                  }
                  onClick={() => {
                    history.push(
                      this.props.location.pathname +
                        "?" +
                        this.props.location.search.slice(1).split("&")[0] +
                        "&" +
                        "salary"
                    );
                  }}
                >
                  <div
                    className="fas fa-dollar-sign"
                    style={{ paddingRight: 10 }}
                  />
                  Salary
                </button>
              </Fragment>
            )}
          </div>

          <div style={{ paddingTop: 20 }}>{page}</div>
        </div>
      </div>
    );
  }
}

export default User;
