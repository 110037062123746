import React, { useState, useEffect, useRef } from "react";
import history from "../utils/history";
import db from "../utils/db";
import Popup from "./Popup";
import TextInput from "./TextInput";
import DatePicker from "./DatePicker";
import moment from "moment";
import { toast } from "react-toastify";

export default function Header(props) {
  const [orgs, setOrgs] = useState([]);
  const [orgSelected, setOrgSelected] = useState(1);
  const [projs, setProjs] = useState(null);
  const [projSelected, setProjSelected] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [startDateIsSet, setStartDateIsSet] = useState(true);
  const [recruitmentEnabled, setRecruitmentEnabled] = useState(false);
  const [milestoneEnabled, setMilestoneEnabled] = useState(false);
  const [cardsEnabled, setCardsEnabled] = useState(false);
  const [fullCompany, setFullCompany] = useState(false);
  const [topRightShow, setTopRightShow] = useState(false);
  const [expandedProjs, setExpandedProjs] = useState(false);
  const [page, setPage] = useState(window.location.pathname);

  const topRightMenu = useRef();
  const newProjName = useRef();
  const endDatePicker = useRef();
  const startDatePicker = useRef();
  const initStartDatePicker = useRef();

  useEffect(() => {
    // Fetch projects - set selected project, if any
    var _fullCompany = localStorage.getItem("fullcompany");
    var selected = _fullCompany
      ? -1
      : parseInt(localStorage.getItem("proj"), 10);

    if (!_fullCompany) {
      props.auth.refreshProjs(
        selected !== undefined && selected !== null ? selected : 0
      );
    }
    const projs = props.auth.getProjs();

    setProjs(projs);
    setProjSelected(selected);
    setFullCompany(_fullCompany);
    setExpandedProjs(projs.length < 8);

    props.dispatch.addListener("orgchange", getOrgs);
    props.dispatch.addListener("projrefresh", getProjs);
    props.dispatch.addListener("projchange", externalSelectProj);
    props.dispatch.addListener("userupdate", getUser);
    props.dispatch.addListener("loggedin", refresh);

    refresh();

    return () => {
      props.dispatch.removeListener("orgchange");
      props.dispatch.removeListener("projchange");
      props.dispatch.removeListener("projrefresh");
      props.dispatch.removeListener("userupdate");
      props.dispatch.removeListener("loggedin", getStartDateIsSet);
    };
  }, []);

  function gotoPage(pg) {
    setPage(pg);
    history.push(pg);
  }

  function logout() {
    props.auth.logout();
    setProjs(null);
    setOrgs(null);
    setProjSelected(null);
  }

  function refresh() {
    getOrgs();
    getUser();
    getStartDateIsSet();
    getProjs();
    getRecruitmentEnabled();
    getMilestoneEnabled();
    getCardsEnabled();
  }

  function selectOrg(index) {
    // If previous was status "invited" then re-fetch orgs data
    if (orgs[orgSelected].status === "invited") {
      props.auth.refreshOrgs(index);
    } else {
      if (orgSelected !== index) {
        props.auth.setOrgs(index, [], () => {
          props.auth.refreshProjs(0, () => {
            getProjs();
            props.dispatch.fire("orgchange");
          });
        });
      }
    }
  }

  function getOrgs() {
    if (localStorage.getItem("orgs-all"))
      setOrgs(JSON.parse(localStorage.getItem("orgs-all")));
    if (localStorage.getItem("org"))
      setOrgSelected(parseInt(localStorage.getItem("org"), 10));
  }

  function selectProj(index, _fullCompany) {
    index = _fullCompany ? -1 : parseInt(index, 10);

    if (
      _fullCompany !== fullCompany ||
      (index >= 0 && index < projs.length && projSelected !== index)
    ) {
      props.auth.selectProj(index);
      props.dispatch.fire("projchange");

      setFullCompany(_fullCompany);
      setProjSelected(index);

      if (_fullCompany !== fullCompany) {
        gotoPage(_fullCompany ? "/overview-company" : window.location.pathname);
      }
    }
    setExpandedProjs(projs.length < 8);
  }

  function getUser() {
    setFirstname(localStorage.getItem("firstname"));
    setLastname(localStorage.getItem("lastname"));
  }

  function showEditProj() {
    setShowPopup("edit");
  }

  async function createProj(e, start, end) {
    if (e) e.preventDefault();
    const data = {
      orgid: props.auth.getOrgId(),
      ownerid: localStorage.getItem("userid"),
      name: newProjName.current.value(),
      start: moment(start).format("DD MM YYYY"),
      end: moment(end).format("DD MM YYYY"),
    };

    const res = await db.request("/auth/newproj", "POST", data);

    if (res.status === 200) {
      props.auth.refreshProjs(res.selected, () => {
        getProjs();
        gotoPage("/overview-project");
        props.dispatch.fire("projchange");
      });
    }
  }

  async function editProj(e, start, end) {
    if (e) e.preventDefault();

    // Only reach db if different data
    if (
      props.auth.getProjName() !== newProjName.current.value() ||
      !moment(props.auth.getProjStart()).isSame(moment(start)) ||
      !moment(props.auth.getProjEnd()).isSame(moment(end))
    ) {
      const data = {
        projid: props.auth.getProjId(),
        name: newProjName.current.value(),
        prevStart: moment(props.auth.getProjStart()).format("DD MM YYYY"),
        prevEnd: moment(props.auth.getProjEnd()).format("DD MM YYYY"),
        start: moment(start).format("DD MM YYYY"),
        end: moment(end).format("DD MM YYYY"),
      };
      const res = await db.request("/auth/editproj", "POST", data);

      if (res.status === 200) {
        props.auth.refreshProjs(-1, () => {
          getProjs();
          props.dispatch.fire("projrefresh");
        });
      } else {
        toast.error("Unknown error, the project could not be edited.");
      }
    }
  }

  async function deleteProj() {
    const res = await db.request("/auth/deleteproj", "POST", {
      userid: localStorage.getItem("userid"),
      orgid: props.auth.getOrgId(),
      projectid: props.auth.getProjId(),
    });

    if (res.status === 200) {
      toast.success("The project is a goner.");

      var projs = JSON.parse(localStorage.getItem("projs-all"));
      var proj = parseInt(localStorage.getItem("proj"), 10);

      var select = -1;
      if (projs.length > 1) {
        select = proj > 0 ? proj - 1 : 0;
      }

      props.auth.refreshProjs(select, getProjs);
      setShowPopup(false);

      if (select === -1) {
        selectProj(-1, true);
      }
    } else {
      toast.error("Unknown error, the project could not be erased.");
    }
  }

  function getProjs() {
    setProjs(props.auth.getProjs());
    setProjSelected(props.auth.getProjIndex());
    // props.dispatch.fire("projchange");
  }

  function validateProjName(val, isEdit = false) {
    var res = "";
    if (!val || (val && val.trim() === "")) {
      res = "Can't be empty";
    } else if (!isEdit) {
      if (localStorage.getItem("projs-all")) {
        var projs = JSON.parse(localStorage.getItem("projs-all"));
        var dupName = projs.some((obj) => {
          return obj.name === val.trim();
        });
        if (dupName) res = "Project name already in use";
      }
    }
    return res;
  }

  function submitProjName(e) {
    if (showPopup === "new") {
      newProjName.current.submit(newProjName.current.value());
      if (validateProjName(newProjName.current.value()) === "") {
        createProj(
          e,
          startDatePicker.current.getDate(),
          endDatePicker.current.getDate()
        );
        gotoPage("/overview-project");
        setShowPopup(false);
      }
    } else if (showPopup === "edit") {
      newProjName.current.submit(newProjName.current.value());
      if (validateProjName(newProjName.current.value(), true) === "") {
        editProj(
          e,
          startDatePicker.current.getDate(),
          endDatePicker.current.getDate()
        );
        gotoPage("/overview-project");
        setShowPopup(false);
      }
    }
  }

  function dateRangeChanged() {
    var state = startDatePicker.current.state;
    endDatePicker.current.setMinDate(
      moment(
        state.year + "-" + state.month + "-" + state.day,
        "YYYY-MMM-DD"
      ).add(1, "week")
    );
  }

  async function getStartDateIsSet() {
    if (props.auth.isAuthenticated()) {
      const res = await db.request("/auth/getsetting", "POST", {
        orgid: props.auth.getOrgId(),
        type: "onboarding",
        name: "setOrgStartdate",
      });

      setStartDateIsSet(res && res.value === "done");
    }
  }

  async function getRecruitmentEnabled() {
    if (props.auth.isAuthenticated()) {
      const res = await db.request("/auth/getsetting", "POST", {
        orgid: props.auth.getOrgId(),
        type: "recruitment",
      });

      setRecruitmentEnabled(
        res && res.status === 200 && res.value === "enabled"
      );
    }
  }

  async function getMilestoneEnabled() {
    if (props.auth.isAuthenticated()) {
      const res = await db.request("/auth/getsetting", "POST", {
        orgid: props.auth.getOrgId(),
        type: "milestone",
      });

      setMilestoneEnabled(
        res &&
          (res.status === 500 ||
            (res.status === 200 && res.value === "enabled"))
      );
    }
  }

  async function getCardsEnabled() {
    if (props.auth.isAuthenticated()) {
      const res = await db.request("/auth/getsetting", "POST", {
        orgid: props.auth.getOrgId(),
        type: "cards",
      });

      setCardsEnabled(
        res &&
          (res.status === 500 ||
            (res.status === 200 && res.value === "enabled"))
      );
    }
  }

  async function setOrgStartdate() {
    const res = await db.request("/auth/updateorgstart", "POST", {
      orgid: props.auth.getOrgId(),
      newstart: initStartDatePicker.current.getDate(),
    });

    if (res.status === 200) {
      await db.request("/auth/setsetting", "POST", {
        orgid: props.auth.getOrgId(),
        type: "onboarding",
        name: "setOrgStartdate",
        date: new Date().format(),
        value: "done",
      });

      props.auth.refreshOrgs(0, getOrgs);

      setStartDateIsSet(true);
      toast.success("Date saved!");
    }
  }

  async function reportTime(type, date) {
    var tmpDate = moment(date).format("DD MM YYYY");
    var data = [];
    data.push({
      date: tmpDate,
      userid: props.auth.getUserId(),
      type: "avail",
      status: type,
    });
    const res = await db.request("/auth/setdays", "POST", {
      data: data,
      orgid: props.auth.getOrgId(),
      projectid: -1,
    });

    if (res.status === 200) {
      props.dispatch.fire("projrefresh");
      props.dispatch.fire("orgrefresh");

      toast.success(
        "Successfully reported " + moment(date).format("YYYY-MM-DD")
      );
    }
  }

  function handleMenuClick(e) {
    if (
      !projs ||
      e.key === "Escape" ||
      (topRightMenu.current && !topRightMenu.current.contains(e.target))
    ) {
      document.removeEventListener("mousedown", handleMenuClick);
      document.removeEventListener("keydown", handleMenuClick);
      setTopRightShow(false);
    }
  }

  function externalSelectProj() {
    setFullCompany(false);
    setProjSelected(props.auth.getProjIndex());
  }

  var hideIn = ["/login", "/signup"];
  var menu = "";
  var topRight = "";
  var projName = projs && projs[projSelected] ? projs[projSelected].name : "-";
  var menuRowStyle = { paddingTop: 8, paddingBottom: 8 };
  var sickIconStyle = {
    paddingRight: 5,
    fontSize: "0.9rem",
    color: "#d66464",
  };
  var vabIconStyle = {
    paddingRight: 5,
    fontSize: "0.9rem",
    color: "#d69964",
  };
  var today = new Date();

  if (props.auth.isAuthenticated()) {
    menu = (
      <div className="mr-auto">
        {false && (
          <div className="btn-group" style={{ paddingRight: 8 }}>
            <button
              className={
                page === "/longterm"
                  ? "btn btn-peepz btn-sm active"
                  : "btn btn-peepz btn-sm"
              }
              onClick={() => {
                gotoPage("/longterm");
              }}
            >
              <i className="fas fa-glasses" style={{ paddingRight: "5px" }} />
              Longterm
            </button>
          </div>
        )}

        {milestoneEnabled && (
          <div className="btn-group" style={{ paddingRight: 8 }}>
            <button
              className={
                page === "/playbook"
                  ? "btn btn-peepz btn-sm active"
                  : "btn btn-peepz btn-sm"
              }
              onClick={() => {
                gotoPage("/playbook");
              }}
            >
              <i className="fas fa-flag" style={{ paddingRight: "5px" }} />
              Milestone
            </button>
          </div>
        )}

        {!fullCompany && cardsEnabled && (
          <div className="btn-group" style={{ paddingRight: 8 }}>
            <button
              className={
                page === "/sprint"
                  ? "btn btn-peepz btn-sm active"
                  : "btn btn-peepz btn-sm"
              }
              onClick={() => {
                gotoPage("/sprint");
              }}
            >
              <i className="fas fa-tasks" style={{ paddingRight: "5px" }} />
              Cards
            </button>
          </div>
        )}

        <div className="btn-group" style={{ paddingRight: 8 }}>
          <button
            className={
              page === "/overview-project"
                ? "btn btn-peepz btn-sm active"
                : "btn btn-peepz btn-sm"
            }
            onClick={() => {
              gotoPage(fullCompany ? "/overview-company" : "/overview-project");
            }}
          >
            <i className="fas fa-users" style={{ paddingRight: "5px" }} />

            {!fullCompany ? "People" : "Roadmap"}
          </button>
        </div>

        {fullCompany && (
          <div className="btn-group" style={{ paddingLeft: 8 }}>
            <button
              className="btn btn-peepz btn-sm"
              onClick={() => {
                gotoPage("/peepz");
              }}
              style={{ paddingLeft: 10 }}
            >
              <i className="fas fa-users" style={{ paddingRight: "5px" }} />
              People
            </button>
          </div>
        )}

        {!fullCompany && (
          <div className="btn-group" style={{ paddingLeft: 30 }}>
            <button
              className="btn btn-info btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                padding: "4px 10px",
                fontSize: "0.8rem",
                boxShadow: "1px 1px 3px 1px #1b1b1b",
              }}
            >
              Report
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {today.getDay() === 6 && (
                <>
                  <button
                    className="dropdown-item"
                    style={menuRowStyle}
                    onClick={() => reportTime("sick", moment().add(2, "days"))}
                  >
                    <i className="fas fa-procedures" style={sickIconStyle} />
                    Sick on Monday
                  </button>
                  <button
                    className="dropdown-item"
                    style={menuRowStyle}
                    onClick={() => reportTime("vab", moment().add(2, "days"))}
                  >
                    <i className="fas fa-child" style={vabIconStyle} />
                    VAB on Monday
                  </button>
                </>
              )}
              {!today.isWeekend() && (
                <button
                  className="dropdown-item"
                  style={menuRowStyle}
                  onClick={() => reportTime("sick", moment())}
                >
                  <i className="fas fa-procedures" style={sickIconStyle} />
                  Sick today
                </button>
              )}
              {today.getDay() < 5 && (
                <button
                  className="dropdown-item"
                  style={menuRowStyle}
                  onClick={() => reportTime("sick", moment().add(1, "days"))}
                >
                  <i className="fas fa-procedures" style={sickIconStyle} />
                  Sick tomorrow
                </button>
              )}
              {!today.isWeekend() && (
                <button
                  className="dropdown-item"
                  style={menuRowStyle}
                  onClick={() => reportTime("vab", moment())}
                >
                  <i className="fas fa-child" style={vabIconStyle} />
                  VAB today
                </button>
              )}
              {today.getDay() < 5 && (
                <button
                  className="dropdown-item"
                  style={menuRowStyle}
                  onClick={() => reportTime("vab", moment().add(1, "days"))}
                >
                  <i className="fas fa-child" style={vabIconStyle} />
                  VAB tomorrow
                </button>
              )}
            </div>
          </div>
        )}

        {props.auth.requiresRole("lead") && recruitmentEnabled && (
          <div
            style={{
              fontSize: 11,
              marginLeft: 70,
              display: "inline",
              paddingRight: 8,
            }}
          >
            Managers:
          </div>
        )}

        {props.auth.requiresRole("lead") && recruitmentEnabled && (
          <div className="btn-group">
            <button
              className={
                page === "/recruit"
                  ? "btn btn-peepz btn-sm active"
                  : "btn btn-peepz btn-sm"
              }
              onClick={() => {
                gotoPage("/recruit");
              }}
              style={{ marginRight: 8 }}
            >
              <i className="fas fa-users" style={{ paddingRight: "5px" }} />
              Recruit
            </button>
          </div>
        )}
      </div>
    );

    var role = props.auth.getRole();
    topRight = (
      <div style={{ display: "inline-flex" }}>
        <div
          style={{
            lineHeight: projName !== "-" ? "0.8" : "0.9",
            textAlign: "right",
            paddingRight: "8px",
            paddingTop: projName !== "-" ? 2 : 7,
            verticalAlign: "middle",
          }}
        >
          <span style={{ fontSize: "0.8em", display: "block" }}>
            <strong>{props.auth.getOrgName()}</strong>
          </span>

          {projName !== "-" && (
            <>
              <span style={{ fontSize: "0.8em" }}>
                {"Project: " + projName}
              </span>
            </>
          )}
        </div>
        <div
          ref={topRightMenu}
          className="btn-group"
          style={{ display: "inline-block" }}
        >
          <button
            type="button"
            className="btn btn-peepz btn-sm"
            style={{ padding: "4px 8px", color: "#000" }}
            onClick={() => {
              document.addEventListener("mousedown", handleMenuClick);
              document.addEventListener("keydown", handleMenuClick);
              setTopRightShow(true);
            }}
          >
            <div className="fas fa-bars" />
          </button>
          {topRightShow && (
            <div
              className="dropdown-menu-right"
              style={{
                position: "absolute",
                top: 30,
                right: 0,
                background: "white",
                zIndex: 6000,
                boxShadow: "2px 2px 4px #666",
                borderRadius: 6,
                padding: "10px 0px",
              }}
              onBlur={() => {
                setTopRightShow(false);
              }}
            >
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  setTopRightShow(false);

                  props.dispatch.fire("inspectuser");
                  gotoPage("/user");
                }}
              >
                <i
                  className="fas fa-user"
                  style={{ fontSize: "0.9rem", paddingRight: "3px" }}
                />{" "}
                {firstname} {lastname ? lastname : ""}
                <br />
                <div
                  style={{
                    fontSize: "0.7rem",
                    marginLeft: 20,
                    marginTop: "-20",
                    color: "#000",
                  }}
                >
                  {role.charAt(0).toUpperCase() + role.slice(1)}
                </div>
              </button>

              <div className="dropdown-divider" />

              <div className="dropdown-header">Project</div>

              {projs &&
                projs.map((val, i) => {
                  if (!expandedProjs && i !== parseInt(projSelected, 10))
                    return null;
                  return (
                    <button
                      className={
                        i === parseInt(projSelected, 10)
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      type="button"
                      onClick={() => {
                        selectProj(i);
                        setTopRightShow(false);
                      }}
                      key={i}
                      block="true"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <i
                        className="fas fa-gamepad"
                        style={{ paddingRight: "5px" }}
                      />
                      {val.name}{" "}
                      {val.status === "invited" ? (
                        <span className="badge badge-success">New</span>
                      ) : (
                        ""
                      )}
                      {i === parseInt(projSelected, 10) && (
                        <i
                          className="fas fa-cog"
                          style={{ float: "right", marginLeft: 18 }}
                          onClick={showEditProj}
                        />
                      )}
                    </button>
                  );
                })}
              {props.auth.requiresRole("janitor") && expandedProjs && (
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => {
                    setShowPopup("new");
                    setTopRightShow(false);
                  }}
                >
                  <i
                    className="fas fa-plus"
                    style={{ paddingRight: "10px", color: "green" }}
                  />
                  Create New
                </button>
              )}
              {!expandedProjs && (
                <center>
                  <button
                    className="btn btn-link"
                    style={{ fontSize: 12 }}
                    onClickCapture={(e) => {
                      setExpandedProjs(true);
                    }}
                  >
                    more projects...
                  </button>
                </center>
              )}
              {projs &&
                projs.length === 0 &&
                props.auth.getRole() === "talent" && (
                  <>
                    <center>No projects</center>
                  </>
                )}

              <div className="dropdown-divider" />
              <div className="dropdown-header">Organizations</div>

              {orgs &&
                orgs.map((val, i) => (
                  <button
                    className={
                      i === orgSelected
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    type="button"
                    onClick={() => {
                      selectOrg(i);
                      setTopRightShow(false);
                    }}
                    key={i}
                    block="true"
                  >
                    <i
                      className="fas fa-building"
                      style={{ paddingRight: "5px" }}
                    />
                    {val.name}{" "}
                    {val.status === "invited" ? (
                      <span className="badge badge-success">New</span>
                    ) : (
                      ""
                    )}
                  </button>
                ))}

              {props.auth.requiresRole("lead") && (
                <>
                  <div className="dropdown-divider" />
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      gotoPage("/admin");
                      setTopRightShow(false);
                    }}
                  >
                    <i className="fas fa-cog" style={{ paddingRight: "5px" }} />
                    Settings
                  </button>
                </>
              )}

              {props.auth.requiresRole("lead") && (
                <>
                  <div className="dropdown-divider" />
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      gotoPage("/analytics");
                      setTopRightShow(false);
                    }}
                  >
                    <i
                      className="fas fa-chart-line"
                      style={{ paddingRight: "5px" }}
                    />
                    Analytics
                  </button>
                </>
              )}

              <div className="dropdown-divider" />
              <button
                className="dropdown-item"
                type="button"
                onClick={() => logout()}
              >
                <i
                  className="fas fa-sign-out-alt"
                  style={{ paddingRight: "5px" }}
                />
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else if (!hideIn.includes(history.location.pathname)) {
    menu = <ul className="navbar-nav mr-auto" />;
    topRight = (
      <ul className="navbar-nav">
        <li
          className="nav-item"
          eventkey={2}
          onClick={() => gotoPage("/login")}
        >
          <button className="btn btn-peepz btn-sm">Login</button>
        </li>
      </ul>
    );
  }

  return (
    <>
      {showPopup && (
        <Popup
          title={showPopup === "new" ? "New project" : "Edit project"}
          submitCallback={(e) => {
            submitProjName(e);
          }}
          closeCallback={() => {
            setShowPopup(false);
          }}
          deleteCallback={showPopup === "new" ? null : deleteProj}
          negativeAction="cancel"
          positiveAction={showPopup === "new" ? "CREATE" : "UPDATE"}
        >
          <TextInput
            validateCallback={validateProjName}
            ref={newProjName}
            placeholder="Blair Witch"
            maxlength="50"
            autofocus
            requireSubmit={true}
            default={showPopup === "edit" ? props.auth.getProjName() : ""}
            keypressCallback={(e) => {
              if (e.key === "Enter") {
                submitProjName(e);
              } else if (e.key === "Escape") {
                setShowPopup(false);
              }
            }}
          />

          <DatePicker
            title="Start"
            default={
              showPopup === "edit"
                ? moment(props.auth.getProjStart())
                : moment()
            }
            minDate={moment(props.auth.getOrgCreation())}
            maxDate={moment().add(2, "years")}
            changeCb={dateRangeChanged}
            ref={startDatePicker}
          />
          <DatePicker
            title="End"
            default={
              showPopup === "edit"
                ? moment(props.auth.getProjEnd())
                : moment().add(7, "months")
            }
            minDate={moment(props.auth.getOrgCreation())}
            maxDate={
              showPopup === "edit"
                ? moment(props.auth.getProjEnd()).add(3, "years")
                : moment().add(5, "years")
            }
            changeCb={dateRangeChanged}
            ref={endDatePicker}
          />
          <br />
        </Popup>
      )}
      {!startDateIsSet && props.auth.isAuthenticated() && (
        <Popup
          title="Is this correct?"
          submitCallback={(e) => {
            setOrgStartdate();
          }}
          positiveAction="SET STARTDATE"
        >
          <div style={{ color: "#000" }}>
            Is this the somewhat{" "}
            <b>correct time when {props.auth.getOrgName()} was founded?</b>
            <br />
            <br />
          </div>
          <DatePicker
            default={moment(props.auth.getOrgCreation())}
            minDate={moment("2000-01-01")}
            maxDate={moment(new Date().offWeekend(true).format(true))}
            ref={initStartDatePicker}
          />
          <br />
        </Popup>
      )}
      <div style={{ overflowX: "visible" }}>
        <nav
          className="nav navbar navbar-light bg-faded"
          style={{ padding: 10 }}
        >
          {menu}
          {topRight}
        </nav>

        {!props.auth.isAuthenticated() && (
          <div
            className="logofont"
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              fontSize: "1.5rem",
              textShadow: "1px 1px 3px #222",
              display: "inline-block",
              padding: "0px 10px",
              borderRadius: 10,
              cursor: "pointer",
              height: 30,
              width: 80,
            }}
            onClick={() => {
              gotoPage("/home");
            }}
          >
            Peepz.io
          </div>
        )}
      </div>
    </>
  );
}
