import React from "react";
import db from "../utils/db";
import moment from "moment";
import Helper from "../utils/helper";
require("../utils/extends");

class OverviewCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // this.fetchData();
  }

  componentWillUnmount() {}

  shouldComponentUpdate(nextProps, nextState) {
    return Helper.stateChanged(this.state, nextState);
  }

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  async fetchData(refresh = false) {
    // On e.g. org change to org without projs, switch to /peepz

    var initState = this.getInitState();

    var years = [];
    var year = initState.projStart.getFullYear();
    while (year <= initState.projEnd.getFullYear()) {
      years.push(year);
      year++;
    }

    // Get public holidays
    const resHolidays = await db.request("/auth/getholidays", "POST", {
      orgid: this.props.auth.getOrgId(),
      years: years
    });

    // Get all goals
    const resGoals = await db.request("/auth/getgoals", "POST", {
      projid: this.props.auth.getProjId()
    });

    const peepzData = await db.request("/auth/getdays", "POST", {
      email: localStorage.getItem("email"),
      projid: this.props.auth.getProjId(),
      orgid: this.props.auth.getOrgId(),
      from: initState.rangeFrom - 1,
      to: initState.rangeTo
    });

    if (peepzData.status === 200) {
      // ORGANIZER LOOP
      var mainArr = [];
      var rowIdx = 0;
      var currUserId =
        peepzData.data.length > 0 ? peepzData.data[0].user_id : -1;
      var maxName = 0;
      var joined = false;
      var pointer = 0;
      var joinsLeaves = new Array(initState.daysToEnd).fill(false);
      var status = new Array(initState.daysToEnd).fill(null);
      var dayid = new Array(initState.daysToEnd).fill(-1);
      var date = new Array(initState.daysToEnd).fill(null);
      var ribbons = new Array(initState.daysToEnd).fill(null);
      var holidays = new Array(initState.daysToEnd).fill(null);
      var ribbonStack = [];
      var myUID = this.props.auth.getUserId();
      var myIdx = -1;

      // Holidays
      for (var p = 0; p < resHolidays.data.length; p++) {
        var holiDate = new Date(resHolidays.data[p].date);
        if (
          holiDate.isSameOrAfterDay(initState.projStart) &&
          holiDate.isSameOrBeforeDay(initState.projEnd) &&
          resHolidays.data[p].enabled
        ) {
          var daysFromProjStart = holiDate.daysBetween(initState.projStart);
          holidays[daysFromProjStart] = resHolidays.data[p];
        }
      }

      for (var i = 0; i < peepzData.data.length; i++) {
        var row = peepzData.data[i];
        var isLast = i === peepzData.data.length - 1; // Check if last post for user, switch row/user

        // First fill straight fwd arrays
        status[row.dayz_fromstart - initState.rangeFrom] = row.dayz_status;
        dayid[row.dayz_fromstart - initState.rangeFrom] = row.dayz_id;
        date[row.dayz_fromstart - initState.rangeFrom] = row.dayz_date;
        if (myIdx === -1 && row.user_id === myUID) myIdx = rowIdx;
        if (!joined && row.dayz_status === "join") {
          joined = true;
          pointer = row.dayz_fromstart;
        } else if (joined && row.dayz_status === "left") {
          joined = false;
          joinsLeaves.fill(
            true,
            pointer - initState.rangeFrom,
            row.dayz_fromstart - initState.rangeFrom + 1
          );
        }

        if (
          (peepzData.data[i + 1] &&
            peepzData.data[i + 1].user_id !== currUserId) ||
          isLast
        ) {
          var final = {
            role: row.attr_value,
            userid: row.user_id,
            firstname: row.user_firstname,
            lastname: row.user_lastname,
            email: row.user_email,
            consultant: row.user_consultant,
            joinsLeaves: joinsLeaves,
            status: status,
            dayid: dayid,
            date: date
          };
          mainArr.push(final);

          if (row.user_firstname.length > maxName)
            maxName = row.user_firstname.length;

          if (!isLast) {
            joinsLeaves = new Array(initState.daysToEnd).fill(false);
            status = new Array(initState.daysToEnd).fill(null);
            dayid = new Array(initState.daysToEnd).fill(-1);
            date = new Array(initState.daysToEnd).fill(null);

            currUserId = peepzData.data[i + 1].user_id;
            joined = false;
            rowIdx++;
          }
        }
      }

      // Add ribbons data
      if (resGoals.status === 200 && resGoals.data.length > 0) {
        for (var k = 0; k < resGoals.data.length; k++) {
          var goalDate = new Date(resGoals.data[k].goal_date);
          var daysToGoal = initState.projStart.daysBetween(goalDate);

          if (!ribbons[daysToGoal]) ribbons[daysToGoal] = [];

          var ribbonY = 0;
          for (var x = 0; x < ribbonStack.length; x++) {
            var stackedRibbon = ribbonStack[x];
            if (
              daysToGoal > stackedRibbon[0] &&
              stackedRibbon[1] > daysToGoal
            ) {
              while (ribbonY === stackedRibbon[2]) {
                ribbonY++;
              }
            }
          }

          ribbons[daysToGoal].push({
            id: resGoals.data[k].goal_id,
            type: "goal",
            name: resGoals.data[k].goal_name,
            desc: resGoals.data[k].goal_desc,
            date: resGoals.data[k].goal_date,
            row: ribbonY,
            height: this.state.monthHeight * 1.01
          });

          // Remember where prev ribbons end to stack nicely
          ribbonStack.push([
            daysToGoal,
            daysToGoal +
              (resGoals.data[k].goal_name.length * 17) / this.state.boxWidth,
            ribbonY
          ]);
        }
      }

      // Calculate max height of ribbon row
      var rows = 0;
      var ribbonsHeight = 0;
      for (var y = 0; y < ribbonStack.length; y++) {
        if (ribbonStack[y][2] > rows) {
          rows = ribbonStack[y][2];
        }
      }
      ribbonsHeight = rows * (22 + 4 * this.state.boxSpaceVert) + 36;

      // Create days array for reference
      var days = [];
      var weekDays = [];
      for (
        var d = new Date(initState.projStart.getTime());
        d <= initState.projEnd;
        d.setDate(d.getDate() + 1)
      ) {
        var newD = new Date(d);
        days.push(newD);
        weekDays.push(newD.getDay());
      }

      this.setState({
        nameCol: maxName * 11,
        newdata: mainArr,
        days: days,
        weekDays: weekDays,
        holidays: holidays,
        ribbons: ribbons,
        ribbonsHeight: ribbonsHeight,
        ribbonsRaw: resGoals,
        myIndex: myIdx,
        loading: false,
        projname: initState.projname,
        orgcreated: initState.orgCreated,
        startdate: initState.projStart,
        enddate: initState.projEnd,
        today: initState.today,
        daysToEnd: initState.daysToEnd,
        rangeFrom: initState.rangeFrom,
        rangeTo: initState.rangeTo,
        winHeight:
          mainArr.length * (this.state.boxWidth + this.state.boxSpaceVert) + 250
      });
    } else {
      this.setState({
        newdata: [],
        days: [],
        loading: false,
        projname: initState.projname
      });
    }
    if (!refresh && this.timegrid.current)
      this.timegrid.current.resetViewPosition();
  }

  // Render  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  render() {
    var orgname = this.props.auth.getOrgName();
    var greets = [
      "You know, sleep is important too..",
      "Top of the morning, " + orgname + "!",
      "G'day, " + orgname,
      "Good evening, " + orgname
    ];

    var hour = moment().hour();
    if (hour >= 18) hour -= 1;
    if (hour >= 24) hour = 23;

    return (
      <div style={{ height: this.state.winHeight }}>
        <div>
          <h2
            style={{
              paddingLeft: this.props.auth.requiresRole("janitor") ? 55 : 10,
              paddingTop: 4
            }}
          >
            <b>{greets[Math.floor(hour / 6)]}</b>
            {this.state.modify && this.props.auth.requiresRole("janitor") && (
              <div
                className="fas fa-cog"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  verticalAlign: "middle",
                  marginTop: -4,
                  paddingLeft: 9,
                  fontSize: "1.7rem",
                  color: "#999",
                  zIndex: 50
                }}
                onClick={() => this.props.dispatch.fire("editproject")}
              />
            )}
          </h2>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

export default OverviewCompany;
