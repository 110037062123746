import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./utils/privateroute";
import Auth from "./utils/auth";
import Dispatch from "./utils/dispatch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import css from "./css/stylejs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import history from "./utils/history";
import { PopupProvider } from "./components/Popup";

// Components
import Header from "./components/Header";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Peepz from "./pages/Peepz";
import NewUser from "./pages/NewUser";
import SignUp from "./pages/SignUp";
import ResetPass from "./pages/ResetPass";
import Administration from "./pages/Administration";
import OverviewProject from "./pages/OverviewProject";
import OverviewCompany from "./pages/OverviewCompany";
import User from "./pages/User";
import Sprint from "./pages/Sprint";
import Product from "./pages/Product";
import Velocity from "./pages/Velocity";
import Recruit from "./pages/Recruit";
import VoteRecruit from "./pages/VoteRecruit";
import Playbook from "./pages/Playbook";
import Analytics from "./pages/Analytics";
import Longterm from "./pages/Longterm";

const dispatch = new Dispatch();
const auth = new Auth(dispatch);

// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update");
//   whyDidYouUpdate(React);
// }

export default function App(props) {
  useEffect(() => {
    resetLastRefresh();
    history.listen(resetLastRefresh);
    document.addEventListener("visibilitychange", onFocus);
    return () => {
      document.removeEventListener("visibilitychange", onFocus);
    };
  }, []);

  const resetLastRefresh = useCallback(() => {
    localStorage.setItem("lastRefresh", new Date().getTime());
  }, []);

  const onFocus = useCallback(() => {
    const lastRefresh = localStorage.getItem("lastRefresh");
    if (lastRefresh && document.visibilityState === "visible") {
      const stamp = new Date(parseInt(lastRefresh, 10));
      if (new Date().getTime() - stamp > 3600000) {
        // Refresh app after 30 mins
        window.location.reload();
        resetLastRefresh();
      }
    }
  }, [resetLastRefresh]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <PopupProvider>
          <ToastContainer
            className={css.base}
            toastClassName={css.toastClass}
            bodyClassName={css.bodyClass}
            progressClassName={css.progressClass}
            position={toast.POSITION.TOP_CENTER}
            closeButton={false}
            autoClose={3000}
          />
          <>
            <Header auth={auth} dispatch={dispatch} />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Home dispatch={dispatch} {...props} />}
              />
              <Route
                path="/home"
                render={(props) => <Home dispatch={dispatch} {...props} />}
              />
              <Route
                path="/login"
                render={(props) => (
                  <Login auth={auth} dispatch={dispatch} {...props} />
                )}
              />
              <Route
                path="/signup"
                render={(props) => (
                  <SignUp auth={auth} dispatch={dispatch} {...props} />
                )}
              />
              <Route
                path="/newuser/:key"
                render={(props) => (
                  <NewUser auth={auth} dispatch={dispatch} {...props} />
                )}
              />
              <Route
                path="/resetpass"
                render={(props) => (
                  <ResetPass auth={auth} dispatch={dispatch} {...props} />
                )}
              />
              <Route
                path="/try"
                render={(props) => (
                  <Product auth={auth} dispatch={dispatch} {...props} />
                )}
              />
              <Route
                path="/voterecruit/:email/:score/:voter"
                render={(props) => <VoteRecruit auth={auth} {...props} />}
              />
              <PrivateRoute
                path="/peepz"
                target={Peepz}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/user"
                target={User}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/overview-project"
                target={OverviewProject}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/sprint/:task"
                target={Sprint}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/sprint"
                target={Sprint}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/overview-company"
                target={OverviewCompany}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor"]}
              />
              <PrivateRoute
                path="/admin/:page"
                target={Administration}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/admin"
                target={Administration}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/velocity"
                target={Velocity}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/recruit"
                target={Recruit}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/playbook"
                target={Playbook}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/analytics"
                target={Analytics}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <PrivateRoute
                path="/longterm"
                target={Longterm}
                auth={auth}
                dispatch={dispatch}
                roles={["janitor", "owner"]}
              />
              <Redirect to="/playbook" />
            </Switch>
          </>
        </PopupProvider>
      </DndProvider>
    </>
  );
}
