import React, { useState } from "react";

export default function Button(props) {
  const [hovering, setHovering] = useState(false);
  const [pressed, setPressed] = useState(false);

  var sizeAdj = 0;
  if (props.small) sizeAdj -= 2;
  else if (props.large) sizeAdj += 2;

  const base = {
    borderRadius: 6,
    padding: "6px 12px",
    zIndex: 2,
    fontSize: 12 + sizeAdj,
    userSelect: "none",
    transition: "background 0.15s, transform 0s",
    transform: "scale(" + (pressed ? "0.95" : "1") + ")",
    cursor: "pointer",
  };

  const customStyle = props.style ? props.style : {};

  const styles = {};
  styles.default = {
    fontWeight: 300,
    background: hovering ? "#7b5ec4" : "#674ea7",
  };
  styles.outline = {
    fontWeight: 500,
    background: hovering ? "#ffffff22" : "transparent",
    border: "1px solid " + (hovering ? "#fff" : "#ffffff88"),
  };
  styles.red = {
    fontWeight: 300,
    background: hovering ? "#f00" : "#d00",
  };
  styles.green = {
    fontWeight: 300,
    background: hovering ? "#0b0" : "#090",
  };

  const selStyle = props.type ? styles[props.type] : styles.default;

  return (
    <div
      style={
        props.disabled || !props.onClick
          ? {
              opacity: "0.4",
              pointerEvents: "none",
              ...base,
              ...selStyle,
              ...customStyle,
            }
          : { ...base, ...selStyle, ...customStyle }
      }
      onClick={(e) => {
        if (!props.disabled && props.onClick) {
          e.preventDefault();
          e.stopPropagation();
          props.onClick(e);
        }
      }}
      onMouseOver={(e) => setHovering(true)}
      onMouseOut={(e) => setHovering(false)}
      onMouseLeave={(e) => {
        setHovering(false);
        setPressed(false);
      }}
      onMouseDown={(e) => setPressed(true)}
      onMouseUp={(e) => setPressed(false)}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          fontWeight: props.desc ? "800" : "initial",
        }}
      >
        {props.fontawesome && (
          <i className={props.fontawesome} style={{ marginRight: 7 }} />
        )}
        {props.value && props.value}
      </div>

      {props.desc && (
        <center>
          <div
            style={{
              fontSize: 10,
              width: "80%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1 /* number of lines to show */,
              WebkitBoxOrient: "vertical",
              color: "#b59cf7",
            }}
          >
            {props.desc}
          </div>
        </center>
      )}
    </div>
  );
}
