import React from "react";
import db from "../utils/db";
import moment from "moment";
import { toast } from "react-toastify";
import TextInput from "../components/TextInput";
import Popup from "../components/Popup";
import Pill from "../components/Pill";
import DatePicker from "../components/DatePicker";
import update from "immutability-helper";

class Administration extends React.Component {
  constructor(props) {
    super(props);

    this.custColors = [
      "#efa51c",
      "#af1cef",
      "#000",
      "#42b842",
      "#1dbcc2",
      "#515be8",
      "#ef1cda",
      "#dd0000",
    ];

    this.state = {
      peepz: [],
      admins: [],
      holidays: [],
      disciplines: [],
      dods: [],
      confirmDeleteAll: false,
      confirmDeleteOrg: false,
      importing: false,
      newstartdate: null,
      confirmNewStartDate: false,
      addHoliday: false,
      addDiscipline: false,
      editDiscipline: null,
      addDod: null,
      editDod: null,
      favrocollections: [],
      favroCollSel: null,
      favropillars: [],
      recruitmentEnabled: false,
      milestoneEnabled: false,
      cardsEnabled: false,
      github: null,
      gotoPage: props.computedMatch.params.page,
    };

    this.getNonActiveUsers = this.getNonActiveUsers.bind(this);
    this.getAdminUsers = this.getAdminUsers.bind(this);
    this.removeInvite = this.removeInvite.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
    this.importTimetastic = this.importTimetastic.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.calculateDeltaDays = this.calculateDeltaDays.bind(this);
    this.getHolidays = this.getHolidays.bind(this);
    this.getDiscAndDods = this.getDiscAndDods.bind(this);
    this.orgStartDateChanged = this.orgStartDateChanged.bind(this);
    this.getMaxOrgCreation = this.getMaxOrgCreation.bind(this);
    this.removeHoliday = this.removeHoliday.bind(this);
    this.removeDiscipline = this.removeDiscipline.bind(this);
    this.removeDod = this.removeDod.bind(this);
    this.moveDod = this.moveDod.bind(this);
    this.importFavro = this.importFavro.bind(this);
    this.importFavroColls = this.importFavroColls.bind(this);
    this.importFavroPillars = this.importFavroPillars.bind(this);
    this.setRecruitment = this.setRecruitment.bind(this);
    this.setMilestone = this.setMilestone.bind(this);
    this.setCards = this.setCards.bind(this);

    this.tokenInput = React.createRef();
    this.startDatePicker = React.createRef();
    this.newHolidayDatePicker = React.createRef();
    this.newHolidayName = React.createRef();
    this.newDiscipline = React.createRef();
    this.favroUser = React.createRef();
    this.favroPass = React.createRef();
    this.tokenGithubRef = React.createRef();
    this.repoGithubRef = React.createRef();
  }

  componentDidMount() {
    this.getAdminUsers();
    this.getNonActiveUsers();
    this.getHolidays();
    this.getDiscAndDods();
    this.getRecruitment();
    this.getMilestone();
    this.getCards();
    this.getGithub();
  }

  async getAdminUsers() {
    const users = await db.request("/auth/getusers", "POST", {
      org: this.props.auth.getOrgId(),
      group: "admins",
    });
    if (users && users.rows !== undefined) {
      var state = this.state;
      state.admins = users.rows;
      this.setState(state);
    }
  }

  async getNonActiveUsers() {
    const users = await db.request("/auth/getusers", "POST", {
      org: this.props.auth.getOrgId(),
      group: "nonactive",
    });
    if (users && users.rows !== undefined) {
      var state = this.state;
      state.peepz = users.rows;
      this.setState(state);
    }
  }

  async getHolidays() {
    // Get public holidays
    const resHolidays = await db.request("/auth/getholidays", "POST", {
      orgid: this.props.auth.getOrgId(),
    });

    if (resHolidays && resHolidays.status === 200) {
      var holidays = [];
      var data = resHolidays.data;
      for (var j = 0; j < data.length; j++) {
        if (data[j].type === "offday") continue;
        holidays.push({
          id: data[j].id,
          date: new Date(data[j].date),
          name: data[j].name,
          enabled: data[j].enabled,
        });
      }
      this.setState({
        holidays: holidays,
      });
    }
  }

  async getDiscAndDods() {
    const discs = await db.request("/auth/getdisciplines", "POST", {
      org: this.props.auth.getOrgId(),
      dods: true,
    });

    if (discs && discs.status === 200) {
      var disciplines = [];
      var idToIdx = {};
      for (var i = 0; i < discs.data.length; i++) {
        var val = discs.data[i];
        idToIdx[val.set_id] = i;
        disciplines.push(val);
        if (val.set_type === "discipline") {
          disciplines[i].dods = [];
        } else {
          disciplines[idToIdx[val.set_relation]].dods.push(val);
        }
      }

      // Finally sort the Dods
      for (var j = 0; j < disciplines.length; j++) {
        if (disciplines[j].set_type === "discipline") {
          if (disciplines[j].dods && disciplines[j].dods.length > 0) {
            disciplines[j].dods.sort((a, b) => {
              return parseInt(a.set_value, 10) - parseInt(b.set_value, 10);
            });
          }
        } else break;
      }
      this.setState({ disciplines: disciplines });
    }
  }

  async setRecruitment(e) {
    this.setState({ recruitmentEnabled: e.target.checked });
    await db.request("/auth/setsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "recruitment",
      value: e.target.checked ? "enabled" : "disabled",
    });

    this.props.dispatch.fire("loggedin");
  }

  async getRecruitment() {
    const recruitment = await db.request("/auth/getsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "recruitment",
    });
    this.setState({
      recruitmentEnabled:
        recruitment.status === 200 && recruitment.value === "enabled",
    });
  }

  async setMilestone(e) {
    this.setState({ milestoneEnabled: e.target.checked });
    await db.request("/auth/setsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "milestone",
      value: e.target.checked ? "enabled" : "disabled",
    });

    this.props.dispatch.fire("loggedin");
  }

  async getMilestone() {
    const milestone = await db.request("/auth/getsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "milestone",
    });
    this.setState({
      milestoneEnabled:
        milestone.status === 500 ||
        (milestone.status === 200 && milestone.value === "enabled"),
    });
  }

  async setCards(e) {
    this.setState({ cardsEnabled: e.target.checked });
    await db.request("/auth/setsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "cards",
      value: e.target.checked ? "enabled" : "disabled",
    });

    this.props.dispatch.fire("loggedin");
  }

  async getCards() {
    const cards = await db.request("/auth/getsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "cards",
    });
    this.setState({
      cardsEnabled:
        cards.status === 500 ||
        (cards.status === 200 && cards.value === "enabled"),
    });
  }

  async setHoliday(name, day, enabled) {
    await db.request("/auth/setsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "holiday",
      name: name,
      date: day,
      value: enabled ? "enabled" : "disabled",
    });
    this.getHolidays();
  }

  async getGithub() {
    const github = await db.request("/auth/getsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "github",
    });

    this.setState(
      {
        github: github.status === 200 ? JSON.parse(github.value) : null,
      },
      () => {
        if (this.state.github && this.tokenGithubRef.current) {
          this.tokenGithubRef.current.setValue(this.state.github.token);
          this.repoGithubRef.current.setValue(this.state.github.repo);
        }
      }
    );
  }

  async setGithub(token, repo) {
    await db.request("/auth/setsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "github",
      date: new Date().format(),
      value: JSON.stringify({ token, repo }),
    });
    this.getGithub();
  }

  async removeGithub(token, repo) {
    const res = await db.request("/auth/deletesetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "github",
    });

    if (res.status === 200) {
      toast.success("GitHub and Peepz is no more.");
      this.getGithub();
      this.tokenGithubRef.current.setValue("");
      this.repoGithubRef.current.setValue("");
    } else {
      toast.error("Could not delete connection to GitHub. Try later?");
    }
  }

  async removeInvite(userId) {
    await db.request("/auth/deleteuser", "POST", {
      id: userId,
      orgid: this.props.auth.getOrgId(),
    });
    this.getNonActiveUsers();
  }

  async resendInvite(email) {
    await db.request("/auth/inviteuser", "POST", {
      email: email,
      org: this.props.auth.getOrgId(),
      inviter: localStorage.getItem("firstname"),
    });
    toast.success("The invitation was successfully re-sent!");
  }

  validateInput(val) {
    var res = "";
    if (!val || (val && val.trim() === "")) {
      res = "Cannot be empty";
    }
    return res;
  }

  async importTimetastic() {
    // First get date (off weekends)
    this.setState({ importing: true });

    var mDate = moment();
    // Ensure date is not weekend
    if (mDate.day() === 0) mDate.add(1, "day");
    else if (mDate.day() === 6) mDate.add(2, "day");
    var date = mDate.format("DD MM YYYY");

    const res = await db.request("/auth/importtimetastic", "POST", {
      orgid: this.props.auth.getOrgId(),
      start: date,
      token: this.tokenInput.current.value().trim(),
    });

    if (res.status === 200) {
      toast.success("Import successfull! W00t w00t");
      this.props.auth.setOrgCreation(res.newOrgCreated);
      this.setState({ importing: false });
    } else {
      this.setState({ importing: false });
      toast.error(
        "Something went wrong. If you're certain the token is correct then it's a system failure :("
      );
    }
  }

  async deleteAll() {
    const res = await db.request("/auth/deleteuser", "POST", {
      orgid: this.props.auth.getOrgId(),
      id: this.props.auth.getUserId(),
      deleteall: true,
    });

    if (res.status === 200) {
      toast.success("It has been done. All but yourself is gone.");
      this.getNonActiveUsers();
    } else if (res.status === 505) {
      toast.warning("One owner spared. There has to be at least one.");
    } else {
      toast.error("Something went wrong. Try again soon.");
    }
    this.setState({ confirmDeleteAll: false });
  }

  async changeAccess(e, userId, firstname) {
    var val = e.target.value;
    const res = await db.request("/auth/setaccess", "POST", {
      orgid: this.props.auth.getOrgId(),
      id: userId,
      level: e.target.value,
    });

    if (res.status === 200) {
      toast.success("Done! " + firstname + " is now " + val);
    } else if (res.status === 505) {
      toast.error("No go. You need at least one owner.");
    } else {
      toast.error("Oh no! Something went wrong.. Try again soon.");
    }
    this.getAdminUsers();
  }

  async changeStatus(e, userId, firstname) {
    var val = e.target.value;
    const res = await db.request("/auth/setuserstatus", "POST", {
      orgid: this.props.auth.getOrgId(),
      id: userId,
      status: e.target.value,
    });

    if (res.status === 200) {
      toast.success("Done! " + firstname + " is now " + val);
    } else if (res.status === 505) {
      toast.error("No go. You need at least one active owner.");
    } else {
      toast.error("Oh no! Something went wrong.. Try again soon.");
    }
    this.getAdminUsers();
  }

  async calculateDeltaDays() {
    await db.request("/auth/calcdeltadays", "POST", {
      orgid: this.props.auth.getOrgId(),
      start: this.props.auth.getOrgCreation(),
    });
  }

  getMaxOrgCreation() {
    var projs = this.props.auth.getProjs();
    var minDate = new Date();
    for (var i = 0; i < projs.length; i++) {
      var curr = new Date(projs[i].start);
      if (curr < minDate) {
        minDate = new Date(curr.getTime());
      }
    }
    return minDate;
  }

  orgStartDateChanged() {
    var date = !new Date(this.props.auth.getOrgCreation()).isSameDay(
      new Date(this.startDatePicker.current.getDate())
    )
      ? new Date(this.startDatePicker.current.getDate())
      : null;
    this.setState({
      newstartdate: date,
    });
  }

  async updateOrgStartDate() {
    const res = await db.request("/auth/updateorgstart", "POST", {
      orgid: this.props.auth.getOrgId(),
      newstart: this.state.newstartdate,
    });

    if (res.status !== 200) {
      toast.error(
        "Something went wrong. The date could not be changed right now."
      );
    } else {
      toast.success("The beginning of time has been re-written!");
      this.props.auth.refreshOrgs();
      this.props.auth.refreshProjs();
    }
    this.setState({
      newstartdate: null,
      confirmNewStartDate: false,
    });
  }

  async createHoliday(name) {
    const res = await db.request("/auth/addholiday", "POST", {
      orgid: this.props.auth.getOrgId(),
      name: name,
      date: this.newHolidayDatePicker.current.getDateString(),
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The holiday could not be created.");
    } else {
      toast.success(
        "Got it. Holiday created for " +
          this.newHolidayDatePicker.current.getDateString()
      );
      this.setState({ addHoliday: false });
      this.getHolidays();
    }
  }

  async removeHoliday(idx) {
    const res = await db.request("/auth/deleteholiday", "POST", {
      id: this.state.holidays[idx].id,
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The holiday could not be deleted.");
    } else {
      toast.success("Holiday " + this.state.holidays[idx].name + " is no more");
      this.getHolidays();
    }
  }

  async createDiscipline(name) {
    const res = await db.request("/auth/adddiscipline", "POST", {
      orgid: this.props.auth.getOrgId(),
      name: name,
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The role could not be created.");
    } else {
      toast.success("New ranks instated! Behold the mighty crew of " + name);
      this.setState({ addDiscipline: false });
      this.getDiscAndDods();
    }
  }

  async updateDiscipline(id, name, color) {
    await db.request("/auth/updatediscipline", "POST", {
      id: id,
      name: name,
      color: color,
    });
    this.setState({ editDiscipline: null });
    this.getDiscAndDods();
  }

  async removeDiscipline(idx) {
    const res = await db.request("/auth/deletediscipline", "POST", {
      id: this.state.disciplines[idx].set_id,
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The role could not be deleted.");
    } else {
      toast.success(
        "The Role " + this.state.disciplines[idx].name + " is no more"
      );
      this.getDiscAndDods();
    }
  }

  async createDod(name, desc, discIdx, position) {
    const res = await db.request("/auth/adddod", "POST", {
      orgid: this.props.auth.getOrgId(),
      name: name,
      desc: desc,
      parent: this.state.disciplines[discIdx].set_id,
      position: position,
    });

    if (res.status !== 200) {
      toast.error("Oops! The definition could not be created.");
    } else {
      this.setState({ addDod: null, editDod: null });
      this.getDiscAndDods();
    }
  }

  async updateDod(id, name, desc) {
    const res = await db.request("/auth/updatedod", "POST", {
      setid: id,
      name: name,
      desc: desc,
    });

    if (res.status !== 200) {
      toast.error("Oh-oh! It couldn't be updated.");
    } else {
      this.setState({ editDod: null, addDod: null });
      this.getDiscAndDods();
    }
  }

  async removeDod(id) {
    const res = await db.request("/auth/deletedod", "POST", {
      id: id,
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The role could not be deleted.");
    } else {
      toast.success("Definition is a goner");
      this.setState({ editDod: null, addDod: null });
      this.getDiscAndDods();
    }
  }

  async moveDod(dragIndex, hoverIndex, group) {
    const dragDod = this.state.disciplines[group].dods[dragIndex];
    let newState = update(this.state, {
      disciplines: {
        [group]: {
          dods: {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragDod],
            ],
          },
        },
      },
    });
    this.setState(newState);

    const res = await db.request("/auth/updatedod", "POST", {
      parent: dragDod.set_relation,
      moved: dragDod.set_id,
      oldOrder: dragIndex + 1,
      newOrder: hoverIndex + 1,
    });

    if (res.status !== 200) {
      toast.error("Something went wrong. The definition could not be updated.");
    }
  }

  async importFavroColls() {
    const res = await db.request("/auth/importfavrocolls", "POST", {
      user: this.favroUser.current.value(),
      pass: this.favroPass.current.value(),
    });
    this.setState({
      favrocollections: res.data.filter((val) => {
        return val.name.match(/tier/i);
      }),
    });
  }

  async importFavroPillars(collectionId) {
    const res = await db.request("/auth/importfavropillars", "POST", {
      org: collectionId,
      user: this.favroUser.current.value(),
      pass: this.favroPass.current.value(),
    });

    this.setState({ favropillars: res.data });
  }

  async importFavro(widgetId) {
    const res = await db.request("/auth/importfavro", "POST", {
      widget: widgetId,
      proj: this.props.auth.getProjId(),
      user: this.favroUser.current.value(),
      pass: this.favroPass.current.value(),
    });

    if (res.status === 200) {
      toast.success("You successfully imported the board from Favro! <3");
      this.setState({
        favrocollections: [],
        favroCollSel: null,
        favropillars: [],
      });
    }
  }

  render() {
    var adminList = this.state.admins.map((user, i) => (
      <tr key={i} style={{ opacity: user.mem_status === "inactive" ? 0.4 : 1 }}>
        <td>{user.user_email}</td>
        <td>
          <center>
            {!this.props.auth.requiresRole("owner") &&
            user.mem_role === "owner" ? (
              <div>owner</div>
            ) : (
              <select
                className="form-control form-control-sm"
                key={i}
                defaultValue={user.mem_role}
                onChange={(e) =>
                  this.changeAccess(e, user.user_id, user.user_firstname)
                }
              >
                {this.props.auth.requiresRole("owner") && (
                  <option>owner</option>
                )}
                {this.props.auth.requiresRole("janitor") && (
                  <option>janitor</option>
                )}
                {this.props.auth.requiresRole("lead") && <option>lead</option>}
                <option>talent</option>
              </select>
            )}
          </center>
        </td>
        <td>
          <center>
            {!this.props.auth.requiresRole("owner") &&
            user.mem_role === "owner" ? (
              <div>owner</div>
            ) : (
              <select
                className="form-control form-control-sm"
                key={"status_" + i}
                defaultValue={user.mem_status}
                onChange={(e) =>
                  this.changeStatus(e, user.user_id, user.user_firstname)
                }
              >
                <option>active</option>
                <option>inactive</option>
              </select>
            )}
          </center>
        </td>
      </tr>
    ));

    var userList = this.state.peepz.map((user, i) => (
      <tr key={i}>
        <td>{user.user_email}</td>
        <td>{user.mem_status}</td>
        <td>
          <center>
            <div style={{ whiteSpace: "nowrap" }}>
              <div
                onClick={() => this.resendInvite(user.user_email)}
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              >
                <i className="fas fa-redo" />
              </div>
              <div
                onClick={() => this.removeInvite(user.user_id)}
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                }}
              >
                <i className="far fa-trash-alt" />
              </div>
            </div>
          </center>
        </td>
      </tr>
    ));

    var adminPill = this.props.auth.requiresRole("janitor") ? (
      <a
        className="nav-link"
        id="v-pills-home-tab"
        data-toggle="pill"
        href="#v-pills-home"
        role="tab"
        aria-controls="v-pills-home"
        aria-selected="false"
      >
        Permissions
      </a>
    ) : (
      <div />
    );

    // Organize public holidays
    var holidayList = [];
    for (var i = this.state.holidays.length - 1; i >= 0; i--) {
      var day = this.state.holidays[i];
      holidayList.push(
        <tr key={i}>
          <td>{day.date.format(true)}</td>
          <td>{day.name}</td>
          <td>
            <center>
              {this.props.auth.requiresRole("owner") ? (
                <button
                  className={
                    day.enabled
                      ? "btn btn-success btn-sm"
                      : "btn btn-danger btn-sm"
                  }
                  onClick={this.setHoliday.bind(
                    this,
                    day.name,
                    day.date.format(),
                    !day.enabled
                  )}
                >
                  {day.enabled ? "Yes" : "No"}
                </button>
              ) : (
                <div>owner</div>
              )}
            </center>
          </td>
          <td>
            <div
              className="fas fa-trash-alt"
              style={{ cursor: "pointer", color: "#888" }}
              onClick={this.removeHoliday.bind(null, i)}
              idx={i}
            />
          </td>
        </tr>
      );
    }

    // Definition of Done
    var dodList = this.state.disciplines.map((disc, i) => {
      if (disc.set_type !== "discipline") return null;

      return (
        <div key={i} style={{ marginBottom: 20 }}>
          <div style={{ display: "inline-flex", marginBottom: 0 }}>
            <b>{disc.set_name}</b>
          </div>

          <div
            style={{
              display: "inline-flex",
              marginBottom: 0,
              marginLeft: 4,
              verticalAlign: "text-top",
            }}
          >
            {disc.dods &&
              disc.dods.map((dod, j) => {
                return (
                  <Pill
                    key={"dod" + dod.set_id}
                    id={dod.set_id}
                    index={j}
                    group={i}
                    name={dod.set_name}
                    onClick={() => {
                      const needRefresh =
                        this.state.editDod && this.state.editDod.row === i;
                      this.setState({ editDod: { ...dod, row: i } });
                      if (needRefresh) {
                        this["newDod" + i].setValue(
                          dod.set_name ? dod.set_name : ""
                        );
                        this["newDodDesc" + i].setValue(
                          dod.set_description ? dod.set_description : ""
                        );
                      }
                    }}
                    movePill={this.moveDod}
                  />
                );
              })}
          </div>

          <div style={{ display: "inline-flex", marginBottom: 0 }}>
            {this.props.auth.requiresRole("lead") && this.state.addDod !== i && (
              <button
                className="btn btn-link btn-sm"
                style={{ fontSize: 12, marginBottom: 0, paddingBottom: 0 }}
                onClick={() => {
                  this.setState({
                    addDod: i,
                  });
                }}
              >
                add
              </button>
            )}
          </div>

          <div>
            {this.props.auth.requiresRole("lead") &&
              (this.state.addDod === i ||
                (this.state.editDod && this.state.editDod.row === i)) && (
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    margin: "14px 0px",
                  }}
                >
                  <TextInput
                    ref={(e) => {
                      this["newDod" + i] = e;
                    }}
                    validateCallback={(val) => {
                      return false;
                    }}
                    placeholder="Definition of done"
                    maxlength="20"
                    default={
                      this.state.editDod ? this.state.editDod.set_name : ""
                    }
                    autofocus
                  />
                  <TextInput
                    ref={(e) => {
                      this["newDodDesc" + i] = e;
                    }}
                    validateCallback={(val) => {
                      return false;
                    }}
                    placeholder="Optional description"
                    maxlength="100"
                    default={
                      this.state.editDod
                        ? this.state.editDod.set_description
                        : ""
                    }
                  />
                  <button
                    className="btn btn-light"
                    style={{ float: "left" }}
                    onClick={() => {
                      this.setState({ addDod: null, editDod: null });
                    }}
                  >
                    cancel
                  </button>
                  {this.state.editDod && (
                    <button
                      className="btn btn-danger"
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      onClick={() => {
                        this.removeDod(this.state.editDod.set_id);
                      }}
                    >
                      Delete
                    </button>
                  )}{" "}
                  <button
                    className="btn btn-info"
                    style={{ float: "right" }}
                    onClick={() => {
                      if (this.state.addDod !== null) {
                        // Add a new dod
                        var lastPos = disc.dods ? disc.dods.length : -1;
                        this.createDod(
                          this["newDod" + i].value(),
                          this["newDodDesc" + i].value(),
                          i,
                          lastPos + 1
                        );
                      } else {
                        // Update an existing
                        this.updateDod(
                          this.state.editDod.set_id,
                          this["newDod" + i].value(),
                          this["newDodDesc" + i].value()
                        );
                      }
                    }}
                  >
                    {this.state.addDod !== null ? "CREATE" : "UPDATE"}
                  </button>
                </div>
              )}
          </div>
        </div>
      );
    });

    return (
      <div style={{ minHeight: 2000 }}>
        {this.state.confirmDeleteAll && (
          <Popup
            title="Confirm delete all"
            closeCallback={() => this.setState({ confirmDeleteAll: false })}
            submitCallback={() => {
              this.deleteAll();
            }}
            negativeAction="Nevermind"
            positiveAction="YES, DO IT"
          >
            DANGER! Are you certain you want to <b>ERASE EVERYONE</b> (except
            yourself) in your organization? This cannot be undone afterwards.
            <br />
          </Popup>
        )}
        {this.state.confirmDeleteOrg && (
          <Popup
            title="DANGER!"
            closeCallback={() => this.setState({ confirmDeleteOrg: false })}
            submitCallback={() => {
              this.deleteOrg();
            }}
            negativeAction="God, no!"
            positiveAction="YES, IT MUST DIE.."
          >
            <center>
              <br />
              WAIT, REALLY?! Are you absolutely certain you want to
              <br />
              <b style={{ fontSize: 18, margin: "0px 10px" }}>
                FOREVER ERASE ORGANIZATION: {this.props.auth.getOrgName()}?
              </b>
              <br />
              This cannot be undone afterwards.
              <br />
              <br />
            </center>
          </Popup>
        )}
        {this.state.confirmNewStartDate && (
          <Popup
            title="Confirm new start date"
            closeCallback={() => this.setState({ confirmNewStartDate: false })}
            submitCallback={() => {
              this.updateOrgStartDate();
            }}
            negativeAction="Oops, no"
            positiveAction="OK, got it!"
          >
            <b>WARNING!</b> Please note that that you will forever loose a lot
            of data by moving the starting date of your organization forward in
            time.
            <br />
          </Popup>
        )}

        <center>
          <h1>Settings</h1>
        </center>
        <br />
        <br />

        <div className="settings-wrapper">
          <div className="row">
            <div className="col-3">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {this.props.auth.requiresRole("janitor") && (
                  <a
                    className={
                      this.state.gotoPage ? "nav-link" : "nav-link active"
                    }
                    id="v-pills-general-tab"
                    data-toggle="pill"
                    href="#v-pills-general"
                    role="tab"
                    aria-controls="v-pills-general"
                    aria-selected="true"
                  >
                    General
                  </a>
                )}

                {adminPill}
                {this.props.auth.requiresRole("janitor") && (
                  <>
                    <a
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      Invites
                    </a>

                    <a
                      className={
                        this.state.gotoPage === "roles"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="v-pills-disciplines-tab"
                      data-toggle="pill"
                      href="#v-pills-disciplines"
                      role="tab"
                      aria-controls="v-pills-disciplines"
                      aria-selected="false"
                    >
                      Roles
                    </a>
                  </>
                )}
                <a
                  className={
                    this.state.gotoPage === "workflows"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="v-pills-workflows-tab"
                  data-toggle="pill"
                  href="#v-pills-workflows"
                  role="tab"
                  aria-controls="v-pills-workflows"
                  aria-selected="false"
                >
                  Workflows
                </a>
                {this.props.auth.requiresRole("janitor") && (
                  <>
                    <a
                      className="nav-link"
                      id="v-pills-holidays-tab"
                      data-toggle="pill"
                      href="#v-pills-holidays"
                      role="tab"
                      aria-controls="v-pills-holidays"
                      aria-selected="false"
                    >
                      Holidays
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-recruitment-tab"
                      data-toggle="pill"
                      href="#v-pills-recruitment"
                      role="tab"
                      aria-controls="v-pills-recruitment"
                      aria-selected="false"
                    >
                      Recruitment
                    </a>
                    <a
                      className={
                        this.state.gotoPage === "integrations"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="v-pills-messages-tab"
                      data-toggle="pill"
                      href="#v-pills-messages"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      Integrations
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-dev-tab"
                      data-toggle="pill"
                      href="#v-pills-dev"
                      role="tab"
                      aria-controls="v-pills-dev"
                      aria-selected="false"
                    >
                      Advanced
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="col-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className={
                    this.state.gotoPage
                      ? "tab-pane fade show"
                      : "tab-pane fade show active"
                  }
                  id="v-pills-general"
                  role="tabpanel"
                  aria-labelledby="v-pills-general-tab"
                >
                  {this.props.auth.requiresRole("janitor") && (
                    <div>
                      <center>
                        <h4>General settings</h4>
                      </center>
                      <div
                        style={{
                          padding: 40,
                          borderRadius: 25,
                        }}
                      >
                        <h6>
                          <b>{"Organization's start date"}</b>
                        </h6>
                        <center>
                          <DatePicker
                            default={moment(this.props.auth.getOrgCreation())}
                            minDate={moment("2000-01-01")}
                            maxDate={moment(
                              this.getMaxOrgCreation().offWeekend().format(true)
                            )}
                            changeCb={this.orgStartDateChanged}
                            ref={this.startDatePicker}
                          />
                          <div
                            className="tinytext"
                            style={{ paddingTop: 5, width: "80%" }}
                          >
                            Note that the earliest started project dictates the
                            allowed organization starting date forward in time.
                          </div>
                          <br />
                          <button
                            className="btn btn-success btn-block"
                            disabled={!this.state.newstartdate}
                            onClick={() => {
                              this.setState({ confirmNewStartDate: true });
                            }}
                          >
                            <div>
                              UPDATE STARTDATE
                              <i
                                className="far fa-clock"
                                style={{ paddingLeft: 8 }}
                              />
                            </div>
                          </button>
                          <br />
                          <br />
                          <input
                            type="checkbox"
                            checked={this.state.milestoneEnabled}
                            onChange={this.setMilestone}
                          />{" "}
                          Enable Milestone
                          <br />
                          <br />
                          <input
                            type="checkbox"
                            checked={this.state.cardsEnabled}
                            onChange={this.setCards}
                          />{" "}
                          Enable Cards
                        </center>
                        <br />
                        <br />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    this.state.gotoPage === "workflows"
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                  id="v-pills-workflows"
                  role="tabpanel"
                  aria-labelledby="v-pills-workflows-tab"
                >
                  {this.props.auth.requiresRole("lead") && (
                    <div>
                      <center>
                        <h4>Definitions of Done</h4>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 200,
                            maxWidth: 400,
                          }}
                        >
                          Define the default set of states per role reflecting
                          progression status of an Epic. These can be
                          individually set per Epic as well.
                        </div>
                      </center>
                      <div
                        style={{
                          padding: 40,
                          paddingTop: 20,
                          borderRadius: 25,
                        }}
                      >
                        {this.props.auth.requiresRole("lead") && (
                          <div
                            style={{
                              padding: 5,
                            }}
                          >
                            {dodList}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    this.state.gotoPage === "roles"
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                  id="v-pills-disciplines"
                  role="tabpanel"
                  aria-labelledby="v-pills-disciplines-tab"
                >
                  {this.props.auth.requiresRole("janitor") && (
                    <div>
                      <center>
                        <h4>Roles</h4>
                      </center>
                      <div
                        style={{
                          padding: 40,
                          paddingTop: 0,
                          borderRadius: 25,
                        }}
                      >
                        <center>
                          {!this.state.addDiscipline && (
                            <button
                              className="btn btn-link btn-sm"
                              onClick={() => {
                                this.setState({
                                  addDiscipline: true,
                                });
                              }}
                            >
                              add Role
                            </button>
                          )}
                          {this.state.addDiscipline && (
                            <div
                              style={{
                                display: "inline-block",
                                width: "100%",
                              }}
                            >
                              <TextInput
                                ref={this.newDiscipline}
                                validateCallback={(val) => {
                                  return false;
                                }}
                                placeholder="Name of Role"
                                maxlength="40"
                                autofocus
                              />
                              <button
                                className="btn btn-light"
                                style={{ float: "left" }}
                                onClick={() => {
                                  this.setState({ addDiscipline: false });
                                }}
                              >
                                cancel
                              </button>
                              <button
                                className="btn btn-info"
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.createDiscipline(
                                    this.newDiscipline.current.value()
                                  );
                                }}
                              >
                                CREATE
                              </button>
                            </div>
                          )}
                          <center>
                            {this.state.disciplines.map((disc, i) => {
                              if (disc.set_type !== "discipline") return null;

                              var pillColor = disc.set_value
                                ? disc.set_value
                                : "green";

                              return (
                                <div key={"disc" + i}>
                                  {!this.state.editDiscipline ||
                                  this.state.editDiscipline.set_id !==
                                    disc.set_id ? (
                                    <div
                                      style={{
                                        position: "relative",
                                        background: pillColor,
                                        borderRadius: 4,
                                        color: "white",
                                        border: "1px solid " + pillColor,
                                        display: "inline-block",
                                        fontSize: 16,
                                        padding: "1px 15px",
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        margin: 4,
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          editDiscipline: {
                                            ...disc,
                                            color: pillColor,
                                          },
                                        });
                                      }}
                                    >
                                      {disc.set_name}
                                      <div
                                        onClick={this.removeDiscipline.bind(
                                          null,
                                          i
                                        )}
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          right: -28,
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                          fontSize: 12,
                                        }}
                                      >
                                        <i className="far fa-trash-alt" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "block",
                                        margin: "20px 0px",
                                      }}
                                    >
                                      <TextInput
                                        ref={this.newDiscipline}
                                        validateCallback={(val) => {
                                          return false;
                                        }}
                                        default={
                                          this.state.editDiscipline.set_name
                                        }
                                        placeholder="Name of Role"
                                        maxlength="40"
                                        centered
                                        autofocus
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: -8,
                                          marginBottom: 8,
                                        }}
                                      >
                                        {this.custColors.map((val, i) => {
                                          return (
                                            <div
                                              key={"option" + i}
                                              style={{
                                                marginRight: 4,
                                                borderRadius: "50%",
                                                background: val,
                                                width: 16,
                                                height: 16,
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                let newData = Object.assign(
                                                  this.state.editDiscipline
                                                );
                                                newData.color = val;
                                                this.setState({
                                                  editDiscipline: newData,
                                                });
                                              }}
                                            >
                                              {this.state.editDiscipline
                                                .color === val && (
                                                <div
                                                  style={{
                                                    borderRadius: "50%",
                                                    background: "#333",
                                                    width: 8,
                                                    height: 8,
                                                    margin: 4,
                                                  }}
                                                />
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <button
                                          className="btn btn-light"
                                          style={{
                                            left: 0,
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              editDiscipline: null,
                                            });
                                          }}
                                        >
                                          cancel
                                        </button>
                                        <button
                                          className="btn btn-info"
                                          style={{
                                            right: 0,
                                          }}
                                          onClick={() => {
                                            this.updateDiscipline(
                                              disc.set_id,
                                              this.newDiscipline.current.value(),
                                              this.state.editDiscipline.color
                                            );
                                          }}
                                        >
                                          UPDATE
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </center>
                        </center>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <center>
                    {this.props.auth.requiresRole("janitor") && (
                      <table
                        style={{
                          borderSpacing: "10px",
                          borderCollapse: "separate",
                        }}
                      >
                        <thead style={{ textAlign: "center" }}>
                          <tr>
                            <th>Account</th>
                            <th>Level</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>{adminList}</tbody>
                      </table>
                    )}
                  </center>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <center>
                    {this.props.auth.requiresRole("janitor") && (
                      <table
                        style={{
                          borderSpacing: "10px",
                          borderCollapse: "separate",
                        }}
                      >
                        <thead style={{ textAlign: "center" }}>
                          <tr>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{userList}</tbody>
                      </table>
                    )}
                  </center>
                </div>

                <div
                  className="tab-pane fade"
                  id="v-pills-recruitment"
                  role="tabpanel"
                  aria-labelledby="v-pills-recruitment-tab"
                >
                  <center>
                    {this.props.auth.requiresRole("owner") && (
                      <div>
                        <h3>Recruitment</h3>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 200,
                            maxWidth: 400,
                          }}
                        >
                          By sending following payload to Peepz.io servers (from
                          selected recruitment platforms or your own website)
                          you can add & track potential recruits.
                        </div>
                        <br /> <br />
                        <h5>Payload</h5>
                        <div
                          style={{
                            textAlign: "left",
                            borderRadius: 8,
                            fontSize: 12,
                            background: "#ccc",
                            padding: "12px 16px",
                            color: "#000",
                            width: "70%",
                          }}
                        >
                          <b>Endpoint:</b> https://www.peepz.io/recruitment
                          <br />
                          <b>Type:</b> POST
                          <br />
                          <br />
                          <center>
                            <b>Body</b>
                          </center>
                          <b>Token:</b> string, base64 encoded recruit email
                          <br />
                          <b>Message:</b> string, html based message
                          <br />
                          <b>Position:</b> string, job position
                          <br />
                          <b>Codename:</b> string, public codename for
                          application domain
                          <br />
                          <b>Domain:</b> "{this.props.auth.getDomain()}"
                        </div>
                        <br />
                        <br />
                        <input
                          type="checkbox"
                          checked={this.state.recruitmentEnabled}
                          onChange={this.setRecruitment}
                        />{" "}
                        Enable recruitment
                      </div>
                    )}
                  </center>
                </div>

                <div
                  className={
                    this.state.gotoPage === "integrations"
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <center>
                    {this.props.auth.requiresRole("owner") && (
                      <>
                        <h5>Import from Timetastic</h5>
                        <TextInput
                          ref={this.tokenInput}
                          validateCallback={this.validateInput}
                          placeholder="API Token"
                          maxlength="40"
                          requireSubmit={true}
                          disabled={this.state.importing}
                          keypressCallback={(e) => {
                            if (e.key === "Enter") {
                              this.tokenInput.current.submit();
                              if (
                                this.validateInput(
                                  this.tokenInput.current.value()
                                ) === ""
                              ) {
                                this.importTimetastic(e);
                              }
                            }
                          }}
                        />
                        <button
                          className="btn btn-success btn-block"
                          disabled={this.state.importing}
                          onClick={() => {
                            this.tokenInput.current.submit();
                            if (
                              this.validateInput(
                                this.tokenInput.current.value()
                              ) === ""
                            ) {
                              this.importTimetastic();
                            }
                          }}
                        >
                          {!this.state.importing ? (
                            <div>
                              IMPORT TIMETASTIC USERS
                              <i
                                className="fas fa-download"
                                style={{ paddingLeft: 8 }}
                              />
                            </div>
                          ) : (
                            <div>
                              IMPORTING <i style={{ paddingLeft: 6 }} />
                              <i className="fas fa-sync iconspin" />
                            </div>
                          )}{" "}
                        </button>

                        <br />
                        <br />
                        <h5>Github Commits Integration</h5>
                        <TextInput
                          ref={this.tokenGithubRef}
                          validateCallback={this.validateInput}
                          placeholder="API Token"
                          maxlength="40"
                          requireSubmit={true}
                          disabled={this.state.github}
                          keypressCallback={(e) => {
                            if (e.key === "Enter") {
                              this.tokenGithubRef.current.submit();
                            }
                          }}
                          password
                        />
                        <TextInput
                          ref={this.repoGithubRef}
                          validateCallback={this.validateInput}
                          placeholder="Owner/repo"
                          maxlength="60"
                          requireSubmit={true}
                          disabled={this.state.github}
                          keypressCallback={(e) => {
                            if (e.key === "Enter") {
                              this.repoGithubRef.current.submit();
                            }
                          }}
                        />
                        {this.state.github && (
                          <button
                            className="btn btn-danger btn-block"
                            disabled={this.state.importing}
                            onClick={() => {
                              this.removeGithub();
                            }}
                          >
                            Delete Github connection
                          </button>
                        )}
                        {!this.state.github && (
                          <button
                            className="btn btn-success btn-block"
                            disabled={this.state.importing}
                            onClick={() => {
                              this.tokenGithubRef.current.submit();
                              this.repoGithubRef.current.submit();
                              if (
                                this.validateInput(
                                  this.tokenGithubRef.current.value()
                                ) === "" &&
                                this.validateInput(
                                  this.repoGithubRef.current.value()
                                ) === ""
                              ) {
                                this.setGithub(
                                  this.tokenGithubRef.current.value(),
                                  this.repoGithubRef.current.value()
                                );
                              }
                            }}
                          >
                            {!this.state.importing ? (
                              <div>
                                CONNECT TO GITHUB
                                <i
                                  className="fas fa-plug"
                                  style={{ paddingLeft: 8 }}
                                />
                              </div>
                            ) : (
                              <div>
                                CONNECTING <i style={{ paddingLeft: 6 }} />
                                <i className="fas fa-sync iconspin" />
                              </div>
                            )}{" "}
                          </button>
                        )}
                      </>
                    )}
                  </center>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-holidays"
                  role="tabpanel"
                  aria-labelledby="v-pills-holidays-tab"
                >
                  <center>
                    <h4>Holidays</h4>

                    {this.props.auth.requiresRole("janitor") &&
                      !this.state.addHoliday && (
                        <button
                          className="btn btn-link btn-sm"
                          onClick={() => {
                            this.setState({ addHoliday: true });
                          }}
                        >
                          add holiday
                        </button>
                      )}

                    {this.state.addHoliday && (
                      <div
                        style={{
                          display: "inline-block",
                          width: "80%",
                          marginBottom: 40,
                        }}
                      >
                        <DatePicker
                          default={moment()}
                          minDate={moment(this.props.auth.getOrgCreation())}
                          maxDate={moment(
                            new Date().addMonths(48).offWeekend().format(true)
                          )}
                          exceptions={Array.from(
                            this.state.holidays,
                            (obj) => obj.date
                          )}
                          ref={this.newHolidayDatePicker}
                        />
                        <div style={{ paddingTop: 10 }} />
                        <TextInput
                          ref={this.newHolidayName}
                          validateCallback={(val) => {
                            return false;
                          }}
                          placeholder="Name of cancelled workday"
                          maxlength="40"
                        />
                        <button
                          className="btn btn-light"
                          style={{ float: "left" }}
                          onClick={() => {
                            this.setState({ addHoliday: false });
                          }}
                        >
                          cancel
                        </button>
                        <button
                          className="btn btn-info"
                          style={{ float: "right" }}
                          onClick={() => {
                            this.createHoliday(
                              this.newHolidayName.current.value()
                            );
                          }}
                        >
                          CREATE
                        </button>
                      </div>
                    )}

                    {this.props.auth.requiresRole("janitor") && (
                      <div>
                        <table
                          style={{
                            borderSpacing: "10px",
                            borderCollapse: "separate",
                          }}
                        >
                          <thead style={{ textAlign: "center" }}>
                            <tr>
                              <th>Date</th>
                              <th>Holiday</th>
                              <th>Enabled</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>{holidayList}</tbody>
                        </table>
                      </div>
                    )}
                  </center>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-dev"
                  role="tabpanel"
                  aria-labelledby="v-pills-dev-tab"
                >
                  <center>
                    {this.props.auth.requiresRole("owner") && (
                      <div>
                        <h5>Advanced Settings</h5>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.setState({ confirmDeleteOrg: true });
                          }}
                        >
                          <div>
                            DELETE ORGANIZATION
                            <i
                              className="fas fa-exclamation-triangle"
                              style={{ paddingLeft: 8 }}
                            />
                          </div>
                        </button>
                      </div>
                    )}

                    <br />
                    <br />
                    <br />
                    {/*
                    {this.props.auth.requiresRole("owner") && (
                      <div>
                        <h5>Delete All</h5>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.setState({ confirmDeleteAll: true });
                          }}
                        >
                          <div>
                            DELETE ALL USERS{" "}
                            <i
                              className="fas fa-exclamation-triangle"
                              style={{ paddingLeft: 8 }}
                            />
                          </div>
                        </button>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                    <h5>Calculate Delta Days</h5>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        this.calculateDeltaDays();
                      }}
                    >
                      <div>
                        EXECUTE{" "}
                        <i
                          className="fas fa-calculator"
                          style={{ paddingLeft: 8 }}
                        />
                      </div>
                    </button>

                    {this.props.auth.requiresRole("owner") && (
                      <div>
                        <br />
                        <br />
                        <br />
                        <h5>Import Favro</h5>
                        <TextInput
                          ref={this.favroUser}
                          validateCallback={val => {
                            return false;
                          }}
                          placeholder="Favro user email"
                          maxlength="50"
                        />
                        <TextInput
                          ref={this.favroPass}
                          validateCallback={val => {
                            return false;
                          }}
                          placeholder="Favro password"
                          maxlength="50"
                        />
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            this.importFavroColls();
                          }}
                        >
                          <div>
                            Do it{" "}
                            <i
                              className="fas fa-plus"
                              style={{ paddingLeft: 8 }}
                            />
                          </div>
                        </button>
                      </div>
                    )}
                    {this.state.favrocollections.map((obj, i) => {
                      if (
                        this.state.favroCollSel !== null &&
                        this.state.favroCollSel !== i
                      )
                        return <div key={obj.collectionId + "_" + i} />;
                      return (
                        <div
                          key={obj.collectionId + "_" + i}
                          style={{ marginTop: 4 }}
                        >
                          {obj.name}{" "}
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              this.setState({ favroCollSel: i });
                              this.importFavroPillars(obj.collectionId);
                            }}
                            disabled={this.state.favroCollSel}
                          >
                            Import
                          </button>
                        </div>
                      );
                    })}
                    {this.state.favropillars.map((obj, i) => {
                      return (
                        <div
                          key={obj.collectionId + "_pillar_" + i}
                          style={{ marginTop: 4 }}
                        >
                          {obj.name}{" "}
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => {
                              this.importFavro(obj.widgetCommonId);
                            }}
                          >
                            Import
                          </button>
                        </div>
                      );
                    })}
                    */}
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Administration;
