import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
} from "react";
import db from "../utils/db";
import Product from "./ProductEmbed";
import ButtonDropdown from "../components/ButtonDropdown";
import Button from "../components/Button";
import Popup from "../components/Popup";
import moment from "moment";

const cardStyle = {
  display: "inline-flex",
  alignItems: "center",
  flexGrow: 1,
  minWidth: 200,
  padding: "14px 22px",
  margin: 6,
  borderRadius: 8,
  background: "#ffffff11",
  boxShadow: "1px 2px 10px 1px #00000033",
};
const inputStyle = {
  border: 0,
  width: "100%",
  outline: 0,
  fontSize: 16,
  color: "#aaa",
  background: "transparent",
  resize: "none",
  lineHeight: 1.2,
  padding: 0,
};

export default function Playbook(props) {
  const [goal, setGoal] = useState({}); //{id, stored}

  const playbook = useRef(null);
  const goals = useRef(null);

  useEffect(() => {
    getData();
    props.dispatch.addListener("orgchange", getData);

    return () => {
      props.dispatch.removeListener("orgchange", getData);
    };
  }, []);

  // Function //////////////////////

  async function getData(prime) {
    var data = {};

    // First get thematic goals
    const reqGoals = await db.request("/auth/getgoals", "POST", {
      projid: props.auth.getProjId(),
      type: "thematic",
    });
    // Get all org specific playbook info
    const req = await db.request("/auth/getplaybook", "POST", {
      org: props.auth.getOrgId(),
    });

    if (req && req.data && reqGoals && reqGoals.data) {
      for (var i = 0; i < req.data.length; i++) {
        if (!req.data[i].set_name) continue;
        const int = parseInt(req.data[i].set_value, 10);
        data[req.data[i].set_name] = int ? int : req.data[i].set_value;
      }

      playbook.current = data;
      goals.current = reqGoals.data;

      const res = {};
      if (prime && prime.id) res.id = prime.id;
      else if (!goal.id) res.id = data.goal ? data.goal : -1;
      if (prime && prime.stored) res.stored = prime.stored;
      else if (!goal.stored) res.stored = data.goal ? data.goal : -1;
      setGoal({ ...goal, ...res });
    }
  }

  function setGoalObject(obj) {
    setGoal({ ...goal, ...obj });
  }

  const goalIdx = goals.current
    ? goals.current.findIndex((val) => val.goal_id === goal.id)
    : -1;

  return !goal.id ? null : (
    <CardsContainer
      dispatch={props.dispatch}
      auth={props.auth}
      playbookData={playbook.current}
      goalsData={goals.current}
      goalStored={goal.stored}
      goalId={goal.id}
      setGoal={setGoalObject}
      refresh={getData}
    >
      <Product
        dispatch={props.dispatch}
        auth={props.auth}
        goals={goals.current}
        goal={goal.id}
        nextGoal={
          goals.current && goalIdx + 1 < goals.current.length
            ? goals.current[goalIdx + 1].goal_id
            : null
        }
        prevGoal={
          goals.current && goals.current.length > 1 && goalIdx > 0
            ? goals.current[goalIdx - 1].goal_id
            : null
        }
        isPastGoal={goal.id < goal.stored}
      />
    </CardsContainer>
  );
}

const CardsContainer = ({
  dispatch,
  auth,
  playbookData,
  goalsData,
  goalStored,
  goalId,
  setGoal,
  refresh,
  children,
}) => {
  const [playbook, setPlaybook] = useState(playbookData);
  const [goals, setGoals] = useState(goalsData);
  const [index, setIndex] = useState(-1);

  const [deleteGoalId, setDeleteGoalId] = useState(-1);

  const goalNameRef = useRef();
  const goalDescRef = useRef();

  const removeGoalCb = useCallback(removeGoal);

  useEffect(() => {
    updateGoal();
  }, [playbookData, goalsData, goalId, goalStored]);

  // Functions xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  function updateGoal() {
    if (goals) {
      let idx = goals.findIndex((val) => val.goal_id === goalId);
      console.log(goalId, idx, goals);
      // If deleted goal, reset data
      if (idx === -1 && goals.length > 0) {
        idx = goals.length - 1;
      }
      setIndex(idx);
      setTimeout(setGoalDescHeight, 50);
    }
  }

  function setGoalDescHeight() {
    if (goalDescRef.current) {
      goalDescRef.current.style.height = "auto";
      goalDescRef.current.style.height = "5px";
      goalDescRef.current.style.height =
        goalDescRef.current.scrollHeight + "px";
    }
  }

  async function submit(field, value) {
    if (field === "goal") {
      if (goalId > -1) {
        await db.request("/auth/updategoal", "POST", {
          id: goalId,
          name: value,
        });
      }
    } else if (field === "goaldesc") {
      if (goalId > -1) {
        await db.request("/auth/updategoal", "POST", {
          id: goalId,
          desc: value.replace(/'/g, "´"),
        });
      }
    } else {
      await db.request("/auth/setplaybook", "POST", {
        org: auth.getOrgId(),
        field: field,
        value: value,
      });
      let newField = {};
      newField[field] = value;
      setPlaybook({ ...playbook, ...newField });
    }
  }

  async function addGoal(name) {
    const res = await db.request("/auth/addgoal", "POST", {
      projectid: auth.getProjId(),
      type: "thematic",
      name: name,
      desc: "",
    });

    if (res && res.status === 200) {
      refresh({ id: res.data[0].goal_id });
    }
  }

  async function removeGoal(id) {
    if (goals.length > 1) {
      const res = await db.request("/auth/deletegoal", "POST", {
        projectid: auth.getProjId(),
        goalid: id,
      });

      if (res && res.status === 200) {
        var toId = 0;
        var toIdx = 0;

        for (var i = goals.length - 1; i >= 0; i--) {
          if (goals[i].goal_id !== id) {
            toId = goals[i].goal_id;
            toIdx = i;
            break;
          }
        }
        selectGoal(toId);
        setDeleteGoalId(-1);
      }
    }
  }

  async function selectGoal(id) {
    await db.request("/auth/setplaybook", "POST", {
      org: auth.getOrgId(),
      field: "goal",
      value: id,
      project: auth.getProjId(),
    });
    setGoal({ id: id, stored: id });
  }

  // Render xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const nextGoal = goals && goals.length > 1 && index < goals.length - 1;
  const prevGoal = index > 0;
  var menu = [];

  if (goals && goals.length > 0 && index === goals.length - 1)
    menu.push(
      <Button
        key="menubtnCreate"
        value="Create next goal"
        fontawesome="fas fa-flag"
        onClick={() => {
          addGoal();
        }}
        style={{
          padding: "12px",
        }}
      />
    );
  if (goalStored !== goalId)
    menu.push(
      <Button
        key="menubtnMakeCurrent"
        value="Make Current"
        fontawesome="fas fa-bullseye"
        onClick={() => {
          selectGoal(goals[index].goal_id);
        }}
        style={{
          padding: "12px",
        }}
      />
    );

  menu.push(
    <Button
      key="menubtnRemove"
      value="Remove goal"
      fontawesome="fas fa-trash"
      onClick={() => {
        setDeleteGoalId(goalId);
      }}
      type="red"
      style={{
        padding: "12px",
      }}
    />
  );

  return (
    <>
      {deleteGoalId > -1 && (
        <Popup
          title="Delete Goal"
          closeCallback={() => {
            setDeleteGoalId(-1);
          }}
          submitCallback={() => {
            removeGoalCb(deleteGoalId);
          }}
          negativeAction="Nevermind"
          positiveAction="Yes, I'm sure"
        >
          Are you sure you want to completely remove the goal{" "}
          <b>"{goals[index].goal_name}"</b>?<br />
          <center style={{ color: "red" }}>
            All planned work within it will be permanently removed.
          </center>
        </Popup>
      )}

      <div
        style={{
          display: "flex",
          padding: "16px 30px",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <Card
          number="1"
          header="Why do we exist?"
          placeholder="Your company's purpose here.."
          value={playbook.why}
          submit={submit}
          type="why"
        />
        <Card
          number="2"
          header="How do we behave?"
          placeholder="Our values of behavior goes here.."
          value={playbook.behave}
          submit={submit}
          type="behave"
        />
        <Card
          number="3"
          header="What do we do?"
          placeholder="Practically, for whom do we do what.."
          value={playbook.what}
          submit={submit}
          type="what"
        />
        <Card
          number="4"
          header="How will we succeed?"
          placeholder="What are the top strategies that will get us there.."
          value={playbook.how}
          submit={submit}
          type="how"
        />
        <div
          style={{
            position: "relative",
            width: "100%",
            margin: 6,
            marginTop: 20,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 20,
              fontSize: 280,
              fontWeight: "bold",
              color: "#c4adff",
              opacity: 0.09,
              lineHeight: 0.7,
              marginRight: 12,
              userSelect: "none",
              zIndex: -10,
            }}
          >
            5
          </div>
          <div style={{ position: "absolute", right: 20, top: 0 }}>
            <ButtonDropdown type="ellipsis" value="..." nocaret right>
              {goals && goals.length > 1 && (
                <>
                  <div style={{ display: "inline-block", width: 140 }}>
                    {prevGoal && (
                      <div style={{ float: "left", width: "45%" }}>
                        <Button
                          key="menubtn2"
                          fontawesome="fas fa-arrow-left"
                          onClick={() => {
                            setGoal({ id: goals[index - 1].goal_id });
                          }}
                          style={{
                            padding: "12px",
                          }}
                        />
                      </div>
                    )}
                    {nextGoal && (
                      <div style={{ float: "right", width: "45%" }}>
                        <Button
                          key="menubtn3"
                          fontawesome="fas fa-arrow-right"
                          onClick={() => {
                            setGoal({ id: goals[index + 1].goal_id });
                          }}
                          style={{
                            padding: "12px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <br />
                </>
              )}
              {menu.map((obj, j) => {
                if (menu.length === 1) {
                  obj.props.style.borderRadius = 8;
                } else if (j === 0) {
                  obj.props.style.borderRadius = "8px 8px 0px 0px";
                } else if (j === menu.length - 1) {
                  obj.props.style.borderRadius = "0px 0px 8px 8px";
                } else {
                  obj.props.style.borderRadius = 0;
                }
                return obj;
              })}
            </ButtonDropdown>
          </div>
          <div>
            <center
              style={{
                fontSize: 17,
                display: "block",
                fontWeight: 600,
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ marginBottom: -6 }}>
                What is most important, right now?
              </div>
              {goals && goalId > -1 ? (
                <>
                  <input
                    ref={goalNameRef}
                    value={
                      goals && index > -1 && goals[index].goal_name
                        ? goals[index].goal_name
                        : ""
                    }
                    placeholder="Your current most important goal here..."
                    style={{
                      border: 0,
                      outline: 0,
                      width: "100%",
                      background: "transparent",
                      fontSize: 44,
                      textAlign: "center",
                      fontWeight: "600",
                      color: goalId >= goalStored ? "#ff9900" : "limegreen",
                    }}
                    onChange={(e) => {
                      setGoals(
                        goals.map((g, i) => {
                          if (i === index) {
                            return { ...g, goal_name: e.target.value };
                          } else return g;
                        })
                      );
                      submit("goal", e.target.value);
                    }}
                  />
                  {goals[index] && goals[index].goal_date && (
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: 200,
                        top: 0,
                        zIndex: -10,
                      }}
                    >
                      <div
                        style={{ position: "absolute", right: 140, top: 52 }}
                      >
                        <Ribbon
                          text={
                            goals[index]
                              ? moment(goals[index].goal_date).format(
                                  "MMM D (YYYY)"
                                )
                              : ""
                          }
                          color={goalId >= goalStored ? "#efa51c" : "limegreen"}
                        />
                      </div>
                    </div>
                  )}
                  <textarea
                    ref={goalDescRef}
                    style={{
                      ...inputStyle,
                      display: "block",
                      textAlign: "center",
                      maxWidth: 700,
                      marginTop: -4,
                      marginBottom: 10,
                      overflow: "hidden",
                      color: goalId >= goalStored ? "#aaa" : "limegreen",
                    }}
                    placeholder="Description of the goal here..."
                    maxLength={320}
                    value={
                      goals && index > -1 && goals[index].goal_description
                        ? goals[index].goal_description
                        : ""
                    }
                    onChange={(e) => {
                      setGoals(
                        goals.map((g, i) => {
                          if (i === index) {
                            return { ...g, goal_description: e.target.value };
                          } else return g;
                        })
                      );
                      submit("goaldesc", e.target.value);

                      setGoalDescHeight();
                    }}
                  />
                </>
              ) : (
                <div
                  className="btn btn-secondary btn-sm"
                  style={{ margin: 10 }}
                  onClick={() => addGoal(goal)}
                >
                  NEW GOAL
                </div>
              )}
            </center>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

const Card = (props) => {
  const [value, setValue] = useState(props.value ? props.value : "");
  const [width, setWidth] = useState();

  const fieldRef = useRef();

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 100);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Update textarea size depending on content and window width
  useEffect(() => {
    if (!fieldRef.current) return;
    fieldRef.current.style.height = "auto";
    fieldRef.current.style.height = "5px";
    fieldRef.current.style.height = fieldRef.current.scrollHeight + 5 + "px";
  }, [value, width]);

  const headerStyle = {
    fontSize: 17,
    display: "block",
    fontWeight: 600,
    whiteSpace: "nowrap",
    clear: "both",
    marginBottom: -2,
  };
  const numberStyle = {
    fontSize: 70,
    fontWeight: "bold",
    color: "#c4adff",
    opacity: 0.4,
    float: "left",
    lineHeight: 0.8,
    marginRight: 16,
  };

  return (
    <div style={cardStyle}>
      <div style={numberStyle}>{props.number}</div>
      <div style={{ width: "100%" }}>
        <div style={headerStyle}>{props.header}</div>
        <textarea
          ref={fieldRef}
          style={inputStyle}
          placeholder={props.placeholder}
          maxLength={220}
          value={value}
          onChange={(e) => {
            let val = e.target.value;
            setValue(val);
            props.submit(props.type, val);
          }}
        />
      </div>
    </div>
  );
};

const Ribbon = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 21,
        zIndex: 1,
        height: 32,
        opacity: 1,
        transition: "none 0s ease 0s",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "inline-block",
            color: "white",
            fontWeight: 600,
            fontStyle: "italic",
            backgroundColor: props.color,
            position: "absolute",
            bottom: -32,
            cursor: "pointer",
            whiteSpace: "nowrap",
            transition: "none 0s ease 0s",
            padding: "4px 10px 4px 1px",
            height: 32,
            fontSize: 17,
            textShadow: "1px 1px 0px rgba(0, 0, 0, 0.9)",
            opacity: 1,
          }}
        >
          {props.text}
        </div>
        <div
          style={{
            width: 20,
            position: "absolute",
            bottom: -32,
            left: 0,
            zIndex: 10,
            borderBottom: "12px solid " + props.color,
            transform: "rotate(-90deg)",
            transformOrigin: "left bottom",
            transition: "none 0s ease 0s",
            height: 32,
            borderRight: "32px solid transparent",
            opacity: 1,
          }}
        />
        <div
          style={{
            width: 8,
            height: 17,
            bottom: -49,
            position: "absolute",
            backgroundImage:
              "linear-gradient(rgb(50, 34, 4) 1px, " + props.color + " 8px)",
            transition: "none 0s ease 0s",
            left: -12,
            opacity: 1,
          }}
        />
      </div>
    </div>
  );
};
