import React from "react";
import Colors from "../utils/colors";

class StatCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var color = Colors[this.props.type].color;
    var icon = Colors[this.props.type].icon;

    return (
      <div
        style={{
          backgroundColor: 0,
          padding: "0px 14px",
          minHeight: 50,
          display: "inline-block",
        }}
      >
        <div
          className="d-flex flex-nowrap"
          style={{ height: 25, marginTop: 6 }}
        >
          <div style={{ fontSize: "1em" }}>{icon}</div>
          <h6
            style={{
              color: color,
              paddingTop: 4,
              paddingLeft: 4,
            }}
          >
            {this.props.title}
          </h6>
        </div>
        <div style={{ marginTop: -6, fontSize: "0.65rem", color: "#666" }}>
          <i>{this.props.data}</i>
        </div>
        {this.props.fineprint && (
          <small className="tinytext">
            <i>{this.props.fineprint}</i>
          </small>
        )}
      </div>
    );
  }
}

export default StatCard;
