const db = {
  request: async function (api, method, body) {
    var httpSecure =
      process.env.NODE_ENV === "development"
        ? "http://localhost:5001"
        : "https://www.peepz.io";

    const localVersion = localStorage.getItem("version");

    const stringifiedJson = body !== undefined ? JSON.stringify(body) : body;

    var heads = new Headers();
    heads.append("Content-Type", "application/json");
    heads.append("Authorization", "Bearer " + localStorage.getItem("token"));

    var request = new Request(httpSecure + api, {
      method: method,
      headers: heads,
      body: stringifiedJson,
    });

    var response = null;
    try {
      response = await fetch(request);
    } catch (err) {
      console.log(Error(err));
    }

    const data = response ? await response.json() : null;
    if (data) {
      var remoteVersion = response.headers.get("Version");
      if (!remoteVersion) remoteVersion = "null";
      console.log(
        "Version - local:" + localVersion + " remote:" + remoteVersion
      );

      if (remoteVersion !== localVersion) {
        localStorage.setItem("version", remoteVersion);
        if (localVersion) {
          console.log(
            " -> NEW VERSION, setting local version AND reloading hard"
          );
          window.location.reload(true);
        } else {
          console.log(" -> FIRST TIME, setting local version");
        }
      }
    }

    return data;
  },
};
export default db;
