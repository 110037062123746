import React, { useState, useCallback } from "react";
import Card from "./Card";
import ButtonAdd from "./ButtonAdd";
import { useDrop } from "react-dnd";
import Colors from "../utils/colors";

export default function Column(props) {
  var columnStyle = {
    position: "relative",
    display: "inline-block",
    flexGrow: 1,
    minWidth: 180,
    minHeight: 40,
    height: "auto",
    width: "25%",
    margin: "0px 6px",
    borderRadius: 3,
  };

  var colColor = "#fff";
  if (props.customColor) colColor = props.customColor;
  else if (props.isDone) colColor = "limegreen";

  return (
    <div style={columnStyle}>
      <div
        style={{
          position: "absolute",
          background: colColor,
          opacity: props.isDone ? 1 : 0.2,
          height: 20,
          width: "100%",
          borderRadius: 2,
        }}
      />
      <div
        style={{
          display: "flex",
          fontSize: 11,
          height: 20,
          width: "100%",
          color: props.isDone ? "#2b116b" : "#fff",
          opacity: 0.5,
          alignItems: "center",
          paddingLeft: 8,
          boxShadow: "1px 1px 3px #000000",
        }}
      >
        {props.colname}
      </div>
      <DropZone {...props} container>
        {/* NOTE: Turned off drag drop prios since they're only annoying
          props.showPriority && (
          <div
            style={{
              position: "absolute",
              right: 0,
              zIndex: 100,
              width: 110,
              height: "auto",
            }}
          >
            <DropZone
              target={4}
              label="Critical"
              color={Colors.critical}
              {...props}
            />
            <DropZone target={3} label="High" color={Colors.high} {...props} />
            <DropZone
              target={2}
              label="Medium"
              color={Colors.medium}
              {...props}
            />
            <DropZone target={1} label="Low" color={Colors.low} {...props} />
            <DropZone target={-1} label="Clear" color={"grey"} {...props} />
          </div>
        )*/}
      </DropZone>
    </div>
  );
}

const DropZone = (props) => {
  const { children, container } = props;
  const [showDoneCards, setShowDoneCards] = useState(false);
  const [isLast, setIsLast] = useState(props.idx === 3);

  const [{ isOver, isOverCurrent }, dropRef] = useDrop(() => ({
    accept: "card",
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) return;

      var priority = props.target;
      if (item.priority && !props.target) priority = item.priority;
      else if (props.target === -1) priority = null;

      props.dropCallback(
        {
          id: props.id ? props.id : item.parent,
          idx: props.idx,
          prevIdx: item.prevStatus,
        },
        item,
        priority
      );

      return { name: "Column" };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  const isActive = isOverCurrent || isOver;

  var color = props.color ? props.color : "transparent";
  if (isActive) {
    color = props.color ? props.color : "#ffffff88";
  }

  var visibleIdx = -1;

  return (
    <div
      ref={dropRef}
      style={{
        background: color,
        height: container ? "96%" : 20,
        margin: container ? 0 : "3px 6px",
        borderRadius: 2,
      }}
      onMouseLeave={() => {
        if (showDoneCards) {
          setShowDoneCards(!showDoneCards);
        }
      }}
      onClick={() => {
        setShowDoneCards(true);
      }}
    >
      {isActive && <>{children}</>}
      {!container && (
        <div
          style={{
            display: "flex",
            fontSize: 11,
            fontWeight: 600,
            height: 20,
            padding: "0px 4px",
            width: "100%",
            color: isActive ? "#000" : "#fff",
            opacity: 0.9,
            alignItems: "center",
            paddingLeft: 8,
            borderRadius: 2,
            border: isOverCurrent ? "2px solid #000" : "2px solid transparent",
            boxShadow: "1px 1px 3px #000",
          }}
        >
          {props.label ? props.label : ""}
        </div>
      )}
      {container && (
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "left",
            margin: 3,
          }}
        >
          {props.data.map((task, j) => {
            if (
              (props.showMyCards &&
                task.card_owner !== props.auth.getUserId()) ||
              (props.showDiscipline &&
                props.showDiscipline.find((y) => y.checked) &&
                !props.showDiscipline.find(
                  (x) => x.checked && x.id === task.card_discipline
                ))
            ) {
              return null;
            } else if (isLast && !showDoneCards) {
              return (
                <div
                  key={"task" + task.card_id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50,
                    height: 30,
                    borderRadius: 5,
                    margin: 2,
                    background: props.isArchived ? "limegreen" : "#fff",
                    opacity: 1 - j / props.data.length,
                  }}
                >
                  <i
                    className="fas fa-check"
                    style={{
                      color: "green",
                    }}
                  />
                </div>
              );
            } else {
              const owner = task.card_owner
                ? props.users[props.idToIdxUsers[task.card_owner]]
                : null;
              const disc = task.card_discipline
                ? props.disciplines[props.idToIdxDiscs[task.card_discipline]]
                    .set_name
                : null;
              const discColor = task.card_discipline
                ? props.disciplines[props.idToIdxDiscs[task.card_discipline]]
                    .set_value
                : null;

              visibleIdx++;

              return (
                <Card
                  key={"task" + task.card_id}
                  data={task}
                  isDone={props.idx === props.maxcols - 1}
                  isAllDone={props.isDone}
                  onClick={props.editCard}
                  owner={owner}
                  discipline={{ name: disc, color: discColor }}
                  estimate={task.card_estimate}
                  highlight={
                    props.highlight && props.highlightCard === visibleIdx
                  }
                  cloudinary={props.cloudinary}
                  isArchived={props.isArchived}
                  showPriority={props.showPriority}
                />
              );
            }
          })}
          {props.idx === 0 && !props.isArchived && !props.isCustom && (
            <div
              style={{
                display: "inline-block",
                width: 160,
                padding: 6,
                margin: 3,
                transition: "none",
                zIndex: 5,
              }}
            >
              <ButtonAdd onClick={props.addTask} lightMode />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
