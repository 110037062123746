import React from "react";
import moment from "moment";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    // Move dates off weekends
    var minDate = moment(this.props.minDate);
    if (minDate.day() === 0) minDate.add(1, "day");
    else if (minDate.day() === 6) minDate.add(2, "day");

    var maxDate = moment(this.props.maxDate);
    if (maxDate.day() === 0) maxDate.add(1, "day");
    else if (maxDate.day() === 6) maxDate.add(2, "day");

    var defaultVal = moment(this.props.default);
    if (defaultVal.day() === 0) defaultVal.add(1, "day");
    else if (defaultVal.day() === 6) defaultVal.add(2, "day");

    this.state = {
      minDate: minDate,
      maxDate: maxDate,
      year: moment(defaultVal).format("YYYY"),
      month: moment(defaultVal).format("MMM"),
      day: moment(defaultVal).format("D"),
      years: [],
      months: [],
      origMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [],
    };

    this.setMinDate = this.setMinDate.bind(this);
    this.calcDates = this.calcDates.bind(this);
    this.changedDate = this.changedDate.bind(this);
    this.getDateString = this.getDateString.bind(this);
  }

  componentDidMount() {
    this.calcDates();
  }

  getDate() {
    return moment(
      this.state.year + "-" + this.state.month + "-" + this.state.day,
      "YYYY-MMM-D"
    ).format();
  }

  getDateString() {
    return moment(
      this.state.year + "-" + this.state.month + "-" + this.state.day,
      "YYYY-MMM-D"
    ).format("DD MM YYYY");
  }

  setMinDate(date) {
    var state = this.state;
    state.minDate = moment(date);
    this.setState(state);

    this.calcDates();
  }

  changedDate(e, type) {
    var state = this.state;
    state[type] = e.target.value;
    this.setState(state);

    this.calcDates();
    if (this.props.changeCb) this.props.changeCb(this.getDate());
  }

  calcDates() {
    var state = this.state;

    if (
      moment(
        state.year + "-" + state.month + "-" + state.day,
        "YYYY-MMM-D"
      ).isSameOrBefore(moment(state.minDate))
    ) {
      state.year = moment(state.minDate).format("YYYY");
      state.month = moment(state.minDate).format("MMM");
      state.day = moment(state.minDate).format("D");
    }

    var years = [];
    var year = moment(state.minDate).year();
    while (
      year <= moment(state.maxDate).year() &&
      year >= moment(state.minDate).year()
    ) {
      years.push(year);
      year++;
    }

    var months = state.origMonths.slice();
    if (parseInt(state.year, 10) === moment(state.maxDate).year()) {
      months = months.slice(0, moment(state.maxDate).month() + 1);
    }
    if (parseInt(state.year, 10) === moment(state.minDate).year()) {
      months = months.slice(moment(state.minDate).month());
    }

    var days = [];
    var weekDay = -1;
    var minDay =
      parseInt(state.year, 10) === moment(state.minDate).year() &&
      state.month === moment(state.minDate).format("MMM")
        ? moment(state.minDate).date()
        : 1;
    var maxDay =
      parseInt(state.year, 10) === moment(state.maxDate).year() &&
      state.month === moment(state.maxDate).format("MMM")
        ? moment(state.maxDate).date()
        : moment(state.year + "-" + state.month, "YYYY-MMM").daysInMonth();
    var day = minDay;

    while (minDay <= day && day < maxDay + 1) {
      var currDay = moment(
        state.year + "-" + state.month + "-" + day.toString(),
        "YYYY-MMM-D"
      );

      if (!this.isExceptionDate(currDay)) {
        weekDay = currDay.day();
        if (weekDay > 0 && weekDay < 6) days.push(day);
      }
      day++;
    }

    if (state.day > days[days.length - 1]) state.day = days[days.length - 1];
    else if (state.day < days[0]) state.day = days[0];
    else if (!days.includes(parseInt(state.day, 10))) {
      state.day = days.includes(parseInt(state.day, 10) - 1)
        ? parseInt(state.day, 10) - 1
        : parseInt(state.day, 10) + 1;
    }

    state.years = years;
    state.months = months;
    state.days = days;
    this.setState(state);
  }

  isExceptionDate(date) {
    return (
      this.props.exceptions &&
      this.props.exceptions.find((e) => e.isSameDay(new Date(date)))
    );
  }

  render() {
    return (
      <div className="form-row">
        {this.props.title && (
          <div
            className="col-2"
            style={{
              paddingTop: 7,
              marginLeft: 8,
              color: "grey",
              fontSize: "1rem",
              fontWeight: 100,
            }}
          >
            <i>{this.props.title}:</i>
          </div>
        )}
        <div className="col">
          <select
            className="custom-select"
            value={this.state.year}
            size="1"
            onChange={(e) => this.changedDate(e, "year")}
            style={{ color: "#777" }}
          >
            {this.state.years.map((val, i) => {
              return <option key={i}>{val}</option>;
            })}
          </select>
        </div>
        <div className="col">
          <select
            className="custom-select"
            value={this.state.month}
            size="1"
            onChange={(e) => this.changedDate(e, "month")}
            style={{ color: "#777" }}
          >
            {this.state.months.map((val, i) => {
              return <option key={i}>{val}</option>;
            })}
          </select>
        </div>
        <div className="col">
          <select
            className="custom-select"
            value={this.state.day}
            size="1"
            onChange={(e) => this.changedDate(e, "day")}
            style={{ color: "#777" }}
          >
            {this.state.days.map((val, i) => {
              return <option key={i}>{val}</option>;
            })}
          </select>
        </div>
      </div>
    );
  }
}

export default DatePicker;
