import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  memo,
} from "react";
import Timeline from "../components/Timeline";
import useKey from "../components/useKey";
import db from "../utils/db";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { LexoRank } from "lexorank";

export default function Longterm(props) {
  const [cardsRaw, setCardsRaw] = useState(null);
  const [idIdxMap, setIdIdxMap] = useState(null);
  const [cards, setCards] = useState(null);
  const [goals, setGoals] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Functions

  async function getData() {
    const resGoals = await db.request("/auth/getgoals", "POST", {
      projid: props.auth.getProjId(),
      type: "thematic",
    });

    // Note: Card priority is used for vertical position inside Row
    // Note: Card order is used for horizontal position inside Row
    const resCards = await db.request("/auth/getcards", "POST", {
      project: props.auth.getProjId(),
      type: "planning",
    });

    setGoals(resGoals.data);
    formatData(resCards.data);
  }

  function formatData(data) {
    if (data && data.length > 0) {
      var _cards = [];
      var _idIdxMap = {};
      var category = 0;

      for (var i = 0; i < data.length; i++) {
        var card = data[i];
        _idIdxMap[card.card_id] = i;

        if (card.card_type === "category") {
          card.children = {};
          card.category = category;
          category++;

          _cards.push({
            type: "CATEGORY",
            id: card.card_id,
            name: card.card_name,
            desc: card.card_body,
            children: [],
          });
        }
      }

      for (i = 0; i < data.length; i++) {
        var card = data[i];
        if (card.card_type === "planned") {
          const parentRow = data[_idIdxMap[card.card_parent]].category;
          card.category = parentRow;
          card.type = "CARD";
          card.id = card.card_id;

          const subRow = card.card_priority ? card.card_priority : 0;
          const subCol = card.card_order_h ? card.card_order_h : 0;

          if (!_cards[parentRow].children[subRow]) {
            _cards[parentRow].children[subRow] = {};
            _cards[parentRow].children[subRow].type = "ROW";
            _cards[parentRow].children[subRow].id = subRow;
            _cards[parentRow].children[subRow].children = [];
          }

          // If horizontal position is taken, splice in
          // if (card.card_priority !== null) {
          //   _cards[parentRow].children[subRow].children.splice(subCol, 0, card);
          // } else {
          //   _cards[parentRow].children[subRow].children.push(card);
          // }

          _cards[parentRow].children[subRow].children.push(card);
        }
      }
      console.log(_cards);
      setCards(_cards);
      setCardsRaw(data);
      setIdIdxMap(_idIdxMap);
    }
  }

  async function addCard(type, parent, priority, order) {
    if (type !== "category") {
      const { category } = findCard(cards, parent, "CATEGORY");
      if (cards[category].children.length > 0) {
        // Has rows
        const blocks = cards[category].children[priority].children;
        const lastBlock = blocks[blocks.length - 1];
        const lexo = LexoRank.parse(lastBlock.card_order_h);
        const nextLexo = lexo.between(LexoRank.max());

        order = nextLexo.value;
      } else {
        // No existing rows
        priority = 0;
        order = LexoRank.middle().value;
      }
    }

    const data = {
      type: type,
      parent: parent, // card -> category
      project: props.auth.getProjId(),
      priority: priority,
      order: order,
    };
    const resAdd = await db.request("/auth/addcard", "POST", data);
    if (resAdd.status === 200) getData();
  }

  async function editCard(id, header, subheader) {
    const data = {
      id: id,
      name: header,
      desc: subheader,
    };
    const resEdit = await db.request("/auth/editcard", "POST", data);
  }

  async function deleteCard(id) {
    const data = {
      id: id,
    };
    const resDelete = await db.request("/auth/deleteplanned", "POST", data);

    if (resDelete.status === 200) getData();
  }

  async function moveCard(fromId, toId, categoryNum, row) {
    var from = findCard(cards, fromId, "CARD");
    var to = toId
      ? findCard(cards, toId, "CARD")
      : { category: categoryNum, row: row, col: 0 };

    var prev = structuredClone(cards);

    var rowFrom = prev[from.category].children[from.row].children;

    if (!prev[to.category].children[to.row]) {
      prev[to.category].children[to.row] = {};
      prev[to.category].children[to.row].type = "ROW";
      prev[to.category].children[to.row].children = [];
    }
    var rowTo = prev[to.category].children[to.row].children;

    var movee = rowFrom.splice(from.col, 1);
    rowTo.splice(to.col, 0, movee[0]);

    prev[from.category].children[from.row].children = rowFrom;
    prev[to.category].children[to.row].children = rowTo;

    setCards(prev);

    // move in db
    var newCategory = null;
    var newOrder = null;

    if (toId) {
      const toCard = cardsRaw[idIdxMap[toId]];
      const toCardPrev =
        cards[to.category].children[to.row].children[to.col - 1];
      if (to.col === 0) {
        newOrder = LexoRank.parse(toCard.card_order_h).between(LexoRank.min())
          .value;
      } else if (
        to.col ===
        cards[to.category].children[to.row].children.length - 1
      ) {
        newOrder = LexoRank.parse(toCard.card_order_h).between(LexoRank.max())
          .value;
      } else {
        newOrder = LexoRank.parse(toCard.card_order_h).between(
          LexoRank.parse(toCardPrev.card_order_h)
        ).value;
      }

      newCategory = toCard.card_parent;
    } else {
      newCategory = cardsRaw[idIdxMap[fromId]].card_parent;
      newOrder = LexoRank.middle().value;
    }

    const data = {
      id: fromId,
      project: props.auth.getProjId(),
      type: "planned",
      toCategory: newCategory,
      toPrio: to.row,
      toOrder: newOrder,
    };
    const resMove = await db.request("/auth/moveplanned", "POST", data);
  }

  function findCard(_data, id, type, path = { category: 0, row: 0, col: 0 }) {
    for (var i = 0; i < _data.length; i++) {
      if (_data[i].type === type && _data[i].id === id) {
        return { category: path.category, row: path.row, col: path.col };
      } else if (_data[i].children) {
        var result = findCard(_data[i].children, id, type, path);

        if (_data[i].type === "CATEGORY") {
          path.category++;
          path.row = 0;
          path.col = 0;
        } else if (_data[i].type === "ROW") {
          path.row++;
          path.col = 0;
        }

        if (result) return result;
      } else {
        path.col++;
      }
    }
  }

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Render

  return (
    <>
      {/*<Timeline
        auth={props.auth}
        startDate={new Date().subtractMonths(20)}
        endDate={new Date().addMonths(12)}
        goals={goals}
      />*/}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Category name="Stage" id="stage" noedit />
        <Category name="Milestones" id="stage" noedit />

        {cards &&
          cards.map((card, i) => {
            return (
              <Category
                key={"category" + card.id}
                id={card.id}
                category={card}
                categoryNum={i}
                name={card.name}
                desc={card.desc}
                children={card.children}
                onChange={editCard}
                addCard={addCard}
                moveCard={moveCard}
                deleteCard={deleteCard}
                getData={getData}
              />
            );
          })}

        <Block onClick={() => addCard("category")} add />
      </div>
    </>
  );
}

const Category = (props) => {
  const [dragging, setDragging] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 6,
      }}
    >
      <CategoryLabel
        id={props.id}
        header={props.name}
        subheader={props.desc}
        onChange={props.onChange}
        onDelete={props.deleteCard}
        noedit={props.noedit}
      />
      <div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {props.children && props.children.length > 0 ? (
          props.children.map((row, i) => {
            return (
              <Row
                key={"row" + row.id}
                category={props.category}
                blocks={row.children}
                row={i}
                addCard={props.addCard}
                editCard={props.onChange}
                moveCard={props.moveCard}
                onDelete={props.deleteCard}
                getData={props.getData}
                setDragging={setDragging}
              />
            );
          })
        ) : (
          <Row
            key={"rowBase"}
            category={props.category}
            row={0}
            addCard={props.addCard}
          />
        )}

        {dragging && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: -5,
              zIndex: 100,
            }}
          >
            <BlockDropzone
              categoryNum={props.categoryNum}
              row={props.children.length - 1}
              moveCard={props.moveCard}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CategoryLabel = (props) => {
  const [hovering, setHovering] = useState(false);
  const [editing, setEditing] = useState(false);
  const [hasHeaderFocus, setHasHeaderFocus] = useState(false);
  const [hasSubheaderFocus, setHasSubheaderFocus] = useState(false);
  const [header, setHeader] = useState(props.header ? props.header : "");
  const [subheader, setSubheader] = useState(
    props.subheader ? props.subheader : ""
  );

  useKey("Enter", handleEnter);

  const headerRef = useRef();
  const subheaderRef = useRef();

  useEffect(() => {
    setSubheaderHeight();
  }, [subheader]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Functions

  function setSubheaderHeight() {
    if (!subheaderRef.current) return;

    subheaderRef.current.style.height = "auto";
    subheaderRef.current.style.height = "5px";
    subheaderRef.current.style.height =
      subheaderRef.current.scrollHeight + "px";
  }

  function handleEnter(e) {
    e.preventDefault();
    if (e.target === headerRef.current) {
      setHasSubheaderFocus(true);
      subheaderRef.current.focus();
    }
  }

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Render

  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
    >
      <div
        style={{
          flexWrap: "wrap",
          position: "relative",
          border: "1px solid #ffffff33",
          borderRadius: 2,
          padding: "6px 10px",
          minWidth: header ? 100 : 30,
          width: 120,
          cursor: props.noedit ? "default" : "pointer",
          background: hovering ? "#ffffff11" : "#ffffff00",
        }}
        onClick={() => {
          if (props.add && !props.noedit) props.onClick("category");
        }}
      >
        {!props.add && (
          <input
            ref={headerRef}
            value={header}
            placeholder={"Title"}
            style={{
              fontSize: 12,
              display: "block",
              fontWeight: 600,
              background: "transparent",
              color: "#fff",
              outline: 0,
              border: "none",
              padding: 0,
              margin: 0,
              maxWidth: "100%",
              cursor: props.noedit ? "default" : "pointer",
              height: 17,
            }}
            onFocus={() => {
              setHasHeaderFocus(true);
            }}
            onBlur={() => {
              setHasHeaderFocus(false);
            }}
            onChange={(e) => {
              setHeader(e.target.value);
              props.onChange(props.id, e.target.value);
            }}
            disabled={props.noedit}
          />
        )}

        {!props.add && !props.noedit && (
          <textarea
            ref={subheaderRef}
            value={subheader}
            placeholder={"Description"}
            style={{
              fontSize: 9,
              display:
                (header && subheader) ||
                hovering ||
                hasHeaderFocus ||
                hasSubheaderFocus
                  ? "block"
                  : "none",
              fontStyle: "italic",
              background: "transparent",
              color: "#ffffffaa",
              minHeight: 11,
              outline: 0,
              border: "none",
              padding: 0,
              margin: 0,
              maxWidth: "100%",
              minWidth: 30,
              resize: "none",
              lineHeight: 1.2,
              cursor: "pointer",
            }}
            onFocus={() => {
              setHasSubheaderFocus(true);
            }}
            onBlur={() => {
              setHasSubheaderFocus(false);
            }}
            onChange={(e) => {
              setSubheader(e.target.value);
              props.onChange(props.id, null, e.target.value);
            }}
          />
        )}

        {!props.header && props.add && (
          <center
            style={{
              fontSize: 30,
              color: hovering ? "#ffffff99" : "#ffffff33",
            }}
          >
            +
          </center>
        )}

        {hovering && !props.add && !props.noedit && (
          <>
            <div
              style={{
                position: "absolute",
                right: 8,
                top: 5,
              }}
            >
              <ButtonDots
                onClick={() => {
                  setEditing(true);
                }}
              />
            </div>
          </>
        )}

        {editing && !props.add && !props.noedit && (
          <div style={{ position: "absolute", left: 60 }}>
            <Popup
              onClose={() => {
                setEditing(false);
              }}
            >
              <div
                className="fas fa-trash-alt"
                style={{
                  color: "red",
                  fontSize: 16,
                  margin: "0px 30px",
                }}
                onClick={() => {
                  props.onDelete(props.id);
                }}
              />
            </Popup>
          </div>
        )}
      </div>
    </div>
  );
};

const Row = (props) => {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        border: "1px solid #ffffff11",
        minWidth: 60,
      }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
    >
      {props.blocks &&
        props.blocks.map((block) => {
          return (
            <Block
              key={"block" + block.card_id}
              id={block.card_id}
              card={block}
              row={props.row}
              size={block.card_estimate}
              onChange={props.editCard}
              onDelete={props.onDelete}
              moveCard={props.moveCard}
              getData={props.getData}
              setDragging={props.setDragging}
            />
          );
        })}

      {hovering && !props.isDragging && props.addCard && (
        <Block
          onClick={() =>
            props.addCard(
              "planned",
              props.category.id,
              props.row,
              props.blocks ? props.blocks.length : 0
            )
          }
          add
        />
      )}
    </div>
  );
};

const Block = (props) => {
  const WEEK_SIZE = 30;

  const [hovering, setHovering] = useState(false);
  const [editing, setEditing] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [rect, setRect] = useState(null);
  const [value, setValue] = useState(
    props.card && props.card.card_name ? props.card.card_name : ""
  );
  const [id, setId] = useState(props.id);
  const [size, setSize] = useState(props.size ? props.size : 4);
  const [resizeOrigin, setResizeOrigin] = useState(-1);

  const ref = useRef();
  const editingRef = useRef();
  const resizingRef = useRef();
  const resizeOriginRef = useRef();
  const sizeRef = useRef();

  useLayoutEffect(() => {
    setRect(ref.current.getBoundingClientRect());
  }, [size]);

  useEffect(() => {
    editingRef.current = editing;
  }, [editing]);

  useEffect(() => {
    resizingRef.current = resizing;
  }, [resizing]);

  useEffect(() => {
    resizeOriginRef.current = resizeOrigin;
  }, [resizeOrigin]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "block",
    item: () => {
      if (props.setDragging) props.setDragging(true);
      return { id: id };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => {
      return !editingRef.current && !resizingRef.current;
    },
    end: () => {
      if (props.setDragging) props.setDragging(false);
    },
  }));

  const [, drop] = useDrop({
    accept: "block",
    hover: (dragged, monitor) => {
      if (dragged.id === id) return;

      if (props.moveCard) props.moveCard(dragged.id, id);
    },
  });

  drag(drop(ref));

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Functions

  function resizeStart(e) {
    setResizing(true);
    setResizeOrigin(e.screenX);
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", resizeStop);
  }

  const resize = useCallback(
    (e) => {
      const newSize = Math.abs(
        size + (e.screenX - resizeOriginRef.current) / WEEK_SIZE
      );

      if (newSize !== size) {
        setSize(newSize);
      }
    },
    [size]
  );

  const resizeStop = useCallback(
    async (e) => {
      const newSize = Math.abs(
        size + Math.round((e.screenX - resizeOriginRef.current) / WEEK_SIZE)
      );

      setResizing(false);
      setSize(newSize);

      await db.request("/auth/editcard", "POST", { id: id, estimate: newSize });

      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", resizeStop);
    },
    [size]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Render

  var bg = "#fff";
  if (isDragging || (props.add && !hovering)) bg = "transparent";
  else if (props.add) bg = "#ffffff11";

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        background: bg,
        fontSize: 10,
        width: size * WEEK_SIZE,
        maxWidth: props.add ? 100 : "initial",
        fontWeight: "initial",
        padding: props.add ? 0 : "5px 14px",
        marginRight: 1,
        color: "#000",
        cursor: "pointer",
        opacity: props.opacity && !hovering ? props.opacity : 1,
        border: isDragging ? "1px dashed #ffffff22" : "1px solid transparent",
      }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
      onClick={props.add ? props.onClick : null}
    >
      {!props.opacity && !props.add && hovering && !isDragging && (
        <>
          <div
            style={{
              position: "absolute",
              right: 14,
            }}
          >
            <ButtonDots
              onClick={() => {
                setEditing(true);
              }}
            />
          </div>
          <ButtonResize resizeStart={resizeStart} />
        </>
      )}
      {!props.add && (
        <input
          style={{
            display: "block",
            textAlign: "center",
            border: 0,
            width: "100%",
            outline: 0,
            fontSize: 12,
            color: "#000",
            background: "transparent",
            verticalAlign: "middle",
            padding: 0,
            overflow: "hidden",
            cursor: "pointer",
            opacity: isDragging ? 0 : 1,
          }}
          placeholder="Job needed done.."
          maxLength={320}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(props.id, e.target.value);
          }}
        />
      )}

      {/*!props.add && (
        <div style={{ display: "block", height: 18 }}>
          {props.tags &&
            props.tags.map((tag, i) => {
              return <div />;
            })}
        </div>
      )*/}

      {props.add && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: 100,
          }}
        >
          <div
            className="fas fa-plus"
            style={{
              fontSize: 20,
              color: hovering ? "#ffffff99" : "#ffffff33",
            }}
          />
        </div>
      )}

      {editing && (
        <div
          style={{
            position: "absolute",
            top: rect.height + 4,
            left: rect.width / 2,
          }}
        >
          <Popup
            onClose={() => {
              setEditing(false);
            }}
          >
            <div
              className="fas fa-trash-alt"
              style={{
                color: "red",
                fontSize: 16,
                margin: "0px 30px",
              }}
              onClick={() => {
                props.onDelete(id);
              }}
            />
          </Popup>
        </div>
      )}
    </div>
  );
};

const BlockDropzone = (props) => {
  const ref = useRef();

  const [, drop] = useDrop(
    {
      accept: "block",
      hover: (dragged, monitor) => {
        console.log(dragged.id, null, props.categoryNum, props.row);
        if (props.moveCard)
          props.moveCard(dragged.id, null, props.categoryNum, props.row + 1);
      },
      canDrop: (item, monitor) => {
        console.log(item);
      },
    },
    [props.categoryNum, props.row]
  );
  drop(ref);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: 15,
        border: "1px red solid",
        background: "#ff990077",
      }}
    />
  );
};

const ButtonDots = (props) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      className="fas fa-ellipsis-h"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "gray",
        width: 20,
        height: 20,
        borderRadius: "50%",
        background: hovering ? "#eee" : "transparent",
        fontSize: 12,
      }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
      onClick={props.onClick ? props.onClick : null}
    />
  );
};

const ButtonResize = (props) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      className="fas fa-grip-lines-vertical"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        right: 0,
        width: 10,
        height: "100%",
        color: "gray",
        background: hovering ? "#eee" : "transparent",
      }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
      onMouseDown={(e) => {
        props.resizeStart(e);
      }}
    />
  );
};

const Popup = (props) => {
  const [width, setWidth] = useState(30);

  const ref = useRef();
  const innerRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleMenuClick);
    document.addEventListener("keydown", handleMenuClick);

    return () => {
      close();
    };
  }, []);

  useLayoutEffect(() => {
    setWidth(innerRef.current.getBoundingClientRect().width);
  }, []);

  function handleMenuClick(e) {
    if (
      e.key === "Escape" ||
      (e.type === "mousedown" && ref.current && !ref.current.contains(e.target))
    ) {
      close();
    }
  }

  function close() {
    document.removeEventListener("mousedown", handleMenuClick);
    document.removeEventListener("keydown", handleMenuClick);
    if (props.onClose) props.onClose();
  }

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        zIndex: 1000,
        marginLeft: -width / 2,
        transition: "none",
      }}
    >
      <div
        className="triangle"
        style={{
          position: "absolute",
          zIndex: 1001,
          top: 2,
          left: width / 2 + 9,
          transition: "none",
        }}
      />
      <div
        ref={innerRef}
        style={{
          position: "absolute",
          display: "inline-block",
          zIndex: 1000,
          minWidth: 30,
          minHeight: 30,
          borderRadius: 10,
          padding: 10,
          background: "#fff",
          boxShadow: "2px 2px 5px #00000088",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

const Goal = (props) => {
  const [showDesc, setShowDesc] = useState(false);
  const [hovering, setHovering] = useState(false);

  return (
    <div
      style={{
        display: "inline-block",
        borderLeft: "1px #ffffff44 solid",
        width: 400,
        padding: 20,
        margin: 5,
        borderRadius: 10,
        whiteSpace: "initial",
        verticalAlign: "top",
        fontWeight: 700,
      }}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
    >
      <div style={{ fontSize: 20 }}>{props.name}</div>
      {props.desc && (
        <div
          style={{
            maxWidth: "100%",
            fontSize: 11,
            color: "#ffffffaa",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowDesc(!showDesc);
          }}
        >
          {showDesc ? props.desc : props.desc.substring(0, 50) + "..."}
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {props.cards &&
          props.cards.map((pillar, i) => {
            return (
              <div key={"pillar" + i}>
                <div style={{ fontSize: 9, fontWeight: 100 }}>
                  {pillar.card_name}
                </div>
                {pillar.children &&
                  pillar.children.map((epic, j) => {
                    return <Block key={"epic" + j} name={epic.card_name} />;
                  })}
              </div>
            );
          })}
        {hovering && <OldEpic name={"+"} opacity={0.3} />}
      </div>
    </div>
  );
};
