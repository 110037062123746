import React, { Fragment } from "react";
import moment from "moment";

class Day extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      hovered: false,
    };

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.dragSource = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    var shouldUpdate =
      (this.props.selected !== undefined &&
        nextProps.selected !== undefined &&
        this.props.selected !== nextProps.selected) ||
      nextProps.type !== this.props.type ||
      nextProps.movement !== this.props.movement ||
      nextProps.modify !== this.props.modify ||
      nextProps.status !== this.props.status ||
      nextProps.moveJoin === true ||
      nextProps.moveLeft === true ||
      nextProps.moveJoin !== this.props.moveJoin ||
      nextProps.moveLeft !== this.props.moveLeft ||
      nextState.hovered !== this.state.hovered ||
      nextProps.multiSelected !== this.props.multiSelected;

    return shouldUpdate;
  }

  onMouseDown(e) {
    e.preventDefault();
    if (
      this.props.onStartDrag &&
      !this.state.dragging &&
      !this.props.disableDrag
    ) {
      document.addEventListener("mouseup", this.onMouseUp);
      var seed = Math.random() * 1000 + 1;
      this.props.onStartDrag(
        this.props.gridKey,
        seed,
        this.props.position,
        this.props.type
      );
      this.dragSource = seed;
      this.setState({ dragging: true });
    }
  }

  onMouseUp() {
    document.removeEventListener("mouseup", this.onMouseUp);
    if (this.props.onStopDrag) this.props.onStopDrag();
    this.setState({ dragging: false });
  }

  onMouseEnter() {
    if (this.props.isDragging && this.props.isDragging !== this.dragSource) {
      this.dragSource = this.props.isDragging;
      this.props.selectDayCb(
        this.props.gridKey,
        this.props.position,
        this.props.type
      );
    } else {
      this.setState({ hovered: true });
      if (this.props.hoveringCb)
        this.props.hoveringCb(this.props.col, this.props.row);
    }
  }

  onMouseLeave() {
    this.setState({ hovered: false });
    if (this.props.hoveringCb) this.props.hoveringCb(null, null);
  }

  render() {
    var result = [];
    var background = "#32CD32";
    var border = null;
    var icon = "";
    var checker = (
      <i
        className="fas fa-check"
        style={{
          display: "block",
          color: "#3c8fa8",
          fontSize: "0.8em",
          verticalAlign: "middle",
          marginLeft: -1,
        }}
      />
    );

    switch (this.props.status) {
      case "sick":
        background = "#ff8c8c";
        icon = "";
        break;
      case "vacation":
        background = "#f1c232";
        icon = "";
        break;
      case "vab":
        background = "#ffc18c";
        icon = "";
        break;
      case "parental":
        background = "#8ca4ff";
        icon = "";
        break;
      case "localholiday":
        background = "transparent";
        border = "2px solid #f1c232";
        break;
      case "otherwork":
        background = "#ccc";
        break;
      case "offproject":
        background = "transparent";
        break;
      default:
    }

    var iconAdd = (
      <i
        className="fas fa-plus"
        style={{
          display: "block",
          color: "#473673",
          fontSize: "0.8em",
          verticalAlign: "middle",
          paddingTop: 2,
          paddingLeft: 1,
        }}
      />
    );

    var iconEdit = (
      <i
        className="fas fa-pencil-alt"
        style={{
          display: "block",
          color: "#cccccc3c",
          verticalAlign: "middle",
          paddingTop: 2,
          paddingLeft: 1,
          fontSize: "0.65em",
        }}
      />
    );

    var iconDot = (
      <i
        className="fas fa-plus-circle"
        style={{
          display: "block",
          color: "#cccccc3c",
          verticalAlign: "middle",
          paddingTop: 2,
          paddingLeft: 1,
          fontSize: "0.75em",
        }}
      />
    );

    var borderRadius = "3px";

    // Override all visuals to indicate available movement of peepz start and leave in projects
    var move = this.props.movement;
    if (move && this.props.row === move.row && this.props.isInCompany) {
      // First available slots for moving the start date earlier to
      var latest = move.latest > -1 ? move.latest : move.latestCompany;
      var sooner =
        this.props.idx < move.current && this.props.idx >= move.soonest;
      var later = this.props.idx > move.current && this.props.idx <= latest;

      if (this.props.isWeekday) {
        if (sooner || later) {
          result.push(
            <button
              key={this.props.gridKey + "_move"}
              className="btn-moveday"
              style={{
                position: "absolute",
                width: this.props.width,
                height: this.props.height,
                borderRadius: "3px",
                padding: "0px",
                margin: "0px",
                cursor: "pointer",
                border: "1px solid #bbb",
                backgroundColor: "transparent",
                color: "#999",
                outline: "none",
              }}
              onClick={() => {
                this.props.submitJoinLeaveCb(
                  this.props.userid,
                  move.current,
                  this.props.idx,
                  this.props.status,
                  this.props.dayid,
                  this.props.projid
                );
              }}
            >
              <i
                className={sooner ? "fas fa-angle-left" : "fas fa-angle-right"}
                style={{
                  display: "block",
                  fontSize: "0.8em",
                  verticalAlign: "middle",
                  paddingTop: 2,
                  paddingLeft: 1,
                }}
              />
            </button>
          );
        } else if (move.current === this.props.idx) {
          result.push(
            <button
              key={this.props.gridKey + "_moveday"}
              style={{
                position: "absolute",
                width: this.props.width,
                height: this.props.height,
                borderRadius: "3px",
                padding: "0px",
                margin: "0px",
                cursor: "pointer",
                border: "1px solid #bbb",
                backgroundColor: "transparent",
                color: "#999",
                outline: "none",
              }}
              onClick={() => {
                this.props.cancelJoinLeaveCb(null, true);
              }}
            >
              <i
                className="fas fa-times"
                style={{
                  display: "block",
                  fontSize: "0.8em",
                  color: "red",
                  verticalAlign: "middle",
                  paddingTop: 2,
                  paddingLeft: 1,
                }}
              />
            </button>
          );
        }
      }
    } else if (this.props.type === "add") {
      result.push(
        <div key={this.props.gridKey + "_add"}>
          <button
            key={this.props.gridKey + "_add2"}
            type="button"
            id="dropdownAddButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              position: "absolute",
              left: 0,
              background: this.props.color ? this.props.color : "#674ea7",
              width: this.props.width,
              height: this.props.height,
              borderRadius: "3px",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              border: 0,
              outline: "none",
            }}
          >
            {iconAdd}
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{
              WebkitTransition: "none",
              msTransition: "none",
              zIndex: 1000,
            }}
          >
            {this.props.auth.requiresRole("janitor") && (
              <Fragment>
                <div className="dropdown-header">
                  {moment(this.props.date).format("dddd")}
                </div>
                <Fragment>
                  <button
                    id="dropMenuItem"
                    className="dropdown-item"
                    onClick={(e) => {
                      this.props.submitCb("person", this.props.date);
                    }}
                  >
                    Person joins
                  </button>
                  <button
                    id="dropMenuItem"
                    className="dropdown-item"
                    onClick={(e) => {
                      this.props.submitCb("milestone", this.props.date);
                    }}
                  >
                    Create milestone
                  </button>

                  {(!this.props.hasHoliday ||
                    (this.props.hasHoliday &&
                      this.props.hasHoliday.type === "offday")) && (
                    <Fragment>
                      <div className="dropdown-divider" />
                      <button
                        id="dropMenuItem"
                        className="dropdown-item"
                        onClick={(e) => {
                          this.props.setWorkdayCb(
                            !this.props.hasHoliday,
                            this.props.date
                          );
                        }}
                      >
                        {!this.props.hasHoliday
                          ? "Cancel workday"
                          : "Reset workday"}
                      </button>
                    </Fragment>
                  )}
                </Fragment>
              </Fragment>
            )}
          </div>
        </div>
      );
    } else if (this.props.type === "addtocompany") {
      result.push(
        <div key={this.props.gridKey + "_addcomp"}>
          <button
            type="button"
            id="dropdownAddButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              position: "absolute",
              left: 0,
              background: this.props.color ? this.props.color : "#bbb",
              width: this.props.width,
              height: this.props.height,
              borderRadius: "3px",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              border: 0,
              outline: "none",
            }}
          >
            {iconAdd}
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{
              WebkitTransition: "none",
              msTransition: "none",
            }}
          >
            {this.props.auth.requiresRole("janitor") && (
              <Fragment>
                <div className="dropdown-header">
                  {moment(this.props.date).format("dddd")}
                </div>
                <Fragment>
                  <button
                    id="dropMenuItem"
                    className="dropdown-item"
                    onClick={(e) => {
                      this.props.submitCb("employee", this.props.date);
                    }}
                  >
                    New employee
                  </button>
                  <button
                    id="dropMenuItem"
                    className="dropdown-item"
                    onClick={(e) => {
                      this.props.submitCb("consultant", this.props.date);
                    }}
                  >
                    New consultant
                  </button>
                </Fragment>
              </Fragment>
            )}
          </div>
        </div>
      );
    } else if (this.props.type === "holiday") {
      var isOffday = this.props.holiday && this.props.holiday.type === "offday";
      result.push(
        <div
          key={this.props.gridKey}
          style={{
            position: "absolute",
            left: 0,
            background: isOffday ? "#ddd" : "transparent",
            width: this.props.width,
            height: this.props.height,
            borderRadius: borderRadius,
            margin: "0px",
            fontSize: this.props.height * 0.56,
            paddingTop: this.props.height * 0.24,
            border: isOffday ? 0 : "2px solid #f1c232",
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      );
    } else if (
      this.props.modify &&
      this.props.isWeekday &&
      this.props.isInCompany
    ) {
      if (this.props.type === "notjoined") {
        if (!this.props.noScatterJoin) {
          result.push(
            <div key={this.props.gridKey}>
              <button
                type="button"
                id="dropdownAddButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  position: "absolute",
                  left: 0,
                  width: this.props.width,
                  height: this.props.height,
                  borderRadius: "3px",
                  padding: "0px",
                  margin: "0px",
                  cursor: "pointer",
                  border: 0,
                  outline: "none",
                  background: "transparent",
                }}
              >
                {iconDot}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  WebkitTransition: "none",
                  msTransition: "none",
                }}
              >
                {this.props.auth.requiresRole("janitor") && (
                  <button
                    id="dropMenuItem"
                    className="dropdown-item"
                    onClick={(e) => {
                      this.props.menuSubmitCb(
                        this.props.row,
                        this.props.idx,
                        "join",
                        this.props.projid
                      );
                    }}
                  >
                    Join {this.props.gridDescriptor}
                  </button>
                )}
              </div>
            </div>
          );
        } else {
          result.push(<div key={this.props.gridKey} />);
        }
      } else {
        result.push(
          <div key={this.props.gridKey}>
            <button
              type="button"
              id="dropdownAddButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                position: "absolute",
                left: 0,
                background: background,
                width: this.props.width,
                height: this.props.height,
                borderRadius: "3px",
                padding: "0px",
                margin: "0px",
                cursor: "pointer",
                border: 0,
                outline: "none",
              }}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              {iconEdit}
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{
                WebkitTransition: "none",
                msTransition: "none",
              }}
            >
              {this.props.auth.requiresRole("janitor") && (
                <button
                  id="dropMenuItem"
                  className="dropdown-item"
                  onClick={(e) => {
                    this.props.menuSubmitCb(
                      this.props.row,
                      this.props.idx,
                      "leave",
                      this.props.projid
                    );
                  }}
                >
                  Leave {this.props.gridDescriptor}
                </button>
              )}
            </div>
          </div>
        );
      }
    } else if (this.props.type === "notjoined" && this.props.isInCompany) {
      if (this.props.isWeekday) {
        var resultIcon2 = this.props.selected ? checker : "";
        result.push(
          <div
            key={this.props.gridKey + "_notjoined"}
            style={{
              position: "absolute",
              left: 0,
              background: this.props.selected ? "#5bc0de" : "transparent",
              width: this.props.width,
              height: this.props.height,
              borderRadius: borderRadius,
              margin: "0px",
              border: "1px solid transparent",
              fontSize: this.props.height * 0.56,
              paddingTop: this.props.height * 0.24,
              paddingLeft: this.props.width * 0.33,
              outline: "none",
            }}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            {resultIcon2}
          </div>
        );
      } else {
        result.push(
          <div
            key={this.props.gridKey + "_notjoined"}
            style={{
              position: "absolute",
              left: 0,
              background: "transparent",
              width: this.props.width,
              height: this.props.height,
              borderRadius: "3px",
              padding: "0px",
              margin: "0px",
              border: this.props.isWeekday ? "1px red solid" : 0,
            }}
          />
        );
      }
    } else {
      if (this.props.isInCompany) {
        var resultIcon = this.props.selected ? checker : icon;

        result.push(
          <div key={this.props.gridKey}>
            <Fragment>
              {this.props.auth.requiresRole("janitor") ||
              this.props.auth.getUserId() === this.props.userid ? (
                <Fragment>
                  <button
                    id="day"
                    type="button"
                    style={{
                      position: "absolute",
                      left: 0,
                      background: this.props.selected ? "#5bc0de" : background,
                      width: this.props.width,
                      height: this.props.height,
                      borderRadius: borderRadius,
                      margin: "0px",
                      cursor: "pointer",
                      border: border ? border : 0,
                      fontSize: this.props.height * 0.56,
                      paddingTop: this.props.height * 0.24,
                      paddingLeft: this.props.width * 0.33,
                      outline: "none",
                      boxShadow:
                        this.props.status === "offproject"
                          ? 0
                          : "1px 1px 2px #333",
                    }}
                    onMouseDown={this.onMouseDown}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                  >
                    {resultIcon}
                  </button>
                  {!this.props.multiSelected && this.props.selected && (
                    <div
                      style={{
                        position: "absolute",
                        background: "#fff",
                        borderRadius: 4,
                        top: 20,
                        WebkitTransition: "none",
                        msTransition: "none",
                        boxShadow: "1px 1px 3px #111",
                        padding: 4,
                        zIndex: 1,
                      }}
                    >
                      {!this.props.moveLeft && !this.props.moveJoin && (
                        <button
                          id="dropMenuItem"
                          className="dropdown-item"
                          onClick={(e) => {
                            this.props.menuSubmitCb(
                              this.props.row,
                              this.props.idx,
                              "leave",
                              this.props.projid
                            );
                            this.props.cancelSelection();
                          }}
                        >
                          Leave {this.props.gridDescriptor}
                        </button>
                      )}
                      {(this.props.moveLeft || this.props.moveJoin) && (
                        <button
                          id="dropMenuItem"
                          className="dropdown-item"
                          onClick={() => {
                            this.props.clickJoinLeaveDay(
                              this.props.row,
                              this.props.idx,
                              this.props.moveLeft ? "left" : "join"
                            );
                            this.props.cancelSelection();
                          }}
                        >
                          Move {this.props.moveLeft ? "end-date" : "start-date"}
                        </button>
                      )}
                    </div>
                  )}
                </Fragment>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    background: background,
                    width: this.props.width,
                    height: this.props.height,
                    borderRadius: "3px",
                    margin: "0px",
                    cursor: "not-allowed",
                    fontSize: this.props.height * 0.56,
                    paddingTop: this.props.height * 0.24,
                    paddingLeft: this.props.width * 0.33,
                    border: 0,
                  }}
                >
                  <center>{icon}</center>
                </div>
              )}
            </Fragment>
          </div>
        );
      }
    }

    // Add moving buttons after main button added

    if (
      this.props.modify &&
      (!this.props.movement || this.props.movement.row !== this.props.row)
    ) {
      var size = 1;
      var pad = (this.props.height * size) / 2;
      if (this.props.moveJoin) {
        result.push(
          <button
            key={this.props.gridKey + "_join"}
            type="button"
            style={{
              position: "absolute",
              left: -pad,
              top: this.props.height / 2 - pad,
              background: "#ddd",
              boxShadow: "1px 1px 3px 2px #111",
              width: this.props.width * size,
              height: this.props.height * size,
              borderRadius: "50%",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              border: 0,
              zIndex: 400,
              outline: "none",
            }}
            onClick={() =>
              this.props.submitCb(this.props.row, this.props.idx, "join")
            }
          >
            <i
              className="fas fa-sign-in-alt"
              style={{
                display: "block",
                color: "#333",
                fontSize: "0.75em",
                verticalAlign: "middle",
                paddingTop: 1,
                paddingLeft: 0,
              }}
            />
          </button>
        );
      }
      if (this.props.moveLeft) {
        result.push(
          <button
            key={this.props.gridKey + "_left"}
            type="button"
            style={{
              position: "absolute",
              left: pad,
              top: this.props.height / 2 - pad,
              background: "#ddd",
              boxShadow: "1px 1px 3px 2px #111",
              width: this.props.width * size,
              height: this.props.height * size,
              borderRadius: "50%",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              border: 0,
              zIndex: 400,
              outline: "none",
            }}
            onClick={() =>
              this.props.submitCb(this.props.row, this.props.idx, "left")
            }
          >
            <i
              className="fas fa-sign-out-alt"
              style={{
                display: "block",
                color: "#333",
                fontSize: "0.75em",
                verticalAlign: "middle",
                paddingTop: 2,
                paddingLeft: 0,
              }}
            />
          </button>
        );
      }
    }

    return result;
  }
}
export default Day;
