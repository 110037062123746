import React, { Component } from "react";
import db from "../utils/db";
import validator from "validator";
import passValidator from "password-validator";
import TextInput from "../components/TextInput";
import SequenceInput from "../components/SequenceInput";
import history from "../utils/history";
import moment from "moment";
import { toast } from "react-toastify";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flowStep: -1,
      offerResetPass: false,
      domainPlaceholder: "companydomain.com",
      emailSubmitted: false,
      profileSubmitted: false,
    };

    this.resetSignup = this.resetSignup.bind(this);
    this.resumeSignup = this.resumeSignup.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.submitCode = this.submitCode.bind(this);
    this.updateDomain = this.updateDomain.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.submitProfile = this.submitProfile.bind(this);
    this.showAlert = this.showAlert.bind(this);

    this.email = React.createRef();
    this.companyname = React.createRef();
    this.domain = React.createRef();
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.password = React.createRef();
  }

  componentDidMount() {
    this.resetSignup();
    // Check if key from previous signup process is provided in url
    var urlKey = this.props.location.pathname;
    urlKey =
      urlKey.search("key=") !== -1
        ? urlKey.slice(urlKey.search("key=") + 4)
        : undefined;
    if (urlKey) {
      this.resumeSignup(urlKey);
    }
  }

  resetSignup(goback = true) {
    var state = this.state;
    if (goback) state.flowStep = 0;
    localStorage.removeItem("signup_email");
    this.setState(state);
  }

  async resumeSignup(key) {
    const res = await db.request("/signup", "POST", {
      action: "check_key",
      key: key,
    });
    var state = this.state;
    if (res.status === 200) {
      // Approved resuming with provided key
      if (res.step === "signup1") state.flowStep = 1;
      else if (res.step === "signup2") state.flowStep = 2;

      localStorage.setItem("signup_email", res.email);
    } else if (res.status === 500) {
      // TODO: The token is either invalid or expired
      this.showAlert("The provided token seems either invalid or expired.");
      this.resetSignup();
    }
    this.setState(state);
  }

  showAlert(msg) {
    toast.warn(() => (
      <div style={{ verticalAlign: "middle" }}>
        <i
          className="fas fa-exclamation-triangle"
          style={{ paddingRight: "5px", fontSize: "1.2rem" }}
        />
        <span>{msg}</span>
      </div>
    ));
  }

  validateEmail(value) {
    var msg = "";
    if (!validator.isEmail(value)) msg = "A valid email please, sir/mam.";
    return msg;
  }

  onChangeEmail() {
    var state = this.state;
    state.offerResetPass = false;
    this.setState(state);
  }

  async submitEmail(e) {
    // Sign Up - Step 1: Register first owner email
    e.preventDefault();
    var state = this.state;
    state.emailSubmitted = true;

    // If email is already registered, resend password reset
    if (state.offerResetPass) {
      var res = await db.request(
        "/resetpass/" + this.email.current.value(),
        "GET"
      );
      if (res.status === 200) {
        history.push("/login");
      }
      return;
    }

    var submitSuccess = false;
    var hasErrors = this.email.current.hasErrors();

    if (!hasErrors) {
      // Send email with code
      await db.request("/signup", "POST", {
        email: this.email.current.value(),
        action: "submit_step1",
      });
      localStorage.setItem("signup_email", this.email.current.value());
      submitSuccess = true;
    }

    if (!submitSuccess) {
      this.email.current.submit();
    } else {
      state.flowStep = 1;
    }
    this.setState(state);
  }

  async submitCode(val) {
    const res = await db.request("/signup", "POST", {
      email: localStorage.getItem("signup_email"),
      code: val,
      action: "submit_step2",
    });
    if (res.status === 200) {
      // Code OK! Move on
      var state = this.state;
      state.flowStep = 2;
      this.setState(state);
      return true;
    }
    return false;
  }

  validateName(value) {
    var msg = "";
    const regex = new RegExp(
      /^[a-zA-ZàáâäãåąčćçęèéêëėįìíîïłńñòóôöõøšùúûüųūÿýżźžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u
    );
    if (validator.trim(value) === "") msg = "This cannot be empty, yo..";
    else if (!regex.test(value)) msg = "Non-valid characters in name";
    return msg;
  }

  updateDomain(value) {
    var retVal = value.toLowerCase();
    retVal = /[àáâäãåąAÀÁÂÄÃÅĄÆ∂ð]/g[Symbol.replace](retVal, "a");
    retVal = /[čćçCĆČÇŒ]/g[Symbol.replace](retVal, "c");
    retVal = /[ęèéêëėEĖĘÈÉÊË]/g[Symbol.replace](retVal, "e");
    retVal = /[įìíîïIÌÍÎÏĮ]/g[Symbol.replace](retVal, "i");
    retVal = /[łŁL]/g[Symbol.replace](retVal, "l");
    retVal = /[ńñNŃÑ]/g[Symbol.replace](retVal, "n");
    retVal = /[òóôöõøOÒÓÔÖÕØ]/g[Symbol.replace](retVal, "o");
    retVal = /[šßSŠ]/g[Symbol.replace](retVal, "s");
    retVal = /[ùúûüųūUÙÚÛÜŲŪ]/g[Symbol.replace](retVal, "u");
    retVal = /[ÿýYŸÝ]/g[Symbol.replace](retVal, "y");
    retVal = /[żźžZŻŹŽ]/g[Symbol.replace](retVal, "z");
    retVal = /[^a-z0-9]/g[Symbol.replace](retVal, "");
    this.domain.current.setValue(retVal);
  }

  validateDomain(value) {
    var msg = "";
    const regex = new RegExp(
      /^[a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž-]+$/u
    );
    if (validator.trim(value) === "") msg = "This cannot be empty, yo..";
    else if (!regex.test(value)) msg = "Non-valid characters in name";
    return msg;
  }

  validatePassword(value) {
    var state = this.state;
    state.password = value;
    this.setState(state);

    var schema = new passValidator();
    schema
      .is()
      .min(8)
      .is()
      .max(25)
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces();

    var messages = {
      min: "At least 8 characters",
      uppercase: "A capital letter",
      lowercase: "A lower case letter",
      digits: "At least a number",
      spaces: "No spaces",
      empty: "Is not empty",
    };

    var errors = schema.validate(value, { list: true });
    if (value === "") errors.push("empty");

    var msg = [];
    Object.keys(messages).map((key) =>
      msg.push({
        valid: !errors.includes(key),
        message: messages[key],
      })
    );

    return msg;
  }

  async submitProfile(e) {
    e.preventDefault();

    var submitSuccess = false;
    var domainStatus = "";
    var mail = localStorage.getItem("signup_email");
    var state = this.state;
    var hasErrors =
      this.companyname.current.hasErrors() ||
      this.domain.current.hasErrors() ||
      this.firstname.current.hasErrors() ||
      this.lastname.current.hasErrors() ||
      this.password.current.hasErrors();

    if (!hasErrors) {
      // Add company & user to db

      // First get date (off weekends)
      var mDate = moment();
      // Ensure date is not weekend
      if (mDate.day() === 0) mDate.add(1, "day");
      else if (mDate.day() === 6) mDate.add(2, "day");
      var date = mDate.format("DD MM YYYY");

      const response = await db.request("/signup", "POST", {
        email: mail,
        company: this.companyname.current.value(),
        domain: this.domain.current.value(),
        firstname: this.firstname.current.value(),
        lastname: this.lastname.current.value(),
        password: this.password.current.value(),
        date: date,
        action: "submit_step3",
        trialProject: localStorage.getItem("project"),
        trialData: localStorage.getItem("product"),
      });

      if (response.status === 200) {
        // New account successfully create
        submitSuccess = true;
      } else if (response.status === 500) {
        // Domain already exists in db
        domainStatus = "That domain is already taken";
      }
    }

    if (!submitSuccess) {
      this.companyname.current.validate(true);
      this.domain.current.submit(domainStatus);
      this.firstname.current.validate(true);
      this.lastname.current.validate(true);
      this.password.current.validate(true);
      this.setState(state);
    } else {
      // SUCCESS - DONE! Sign up completed successfully

      // Log new owner user in
      const resLogin = await db.request("/login", "POST", {
        email: mail,
        password: this.password.current.value(),
        domain: this.domain.current.value(),
      });

      this.props.auth.login(resLogin.token, resLogin.expires);
      this.props.auth.setUser(
        resLogin.firstname,
        resLogin.lastname,
        resLogin.email,
        resLogin.userid
      );
      this.props.auth.setOrgs(resLogin.org, resLogin.orgs);
      this.props.auth.setProjs(JSON.parse(resLogin.projs), 0);
      this.props.dispatch.fire("orgchange");
      this.props.dispatch.fire("projrefresh", resLogin.proj);
      this.props.dispatch.fire("userupdate");

      this.resetSignup(false);

      // Clear any local try-data
      localStorage.removeItem("project");
      localStorage.removeItem("cardIdx");
      localStorage.removeItem("product");

      history.push("/playbook");
    }
  }

  render() {
    var page = "";
    var btnStyle = !this.state.offerResetPass
      ? "btn btn-success btn-lg btn-block"
      : "btn btn-danger btn-lg btn-block";
    var btnLabel = !this.state.offerResetPass ? "NEXT" : "Go reset password";

    btnStyle +=
      (this.state.flowStep === 0 && this.state.emailSubmitted) ||
      (this.state.flowStep === 2 && this.state.profileSubmitted)
        ? " disabled"
        : "";

    switch (this.state.flowStep) {
      case 0:
        page = (
          <div>
            <center>
              <h1>Sure thing.</h1>
              First, your work email. You will have the role of{" "}
              <strong>Owner</strong>.
            </center>
            <br />
            <form onSubmit={this.submitEmail}>
              <TextInput
                validateCallback={this.validateEmail}
                changeCallback={this.onChangeEmail}
                requireSubmit={true}
                ref={this.email}
                placeholder="you@companydomain.com"
                maxlength="50"
                autofocus
              />
              <button className={btnStyle} onClick={this.submitEmail}>
                <div>
                  {btnLabel} <i className="fas fa-angle-right" />
                </div>
              </button>
            </form>
          </div>
        );
        break;
      case 1:
        page = (
          <div>
            <center>
              <h1>Confirm You</h1>
              Check your inbox. There´s a <strong>code</strong> you need to get
              right now.
            </center>
            <br />
            <SequenceInput validateCallback={this.submitCode} />
          </div>
        );
        break;
      case 2:
        page = (
          <div>
            <center>
              <h1>Alright! Last step.</h1>
              The <strong>basic info</strong> to get you up & running.
            </center>
            <br />
            <form onSubmit={this.submitProfile} id="profile">
              <TextInput
                validateCallback={this.validateName}
                changeCallback={this.updateDomain}
                ref={this.companyname}
                placeholder="Company name"
                maxlength="50"
                autofocus
              />

              <div className="form-row">
                <div className="col">
                  <TextInput
                    validateCallback={this.validateDomain}
                    changeCallback={this.updateDomain}
                    requireSubmit={true}
                    ref={this.domain}
                    placeholder={"companydomain"}
                    maxlength="50"
                  />
                </div>
                <div
                  className="col-auto"
                  style={{
                    marginTop: "9px",
                    fontSize: "1.2rem",
                    color: "grey",
                  }}
                >
                  .peepz.io
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <TextInput
                    validateCallback={this.validateName}
                    ref={this.firstname}
                    placeholder="First name"
                    maxlength="30"
                  />
                </div>
                <div className="col">
                  <TextInput
                    validateCallback={this.validateName}
                    ref={this.lastname}
                    placeholder="Last name"
                    maxlength="30"
                  />
                </div>
              </div>
              <TextInput
                placeholder="Password"
                maxlength="25"
                validateCallback={this.validatePassword}
                ref={this.password}
                password
              />
            </form>
            <button className={btnStyle} type="submit" form="profile">
              <div>
                FINISH <i className="fas fa-check" />
              </div>
            </button>
          </div>
        );
        break;
      default:
    }

    return <div className="form-wrapper">{page}</div>;
  }
}

export default SignUp;
