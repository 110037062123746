import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

/*
PROPS
- value
- small
- large
- type [default, outline, green, naked]
- disabled
- onClick
*/

export default forwardRef(function ButtonDropdown(props, ref) {
  const [hovering, setHovering] = useState(false);
  const [listOpen, setListOpen] = useState(false);

  const listRef = useRef(null);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleMenuClick);
      document.removeEventListener("keydown", handleMenuClick);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    close: () => {
      setListOpen(false);
      return;
    },
  }));

  // Functions ---------------------------------------------------------------

  function handleMenuClick(e) {
    if (
      e.key === "Escape" ||
      (listRef.current && !listRef.current.contains(e.target))
    ) {
      document.removeEventListener("mousedown", handleMenuClick);
      document.removeEventListener("keydown", handleMenuClick);
      setListOpen(false);
    }
  }

  // Render ---------------------------------------------------------------

  var sizeAdj = 0;
  if (props.small) sizeAdj -= 2;
  else if (props.large) sizeAdj += 2;

  const buttonBase = {
    position: "relative",
    textAlign: "center",
    zIndex: 4,
    borderRadius: 6,
    padding: props.type === "ellipsis" ? "3px 6px" : "6px 12px",
    fontSize: 12 + sizeAdj,
    userSelect: "none",
    transition: "background 0.15s, transform 0s",
    cursor: "pointer",
  };

  const listBase = {
    position: "absolute",
    display: "inline-block",
    width: "fit-content",
    height: "fit-content",
    top: "120%",
    zIndex: 10,
    textAlign: "center",
    borderRadius: 8,
    background: "#fff",
    boxShadow: "1px 1px 5px #00000099",
    padding: 4,
  };

  var listAlign = {
    left: "50%",
    transform: "translateX(-50%)",
  };
  if (props.right) {
    listAlign = {
      right: 0,
    };
  } else if (props.left) {
    listAlign = {
      left: 0,
    };
  }

  const styles = {};
  styles.default = {
    fontWeight: 300,
    background: hovering ? "#7b5ec4" : "#674ea7",
  };
  styles.outline = {
    fontWeight: 500,
    background: hovering ? "#ffffff22" : "transparent",
    border: "1px solid " + (hovering ? "#fff" : "#ffffff88"),
  };
  styles.green = {
    fontWeight: 300,
    background: hovering ? "#0b0" : "#090",
  };
  styles.ellipsis = {
    fontWeight: 700,
    background: hovering ? "#7b5ec4" : props.naked ? "transparent" : "#674ea7",
    borderRadius: "50%",
    height: 23,
    width: 23,
  };

  const selStyle = props.type ? styles[props.type] : styles.default;

  return (
    <div
      ref={listRef}
      style={
        props.disabled
          ? {
              opacity: "0.4",
              pointerEvents: "none",
              ...buttonBase,
              ...selStyle,
            }
          : { ...buttonBase, ...selStyle }
      }
      onClick={(e) => {
        if (!props.disabled) {
          if (props.onClick) props.onClick();
          setListOpen((prev) => !prev);
          document.addEventListener("mousedown", handleMenuClick);
          document.addEventListener("keydown", handleMenuClick);

          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onMouseOver={(e) => setHovering(true)}
      onMouseOut={(e) => setHovering(false)}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
    >
      {props.value && props.type !== "ellipsis" && (
        <>
          {props.value}{" "}
          {!props.nocaret && (
            <i
              className="fas fa-caret-down"
              style={{
                transform: listOpen ? "translateY(2px)" : "translateY(0px)",
              }}
            />
          )}
        </>
      )}
      {props.type === "ellipsis" && (
        <i className="fas fa-ellipsis-h" style={{}} />
      )}
      {listOpen && (
        <div style={{ ...listBase, ...listAlign }}>{props.children}</div>
      )}
    </div>
  );
});
