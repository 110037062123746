import { useEffect } from "react";

export default function useKey(key, callbackDown, callbackUp) {
  useEffect(() => {
    function handleKeydown(e) {
      if (e.key === key && callbackDown) callbackDown(e);
    }
    function handleKeyup(e) {
      if (e.key === key && callbackUp) callbackUp(e);
    }

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, []);
  return;
}
