import React, { Fragment } from "react";
import Helper from "../utils/helper";
import DatePicker from "./DatePicker";
import moment from "moment";

class InspectBar extends React.Component {
  constructor(props) {
    super(props);

    this.types = {
      major: "Major",
      goal: "Normal",
      event: "Minor",
      sprint: "Pin",
      thematic: "Thematic",
    };
    this.typeColors = {
      goal: { background: "#efa51c", font: "#fff" },
      event: { background: "#888", font: "#fff" },
      sprint: { background: "#aaa", font: "#000" },
      major: { background: "#efa51c", font: "#fff" },
      thematic: { background: "#efa51c", font: "#fff" },
    };
    this.typeKeys = Object.keys(this.types);

    this.custColors = [
      "#efa51c",
      "#af1cef",
      "#aaa",
      "#42b842",
      "#1dbcc2",
      "#515be8",
      "#ef1cda",
    ];

    this.state = {
      focus: 0,
      hoverDate: false,
      editDate: false,
      hoverType: false,
      editType: false,
      type: null,
      color: props.data.color ? props.data.color : "",
      capWithVelo: true,
      desc: "",
    };

    this.setTimer = this.setTimer.bind(this);
    this.setDescHeight = this.setDescHeight.bind(this);
    this.setNameHeight = this.setNameHeight.bind(this);
    this.handleKey = this.handleKey.bind(this);

    this.milestoneDatePicker = React.createRef();
    this.bodyInput = React.createRef();
    this.nameInput = React.createRef();
    this.timer = null;
  }

  shouldComponentUpdate(props, state) {
    return (
      Helper.stateChanged(this.props, props) ||
      Helper.stateChanged(this.state, state)
    );
  }

  componentDidUpdate() {
    this.setDescHeight();
    this.setNameHeight();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKey, false);
    this.setDescHeight();
    this.setNameHeight();
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
    document.removeEventListener("keydown", this.handleKey, false);
  }

  static getDerivedStateFromProps(props, state) {
    return state.id !== props.data.id ? { ...state, ...props.data } : state;
  }

  handleKey(e) {
    if (e.key === "Escape") {
      if (this.props.onChange) this.props.onChange(this.state);
      this.props.closeCallback();
    }
  }

  setTimer() {
    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.props.onChange) this.props.onChange(this.state);
    }, 2000);
  }

  setNameHeight() {
    if (!this.nameInput.current) return;

    this.nameInput.current.style.height = "auto";
    this.nameInput.current.style.height = "5px";
    this.nameInput.current.style.height =
      this.nameInput.current.scrollHeight + 5 + "px";
  }

  setDescHeight() {
    if (!this.bodyInput.current) return;

    this.bodyInput.current.style.height = "auto";
    this.bodyInput.current.style.height = "5px";
    this.bodyInput.current.style.height =
      this.bodyInput.current.scrollHeight + 5 + "px";
  }

  render() {
    var capacity =
      this.props.data && this.props.capacityCb
        ? this.props.capacityCb(this.props.data.date, this.state.capWithVelo)
        : {};

    return (
      <Fragment>
        <div
          style={{
            display: this.props.data ? "inline-block" : "none",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            minHeight: "120vh",
            zIndex: 4990,
            background: "#000",
            opacity: 0.2,
          }}
          onClick={() => {
            this.props.closeCallback();
          }}
        />
        <div
          id="inspectbar"
          style={{
            display: this.props.data ? "inline-block" : "none",
            position: "absolute",
            width: "30%",
            minWidth: 300,
            minHeight: "120vh",
            right: this.props.data ? 0 : -500,
            top: 0,
            zIndex: 5000,
            backgroundColor: "white",
            boxShadow: "-1px 0px 7px #111",
            padding: 20,
          }}
        >
          <div
            style={{
              position: "absolute",
              background: "#fff",
              borderRadius: "50%",
              width: 30,
              height: 30,
              left: -15,
              cursor: "pointer",
              boxShadow: "-1px 0px 9px #111",
            }}
          />
          <div
            style={{
              position: "absolute",
              background: "#fff",
              top: 0,
              left: 0,
              height: 60,
              width: 20,
            }}
          />
          <div
            style={{
              position: "absolute",
              width: 30,
              height: 30,
              borderRadius: "50%",
              textAlign: "center",
              lineHeight: 1.9,
              left: -15,
              fontSize: 16,
              color: "#888",
              cursor: "pointer",
            }}
            className="fas fa-times"
            onClick={() => {
              if (this.props.onChange) this.props.onChange(this.state);
              this.props.closeCallback();
            }}
          />
          <div
            style={{
              position: "absolute",
              width: 30,
              height: 30,
              borderRadius: "50%",
              textAlign: "center",
              lineHeight: 1.9,
              right: 30,
              fontSize: 16,
              color: "#888",
              cursor: "pointer",
            }}
            className="fas fa-trash-alt"
            onClick={() => {
              if (this.props.deleteGoal)
                this.props.deleteGoal(
                  this.props.data.id,
                  this.props.data.type === "thematic"
                );
              this.props.closeCallback();
            }}
          />

          <textarea
            ref={this.nameInput}
            className="form-control form-control-lg"
            placeholder="Milestone title"
            value={this.state.name}
            maxLength={35}
            style={{
              padding: 0,
              color: "#000",
              fontSize: 30,
              width: "90%",
              border: 0,
              boxShadow: "none",
              marginBottom: -4,
              background: "white",
              lineHeight: 1,
              resize: "none",
            }}
            onChange={(e) => {
              if (!/[\n]/g.test(e.target.value))
                this.setState({ name: e.target.value }, this.setTimer);
            }}
            onFocus={() => {
              this.setState({ focus: 0 });
            }}
          />

          {!this.state.editType && !this.state.editDate && (
            <div style={{ display: "inline", marginRight: 8 }}>
              <div
                style={{
                  display: "inline",
                  fontSize: 12,
                  cursor:
                    this.state.type !== "thematic" ? "pointer" : "default",
                  padding: "2px 8px",
                  background: this.state.color
                    ? this.state.color
                    : this.typeColors[this.state.type].background,
                  color: this.typeColors[this.state.type].font,
                  textShadow:
                    this.state.type !== "sprint" ? "1px 1px 1px #333" : "",
                  borderRadius: 4,
                }}
                onMouseOver={() => {
                  this.setState({ hoverType: true });
                }}
                onMouseOut={() => {
                  this.setState({ hoverType: false });
                }}
                onClick={() => {
                  if (this.state.type !== "thematic")
                    this.setState({ editType: true });
                }}
              >
                {this.types[this.state.type]}{" "}
                {this.state.hoverType &&
                  !this.state.editType &&
                  this.state.type !== "thematic" && (
                    <i
                      className="fas fa-pencil-alt"
                      style={{
                        fontSize: 10,
                        marginLeft: 3,
                        marginTop: -2,
                        textShadow: "none",
                      }}
                    />
                  )}
              </div>
            </div>
          )}

          {this.state.editType && (
            <div style={{ display: "flow-root", marginTop: 8 }}>
              <select
                className="form-control form-control-sm"
                value={this.state.type}
                style={{ marginBottom: 5 }}
                onChange={(e) => {
                  this.setState({ type: e.target.value }, () => {
                    if (this.props.onChange) this.props.onChange(this.state);
                  });
                }}
              >
                {this.typeKeys.map((val, i) => {
                  return (
                    <option key={"opt" + i} value={val}>
                      {this.types[val]}
                    </option>
                  );
                })}
              </select>

              <div style={{ display: "flex", marginBottom: 5 }}>
                {this.custColors.map((val, i) => {
                  return (
                    <div
                      key={"option" + i}
                      style={{
                        margin: 4,
                        borderRadius: "50%",
                        background: val,
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({ color: val }, () => {
                          if (this.props.onChange)
                            this.props.onChange(this.state);
                        });
                      }}
                    >
                      {this.state.color === val && (
                        <div
                          style={{
                            borderRadius: "50%",
                            background: "#333",
                            width: 8,
                            height: 8,
                            margin: 4,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <button
                className={"btn btn-sm btn-success"}
                style={{
                  float: "right",
                }}
                onClick={() => {
                  this.setState({ editType: false, hoverType: false });
                }}
              >
                DONE
              </button>
            </div>
          )}

          {!this.state.editType && (
            <div style={{ display: "inline" }}>
              <div
                style={{
                  fontSize: 12,
                  color: "#999",
                  cursor: "pointer",
                  display: "inline",
                }}
                onMouseOver={() => {
                  this.setState({ hoverDate: true });
                }}
                onMouseOut={() => {
                  this.setState({ hoverDate: false });
                }}
                onClick={() => {
                  this.setState({ editDate: true });
                }}
              >
                {this.props.data && !this.state.editDate
                  ? moment(this.state.date).format("dddd, MMMM Do YYYY")
                  : ""}
              </div>
              {this.state.hoverDate && !this.state.editDate && (
                <i
                  className="fas fa-pencil-alt"
                  style={{ paddingLeft: 6, fontSize: 11 }}
                />
              )}
            </div>
          )}

          {this.state.editDate && (
            <div
              style={{ display: "flow-root", marginBottom: 20, marginTop: 8 }}
            >
              <DatePicker
                default={moment(this.props.data.date)}
                minDate={moment(this.props.auth.getProjStart())}
                maxDate={moment(this.props.auth.getProjEnd())}
                ref={this.milestoneDatePicker}
              />
              <button
                className={"btn btn-sm btn-success"}
                style={{
                  float: "left",
                  marginTop: 5,
                }}
                onClick={() => {
                  this.setState(
                    {
                      date: this.milestoneDatePicker.current.getDate(),
                      editDate: false,
                    },
                    () => {
                      if (this.props.onChange) this.props.onChange(this.state);
                    }
                  );
                }}
              >
                SAVE
              </button>
              <div
                style={{
                  float: "right",
                  fontSize: 12,
                  padding: 4,
                  cursor: "pointer",
                  marginTop: 5,
                }}
                onClick={() => {
                  this.setState({ editDate: false, hoverDate: false });
                }}
              >
                Cancel
              </div>
            </div>
          )}

          {capacity && Object.keys(capacity).length > 0 && (
            <div
              className="bg-secondary"
              style={{
                display: "block",
                position: "relative",
                padding: 4,
                paddingLeft: 10,
                overflow: "visible",
                marginTop: 22,
                marginLeft: -30,
                marginRight: -20,
                color: "white",
                boxShadow: "2px 2px 4px #888",
              }}
            >
              {capacity &&
                Object.keys(capacity).map((keyName, i) => {
                  return (
                    <div
                      key={keyName + i}
                      style={{
                        display: "inline-block",
                        marginRight: 3,
                        marginBottom: 3,
                        padding: "4px 8px",
                        fontSize: 12,
                        textShadow: "1px 1px 2px #333",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <i style={{ fontSize: 22 }}>
                          {capacity[keyName].value}{" "}
                        </i>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: 5,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 9,
                            color: "#ddd",
                            textShadow: "1px 1px 2px #444",
                          }}
                        >
                          <i>days</i>
                        </div>
                        <div style={{ marginTop: -5 }}>
                          {capacity[keyName].name !== "null"
                            ? capacity[keyName].name
                            : "Other"}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 2,
                  display: "block",
                  fontSize: 10,
                }}
              >
                incl velocity{" "}
                <input
                  type="checkbox"
                  onChange={() => {
                    this.setState({ capWithVelo: !this.state.capWithVelo });
                  }}
                  checked={this.state.capWithVelo}
                />
              </div>
            </div>
          )}

          <textarea
            ref={this.bodyInput}
            className="form-control form-control-lg"
            placeholder="Description of the goal"
            value={this.state.desc ? this.state.desc : ""}
            style={{
              padding: 0,
              color: "#000",
              fontSize: 14,
              width: "99%",
              border: 0,
              marginTop: 15,
              boxShadow: "none",
              resize: "none",
              overflowX: "hidden",
            }}
            onChange={(e) => {
              this.setState({ desc: e.target.value }, this.setTimer);
            }}
            onFocus={() => {
              this.setState({ focus: 1 });
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default InspectBar;
