import React, { useState } from "react";

/*
PROPS
- text
- state
- small
- large
*/

export default function Toggle(props) {
  const [hovering, setHovering] = useState(false);
  const [checked, setChecked] = useState(props.state);

  var sizeAdj = 0;
  if (props.small) sizeAdj -= 2;
  else if (props.large) sizeAdj += 2;

  var bg = hovering ? "#2d1b5c" : "#25154d";
  if (checked) bg = hovering ? "#efe" : "#cfc";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        padding: "2px 8px",
        margin: 2,
        borderRadius: 6 + sizeAdj,
        fontSize: 11 + sizeAdj,
        color: checked ? "#111" : "#674ea7",
        border: checked ? "1px solid #cfc" : "1px solid #674ea7",
        background: bg,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick) props.onClick(!checked);
        setChecked(!checked);
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {props.fontawesome && (
        <i className={props.fontawesome} style={{ marginRight: 4 }} />
      )}
      {props.text}
    </div>
  );
}
