import React, { useRef, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useKey from "./useKey";

const Context = React.createContext();

export function PopupProvider({ children }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </div>
  );
}

export default function Popup({ closeCallback, children, ...props }) {
  const modalNode = useContext(Context);
  const [top] = useState(window.scrollY);

  useEffect(() => {
    sessionStorage.setItem("popup", true);

    return () => {
      sessionStorage.removeItem("popup");
    };
  }, []);

  useKey("Escape", () => {
    if (props.negativeAction || props.cancelOutside) {
      closeCallback();
    }
  });

  return modalNode
    ? ReactDOM.createPortal(
        <>
          <div
            style={{
              position: "absolute",
              top: top + 30,
              marginLeft: "50%",
              transform: "translate(-50%)",
              minWidth: props.large ? 900 : 450,
              maxWidth: props.large ? null : 600,
              background: "#FFFFFF",
              borderRadius: 8,
              boxShadow: "0px 0px 20px 0px #111",
              padding: 20,
              zIndex: 6000,
            }}
            onClick={function (e) {
              e.stopPropagation();
            }}
          >
            {props.title && (
              <center>
                <h1 style={{ color: "#000", textShadow: "none" }}>
                  {props.title}
                </h1>
              </center>
            )}
            {props.deleteCallback && (
              <div
                type="button"
                className="fas fa-trash-alt"
                onClick={() => {
                  props.deleteCallback();
                }}
                style={{
                  position: "absolute",
                  right: 20,
                  bottom: 20,
                  cursor: "pointer",
                  color: "#999",
                  WebkitTransition: "none",
                  msTransition: "none",
                }}
              />
            )}
            <div style={{ display: "block", color: "#000" }}>{children}</div>

            <div style={{ marginTop: 16 }}>
              {props.negativeAction && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    closeCallback();
                  }}
                  style={{ float: "left" }}
                >
                  {props.negativeAction}
                </button>
              )}
              {props.positiveAction && (
                <button
                  type="button"
                  className={
                    !props.negativeAction
                      ? "btn btn-success btn-block"
                      : "btn btn-success"
                  }
                  onClick={props.submitCallback}
                  style={{ float: "right" }}
                >
                  {props.positiveAction}
                </button>
              )}
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: 5000,
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              overflow: "hidden",
              backgroundColor: "rgba(0,0,0,0.85)",
            }}
            onClick={() => {
              if (closeCallback) closeCallback();
            }}
          />
        </>,
        modalNode
      )
    : null;
}
