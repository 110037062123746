import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import _ from "lodash";
import moment from "moment";

export default forwardRef(function InputDropdown(props, ref) {
  var _prefix = props.prefix ? props.prefix : null;
  var _data = buildData(props.data, _prefix);

  const [data, setData] = useState(_data);
  const [value, setValue] = useState("");
  const [hovered, setHovered] = useState(-1);
  const hoveredRef = useRef(hovered);
  const [prefix, setPrefix] = useState(_prefix);
  const [selected, setSelected] = useState(-1);
  const [listShowing, setListShowing] = useState(false);
  const listShowingRef = useRef(listShowing);
  const [list, setList] = useState(null);
  const listRef = useRef(list);
  const [textShowing, setTextShowing] = useState(true);
  const [textFocused, setTextFocused] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [width, setWidth] = useState(false);

  const inputRef = useRef();
  const hiddenInputRef = useRef();
  const pillDivRef = useRef();
  const listDivRed = useRef();

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, []);

  useEffect(() => {
    const _setSelected =
      (props.numPrefix && props.selected > -1 && props.prefix) ||
      (!props.numPrefix && props.selected > -1);
    setSelected(_setSelected ? props.selected : -1);
    setTextShowing(!_setSelected);
    setPrefix(props.prefix);
  }, [props.selected, props.numPrefix, props.prefix]);

  useEffect(() => {
    if (props.data && props.data.length !== data.length) {
      var newData = props.data.map((val, idx) => {
        var obj = {};
        obj.name = prefix ? prefix + " " + val.name : val.name;
        obj.value = val.value;
        obj.idx = val.idx ? val.idx : idx;
        return obj;
      });
      setData(newData);
    }
  }, [props.data, data.length, prefix]);

  useEffect(() => {
    listShowingRef.current = listShowing;
    listRef.current = list;
  }, [listShowing]);

  useEffect(() => {
    hoveredRef.current = hovered;
  }, [hovered]);

  useEffect(() => {
    setWidth(hiddenInputRef.current.getBoundingClientRect().width + 15);
  }, [value, props.placeholder]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      setTextShowing(true);
      inputRef.current.focus();
    },
  }));

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  function handleKey(e) {
    if (
      listShowingRef.current &&
      (e.key === "ArrowDown" || e.key === "ArrowUp")
    ) {
      // e.stopPropagation();
      if (e.key === "ArrowDown") {
        setHovered((prev) => (hovered < list.length - 1 ? prev + 1 : 0));
      } else {
        setHovered((prev) => (hovered > 0 ? prev - 1 : list.length - 1));
      }
    } else if (e.keyCode === 32) {
      // Spacebar
      if (!listShowingRef.current && textFocused) {
        showList("");
      }
    } else if (e.key === "Enter") {
      if (listShowing && hovered > -1 && list.length > 0) {
        submit(list[hovered].idx);
      } else if (props.allowNew && value.trim().length > 0) {
        submit(null, value);
      } else if (listShowing && hovered === -1 && hasValue()) {
        close();
      }

      if (props.nextFocus && props.nextFocus.current) {
        props.nextFocus.current.focus();
      }
    } else if (e.key === "Escape") {
      e.preventDefault();
      close();
    } else if (e.key === "Backspace") {
      if (textFocused && value.length === 0 && hasValue()) {
        e.stopPropagation();
        submit(-1);
      }
    }
  }

  function buildData(data, prefix) {
    return data
      ? data.map((val, idx) => {
          var obj = {};
          obj.name = prefix ? prefix + " " + val.name : val.name;
          obj.value = val.value;
          obj.idx = val.idx ? val.idx : idx;
          return obj;
        })
      : [];
  }

  function handleOutsideClick(e) {
    if (
      ref &&
      pillDivRef.current !== e.target &&
      ref.current !== e.target &&
      listDivRed.current &&
      !listDivRed.current.contains(e.target) &&
      inputRef.current !== e.target
    ) {
      close();
    }
  }

  function hasValue() {
    if (props.numPrefix) {
      return selected > -1 && prefix && prefix !== "";
    } else {
      return selected > -1;
    }
  }

  function submit(idx, newValue) {
    var prefixArr = value.replace(/\s/g, "").match(/\d{0,3}/g);
    var _prefix = prefixArr[0] !== "" ? prefixArr[0] : prefix;

    if (props.submitCb) {
      props.submitCb(
        idx,
        props.numPrefix && _prefix && _prefix !== "" ? _prefix : null,
        newValue
      );
    }

    if (idx === -1 || (idx > -1 && props.numPrefix && !_prefix)) {
      setSelected(-1);
      setValue("");
      setPrefix(null);
      setHovered(-1);
      setData(buildData(props.data, null));
      setTextShowing(true);
    } else {
      setSelected(props.noPill ? -1 : idx);
      setPrefix(props.numPrefix && _prefix && _prefix !== "" ? _prefix : null);
      setValue("");
      close();
    }
  }

  function close() {
    setListShowing(false);
    setTextShowing(false);
    setTextFocused(false);
    setHovered(-1);
    setValue("");
    inputRef.current.blur();
    if (props.closeCb) props.closeCb();
  }

  function showList(value, _data) {
    var _list = [];

    var re = new RegExp(_.escapeRegExp(value.replace(/\s/g, "")), "i");
    const isMatch = (result) => re.test(result.name.replace(/\s/g, ""));
    _list = _.filter(_data ? _data : data, isMatch);
    setListShowing(props.waitForInput && value === "" ? false : true);
    setValue(value);
    setList(_list);
    setHovered(value.trim() === "" || list.length === 0 ? -1 : 0);
    setTextShowing(true);

    document.addEventListener("click", handleOutsideClick);
  }

  //
  // Render

  const addStyle =
    Array.isArray(props.pillStyle) && selected > -1
      ? props.pillStyle[selected]
      : props.pillStyle;
  const style = {
    display: "inline-block",
    fontSize: 10,
    color: "#000",
    padding: "1px 4px",
    background: "pink",
    borderRadius: 4,
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  var listStyle = {
    display: "inline-block",
    position: "absolute",
    top: inputRef.current ? inputRef.current.clientHeight + 2 : 12,
    background: "#fff",
    boxShadow: "1px 1px 3px #888",
    borderRadius: 4,
    zIndex: 1000,
  };
  listStyle = props.alignedRight
    ? { ...listStyle, right: 0 }
    : {
        ...listStyle,
        left: pillDivRef.current ? pillDivRef.current.clientWidth + 15 : 0,
      };

  return (
    <div
      style={{
        position: "relative",
        maxWidth: "100%",
        display: "inline-block",
        marginRight: 4,
      }}
    >
      {props.allowNew && value.length > 0 && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 30,
            height: 26,
            top: "50%",
            right: 30,
            background: "#ccc",
            transform: "translateY(-50%)",
            borderRadius: 6,
            cursor: "pointer",
          }}
          onClick={() => {
            if (props.allowNew && value.trim().length > 0) submit(null, value);
          }}
        >
          <i
            className="fas fa-level-down-alt"
            style={{ transform: "rotate(90deg)" }}
          />
        </div>
      )}

      <input
        ref={inputRef}
        placeholder={textFocused || !hasValue() ? props.placeholder : ""}
        value={value}
        maxLength={props.maxlength ? props.maxlength : 50}
        style={{
          display: textShowing ? "block" : "none",
          background: props.large ? "transparent" : "white",
          boxShadow: "none",
          padding: props.large ? "4px 10px" : "0px 6px",
          fontSize: props.large ? 14 : 11,
          outline: "none",
          border: props.large ? "1px solid #674ea7" : "1px solid #ddd",
          marginLeft:
            pillDivRef.current && hasValue() && !props.minify
              ? pillDivRef.current.clientWidth + 12
              : 0,
          width: width,
          minWidth: 30,
          borderRadius: props.large ? 8 : 4,
          color: props.large ? "#fff" : "#000",
          cursor: "pointer",
        }}
        onKeyDown={handleKey}
        onChange={(e) => {
          var str = e.target.value;
          if (props.numPrefix) {
            var numPrefix = str.replace(/\s/g, "").match(/\d{0,3}/g);
            const _data = buildData(props.data, numPrefix[0]);
            setData(_data);
            showList(str, _data);
          } else {
            showList(e.target.value.trim());
          }
        }}
        onFocus={(e) => {
          if (props.setFocusCb) props.setFocusCb();
          setTextFocused(true);
          showList("");
        }}
        onBlur={() => {
          if (!props.allowNew) {
            setTextFocused(false);
            setListShowing(false);
            setValue("");
          }
        }}
      />

      {/* Hidden field just for measuring width */}
      <span
        ref={hiddenInputRef}
        style={{
          display: "inline-block",
          position: "absolute",
          fontSize: 11,
          visibility: "hidden",
        }}
      >
        {value ? value : props.placeholder}
      </span>

      {!props.noPill && !textShowing && (
        <div
          ref={pillDivRef}
          style={{ ...style, ...addStyle }}
          onClick={() => {
            submit(-1);
          }}
          onMouseOver={() => {
            setShowDelete(true);
          }}
          onMouseLeave={() => {
            setShowDelete(false);
          }}
        >
          {props.numPrefix &&
            selected > -1 &&
            prefix + " " + props.data[selected].name}
          {selected > -1 && !props.numPrefix ? data[selected].name : ""}
          {showDelete && (
            <i
              className="fas fa-times"
              style={{ marginLeft: 6, textShadow: "none" }}
            />
          )}
        </div>
      )}
      {listShowing && list.length > 0 && (
        <div ref={listDivRed} style={listStyle}>
          {list.map((val, i) => {
            var _hovered = hovered === i;
            return (
              <div
                key={"InputDropdown" + val.name + i.toString()}
                style={{
                  padding: "3px 8px",
                  margin: "4px 4px",
                  borderRadius: 4,
                  background: _hovered ? "#b7e9f7" : "none",
                  cursor: "pointer",
                }}
                onMouseDown={() => {
                  submit(val.idx);
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: "#000",
                    whiteSpace: "nowrap",
                  }}
                >
                  {val.name}
                </div>
                {val.date && (
                  <div style={{ fontSize: 10, color: "#444" }}>
                    {new Date(val.date).format(true) +
                      ": " +
                      moment(val.date).format("dddd") +
                      " " +
                      moment().to(val.date)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
