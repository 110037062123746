import React, { useState, useRef, useEffect, useCallback, memo } from "react";
import db from "../utils/db";
import history from "../utils/history";
import validator from "validator";
import EditCard from "../components/EditCard";
import ButtonAdd from "../components/ButtonAdd";
import Box from "../components/Box";
import TextInput from "../components/TextInput";
import { Canvas, extend, useThree, useRender } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import socket from "../utils/socket";
import { useDrag, useDrop } from "react-dnd";

extend({ OrbitControls });

const Controls = () => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();

  useRender(() => {
    orbitRef.current.update();
  });

  return (
    <orbitControls
      autoRotate
      maxPolarAngle={Math.PI / 3}
      minPolarAngle={Math.PI / 3}
      args={[camera, gl.domElement]}
      ref={orbitRef}
    />
  );
};

export default function Product(props) {
  const [editCard, setEditCard] = useState(null);
  const editCardRef = useRef(editCard);
  const [discDods, setDiscDods] = useState(null);
  const [amountDods, setAmountDods] = useState({});
  const [hoveringCol, setHoveringCol] = useState(null);
  const [hovering, setHovering] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [newCardName, setNewCardName] = useState("");
  const [projEstimate, setProjEstimate] = useState(null);

  const cards = useRef();
  const idToIdx = useRef();
  const resVelocs = useRef();
  const pillars = useRef();
  const epics = useRef();
  const standards = useRef([]);
  const projectNameFullRef = useRef();
  const useLocal = useRef();
  const goalRef = useRef();
  const isPastGoalRef = useRef();
  const newCard = useRef();
  const mounted = useRef(false);

  const productUpdatedEventCb = useCallback(productUpdatedEvent);
  const cardDeletedEventCb = useCallback(cardDeletedEvent);

  useEffect(() => {
    props.dispatch.addListener("orgchange", refresh);
    props.dispatch.addListener("projchange", refresh);
    useLocal.current = !props.auth.isAuthenticated();

    // Connect to socket room
    const room = props.auth.getOrgId() + "product";
    socket.emit("joinroom", {
      room: room,
      user: props.auth.getUserName(),
    });
    socket.on("productupdated", productUpdatedEventCb);
    socket.on("carddelete", cardDeletedEventCb);

    return () => {
      const room = props.auth.getOrgId() + "product";
      socket.emit("leaveroom", {
        room: room,
        user: props.auth.getUserName(),
      });
      socket.removeListener("productupdated", productUpdatedEventCb);
      socket.removeListener("carddelete", cardDeletedEventCb);

      props.dispatch.removeListener("orgchange", refresh);
      props.dispatch.removeListener("projchange", refresh);
    };
  }, []);

  useEffect(() => {
    editCardRef.current = editCard;
  }, [editCard]);

  useEffect(() => {
    goalRef.current = props.goal;
    if (props.goal > 0) refresh();
  }, [props.goal]);

  useEffect(() => {
    isPastGoalRef.current = props.isPastGoal;
    // if (mounted) refresh();
  }, [props.isPastGoal]);

  //////////////////////////////////////////
  // Functions

  function refresh() {
    fetchData();
    getDiscAndDods();
    mounted.current = true;
  }

  async function fetchData(localCards, cb) {
    var _cards = [];
    var _pillars = [];
    var _epics = [];
    var _standards = [];
    var _idToIdx = {};
    var _resVelocs = resVelocs.current ? resVelocs.current : {};
    var estimatedTime = 0;
    var avgEstimatePillars = 0;
    var avgEstimateEpics = 0;
    var resCards =
      localCards !== undefined ? { status: 200, data: localCards } : {};

    if (useLocal.current) {
      resCards.status = 200;
      resCards.data = JSON.parse(localStorage.getItem("product"));
    } else if (localCards === undefined) {
      // Get all cards, in order pillar, epic & story
      resCards = await db.request("/auth/getcards", "POST", {
        project: props.auth.getProjId(),
        type: "product",
        goal: goalRef.current,
      });

      // Get historical average velocities for all card categories
      _resVelocs = await db.request("/auth/getvelocity", "POST", {
        proj: props.auth.getProjId(),
      });
    }

    if (
      resCards &&
      resCards.status === 200 &&
      resCards.data &&
      resCards.data.length > 0
    ) {
      avgEstimateEpics = _resVelocs.data.avgStories
        ? _resVelocs.data.avgStories *
          _resVelocs.data.avgTasks *
          _resVelocs.data.avgCardTime
        : 0;
      avgEstimatePillars = _resVelocs.data.avgEpics
        ? _resVelocs.data.avgEpics * avgEstimateEpics
        : 0;

      for (var i = 0; i < resCards.data.length; i++) {
        var card = resCards.data[i];
        card.children = [];
        _idToIdx[card.card_id] = i;

        if (card.card_parent && _idToIdx[card.card_parent] !== undefined) {
          _cards[_idToIdx[card.card_parent]].children.push(card);
          card.parent = _cards[_idToIdx[card.card_parent]].card_name;
          card.parentId = _cards[_idToIdx[card.card_parent]].card_id;
        }

        _cards.push(card);

        if (card.card_type === "pillar") _pillars.push(card);
        else if (card.card_type === "epic") _epics.push(card);
        else if (card.card_type === "standard") _standards.push(card);
      }
    }

    // Calculate completions for all parent levels
    // + add projected estimate per epic
    for (var j = 0; j < _epics.length; j++) {
      _epics[j].completion = getEpicCompletion(_epics[j]);
      estimatedTime += avgEstimateEpics;
    }
    for (var k = 0; k < _pillars.length; k++) {
      if (_pillars[k].children.length === 0) {
        estimatedTime += avgEstimatePillars;
      }
    }

    var _projectName = -1;
    const project = JSON.parse(localStorage.getItem("project"));
    if (props.auth.getProjName() !== -1)
      _projectName = props.auth.getProjName();
    else if (project) _projectName = project.name;
    console.log(_cards);
    idToIdx.current = _idToIdx;
    resVelocs.current = _resVelocs;
    cards.current = _cards;
    pillars.current = _pillars;
    epics.current = _epics;
    standards.current = _standards;
    setLoading(false);
    setProjectName(_projectName);
    setProjEstimate(
      estimatedTime > 0 ? (estimatedTime / (1000 * 60)).convertMinutes() : null
    );
    if (cb) cb();
  }

  async function getDiscAndDods() {
    var discs = {};

    if (useLocal.current) {
      discs.status = 200;
      discs.data = [{ set_id: 1, set_type: "discipline", set_relation: null }];
    } else {
      discs = await db.request("/auth/getdisciplines", "POST", {
        org: props.auth.getOrgId(),
        dods: true,
      });
    }

    if (discs && discs.status === 200) {
      var disciplines = [];
      var disciplinesById = {};
      var amountDods = {};
      var _idToIdx = {};
      for (var i = 0; i < discs.data.length; i++) {
        var val = discs.data[i];
        _idToIdx[val.set_id] = i;
        disciplines.push(val);
        disciplinesById[val.set_id] = val;
        if (val.set_type === "discipline") {
          disciplines[i].dods = [];
          amountDods[val.set_id] = 0;
        } else {
          disciplines[_idToIdx[val.set_relation]].dods.push(val);
          amountDods[val.set_relation] += 1;
        }
      }

      // Finally sort the Dods
      for (var j = 0; j < disciplines.length; j++) {
        if (disciplines[j].dods && disciplines[j].dods.length > 0)
          if (disciplines[j].set_type === "discipline") {
            if (disciplines[j].dods && disciplines[j].dods.length > 0) {
              disciplines[j].dods.sort((a, b) => {
                return parseInt(a.set_value, 10) - parseInt(b.set_value, 10);
              });
            }
          } else break;
      }

      setDiscDods(disciplines);
      setAmountDods(amountDods);
    }
  }

  async function addCard(type, parentId) {
    const data = {
      type: type,
      parent: parentId,
      project: props.auth.getProjId(),
      goal: goalRef.current,
    };
    var resAdd = {};

    if (useLocal.current) {
      var product = JSON.parse(localStorage.getItem("product"));
      if (!product) product = [];
      var cardIdx = parseInt(localStorage.getItem("cardIdx"), 10);
      if (!cardIdx) cardIdx = 1;
      data.card_id = cardIdx;
      data.card_status = null;
      data.card_images = null;
      data.card_type = type;
      data.card_parent = parentId;
      product.push(data);
      localStorage.setItem("product", JSON.stringify(product));
      localStorage.setItem("cardIdx", cardIdx + 1);
      resAdd.status = 200;
      resAdd.data = [];
      resAdd.data.push({ card_id: data.card_id });
    } else {
      resAdd = await db.request("/auth/addcard", "POST", data);
    }

    if (resAdd && resAdd.status === 200) {
      emitProductUpdate();
      newCard.current = resAdd.data[0].card_id;
      fetchData(undefined, () => {
        setNewCardName("");
      });
    }
  }

  const submitCard = useCallback(
    async (
      {
        id,
        name,
        desc,
        goal,
        disc,
        estimate,
        estType,
        priority,
        parent,
        status,
      },
      forceUpdate
    ) => {
      if (useLocal.current) {
        const product = JSON.parse(localStorage.getItem("product"));
        var item = product[idToIdx.current[id]];

        if (item) {
          item.card_name = name;
          item.card_body = desc;
          item.card_estimate = estimate;
          item.card_estimate_type = estType;
          product[idToIdx.current[id]] = item;
          localStorage.setItem("product", JSON.stringify(product));
          fetchData();
        }
      } else {
        if (idToIdx.current[id] === null) return;
        if (name !== undefined)
          cards.current[idToIdx.current[id]].card_name = name;
        if (desc !== undefined)
          cards.current[idToIdx.current[id]].card_body = desc;
        if (goal !== undefined)
          cards.current[idToIdx.current[id]].card_goal = goal;
        if (disc !== undefined)
          cards.current[idToIdx.current[id]].card_discipline = disc;
        if (estimate !== undefined)
          cards.current[idToIdx.current[id]].card_estimate = estimate;
        if (estType !== undefined)
          cards.current[idToIdx.current[id]].card_estimate_type = estType;
        if (priority !== undefined)
          cards.current[idToIdx.current[id]].card_priority = priority;
        if (parent !== undefined)
          cards.current[idToIdx.current[id]].card_parent = parent;
        if (status !== undefined)
          cards.current[idToIdx.current[id]].card_status = status;
        console.log("submit");
        const resEdit = await db.request("/auth/editcard", "POST", {
          id: id,
          name: name ? name : null,
          desc: desc ? desc : null,
          goal: goal ? goal : null,
          disc: disc ? disc : null,
          estimate: estimate ? estimate : null,
          estType: estType ? estType : null,
          priority: priority ? priority : null,
          parent: parent,
          status: status,
        });

        if (resEdit && resEdit.status === 200) {
          emitProductUpdate();
          if (forceUpdate) fetchData();
        }
      }
    },
    []
  );

  async function deleteCard(id) {
    var resDel = {};
    var doDelete = false;
    const name = cards.current[idToIdx.current[id]].card_name
      ? cards.current[idToIdx.current[id]].card_name
      : "this card";

    if (
      !cards.current[idToIdx.current[id]].card_name &&
      cards.current[idToIdx.current[id]].children.length === 0
    ) {
      doDelete = true;
    } else if (
      window.confirm(
        "Are you sure you want to delete " +
          name.toUpperCase() +
          " and ALL of its CHILDREN?"
      )
    ) {
      if (
        window.confirm("ARE YOU SURE?? \nThis is an Epic with many children.")
      ) {
        doDelete = true;
      }
    }

    if (doDelete) {
      if (useLocal.current) {
        resDel.status = 200;
        var product = JSON.parse(localStorage.getItem("product"));

        const newProduct = product.filter((val) => {
          return val.parent !== id && val.card_id !== id;
        });

        localStorage.setItem("product", JSON.stringify(newProduct));
      } else {
        resDel = await db.request("/auth/deletecard", "POST", {
          id: id,
        });
      }

      if (resDel && resDel.status === 200) {
        emitProductUpdate(id);
        setEditCard(null);
        fetchData();
      }
    }
  }

  async function setDod({ card, id, idx, max, enableMode, trackWithCards }) {
    const res = await db.request("/auth/setdod", "POST", {
      cardId: card,
      discId: id,
      dodIdx: idx,
      max: max - 1,
      enableMode: enableMode,
      trackWithCards: trackWithCards,
    });

    if (res && res.status === 200) {
      emitProductUpdate();
      fetchData();
    }
  }

  function clickedEpic(id) {
    setEditCard(id);
  }

  function getDodColor(val, hovering) {
    var color = "#fff";
    if (val > 99) color = hovering ? "#82eb3d" : "#69db1d";
    else if (val > 0) color = hovering ? "#ffcd7d" : "#ffbf59";
    return color;
  }

  function validateName(value) {
    var msg = "";
    const regex = new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-]+$/u
    );

    if (validator.trim(value) === "") msg = "Product need name, yo..";
    else if (!regex.test(value)) msg = "Non-valid characters in name";

    return msg;
  }

  async function createProj(e, txt) {
    if (e) e.preventDefault();
    const data = {
      orgid: props.auth.getOrgId(),
      ownerid: localStorage.getItem("userid"),
      name: txt,
      start: new Date().offWeekend().subtractDays(7).format(),
      end: new Date().offWeekend(true).addMonths(6).format(),
    };

    localStorage.setItem("project", JSON.stringify(data));
    refresh();
  }

  function emitProductUpdate(deleteId) {
    socket.emit("productupdated", {
      room: props.auth.getOrgId() + "product",
      name: props.auth.getUserName(),
      deleteId: deleteId,
    });
  }

  function productUpdatedEvent(author) {
    console.log("Product update by " + author);
    // refresh();
  }

  function cardDeletedEvent(deleteId) {
    if (editCardRef.current === deleteId) {
      setEditCard(null);
    }
    refresh();
  }

  function getEpicCompletion(epic) {
    var status = JSON.parse(epic.card_status);
    var dodsTotal = 0;
    var dods = 0;
    var cardsTotal = 0;
    var _cards = 0;

    // Calculate progression in children cards, if trackWithCards is enabled
    for (var i = 0; i < epic.children.length; i++) {
      var story = epic.children[i];
      for (var j = 0; j < story.children.length; j++) {
        var task = story.children[j];
        if (parseInt(task.card_status, 10) > 1) {
          _cards++;
        }
        cardsTotal++;
      }
    }

    // Calculate manual dods per discipline
    if (status) {
      var keys = Object.keys(amountDods);
      for (var s = 0; s < keys.length; s++) {
        dodsTotal +=
          status[keys[s]] && typeof status[keys[s]] !== "number"
            ? status[keys[s]][1] + 1
            : amountDods[keys[s]];

        if (status[keys[s]] && typeof status[keys[s]] !== "number") {
          if (status[keys[s]][0] > status[keys[s]][1]) {
            // More dods completed than required for this Epic, cap to max enabled
            dods += status[keys[s]][1] + 1;
          } else {
            dods += status[keys[s]][0] + 1;
          }
        } else {
          if (typeof status[keys[s]] === "number" && status[keys[s]] >= 0) {
            dods += status[keys[s]] + 1;
          }
        }
      }
    }

    const res = {
      total:
        status && status.trackWithCards === false
          ? Math.round((dods / dodsTotal) * 100)
          : Math.round(((_cards + dods) / (cardsTotal + dodsTotal)) * 100),
      cards: cardsTotal === 0 ? 0 : Math.round((_cards / cardsTotal) * 100),
    };
    return res;
  }

  const Pillar = ({
    id,
    col,
    nameInit,
    newCard,
    epics,
    submitCard,
    children,
  }) => {
    const [name, setName] = useState(nameInit ? nameInit : "");

    const ref = useRef(null);
    const [{ isOver }, drop] = useDrop({
      accept: "card",
      drop(item) {
        submitCard({ id: item.id, parent: id }, true);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });
    drop(ref);

    // eslint-disable-next-line no-unused-vars
    const [{ isDragging }, drag] = useDrag({
      type: "pillar",
      item: { id },
    });
    drag(ref);

    // If all epics are complete then make pillar green as well
    var pillarAverageCompletion = 0;
    var m = 0;
    for (m = 0; m < epics.length; m++) {
      if (epics[m].completion)
        pillarAverageCompletion += epics[m].completion.total;
    }

    const pillarDone = pillarAverageCompletion / m >= 100;
    var pillarColor = "#ccc";

    if (pillarDone) {
      pillarColor = hovering === id ? "#82eb3d" : "#69db1d";
    } else {
      pillarColor = hovering === id ? "#dcdcdc" : "#ccc";
    }

    return (
      <div
        ref={ref}
        style={{
          position: "relative",
          margin: 6,
          maxWidth: 320,
          width: 220,
          background: isOver ? "#ffffff55" : "initial",
        }}
        onMouseEnter={(e) => {
          if (!newCard) {
            setHoveringCol(col);
          }
        }}
        onMouseLeave={(e) => {
          if (!newCard) {
            setHoveringCol(null);
          }
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            background: pillarColor,
            borderRadius: 4,
            marginBottom: 3,
            cursor: "pointer",
            boxShadow: "0px 3px 4px #00000099",
            padding: 7,
            fontSize: 13,
            textAlign: "center",
          }}
          onClick={(e) => {
            setEditCard(id);
          }}
          onMouseEnter={() => {
            setHovering(id);
          }}
          onMouseLeave={() => {
            setHovering(null);
          }}
        >
          <input
            value={name}
            onChange={(e) => {
              const val = new RegExp(/[A-Za-z0-9 ]+/i).test(e.target.value);
              const text = val ? e.target.value.match(/[A-Za-z0-9 ]+/i)[0] : "";
              setName(text);
              submitCard({ id: id, name: name });
            }}
            onBlur={() => {
              console.log("blur");
              if (newCard) {
                if (name && name.trim().length > 0) {
                  submitCard({ id: id, name: name });
                } else {
                  deleteCard(newCard);
                }
                newCard = null;
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (name && name.trim().length > 0) {
                  submitCard({ id: id, name: newCardName });
                  addCard("epic", newCard);
                } else {
                  deleteCard(newCard);
                  newCard = null;
                }
              }
            }}
            style={{
              height: "100%",
              background: "transparent",
              overflow: "hidden",
              border: 0,
              outline: 0,
              pointerEvents: "none",
              fontSize: 13,
              fontWeight: "bold",
              textAlign: "center",
              color: pillarDone ? "#235203" : "#000000",
            }}
            readOnly={newCard !== id}
            autoFocus={newCard === id}
          />
          {pillarDone && (
            <div
              className={hovering === id ? "fas fa-arrows-alt" : "fas fa-check"}
              style={{
                position: "absolute",
                right: 6,
                top: "50%",
                transform: "translateY(-52%)",
                fontSize: 15,
                marginRight: 4,
                color: "#317305",
              }}
            />
          )}
          {hovering === id && (
            <div
              className="fas fa-arrows-alt"
              style={{
                position: "absolute",
                right: 6,
                top: "50%",
                transform: "translateY(-52%)",
                fontSize: 15,
                marginRight: 4,
                color: "#666",
              }}
            />
          )}
        </div>

        <center
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {children}
        </center>
      </div>
    );
  };

  const Epic = ({ epic, k, obj, id }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
      type: "card",
      item: { id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);

    const completion = epic.completion ? epic.completion.total : 0;

    var color = getDodColor(completion, hovering === epic.card_id);
    const epicName = epic.card_name ? epic.card_name : "";

    return (
      <div
        ref={ref}
        style={{
          position: "relative",
          width: "100%",
          background: "#fff",
          borderRadius: 3,
          padding: 3,
          margin: 2,
          fontSize: 11,
          cursor: "pointer",
          flexGrow: 1,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 3px 4px #00000099",
          opacity: opacity,
        }}
        onClick={clickedEpic.bind(this, epic.card_id)}
        onMouseEnter={() => {
          setHovering(epic.card_id);
        }}
        onMouseLeave={() => {
          setHovering(null);
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            left: 0,
            width: completion.toString() + "%",
            background: color,
          }}
        />
        <>
          <div
            style={{
              marginBottom: 2,
              width: 130,
              lineHeight: 1,
              color: "#000",
              zIndex: 1,
            }}
          >
            <input
              value={newCard.current === epic.card_id ? newCardName : epicName}
              onChange={(e) => {
                const val = new RegExp(/[A-Za-z0-9 ]+/i).test(e.target.value);
                const text = val
                  ? e.target.value.match(/[A-Za-z0-9 ]+/i)[0]
                  : "";
                setNewCardName(text);
              }}
              onBlur={() => {
                if (newCard.current) {
                  if (newCardName && newCardName.trim().length > 0) {
                    submitCard({ id: newCard.current, name: newCardName });
                  } else {
                    deleteCard(newCard.current);
                  }
                  newCard.current = null;
                }
                setNewCardName("");
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (newCardName && newCardName.trim().length > 0) {
                    submitCard({ id: newCard.current, name: newCardName });
                    addCard("epic", obj.card_id);
                  } else {
                    deleteCard(newCard.current);
                    newCard.current = null;
                  }
                }
              }}
              style={{
                height: "100%",
                background: "transparent",
                overflow: "hidden",
                border: 0,
                outline: 0,
                pointerEvents: "none",
                fontSize: 11,
                textAlign: "center",
                color: completion >= 100 ? "#235203" : "#000000",
              }}
              readOnly={newCard.current !== epic.card_id}
              autoFocus={newCard.current === epic.card_id}
            />
          </div>
          {/*epic.children.length > 0 && (
            <center>
              <div
                style={{
                  display: "flex"
                }}
              >
                {epic.children.map((story, j) => {
                  return (
                    <div
                      key={"epic" + j}
                      style={{
                        display: "flex",
                        background: "#8c5806",
                        borderRadius: "50%",
                        width: 6,
                        height: 6,
                        marginRight: 1,
                        marginBottom: 1
                      }}
                    />
                  );
                })}
              </div>
            </center>
          )*/}
        </>
      </div>
    );
  };

  const Standard = ({ obj, i, children, targetId }) => {
    const cardName = obj.card_name ? obj.card_name : "";

    const colors = ["#ff0000", "#ff9900", "#00bb00"];
    const color = obj.card_status
      ? colors[parseInt(obj.card_status, 10)]
      : "#00bb00";

    var pillarColor = hovering === obj.card_id ? "#dcdcdc" : "#ccc";

    return (
      <div
        style={{
          position: "relative",
          margin: 6,
          maxWidth: 320,
          width: 180,
        }}
        onMouseEnter={(e) => {
          if (!newCard.current) {
            setHoveringCol(null);
          }
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            background: pillarColor,
            borderRadius: 4,
            marginBottom: 3,
            cursor: "pointer",
            padding: 7,
            fontSize: 13,
            textAlign: "center",
          }}
          onClick={(e) => {
            setEditCard(obj.card_id);
          }}
          onMouseEnter={() => {
            setHovering(obj.card_id);
          }}
          onMouseLeave={() => {
            setHovering(null);
          }}
        >
          <input
            value={newCard.current === obj.card_id ? newCardName : cardName}
            onChange={(e) => {
              const val = new RegExp(/[A-Za-z0-9 ]+/i).test(e.target.value);
              const text = val ? e.target.value.match(/[A-Za-z0-9 ]+/i)[0] : "";
              setNewCardName(text);
            }}
            onBlur={() => {
              if (newCard.current) {
                if (newCardName && newCardName.trim().length > 0) {
                  submitCard({ id: newCard.current, name: newCardName });
                } else {
                  deleteCard(newCard.current);
                }
                newCard.current = null;
              }
              setNewCardName("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (newCardName && newCardName.trim().length > 0) {
                  submitCard({ id: newCard.current, name: newCardName });
                  addCard("standard");
                } else {
                  deleteCard(newCard.current);
                  newCard.current = null;
                }
              }
            }}
            style={{
              height: "100%",
              background: "transparent",
              overflow: "hidden",
              border: 0,
              outline: 0,
              pointerEvents: "none",
              fontSize: 13,
              fontWeight: "bold",
              textAlign: "center",
              color: "#000000",
              width: "100%",
            }}
            readOnly={newCard.current !== obj.card_id}
            autoFocus={newCard.current === obj.card_id}
          />

          <div
            style={{
              position: "absolute",
              width: 10,
              height: 10,
              borderRadius: "50%",
              right: 6,
              top: "50%",
              transform: "translateY(-52%)",
              marginRight: 4,
              background: color,
            }}
          />
        </div>

        <center
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {children}
        </center>
      </div>
    );
  };

  //////////////////////////////////////////
  // Render

  const hasContent = cards.current && cards.current.length > 0;

  return (
    <>
      <div style={{ height: "90vh" }}>
        {editCard && (
          <EditCard
            id={editCard}
            data={cards.current[idToIdx.current[editCard]]}
            dods={discDods}
            setDod={setDod}
            submitCallback={submitCard}
            closeCallback={() => {
              setEditCard(null);
            }}
            deleteCallback={deleteCard}
            parent={
              cards.current &&
              editCard &&
              idToIdx.current[editCard] &&
              cards.current[idToIdx.current[editCard]].parent
                ? cards.current[idToIdx.current[editCard]].parent
                : []
            }
            disabled={!props.auth.requiresRole("lead") && !useLocal.current}
            goals={[]}
            addCard={addCard}
            useLocal={useLocal.current}
          />
        )}
        {props.prevGoal && (
          <OtherGoal
            text="MOVE TO PREVIOUS GOAL"
            targetGoal={props.prevGoal}
            submit={submitCard}
            style={{ left: 30, top: 30 }}
          />
        )}
        {props.nextGoal && (
          <OtherGoal
            text="MOVE TO NEXT GOAL"
            targetGoal={props.nextGoal}
            submit={submitCard}
            style={{ right: 30, top: 30 }}
          />
        )}
        {projectName === -1 ? (
          <center>
            <Canvas
              style={{
                height: 240,
                width: 240,
                outline: "none !important",
                marginTop: -36,
              }}
              camera={{ position: [0, 0.1, 15], fov: 10 }}
            >
              <spotLight position={[0, 3, 2]} penumbra={1} />
              <ambientLight intensity={0.6} />
              <Controls />
              <Box hasControls />
            </Canvas>
            <br />
            <div className="fancy" style={{ fontSize: 38, lineHeight: 1.2 }}>
              Hello stranger,
            </div>
            <div style={{ fontSize: 20 }}>what are we building?</div>
            <div style={{ width: 400, marginTop: 10 }}>
              <TextInput
                placeholder="Product name"
                maxlength="20"
                validateCallback={validateName}
                ref={projectNameFullRef}
                keypressCallback={(e) => {
                  if (e.key === "Enter") {
                    createProj(e, projectNameFullRef.current.value());
                  }
                }}
                centered
                autofocus
              />
            </div>
          </center>
        ) : (
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexWrap: "nowrap",
              width: "100%",
              margin: 0,
            }}
          >
            {/*projEstimate && projEstimate.days && (
              <div
                style={{
                  position: "absolute",
                  right: 20,
                  top: 30,
                  fontSize: 12,
                  color: "#674ea7",
                }}
              >
                {Object.keys(projEstimate).map((val, i) => {
                  return ["years", "months", "weeks", "days"].includes(val) &&
                    projEstimate[val] > 0
                    ? projEstimate[val] + " " + val + " "
                    : null;
                })}
              </div>
            )*/}
            <div className="column">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                  marginTop: 10,
                  background: "#ffffff22",
                  borderRadius: 10,
                  padding: 16,
                }}
              >
                {goalRef.current > -1 && !hasContent && !loading && (
                  <div style={{ marginTop: 10 }}>
                    <center>
                      <ButtonAdd
                        onClick={() => {
                          addCard("pillar");
                        }}
                        lightMode={true}
                        large={true}
                      />

                      <div
                        style={{
                          marginTop: 8,
                          width: 12,
                          height: 12,
                          borderLeft: "12px solid transparent",
                          borderRight: "12px solid transparent",
                          borderBottom: "12px solid #6e55ad",
                        }}
                      />
                      <div
                        style={{
                          background: "#6e55ad",
                          width: 8,
                          height: 34,
                        }}
                      />

                      <h3 style={{ marginTop: 8, marginLeft: -8 }}>
                        <b>Components</b>
                      </h3>
                      <div
                        style={{
                          width: 300,
                          lineHeight: "initial",
                          fontWeight: 200,
                          fontSize: 15,
                          marginTop: -6,
                        }}
                      >
                        Please break {projectName} down into its larger
                        components/categories/pillars/areas.
                      </div>
                    </center>
                  </div>
                )}

                {hasContent &&
                  pillars.current.map((obj, i) => {
                    return (
                      <Pillar
                        key={"pillar" + i}
                        col={i}
                        id={obj.card_id}
                        nameInit={obj.card_name}
                        epics={obj.children}
                        newCard={newCard.current}
                        submitCard={submitCard}
                      >
                        {obj.children.map((epic, k) => {
                          return (
                            <Epic
                              key={"epic" + k}
                              {...{ epic, k, obj }}
                              id={epic.card_id}
                            />
                          );
                        })}

                        {pillars.current.length > 1 && epics.length === 0 ? (
                          <center style={{ width: "100%" }}>
                            <div style={{ width: 160 }}>
                              <ButtonAdd
                                onClick={() => {
                                  addCard("epic", obj.card_id);
                                }}
                                lightMode={true}
                              />

                              {i === 0 && (
                                <>
                                  <div
                                    style={{
                                      marginTop: 8,
                                      width: 12,
                                      height: 12,
                                      borderLeft: "12px solid transparent",
                                      borderRight: "12px solid transparent",
                                      borderBottom: "12px solid #6e55ad",
                                    }}
                                  />
                                  <div
                                    style={{
                                      background: "#6e55ad",
                                      width: 8,
                                      height: 34,
                                    }}
                                  />

                                  <h3 style={{ marginTop: 8, marginLeft: -8 }}>
                                    <b>Epics</b>
                                  </h3>
                                  <div
                                    style={{
                                      lineHeight: "initial",
                                      fontWeight: 200,
                                      fontSize: 15,
                                      marginTop: -6,
                                    }}
                                  >
                                    A Component has Epics. An Epic in Scrum
                                    methodology takes longer than a sprint to
                                    finish.
                                  </div>
                                </>
                              )}
                            </div>
                          </center>
                        ) : (
                          <>
                            {hoveringCol === i && pillars.current.length > 1 && (
                              <center style={{ width: "100%" }}>
                                <ButtonAdd
                                  onClick={() => {
                                    addCard("epic", obj.card_id);
                                  }}
                                  lightMode
                                />
                              </center>
                            )}
                          </>
                        )}
                      </Pillar>
                    );
                  })}
                {hasContent && !loading && (
                  <div style={{ marginLeft: 6, marginTop: 8 }}>
                    <ButtonAdd
                      onClick={() => {
                        addCard("pillar");
                      }}
                      lightMode
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!isPastGoalRef.current && (
          <>
            {hasContent && (
              <center
                style={{
                  fontSize: 17,
                  display: "block",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginTop: 20,
                }}
              >
                <div style={{ marginBottom: -26 }}>Reocurring objectives</div>
              </center>
            )}
            <div
              className="row"
              style={{
                justifyContent: "center",
                flexWrap: "nowrap",
                width: "100%",
                margin: 0,
              }}
            >
              <div className="column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 30,
                    marginTop: 10,
                    padding: 16,
                  }}
                >
                  {hasContent &&
                    standards.current.map((obj, i) => {
                      return (
                        <Standard
                          key={"standard" + i}
                          {...{
                            obj,
                            i,
                          }}
                          targetId={obj.card_id}
                        />
                      );
                    })}
                  {hasContent && !loading && (
                    <div style={{ marginLeft: 6, marginTop: 8 }}>
                      <ButtonAdd
                        onClick={() => {
                          addCard("standard");
                        }}
                        lightMode
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {useLocal.current && projectName !== -1 && (
          <div
            className="tinytext"
            style={{
              display: "flex",
              position: "fixed",
              bottom: 30,
              left: 10,
              cursor: "pointer",
            }}
            onClick={() => history.push("/signup")}
          >
            <div
              className="fas fa-exclamation-triangle"
              style={{ fontSize: 15, marginRight: 4 }}
            />{" "}
            <b>LOCAL MODE</b>
            <div className="tinytext" style={{ marginLeft: 3 }}>
              - Click to store in cloud
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const OtherGoal = (props) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ["pillar"],
    drop(item) {
      props.submit({ id: item.targetId, goal: props.targetGoal }, true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  drop(ref);

  return (
    <div
      ref={ref}
      className={isOver ? "" : "pulse"}
      style={{
        display: "flex",
        visibility: canDrop ? "visible" : "hidden",
        position: "absolute",
        width: 80,
        height: 60,
        borderRadius: 10,
        background: isOver ? "#ff9900cc" : "#ff990055",
        fontSize: 13,
        padding: 4,
        textAlign: "center",
        lineHeight: 1,
        justifyContent: "center",
        alignItems: "center",
        border: isOver ? "2px white solid" : "2px transparent solid",
        ...props.style,
      }}
    >
      {props.text}
    </div>
  );
};
