import React from "react";
import TimeGrid from "../components/TimeGrid";
import db from "../utils/db";
import moment from "moment";
import { toast } from "react-toastify";

class UserProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      boxWidth: 16,
      boxSpaceVert: 1,
      nameCol: 0,
      movement: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.submitDays = this.submitDays.bind(this);
    this.setMovement = this.setMovement.bind(this);
    this.submitJoinLeaveDay = this.submitJoinLeaveDay.bind(this);
    this.hasAdjacentMove = this.hasAdjacentMove.bind(this);
    this.menuSubmit = this.menuSubmit.bind(this);

    this.timegrid = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  //////////////////////////////////////////////////////

  getInitState() {
    var initState = {};
    var today = new Date();
    var orgCreated = new Date(this.props.auth.getOrgCreation());

    initState.data = [];
    initState.days = [];
    initState.loading = true;
    initState.modify = false;
    initState.orgCreated = orgCreated;
    initState.today = today;
    return initState;
  }

  async fetchData(refresh = false, cb) {
    var initState = this.getInitState();

    const peepzData = await db.request("/auth/getdays", "POST", {
      orgid: this.props.auth.getOrgId(),
      userid: this.props.userid,
    });

    if (peepzData && peepzData.status === 200) {
      var startDate = new Date(peepzData.data[0].dayz_date);
      var endDate = new Date(
        peepzData.data[peepzData.data.length - 1].dayz_date
      );
      var daysToEnd = startDate.daysBetween(endDate) + 1;
      var rangeFrom = initState.orgCreated.daysBetween(startDate);
      var rangeTo = initState.orgCreated.daysBetween(endDate) + 1;
      const userid = this.props.userid;

      var years = [];
      var year = startDate.getFullYear();
      while (year <= endDate.getFullYear()) {
        years.push(year);
        year++;
      }

      // Get public holidays
      const resHolidays = await db.request("/auth/getholidays", "POST", {
        orgid: this.props.auth.getOrgId(),
        years: years,
      });

      // ORGANIZER LOOP
      var mainArr = [];
      var maxName = 0;
      var joinedOrg = true;
      var joinedOrgPointer = 0;
      var joined = {};
      var joinedFrom = {};
      var lastLeave = {};
      var projname = {};
      var status = {};
      var inCompany = new Array(daysToEnd).fill(true);
      var dayid = new Array(daysToEnd).fill(-1);
      var date = new Array(daysToEnd).fill(null);

      var joinsLeaves = {};
      var holidays = {};
      var ribbons = {};

      // Holidays
      for (var p = 0; p < resHolidays.data.length; p++) {
        var holiDate = new Date(resHolidays.data[p].date);
        if (
          holiDate.isSameOrAfterDay(startDate) &&
          holiDate.isSameOrBeforeDay(endDate) &&
          resHolidays.data[p].enabled
        ) {
          var daysFromProjStart = holiDate.daysBetween(startDate);
          holidays[daysFromProjStart] = resHolidays.data[p];
        }
      }

      // Main loop through db data
      for (var i = 0; i < peepzData.data.length; i++) {
        var row = peepzData.data[i];
        var projid = row.dayz_project;

        // Initiate holders if project's first row
        if (projid && projid > 0 && !joinsLeaves[projid]) {
          projname[projid] = row.proj_name;
          joined[projid] = false;
          joinedFrom[projid] = 0;
          lastLeave[projid] = -1;
          joinsLeaves[projid] = new Array(daysToEnd).fill(false);
          status[projid] = new Array(daysToEnd).fill(null);
        }

        // First fill straight fwd arrays
        dayid[row.dayz_fromstart - rangeFrom] = row.dayz_id;
        date[row.dayz_fromstart - rangeFrom] = row.dayz_date;

        if (row.dayz_status !== "offproject") {
          var statusKeys = Object.keys(status);
          for (var s = 0; s < statusKeys.length; s++) {
            status[statusKeys[s]][row.dayz_fromstart - rangeFrom] =
              row.dayz_status;
          }
        } else {
          status[projid][row.dayz_fromstart - rangeFrom] = row.dayz_status;
        }

        if (row.dayz_type === "org") {
          // In or out of company
          if (row.dayz_status === "join") {
            inCompany.fill(
              false,
              joinedOrgPointer,
              row.dayz_fromstart - rangeFrom
            );
            joinedOrgPointer = row.dayz_fromstart - rangeFrom;
            joinedOrg = true;
          } else if (row.dayz_status === "left") {
            joinedOrgPointer = row.dayz_fromstart - rangeFrom;
            joinedOrg = false;
          }
        } else {
          // Off or on the project
          if (!joined[projid] && row.dayz_status === "join") {
            joined[projid] = true;
            joinedFrom[projid] = row.dayz_fromstart;
          } else if (joined[projid] && row.dayz_status === "left") {
            joined[projid] = false;
            lastLeave[projid] = row.dayz_fromstart - rangeFrom;
            joinsLeaves[projid].fill(
              true,
              joinedFrom[projid] - rangeFrom,
              lastLeave[projid] + 1
            );
          }
        }
      }

      // Follow up if there was a company leave after loop
      if (!joinedOrg) {
        inCompany.fill(false, joinedOrgPointer + 1);
      }

      // Assemble final Timegrid data
      Object.keys(joinsLeaves).forEach(function (key) {
        mainArr.push({
          userid: userid,
          projectid: parseInt(key, 10),
          firstname: projname[key],
          lastname: "",
          joinsLeaves: joinsLeaves[key],
          status: status[key],
          dayid: dayid,
          date: date,
          inCompany: inCompany,
          lastLeave: lastLeave[key],
        });

        if (projname[key] && projname[key].length > maxName)
          maxName = projname[key].length;
      });

      // Create days array for reference
      var days = [];
      var weekDays = [];

      for (
        var d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        var newD = new Date(d);
        days.push(newD);
        weekDays.push(newD.getDay());
      }

      this.setState({
        nameCol: maxName * 11,
        data: mainArr,
        days: days,
        weekDays: weekDays,
        holidays: holidays,
        ribbons: ribbons,
        loading: false,
        orgcreated: initState.orgCreated,
        startdate: startDate,
        enddate: endDate,
        today: initState.today,
        daysToEnd: daysToEnd,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        winHeight:
          mainArr.length * (this.state.boxWidth + this.state.boxSpaceVert) +
          250,
      });
    } else {
      this.setState({
        data: [],
        days: [],
        loading: false,
      });
    }
    if (!refresh && this.timegrid.current)
      this.timegrid.current.resetViewPosition();

    if (cb) cb();
  }

  formatDayData(status, type, selectedDays, repeat) {
    var tmpArr = [];
    var tmpDates = {};

    selectedDays.forEach((val) => {
      var idxs = val.split("-");
      var day = this.state.startdate.addDays(parseInt(idxs[2], 10));
      var row = parseInt(idxs[0], 10);

      if (!tmpDates[idxs[1]]) tmpDates[idxs[1]] = [];

      if (repeat === -1) {
        tmpArr.push({
          date: moment(day).format("DD MM YYYY"),
          userid: this.props.userid,
          type: type,
          status: status,
          projid: parseInt(this.state.data[row].projectid, 10),
        });
      } else if (repeat > 0) {
        for (var i = 0; i <= repeat; i++) {
          if (
            day.isSameOrBeforeDay(this.state.enddate) &&
            !tmpDates[idxs[1]].includes(day.getTime())
          ) {
            tmpArr.push({
              date: moment(day).format("DD MM YYYY"),
              userid: this.props.userid,
              type: type,
              status: status,
              projid: parseInt(this.state.data[row].projectid, 10),
            });
            tmpDates[idxs[1]].push(day.getTime());
          }
          day = new Date(day.addDays(7));
        }
      } else if (repeat === 0) {
        while (
          day.isSameOrBeforeDay(this.state.enddate) &&
          !tmpDates[idxs[1]].includes(day.getTime())
        ) {
          tmpArr.push({
            date: moment(day).format("DD MM YYYY"),
            userid: this.props.userid,
            type: type,
            status: status,
            projid: parseInt(this.state.data[row].projectid, 10),
          });
          tmpDates[idxs[1]].push(day.getTime());
          day = day.addDays(7);
        }
      }
    });

    return tmpArr;
  }

  async submitDays(status, type, selectedDays, repeat) {
    await db.request("/auth/setdays", "POST", {
      data: this.formatDayData(status, type, selectedDays, repeat),
      status: status,
      orgid: this.props.auth.getOrgId(),
    });

    this.fetchData(true);
  }

  setMovement(obj) {
    this.setState({ movement: obj });
  }

  async submitJoinLeaveDay(
    userid,
    fromDate,
    toDate,
    since,
    status,
    projid,
    callback
  ) {
    var hasAdjacent = this.hasAdjacentMove(
      this.state.movement.row,
      since - this.state.rangeFrom,
      status
    );

    var dayid = null;
    var move = Object.assign({}, this.state.movement);
    if (status === "join") {
      if (move.soonestCompany === -1) {
        if (move.soonest === -1) {
          move.soonest = 0;
        }
      } else {
        if (move.soonest < move.soonestCompany) {
          dayid = this.state.data[move.row].dayid[move.soonest];
        }
      }
    } else {
      if (move.latestCompany === -1) {
        if (move.latest === -1) {
          move.latest = this.state.data[move.row].joinsLeaves.length - 1;
        } else {
          dayid = this.state.data[move.row].dayid[move.latest];
        }
      } else {
        if (move.latest > move.latestCompany) {
          dayid = this.state.data[move.row].dayid[move.latest];
        }
      }
    }

    const res = await db.request("/auth/moveday", "POST", {
      projectid: projid,
      userid: userid,
      type: "project",
      status: status,
      fromdate: fromDate.format(),
      todate: toDate.format(),
      since: since,
      hasAdjacent: hasAdjacent,
      dayid: dayid,
    });

    callback(res);
  }

  hasAdjacentMove(row, toidx, type) {
    var wOffsets = 0;
    const weekends = [0, 6];
    var isJoined = this.state.data[row].joinsLeaves[toidx];

    if (type === "join") {
      while (weekends.includes(this.state.weekDays[toidx - wOffsets - 1])) {
        wOffsets++;
      }
      var prevJoined =
        toidx > 0
          ? this.state.data[row].joinsLeaves[toidx - wOffsets - 1]
          : false;

      return prevJoined && !isJoined;
    } else if (type === "left") {
      while (weekends.includes(this.state.weekDays[toidx + wOffsets + 1])) {
        wOffsets++;
      }
      var nextJoined =
        toidx < this.state.data[row].joinsLeaves.length
          ? this.state.data[row].joinsLeaves[toidx + wOffsets + 1]
          : false;

      return !isJoined && nextJoined;
    } else {
      return false;
    }
  }

  async menuSubmit(row, idx, type, projid) {
    var day = this.state.orgcreated.addDays(idx + this.state.rangeFrom);

    if (type === "leave") {
      const resLeave = await db.request("/auth/leaveday", "POST", {
        userid: this.state.data[row].userid,
        todate: moment(day).format("DD MM YYYY"),
        projid: projid,
        isProject: true,
      });

      if (resLeave.status === 200) {
        this.fetchData(true);
      } else {
        toast.error("Unknown error, day could not be set.");
      }
    } else if (type === "join") {
      const resJoin = await db.request("/auth/joinday", "POST", {
        userid: this.state.data[row].userid,
        date: moment(day).format("DD MM YYYY"),
        projid: projid,
        orgid: this.props.auth.getOrgId(),
        isProject: true,
      });

      if (resJoin.status === 200) {
        this.fetchData(true);
      } else {
        toast.error("Unknown error, day could not be set.");
      }
    }
  }

  //////////////////////////////////////////////////////

  render() {
    return (
      <>
        {!this.state.loading && this.state.data.length === 0 && (
          <center>No data</center>
        )}
        {!this.state.loading && this.state.data.length > 0 && (
          <TimeGrid
            ref={this.timegrid}
            auth={this.props.auth}
            boxWidth={this.state.boxWidth}
            boxSpaceHoriz={1}
            boxSpaceVert={this.state.boxSpaceVert}
            daysHeight={12}
            monthHeight={38}
            nameCol={this.state.nameCol}
            originDate={this.state.orgcreated}
            startDate={this.state.startdate}
            endDate={this.state.enddate}
            data={this.state.data}
            days={this.state.days}
            weekDays={this.state.weekDays}
            holidays={this.state.holidays}
            movement={this.state.movement}
            ribbons={{}}
            showAllRows={true}
            url="/overview-project"
            fetchData={this.fetchData}
            submitDays={this.submitDays}
            setMovement={this.setMovement}
            submitJoinLeaveDay={this.submitJoinLeaveDay}
            menuSubmit={this.menuSubmit}
            arrowsOffset={110}
            noMenu
          />
        )}
      </>
    );
  }
}

export default UserProjects;
