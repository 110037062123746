import React from "react";
import db from "../utils/db";
import TextInput from "../components/TextInput";
import validator from "validator";
import history from "../utils/history";
import PassValidator from "password-validator";
import { toast } from "react-toastify";

import positive from "../imgs/positive.png";

class ResetPass extends React.Component {
  constructor(props) {
    super(props);

    var key = this.props.location.pathname;
    key =
      key.search("resetpass/") !== -1
        ? key.slice(key.search("resetpass/") + 10)
        : undefined;

    this.state = {
      flowStep: -1,
      userKey: key,
      password: "",
      disable: true,
    };

    this.checkKey = this.checkKey.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirm = this.validateConfirm.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.submitPassword = this.submitPassword.bind(this);

    this.email = React.createRef();
    this.password = React.createRef();
    this.confirm = React.createRef();
  }

  componentDidMount() {
    this.checkKey().then(() => {
      if (this.password && this.password.current)
        this.password.current.select();
    });
  }

  async checkKey() {
    var state = this.state;
    if (this.state.userKey) {
      const checkKey = await db.request("/resetpass", "POST", {
        action: "check_key",
        key: state.userKey,
      });

      if (checkKey.status === 200) {
        state.flowStep = 2;
        state.disable = false;
      } else {
        toast.warn(() => (
          <div style={{ verticalAlign: "middle" }}>
            <i
              className="fas fa-exclamation-triangle"
              style={{ paddingRight: "5px", fontSize: "1.2rem" }}
            />
            <span>The user key is no longer valid.</span>
          </div>
        ));

        history.push("/login");
      }
    } else {
      state.flowStep = 0;
      const email = localStorage.getItem("reset_email");
      if (email && this.email && this.email.current)
        this.email.current.setValue(email);
      state.disable = false;
    }
    this.setState(state);
  }

  validateEmail(value) {
    var msg = "";
    if (!validator.isEmail(value)) msg = "A valid email please, sir/mam.";
    return msg;
  }

  async submitEmail(e) {
    e.preventDefault();
    var state = this.state;
    var emailStatus = "";
    var submitSuccess = false;
    var hasErrors = this.email.current.hasErrors();

    if (!hasErrors) {
      // Send email with reset link
      const resMail = await db.request("/resetpass", "POST", {
        email: this.email.current.value(),
        action: "send_mail",
      });
      submitSuccess = resMail.status === 200;
      emailStatus = submitSuccess
        ? ""
        : "Error sending email. Please try again later.";
    }

    if (!submitSuccess) {
      this.email.current.submit(emailStatus);
    } else {
      state.flowStep = 1;
    }
    this.setState(state);
  }

  validatePassword(value) {
    var state = this.state;
    state.password = value;
    this.setState(state);

    var schema = new PassValidator();
    schema
      .is()
      .min(8)
      .is()
      .max(25)
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces();

    var messages = {
      min: "At least 8 characters",
      uppercase: "A capital letter",
      lowercase: "A lower case letter",
      digits: "At least a number",
      spaces: "No spaces",
      empty: "Is not empty",
    };

    var errors = schema.validate(value, { list: true });
    if (value === "") errors.push("empty");

    var msg = [];
    Object.keys(messages).map((key) =>
      msg.push({
        valid: !errors.includes(key),
        message: messages[key],
      })
    );

    return msg;
  }

  validateConfirm(value) {
    return value === this.state.password ? "" : "Password does not match";
  }

  async submitPassword(e) {
    e.preventDefault();

    var submitSuccess = false;
    var state = this.state;
    var hasErrors =
      this.password.current.hasErrors() || this.confirm.current.hasErrors();

    if (!hasErrors) {
      // Add user to db
      const response = await db.request("/resetpass", "POST", {
        action: "reset",
        pass: this.confirm.current.value(),
        key: state.userKey,
      });

      if (response.status === 200) {
        submitSuccess = true;
      } else {
        state.disable = true;
        toast.warn(
          () => (
            <div style={{ verticalAlign: "middle" }}>
              <i
                className="fas fa-exclamation-triangle"
                style={{ paddingRight: "5px", fontSize: "1.2rem" }}
              />
              <span>Couldn´t reset password. Please try later.</span>
            </div>
          ),
          {
            onClose: () => {
              var state = this.state;
              state.disable = false;
              this.setState(state);
            },
          }
        );
      }
    }

    if (!submitSuccess) {
      this.password.current.validate(true);
      this.confirm.current.validate(true);
    } else {
      state.flowStep = 3;
    }
    this.setState(state);
  }

  render() {
    var page = "";
    var disabled = this.state.disable;

    switch (this.state.flowStep) {
      case 0:
        page = (
          <div>
            <center>
              <h1>Reset password</h1>
              First, the email you{"'"}re certain you registered with..
            </center>
            <br />
            <form onSubmit={this.submitEmail}>
              <fieldset disabled={disabled}>
                <TextInput
                  validateCallback={this.validateEmail}
                  requireSubmit={true}
                  ref={this.email}
                  placeholder="you@companydomain.com"
                  maxlength="50"
                  autofocus
                />
                <button
                  className="btn btn-success btn-lg btn-block"
                  onClick={this.submitEmail}
                >
                  <div>
                    SUBMIT <i className="fas fa-angle-right" />
                  </div>
                </button>
              </fieldset>
            </form>
          </div>
        );
        break;
      case 1:
        page = (
          <div>
            <center>
              <h1>Reset password</h1>
              <br />
              <img
                src={positive}
                width="120px"
                height="120px"
                alt="SUBMITTED"
              />
              <br />
              <strong>Email sent!</strong>
            </center>
          </div>
        );
        break;
      case 2:
        page = (
          <div>
            <center>
              <h1>Reset password</h1>
              Select your new password!
            </center>
            <br />
            <form>
              <fieldset disabled={disabled}>
                <TextInput
                  placeholder="Password"
                  maxlength="25"
                  validateCallback={this.validatePassword}
                  ref={this.password}
                  password
                />
                <TextInput
                  placeholder="Confirm password"
                  maxlength="25"
                  validateCallback={this.validateConfirm}
                  ref={this.confirm}
                  password
                />
                <button
                  type="submit"
                  className="btn btn-danger btn-lg btn-block"
                  onClick={this.submitPassword}
                >
                  SUBMIT
                </button>
              </fieldset>
            </form>
          </div>
        );
        break;
      case 3:
        page = (
          <div>
            <center>
              <h1>Password reset!</h1>
              <br />
              <img
                src={positive}
                width="120px"
                height="120px"
                alt="SUBMITTED"
              />
              <br />
              <strong>Consider it done.</strong>
              <br />
              Goto{" "}
              <button
                className="btn btn-link"
                onClick={() => history.push("/login")}
              >
                LOGIN
              </button>
            </center>
          </div>
        );
        break;
      default:
    }
    return <div className="form-wrapper">{page}</div>;
  }
}

export default ResetPass;
