import React, { useEffect, useRef } from "react";
import history from "../utils/history";
import { Canvas, extend, useThree, useRender } from "react-three-fiber";
import Box from "../components/Box";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

const Controls = () => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();

  useRender(() => {
    orbitRef.current.update();
  });

  return (
    <orbitControls
      autoRotate
      maxPolarAngle={Math.PI / 3}
      minPolarAngle={Math.PI / 3}
      args={[camera, gl.domElement]}
      ref={orbitRef}
    />
  );
};

export default function Home(props) {
  useEffect(() => {
    // To refresh header
    props.dispatch.fire("loggedin");
  }, []);

  return (
    <center>
      <Canvas
        style={{
          height: 240,
          width: 240,
          outline: "none !important",
          marginTop: -36,
        }}
        camera={{ position: [0, 0.1, 15], fov: 10 }}
      >
        <spotLight position={[0, 3, 2]} penumbra={1} />
        <ambientLight intensity={0.6} />
        <Controls />
        <Box hasControls />
      </Canvas>
      <div>Serious about the project you're building? Then..</div>
      <div
        className="fancy"
        style={{ fontSize: 50, textShadow: "1px 1px 4px #000" }}
      >
        Define your Product!
      </div>
      <div>...and let your team(s) manage the rest.</div>
      <br />
      <button
        className="btn btn-success btn-lg"
        style={{ width: 400 }}
        onClick={() => history.push("/try")}
      >
        {localStorage.getItem("product") ? "CONTINUE TRYING" : "TRY IT QUICKLY"}
      </button>
      <br />
    </center>
  );
}
