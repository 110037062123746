import React, { Fragment } from "react";
import { TweenMax, Elastic } from "gsap/all";
import Color from "color";

class Ribbon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      ready: false,
      width: null,
      height: null
    };

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.doEnter = this.doEnter.bind(this);

    this.root = {};
    this.rootFunc = e => {
      this.root = e;
    };

    this.ribbon = {};
    this.ribbonFunc = e => {
      if (e) {
        this.ribbon = e;

        if (this.props.reportCb && e)
          this.props.reportCb(
            this.props.idx,
            this.props.data.id,
            e.clientWidth
          );

        this.setState({
          ready: true,
          width: e.clientWidth,
          height: e.clientHeight
        });
      }
    };

    this.pillar = React.createRef();
    this.fold = React.createRef();

    this.ribbonStyle = { height: 0 };
    this.marginSize = { small: 6, large: -6, medium: 0 };
  }

  componentDidMount() {
    if (this.props.show) {
      this.doEnter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.id !== this.props.data.id) {
      this.forceUpdate();
    }

    if (!prevProps.show && this.props.show) {
      if (this.props.movedRibbon !== this.props.data.id) {
        this.doEnter();
      }
    }
  }

  onMouseMove(e) {
    if (this.props.onMove)
      this.props.onMove(e.clientX, e.clientY - this.props.top);
  }

  onMouseUp() {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
    if (this.props.onStopDrag) this.props.onStopDrag();
    this.setState({ dragging: false });
  }

  doEnter() {
    TweenMax.fromTo(
      [this.fold.current, this.ribbon],
      0.6,
      { bottom: -this.ribbonStyle.height - this.props.height + 12, opacity: 0 },
      {
        bottom: -this.ribbonStyle.height,
        opacity: 1,
        ease: Elastic.easeOut.config(1, 0.3),
        delay: this.props.animTiming * 0.03
      }
    );
    TweenMax.fromTo(
      this.pillar.current,
      0.6,
      { opacity: 0, height: 10 },
      {
        opacity: 1,
        height: this.props.height - 2,
        ease: Elastic.easeOut.config(1, 0.3),
        delay: this.props.animTiming * 0.03
      }
    );
  }

  render() {
    var mainWidth = 0;
    if (this.ribbon.clientWidth) {
      mainWidth = this.ribbon.clientWidth;
    }

    var size = this.props.size ? this.props.size : "small";
    this.ribbonStyle = !this.props.invert
      ? {
          display: "inline-block",
          color: "#fff",
          fontWeight: 500,
          fontStyle: "italic",
          backgroundColor: this.props.color,
          position: "absolute",
          bottom: -this.ribbonStyle.height,
          cursor: "pointer",
          whiteSpace: "nowrap",
          transition: "none"
        }
      : {
          color: "#fff",
          fontWeight: 500,
          fontStyle: "italic",
          backgroundColor: this.props.color,
          position: "absolute",
          left: -mainWidth - 12,
          cursor: "pointer",
          whiteSpace: "nowrap",
          transition: "none"
        };

    var foldStyle = !this.props.invert
      ? {
          content: "",
          width: "20px",
          position: "absolute",
          bottom: 0,
          left: "0px",
          zIndex: 10,
          borderBottom: "12px solid " + this.props.color,
          WebkitTransform: "rotate(-90deg)",
          WebkitTransformOrigin: "left bottom",
          MozTransform: "rotate(-90deg)",
          MozTransformOrigin: "left bottom",
          OTransform: "rotate(-90deg)",
          OTransformOrigin: "left bottom",
          MsTransform: "rotate(-90deg)",
          MsTransformOrigin: "left bottom",
          transform: "rotate(-90deg)",
          transformOrigin: "left bottom",
          transition: "none"
        }
      : {
          content: "",
          width: "20px",
          position: "absolute",
          bottom: 0,
          zIndex: 10,
          WebkitTransform: "rotate(-90deg)",
          WebkitTransformOrigin: "left bottom",
          MozTransform: "rotate(-90deg)",
          MozTransformOrigin: "left bottom",
          OTransform: "rotate(-90deg)",
          OTransformOrigin: "left bottom",
          MsTransform: "rotate(-90deg)",
          MsTransformOrigin: "left bottom",
          transform: "rotate(-90deg)",
          transformOrigin: "left bottom",
          transition: "none"
        };

    var shadecolor = Color(this.props.pillarcolor)
      .darken(0.8)
      .hex();
    var pillarStyle = {
      content: "",
      width: this.props.width,
      height: this.props.height - 2,
      bottom: 0,
      position: "absolute",
      backgroundImage:
        "linear-gradient(to bottom, " +
        shadecolor +
        " 1px, " +
        this.props.pillarcolor +
        " 8px)",
      transition: "none"
    };

    var pegStyle = {
      position: "absolute",
      left: "-12px",
      width: "0px",
      height: "0px",
      borderLeft: (this.props.width / 2).toString() + "px solid transparent",
      borderRight: (this.props.width / 2).toString() + "px solid transparent",
      borderTop:
        (this.props.width / 2).toString() +
        "px solid " +
        this.props.pillarcolor,
      transition: "none"
    };

    var trashStyle = {
      position: "absolute",
      left: mainWidth + 5,
      cursor: "pointer",
      color: "#999",
      WebkitTransition: "none",
      msTransition: "none"
    };

    var moveLeftStyle = {
      position: "absolute",
      left: -15,
      top: this.state.height / 2 - 8,
      color: "#ccc",
      WebkitTransition: "none",
      msTransition: "none",
      zIndex: 50
    };

    var moveRightStyle = {
      position: "absolute",
      left: mainWidth + 5,
      top: this.state.height / 2 - 8,
      color: "#ccc",
      WebkitTransition: "none",
      msTransition: "none",
      zIndex: 50
    };

    switch (size) {
      case "large":
        if (!this.props.invert) {
          this.ribbonStyle.paddingTop = "4px";
          this.ribbonStyle.paddingLeft = "1px";
          this.ribbonStyle.paddingBottom = "4px";
          this.ribbonStyle.paddingRight = 10;
          pillarStyle.left = "-12px";
        } else {
          this.ribbonStyle.padding = "4px 12px";
          this.ribbonStyle.paddingLeft = "10px";
          foldStyle.borderTop = "13px solid " + this.props.color;
          foldStyle.left = "20px";
          pillarStyle.left = "-9px";
          pegStyle.left = "-9px";
        }
        this.ribbonStyle.height = 32;
        this.ribbonStyle.fontSize = "17px";
        this.ribbonStyle.textShadow = "1px 1px 0px rgba(0, 0, 0, 0.9)";
        this.ribbonStyle.fontWeight = 600;
        foldStyle.height = "32px";
        foldStyle.borderRight = "32px solid transparent";
        foldStyle.bottom = -this.ribbonStyle.height;
        pillarStyle.bottom = -this.props.height - 30;
        pegStyle.top = this.props.height + 30;
        trashStyle.top = "8px";
        break;
      case "medium":
        if (!this.props.invert) {
          this.ribbonStyle.padding = "2px 1px";
          this.ribbonStyle.paddingRight = "10px";
          pillarStyle.left = "-12px";
        } else {
          this.ribbonStyle.padding = "2px 12px";
          this.ribbonStyle.paddingLeft = "10px";
          foldStyle.borderTop = "10px solid " + this.props.color;
          foldStyle.left = "14px";
          pillarStyle.left = "-12px";
        }
        this.ribbonStyle.height = 26;
        this.ribbonStyle.fontSize = "14px";
        this.ribbonStyle.textShadow = "1px 1px 0px rgba(0, 0, 0, 0.7)";
        foldStyle.height = "26px";
        foldStyle.borderRight = "26px solid transparent";
        foldStyle.bottom = -this.ribbonStyle.height;
        pillarStyle.bottom = -this.props.height - 24;
        pegStyle.top = this.props.height + 24;
        trashStyle.top = "5px";

        break;
      case "small":
      default:
        if (!this.props.invert) {
          this.ribbonStyle.padding = "2px 1px";
          this.ribbonStyle.paddingRight = "10px";
          pillarStyle.left = "-12px";
        } else {
          this.ribbonStyle.padding = "2px 12px";
          this.ribbonStyle.paddingLeft = "10px";
          foldStyle.borderTop = "8px solid " + this.props.color;
          foldStyle.left = "8px";
          pillarStyle.left = "-12px";
        }
        this.ribbonStyle.height = 20;
        this.ribbonStyle.fontSize = "11px";
        this.ribbonStyle.textShadow = "1px 1px 0px rgba(0, 0, 0, 0.5)";
        foldStyle.height = "20px";
        foldStyle.borderRight = "20px solid transparent";
        foldStyle.bottom = -this.ribbonStyle.height;
        pillarStyle.bottom = -this.props.height - 18;
        pegStyle.top = this.props.height + 18;
        trashStyle.top = "2px";
    }

    var topVal = null;
    if (this.props.top) topVal += this.props.top;
    if (this.marginSize[this.props.size])
      topVal += this.marginSize[this.props.size];

    return (
      <div
        ref={this.rootFunc}
        style={{
          position: "absolute",
          top: topVal,
          left: this.props.left,
          zIndex: this.props.zIndex,
          width: this.state.width,
          height: this.ribbonStyle.height,
          opacity: this.props.opacity,
          transition: "none"
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            style={this.ribbonStyle}
            ref={this.ribbonFunc}
            onClick={() => {
              if (this.props.clickCallback)
                this.props.clickCallback(this.props.data);
            }}
            onMouseDown={e => {
              e.preventDefault();
              if (
                this.props.onStartDrag &&
                !this.state.dragging &&
                !this.props.disableDrag
              ) {
                document.addEventListener("mousemove", this.onMouseMove);
                document.addEventListener("mouseup", this.onMouseUp);
                var coord = {};
                coord.x = e.clientX;
                coord.y = e.clientY;
                this.props.onStartDrag(
                  this.props.data.id,
                  coord,
                  this.props.idx
                );
                this.setState({ dragging: true });
              }
            }}
          >
            {this.props.data.name}
          </div>
          <div style={foldStyle} ref={this.fold} />
          <div style={pillarStyle} ref={this.pillar} />

          <div className="ribbon-peg" style={pegStyle} />
          {this.state.dragging && (
            <Fragment>
              <div className="fas fa-caret-left" style={moveLeftStyle} />
              <div className="fas fa-caret-right" style={moveRightStyle} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Ribbon;
