import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import ContentEditable from "react-contenteditable";
import gsap from "gsap";

// -- Props Functions ---------
// default = string
// placeholder = string
// maxlength = number
// validateCb = function
// onChange = function(value)
// ref = ref
// multiline = bool
// disabled = bool
// email = string

export default forwardRef(function Textfield(props, ref) {
  const colorStates = {
    neutral: "#ccc",
    invalid: "red",
    valid: "green"
  };
  const borderStates = {
    neutral: "1px solid #ccc",
    invalid: "1px solid red",
    valid: "1px solid green"
  };
  const bgStates = {
    neutral: "#00000099",
    invalid: "#33000099",
    valid: "#00330099"
  };

  const [prefixWidth, setPrefixWidth] = useState(0);
  const [styleState, setStyleState] = useState("neutral");
  const [isValid, setIsValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [animation, setAnimation] = useState(null);
  const [value, setValue] = useState(
    props.default ? props.default.toString() : ""
  );
  const validateCb = useCallback(validate, [value, triedSubmit]);
  useEffect(() => {
    validateCb();
  }, [value, validateCb]);

  const wrapper = useRef(null);
  const multilineValue = useRef(props.default ? props.default : "");
  const prefix = useRef(null);

  useEffect(() => {
    if (!errorMsg && isValid) setStyleState("valid");
    else if (triedSubmit) setStyleState("invalid");
    else setStyleState("neutral");
  }, [errorMsg, isValid, triedSubmit]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      const valid = validate(true);
      setTriedSubmit(true);
      return valid;
    },
    value: () => {
      return value;
    },
    reset: () => {
      setValue("");
      setTriedSubmit(false);
      setIsValid(false);
      setErrorMsg(null);
      if (props.multiline) multilineValue.current = "";
    }
  }));

  useEffect(() => {
    if (prefix.current)
      setPrefixWidth(prefix.current.getBoundingClientRect().width + 26);
  }, []);

  // Functions ---------------------------------------------

  function validate(submit = false) {
    if (!props.validateCb) {
      throw new Error(
        "Tried validating TextInput component that doesn't have a validateCb method."
      );
    } else {
      const res = props.validateCb(value);
      if (!res) {
        // Parent reports no error
        setErrorMsg(null);
        setIsValid(true);
        return true;
      } else {
        // There's an error
        if (triedSubmit) {
          if (submit) shakeAnim();
          setErrorMsg(res);
        }
        setIsValid(false);
        return false;
      }
    }
  }

  function onInput(e) {
    var val = !e.target.value ? "" : e.target.value;
    if (props.multiline) multilineValue.current = val;
    setValue(val);
    if (props.onChange) props.onChange(val);
  }

  function onLeaveField(e) {
    if (
      e.relatedTarget &&
      ["INPUT", "BUTTON"].includes(e.relatedTarget.nodeName)
    ) {
      validate(true);
    }
  }

  function shakeAnim() {
    if (animation) animation.kill();
    setAnimation(
      gsap.fromTo(
        wrapper.current,
        0.06,
        { x: -10 },
        {
          x: 10,
          repeat: 3,
          yoyo: true,
          ease: "sine.inOut",
          onComplete: () => {
            setAnimation(
              gsap.to(wrapper.current, 0.3, {
                x: 0,
                ease: "elastic.easeOut",
                onComplete: () => {
                  setAnimation(null);
                }
              })
            );
          }
        }
      )
    );
  }

  const style = {
    fontSize: 16,
    borderRadius: 6,
    padding: 14,
    margin: 4,
    textAlign: props.centered ? "center" : "left",
    outline: 0,
    background: bgStates[styleState],
    color: "#ccc",
    border: borderStates[styleState],
    width: props.prefix ? 400 - prefixWidth + 14 : 400,
    height: props.multiline && !props.disabled ? "auto" : 20,
    minHeight: props.multiline && !props.disabled ? 200 : 20,
    opacity: props.disabled ? 0.4 : 1,
    transition: "min-height 0.3s, height 0.3s",
    paddingLeft: props.prefix ? prefixWidth : 14,
    overflow: "hidden",
    maxWidth: "100%"
  };

  return (
    <>
      <div
        ref={wrapper}
        style={{
          position: "relative",
          width: 400,
          maxWidth: "100%",
          textAlign: props.centered ? "center" : "left"
        }}
      >
        {props.prefix && (
          <div
            ref={prefix}
            style={{
              position: "absolute",
              left: 14,
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: 11,
              color: colorStates[styleState],
              paddingTop: 2
            }}
          >
            {props.prefix}
          </div>
        )}
        {!props.multiline ? (
          <input
            value={value}
            onChange={onInput}
            onBlur={onLeaveField}
            autoFocus={props.autofocus}
            placeholder={props.placeholder}
            maxLength={props.maxlength}
            style={style}
            disabled={props.disabled}
          />
        ) : (
          <ContentEditable
            html={multilineValue.current} // innerHTML of the editable div
            disabled={props.disabled} // use true to disable editing
            onChange={onInput} // handle innerHTML change
            tagName="article" // Use a custom HTML tag (uses a div by default)
            onBlur={onLeaveField}
            style={style}
            data-placeholder={props.placeholder}
          />
        )}
      </div>
      <center>
        <div style={{ fontSize: 12, fontWeight: "bold", color: "#f00" }}>
          {errorMsg}
        </div>
      </center>
    </>
  );
});
