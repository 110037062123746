import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import Colors from "../utils/colors";

export default function Card(props) {
  const images = props.data.card_images
    ? JSON.parse(props.data.card_images)
    : null;
  const priorities = [
    "transparent",
    Colors.low,
    Colors.medium,
    Colors.high,
    Colors.critical,
  ];

  const [tooltip, setTooltip] = useState();

  const [collected, dragRef] = useDrag(
    () => ({
      type: "card",
      item: (monitor) => {
        return {
          id: props.data.card_id,
          parent: props.data.card_parent,
          prevStatus: props.data.card_status,
          priority: props.data.card_priority,
          tooltip: tooltip,
        };
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
      canDrag: (monitor) => {
        return !props.isArchived;
      },
    }),
    [props.data.card_priority, props.data.card_parent, props.data.card_status]
  );

  const { opacity, isDragging } = collected;

  var border = "3px solid #fff";
  if (isDragging) border = "2px dashed #ffffff88";
  else if (props.highlight) border = "3px dotted orange";
  else if (
    props.showPriority &&
    props.data.card_priority &&
    props.data.card_priority > 0
  )
    border = "3px solid " + priorities[props.data.card_priority];

  var fullName = "";
  if (props.owner) {
    fullName = props.owner.user_firstname.substr(0, 2);
    if (props.owner.user_lastname) {
      fullName += props.owner.user_lastname.charAt(0);
    }
  }

  var bgColor = "#fff";
  if (isDragging) bgColor = "transparent";
  else if (props.isAllDone) bgColor = "#8f8";
  else if (
    props.showPriority &&
    props.data.card_priority &&
    props.data.card_priority > 0
  )
    bgColor = priorities[props.data.card_priority] + "55";

  var shadow = "1px 3px 5px #000000aa";
  if (isDragging) shadow = "";
  else if (props.isAllDone) shadow = "";

  return (
    <div
      ref={dragRef}
      style={{
        display: "inline-block",
        position: "relative",
        border: border,
        borderRadius: 5,
        width: "48%",
        minHeight: isDragging ? 60 : 10,
        maxWidth: 190,
        boxShadow: shadow,
        color: isDragging ? "#eee" : "#000",
        padding: 6,
        margin: 3,
        transition: "none",
        cursor: "pointer",
        zIndex: 5,
        opacity: props.data.outOfScope ? 0.36 : 1,
      }}
      onMouseEnter={() => {
        setTooltip("Out of scope");
      }}
      onMouseLeave={() => {
        setTooltip(null);
      }}
      onClick={(e) => {
        props.onClick(props.data.card_id, e);
        e.stopPropagation();
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: isDragging ? "transparent" : "#fff",
          borderRadius: 2,
          zIndex: -11,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: bgColor,
          borderRadius: 2,
          zIndex: -10,
        }}
      />
      {!isDragging && (
        <>
          <div
            className="cardText"
            style={{
              textAlign: props.align ? props.align : "left",
              fontSize: 13,
              lineHeight: 1,
              color: "#000",
              overflow: "visible",
            }}
          >
            {props.icon && !isDragging && (
              <div
                className={props.icon}
                style={{
                  fontSize: 11,
                  paddingRight: 8,
                }}
              />
            )}
            {props.isDone && (
              <i
                className="fas fa-check-circle"
                style={{
                  marginRight: 3,
                  color: "green",
                }}
              />
            )}
            {props.data.card_name}
          </div>
          {images && images.length > 0 && (
            <div
              style={{
                width: "100%",
                height: 40,
                overflow: "hidden",
                margin: "3px 0px",
              }}
            >
              {" "}
              <img
                id={images[0]}
                src={props.cloudinary.url(images[0])}
                alt="Card cap"
                style={{
                  width: "100%",
                  paddingTop: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </div>
          )}
          {props.discipline.name && (
            <div
              style={{
                display: "inline-block",
                fontSize: 9,
                padding: "1px 4px",
                marginRight: 3,
                borderRadius: 3,
                background: props.discipline.color
                  ? props.discipline.color
                  : "green",
                color: "white",
                border: props.discipline.color
                  ? "1px solid " + props.discipline.color
                  : "1px solid green",
              }}
            >
              {props.discipline.name}
            </div>
          )}
          {props.owner && (
            <div
              style={{
                display: "inline-block",
                fontSize: 9,
                fontWeight: "bold",
                padding: "1px 4px",
                background: "#aaa",
                borderRadius: 10,
                color: "white",
                border: "1px solid #aaa",
              }}
            >
              {fullName}
            </div>
          )}

          {props.estimate && (
            <div
              style={{
                display: "inline-block",
                float: "right",
                marginTop: 6,
                fontSize: 11,
                padding: "1px 4px",
                background: "transparent",
                borderRadius: 0,
                color: "#666",
                fontStyle: "italic",
              }}
            >
              {props.estimate + "h"}
            </div>
          )}

          {props.body && !isDragging && (
            <div
              style={{
                fontSize: 12,
                textAlign: "left",
                lineHeight: 1,
                color: "#555",
              }}
            >
              <i>{props.body}</i>
            </div>
          )}
          {tooltip && props.data.outOfScope && (
            <div
              style={{
                position: "absolute",
                background: "#f00",
                color: "#000",
                boxShadow: "1px 1px 3px #00000077",
                borderRadius: 5,
                bottom: 6,
                right: 6,
                padding: "4px 10px",
                fontSize: 12,
                opacity: 1,
              }}
            >
              {tooltip}
            </div>
          )}
        </>
      )}
    </div>
  );
}
