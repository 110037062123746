import React, { Fragment } from "react";
import moment from "moment";

class Pin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false
    };

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  componentDidMount() {
    if (this.props.reportCb)
      this.props.reportCb(this.props.idx, this.props.data.id, 20);
  }

  onMouseMove(e) {
    if (this.props.onMove)
      this.props.onMove(e.clientX, e.clientY - this.props.top);
  }

  onMouseUp() {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
    if (this.props.onStopDrag) this.props.onStopDrag();
    this.setState({ dragging: false });
  }

  render() {
    var height = 25;
    var size = 10;

    if (this.props.height) height = this.props.height;
    if (this.props.size) size = this.props.size;

    var tinyDiam = Math.round(size / 3);
    var marginLeft = Math.round(size / 5);
    var isEarlyInMonth = moment(this.props.data.date).date() < 10;

    var moveLeftStyle = {
      position: "absolute",
      left: -8,
      top: -3,
      color: "#ccc",
      WebkitTransition: "none",
      msTransition: "none",
      zIndex: 50
    };

    var moveRightStyle = {
      position: "absolute",
      left: 12,
      top: -3,
      color: "#ccc",
      WebkitTransition: "none",
      msTransition: "none",
      zIndex: 50
    };

    return (
      <div
        style={{
          position: "absolute",
          top: this.props.top,
          left: this.props.left,
          cursor: "pointer",
          marginLeft: -size / 2,
          zIndex: this.props.zIndex,
          transition: "none"
        }}
        onClick={() => {
          if (this.props.clickCallback)
            this.props.clickCallback(this.props.data);
        }}
        onMouseDown={e => {
          e.preventDefault();
          if (
            this.props.onStartDrag &&
            !this.state.dragging &&
            !this.props.disableDrag
          ) {
            document.addEventListener("mousemove", this.onMouseMove);
            document.addEventListener("mouseup", this.onMouseUp);
            var coord = {};
            coord.x = e.clientX;
            coord.y = e.clientY;
            this.props.onStartDrag(this.props.data.id, coord, this.props.idx);
            this.setState({ dragging: true });
          }
        }}
      >
        {this.state.dragging && (
          <Fragment>
            <div className="fas fa-caret-left" style={moveLeftStyle} />
            <div className="fas fa-caret-right" style={moveRightStyle} />
          </Fragment>
        )}
        {isEarlyInMonth && this.props.isEarlyInMonth && (
          <div>
            <div
              style={{
                position: "absolute",
                width: size,
                height: size,
                marginLeft: 0,
                borderRadius: "50%",
                boxShadow: "0px 0px 0px 3px #fff"
              }}
            />
            <div
              style={{
                position: "absolute",
                width: 2,
                height: height,
                marginLeft: 4,
                top: 6,
                boxShadow: "0px 0px 0px 2px #fff"
              }}
            />
          </div>
        )}
        <div
          style={{
            position: "absolute",
            width: 2,
            height: height,
            marginLeft: 4,
            opacity: this.props.faded && !this.props.isEarlyInMonth ? 0.25 : 1,
            backgroundImage:
              "linear-gradient(to bottom, #333 " +
              (height * 0.32).toString() +
              "px, #aaa " +
              (height * 0.6).toString() +
              "px)",
            top: 6
          }}
        />
        <div
          style={{
            position: "absolute",
            width: size,
            height: size,
            marginLeft: 0,
            borderRadius: "50%",
            opacity: this.props.faded && !this.props.isEarlyInMonth ? 0.25 : 1,
            backgroundImage: this.props.faded
              ? "linear-gradient(to bottom, #f0f0f0 7px, #858585 10px)"
              : "linear-gradient(to bottom, #efa51c 7px, #845b0f 10px)"
          }}
        />{" "}
        <div
          style={{
            position: "absolute",
            width: tinyDiam,
            height: tinyDiam,
            marginLeft: marginLeft,
            borderRadius: "50%",
            background: "#fff",
            opacity: 0.4
          }}
        />
      </div>
    );
  }
}

export default Pin;
