import React from "react";

const Colors = {
  main: {
    light: "#4e29aa",
    dark: "#1e123d",
  },
  vacation: {
    background: "#fdff8c",
    color: "#e0cc5c",
    icon: (
      <div
        className="fas fa-plane"
        style={{
          color: "#e0cc5c",
        }}
      />
    ),
  },
  vab: {
    background: "#ffc18c",
    color: "#d69964",
    icon: (
      <i
        className="fas fa-child"
        style={{
          color: "#d69964",
        }}
      />
    ),
  },
  sick: {
    background: "#ff8c8c",
    color: "#d66464",
    icon: (
      <i
        className="fas fa-procedures"
        style={{
          color: "#d66464",
        }}
      />
    ),
  },
  parental: {
    background: "#8ca4ff",
    color: "#6d83d1",
    icon: (
      <i
        className="fas fa-child"
        style={{
          color: "#6d83d1",
        }}
      />
    ),
  },
  critical: "#ff0000",
  high: "#ff9900",
  medium: "#ffdd00",
  low: "#59f1ff",
};

export default Colors;
