import React, { useState, useEffect, useRef } from "react";
import db from "../utils/db";
import DatePicker from "../components/DatePicker";
import ButtonAdd from "../components/ButtonAdd";
import Popup from "../components/Popup";
import { toast } from "react-toastify";

export default function UserSalary(props) {
  const [data, setData] = useState([]);
  const [changed, setChanged] = useState(-1);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [takeInput, setTakeInput] = useState(false);

  const inputRef = useRef([]);
  const changedRef = useRef(changed);

  useEffect(() => {
    fetchData();
    return () => {
      setTakeInput(false);
    };
  }, []);

  useEffect(() => {
    changedRef.current = changed;
  }, [changed]);

  useEffect(() => {
    function handleKey(e) {
      if (e.key === "Escape") {
        cancelSalary(changedRef.current);
      } else if (e.key === "Enter") {
        if (
          data[changedRef.current].attr_value !==
          data[changedRef.current].oldsalary
        ) {
          setConfirmPopup(true);
        } else {
          cancelSalary(changedRef.current);
        }
      }
    }

    if (takeInput) {
      document.addEventListener("keydown", handleKey);
    } else {
      document.removeEventListener("keydown", handleKey);
    }
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [takeInput]);

  // Functions xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  async function fetchData() {
    const res = await db.request("/auth/getsalaries", "POST", {
      userid: props.userid,
    });
    setData(res && res.status === 200 ? res.data : []);
  }

  async function submitSalary(idx) {
    const res = await db.request("/auth/setsalary", "POST", {
      attrid: data[idx].attr_id,
      value: data[idx].attr_value,
      submitted: new Date(data[idx].attr_submitted).format(),
      new: data[idx].new,
      userid: props.userid,
    });

    if (res.status === 200) {
      fetchData();
      cancelSalary(changed);
    } else {
      toast.error(
        "Salaries couldn't be altered for some reason. Try later? :("
      );
    }
  }

  async function deleteSalary(idx) {
    if (!data[idx].new) {
      const res = await db.request("/auth/setsalary", "POST", {
        attrid: data[idx].attr_id,
        delete: true,
      });

      if (res.status === 200) {
        fetchData();
        cancelSalary(changed);
      } else {
        toast.error(
          "Salaries couldn't be altered for some reason. Try later? :("
        );
      }
    } else {
      cancelSalary(changed);
    }
  }

  function cancelSalary(idx) {
    setTakeInput(false);
    if (data[0].new) {
      let arr = data.slice();
      arr.splice(0, 1);
      setData(arr);
    }
    if (inputRef.current[idx]) inputRef.current[idx].blur();
    setConfirmPopup(false);
    setConfirmDelete(false);
    setChanged(-1);
    fetchData();
  }

  function editData(idx) {
    if (idx !== null) {
      setTakeInput(true);
    }
    setChanged(idx);

    let arr = data.slice();
    if (data[idx].attr_id !== -1) {
      arr[idx].oldsalary = data[idx].attr_value;
      arr[idx].olddate = data[idx].attr_submitted;
    }
    setData(arr);
  }

  function newSalary() {
    const hasData = data && data.length > 0;
    const newData = {
      idx: 0,
      attr_id: -1,
      attr_value: hasData ? Math.round(data[0].attr_value * 1.03) : 0,
      oldsalary: hasData ? data[0].attr_value : 0,
      attr_submitted: new Date().offWeekend(),
      olddate: hasData
        ? new Date(data[0].attr_submitted)
        : new Date().subtractDays(7),
      new: true,
    };
    setData((prev) => [newData, ...prev]);
    setChanged(0);
  }

  // Render xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    <>
      {confirmPopup && (
        <Popup
          title="Confirm change"
          closeCallback={() => {
            cancelSalary(changed);
          }}
          submitCallback={() => {
            submitSalary(changed);
          }}
          negativeAction="Nevermind"
          positiveAction="YES, DO IT"
        >
          <center style={{ margin: "20px 0px" }}>
            Are you sure you want to change <b>{props.name}'s</b> salary to
            <br />
            <div
              style={{
                display: "inline-block",
                fontSize: 27,
                color: "#000",
                margin: "10px 0px",
              }}
            >
              <b>
                {data[changedRef.current] && data[changedRef.current].oldsalary}
              </b>
              kr to{" "}
              <b>
                {data[changedRef.current] &&
                  data[changedRef.current].attr_value}
              </b>
              kr
            </div>{" "}
            ?
          </center>
        </Popup>
      )}
      {confirmDelete && (
        <Popup
          title="Confirm delete"
          closeCallback={() => {
            cancelSalary(changed);
          }}
          submitCallback={() => {
            deleteSalary(changed);
          }}
          negativeAction="no"
          positiveAction="YES"
        >
          <center style={{ margin: "20px 0px" }}>
            Are you sure you want to delete <b>{props.name}'s</b> salary entry
            <br />
            <div
              style={{
                display: "inline-block",
                fontSize: 27,
                color: "#000",
                margin: "10px 0px",
              }}
            >
              <b>{}</b> {data[changed] && data[changed].oldsalary}kr
            </div>{" "}
            ?
          </center>
        </Popup>
      )}
      <div style={{ margin: 14 }}>
        <ButtonAdd
          onClick={newSalary}
          large
          lightMode
          disabled={changed > -1}
        />
        {data &&
          data.map((val, i) => {
            const isShowing =
              (changed === -1 && i === 0) || (changed > -1 && changed === i);
            return (
              <div
                key={val.attr_id}
                style={{
                  position: "relative",
                  height: isShowing ? 60 : 40,
                  maxWidth: 500,
                  borderRadius: 8,
                  margin: "14px 0px",
                  background: "#fff",
                  opacity: isShowing ? 1 : 0.6,
                }}
              >
                <input
                  style={{
                    fontSize: isShowing ? 26 : 16,
                    padding: 13,
                    height: "100%",
                    width: 200,
                    background: "transparent",
                    overflow: "hidden",
                    border: 0,
                    outline: 0,
                    cursor: "pointer",
                  }}
                  value={
                    changed === i
                      ? data[changed].attr_value
                      : val.attr_value + "kr"
                  }
                  onFocus={() => {
                    editData(i);
                  }}
                  onChange={(e) => {
                    const val = new RegExp(/[0-9]+/i).test(e.target.value);
                    const salary = val
                      ? parseInt(e.target.value.match(/[0-9]+/i)[0], 10)
                      : 0;

                    setData((prevData) => {
                      let arr = data.slice();
                      arr[changed].attr_value = salary;
                      return arr;
                    });
                  }}
                  ref={(el) => (inputRef.current[i] = el)}
                  autoFocus={changed === i}
                />
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translate(0, -50%)",
                    color: "#444",
                    fontSize: 10,
                    cursor: "pointer",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {changed === i ? (
                      <DatePicker
                        default={val.attr_submitted}
                        minDate={new Date(props.auth.getOrgCreation())}
                        maxDate={new Date(val.attr_submitted).addDays(1000)}
                        changeCb={(date) => {
                          setData((prevData) => {
                            let arr = data.slice();
                            arr[changed].attr_submitted = date;
                            return arr;
                          });
                        }}
                      />
                    ) : (
                      <div
                        style={{ color: "#000" }}
                        onClick={() => {
                          editData(i);
                        }}
                      >
                        Effective date
                        <br />
                        <b>{new Date(val.attr_submitted).format(true)}</b>
                      </div>
                    )}
                    {changed === i && (
                      <>
                        <div
                          className="btn btn-danger"
                          style={{
                            marginLeft: 8,
                            width: 40,
                            height: "100%",
                          }}
                          onClick={() => {
                            cancelSalary(changed);
                          }}
                        >
                          <i className="fas fa-times" />
                        </div>
                        <button
                          className="btn btn-success"
                          style={{
                            marginLeft: 8,
                            width: 50,
                            height: "100%",
                          }}
                          onClick={(e) => {
                            if (
                              data[changed].attr_value !==
                              data[changed].oldsalary
                            ) {
                              setConfirmPopup(true);
                            } else if (
                              data[changed].attr_submitted !==
                              data[changed].olddate
                            ) {
                              submitSalary(changed);
                            } else {
                              cancelSalary(changed);
                            }
                          }}
                          disabled={data[changed].attr_value.length < 1}
                        >
                          <i className="fas fa-check" />
                        </button>
                        <div
                          style={{
                            position: "absolute",
                            right: -40,
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "red",
                            fontSize: 16,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setConfirmDelete(true);
                          }}
                        >
                          <i className="fas fa-trash-alt" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
