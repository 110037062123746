import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import history from "./history";

// Pages
// import NoPage from "../pages/NoPage";

class PrivateRoute extends Component {
  render() {
    var page = null;
    if (this.props.auth.isAuthenticated()) {
      if (this.props.auth.isAuthorized(this.props.roles)) {
        page = (
          <Route
            {...this.props}
            render={props => <this.props.target {...this.props} />}
          />
        );
      }
      // else {
      //   page = <Route {...this.props} render={props => <NoPage {...this.props} reason="notallowed" />} />;
      // }
    } else {
      if (history.location.pathname !== "/login") {
        page = <Redirect to="/home" />;
      }
    }

    return page;
  }
}

export default PrivateRoute;
