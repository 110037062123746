import React, { Component } from "react";
import db from "../utils/db";
import validator from "validator";
import TextInput from "../components/TextInput";
import InputDropdown from "../components/InputDropdown";
import moment from "moment";
import { toast } from "react-toastify";

class InviteUser extends Component {
  constructor(props) {
    super(props);
    var date = "";

    if (
      localStorage.getItem("add-date") &&
      localStorage.getItem("add-date") !== "undefined"
    ) {
      date = moment(new Date(localStorage.getItem("add-date"))).format(
        "DD MM YYYY"
      );
    } else {
      var mDate =
        this.props.type === "project"
          ? moment(this.props.auth.getProjStart())
          : moment();

      // Ensure date is not weekend
      if (mDate.day() === 0) mDate.add(1, "day");
      else if (mDate.day() === 6) mDate.add(2, "day");
      date = mDate.format("DD MM YYYY");
    }

    this.state = {
      peepz: null,
      consultants: null,
      peepzready: false,
      consultantsready: false,
      date: date,
      selected: [],
    };

    this.refresh = this.refresh.bind(this);
    this.getPeepz = this.getPeepz.bind(this);
    this.getConsultants = this.getConsultants.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.addUserToProject = this.addUserToProject.bind(this);

    this.email = React.createRef();
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.invitee = null;
    this.inviteeFunc = (e) => {
      this.invitee = e;
      if (e) this.invitee.focus();
    };

    this.refresh();
    localStorage.removeItem("add-date");

    document.addEventListener("keydown", this.handleKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey, false);
    localStorage.removeItem("add-date");
  }

  refresh() {
    this.getPeepz();
    this.getConsultants();
  }

  async getPeepz() {
    const res = await db.request("/auth/getusers", "POST", {
      org: this.props.auth.getOrgId(),
      group: "active_unique_employees",
      projectid: this.props.auth.getProjId(),
    });

    this.setState({
      peepz: res.rows,
      peepzready: true,
    });
  }

  async getConsultants() {
    const res = await db.request("/auth/getusers", "POST", {
      org: this.props.auth.getOrgId(),
      group: "active_unique_consultants",
      projectid: this.props.auth.getProjId(),
    });

    if (res.rowCount > 0) {
      this.setState({
        consultants: res.rows,
        consultantsready: true,
      });
    }
  }

  validateEmail(value) {
    var msg = "";
    if (!validator.isEmail(value)) msg = "A valid email please, sir.";
    else if (value === localStorage.getItem("email"))
      msg = "You can't invite yourself, silly..";
    return msg;
  }

  validateName(val) {
    var res = "";
    if (!val || (val && val.trim() === "")) {
      res = "This cannot be empty";
    }
    return res;
  }

  async submitUser(
    e,
    email,
    firstname,
    lastname,
    org,
    inviter,
    date,
    isConsultant = false,
    userid = null
  ) {
    if (e) e.preventDefault();

    var submitSuccess = false;
    var emailStatus = "";
    var state = this.state;
    var hasErrors =
      this.email.current.hasErrors() ||
      this.lastname.current.hasErrors() ||
      this.lastname.current.hasErrors();

    if (!hasErrors) {
      var data = {
        email: email,
        firstname: firstname,
        lastname: lastname,
        org: org,
        inviter: inviter,
        date: date,
        isConsultant: isConsultant,
      };

      if (!userid) {
        // Add user to db
        const response = await db.request("/auth/inviteuser", "POST", data);
        if (response.status === 200) {
          submitSuccess = true;
        } else {
          emailStatus = "The user already exist in your organization";
        }
      } else {
        // Update planned user to invited
        await db.request("/auth/updateuser", "POST", {
          userid: userid,
          firstname: firstname,
          lastname: lastname,
          email: email,
          sendemail: true,
          inviter: inviter,
        });
        submitSuccess = true;
      }
    }

    if (!submitSuccess) {
      this.email.current.submit(emailStatus);
      this.firstname.current.submit();
      this.lastname.current.submit();
      this.setState(state);
    } else {
      toast.success("Successfully invited " + firstname + "!");
      this.props.dispatch.fire("orgrefresh");
      this.props.dispatch.fire("projrefresh");
      if (this.props.closeCallback) this.props.closeCallback();
    }
  }

  matchUserIndex(arr, newUsers, num) {
    return arr.findIndex((val) => val === newUsers[num].user_id);
  }

  async addUserToProject() {
    const orgcreated = this.props.auth.getOrgCreation();
    const projstart = this.props.auth.getProjStart();
    var date = this.state.date
      ? moment(this.state.date, "DD MM YYYY")
      : moment(projstart);

    // If join on weekend, push date forward
    while (!(date.day() < 6 && date.day() > 0)) {
      date.add(1, "day");
    }

    var selected = this.state.selected.slice();
    const newUsers = this.state.peepz.filter(
      (val) => val.new && !validator.isEmail(val.user_firstname)
    );

    var idx = -1;
    for (var k = 0; k < newUsers.length; k++) {
      idx = this.matchUserIndex(selected, newUsers, k);
      selected.splice(idx, 1);
    }

    // Add any new users
    if (newUsers.length > 0) {
      const resAdd = await db.request("/auth/adduser", "POST", {
        orgid: this.props.auth.getOrgId(),
        projectid: this.props.auth.getProjId(),
        users: newUsers.map((val) => {
          return val.user_firstname;
        }),
        ids: newUsers.map((val) => {
          return val.user_id;
        }),
      });

      for (var j = 0; j < resAdd.data.length; j++) {
        selected.push(resAdd.data[j].user_id);
      }
    }

    var data = [];
    for (var i = 0; i < selected.length; i++) {
      data.push({
        date: date.format("DD MM YYYY"),
        userid: selected[i],
        type: "project",
        status: "join",
      });
      data.push({
        date: moment(this.props.auth.getProjEnd()).format("DD MM YYYY"),
        userid: selected[i],
        type: "project",
        status: "left",
      });
    }

    const res = await db.request("/auth/setdays", "POST", {
      data: data,
      orgid: this.props.auth.getOrgId(),
      projectid: this.props.auth.getProjId(),
      since: new Date(orgcreated).daysBetween(new Date(date)),
    });

    if (res.status === 200) {
      this.props.refreshCallback();
      window.scrollTo(0, 0);
    }
  }

  handleKey(e) {
    if (e.keyCode === 27) this.props.closeCallback(); // Escape
  }

  render() {
    var isProject = this.props.type === "project";

    return (
      <div className="form-wrapper">
        {isProject ? (
          <>
            <center>
              <div
                style={{
                  background: "#ddd",
                  padding: "8px 16px",
                  borderRadius: 8,
                  color: "#444",
                  fontSize: 11,
                  maxWidth: "90%",
                }}
              >
                Only first- or full name at this point.
                <br />
                <b>No invitations</b> will be sent.
              </div>
            </center>
            <div style={{ paddingTop: 20, paddingBottom: 10 }}>
              {this.state.peepzready ? (
                <div>
                  <InputDropdown
                    ref={this.inviteeFunc}
                    waitForInput={true}
                    noPill={true}
                    allowNew={true}
                    data={this.state.peepz
                      .filter(
                        (obj, j) => !this.state.selected.includes(obj.user_id)
                      )
                      .map((val, i) => {
                        var fullName = val.user_firstname;
                        if (val.user_lastname)
                          fullName += " " + val.user_lastname;
                        return {
                          name: fullName,
                          idx: val.user_id,
                        };
                      })}
                    placeholder={"Type name here"}
                    submitCb={(id, prefix, newValue) => {
                      var state = this.state;

                      if (newValue) {
                        const newValueArr = newValue.split(" ");
                        const fourDigit = Math.floor(
                          1000 + Math.random() * 9000
                        );
                        state.peepz.push({
                          user_id: fourDigit,
                          user_firstname: newValueArr[0],
                          user_lastname:
                            newValueArr.length > 1
                              ? newValue.split(" ")[1]
                              : null,
                          new: true,
                        });
                        state.selected.push(fourDigit);
                      } else {
                        state.selected.push(id);
                      }

                      this.setState(state);
                    }}
                    maxlength={20}
                  />

                  <div
                    className="list-group"
                    style={{
                      overflow: "scroll",
                      maxHeight: "42vh",
                      marginTop: 12,
                    }}
                  >
                    {this.state.selected.map((val, i) => {
                      var user = this.state.peepz.find(
                        (peep) => peep.user_id === val
                      );
                      var name = user.user_firstname;
                      if (user.user_lastname) name += " " + user.user_lastname;

                      return (
                        <button
                          key={i}
                          className={
                            "list-group-item list-group-item-action active"
                          }
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "#ddd",
                            color: "#000",
                          }}
                        >
                          {name}

                          <div
                            style={{
                              paddingTop: 4,
                              fontSize: "1.1rem",
                              color: "grey",
                              float: "right",
                              cursor: "pointer",
                            }}
                            className="fas fa-trash"
                            onClick={() => {
                              var arr = this.state.selected.slice();
                              arr.splice(i, 1);

                              if (user.new) {
                                const userIdx = this.state.peepz.findIndex(
                                  (peep) => peep.user_id === val
                                );
                                var newPeepz = this.state.peepz.slice();
                                newPeepz.splice(userIdx, 1);
                                this.setState({
                                  selected: arr,
                                  peepz: newPeepz,
                                });
                              } else {
                                this.setState({ selected: arr });
                              }
                            }}
                          />
                        </button>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <h5 style={{ color: "#000" }}>
                  <center>No more employees</center>
                </h5>
              )}
            </div>

            <button
              className={
                this.state.selected.length > 0
                  ? "btn btn-success btn-lg btn-block"
                  : "btn btn-success btn-lg btn-block disabled"
              }
              onClick={() => {
                this.addUserToProject();
                this.props.closeCallback();
              }}
              disabled={this.state.selected.length === 0}
              style={{ marginTop: 8 }}
            >
              INVITE
            </button>
          </>
        ) : (
          <div>
            <form
              onSubmit={(e) => {
                this.submitUser(
                  e,
                  this.email.current.value(),
                  this.firstname.current.value(),
                  this.lastname.current.value(),
                  this.props.auth.getOrgId(),
                  localStorage.getItem("firstname"),
                  moment(this.state.date, "DD MM YYYY").format("DD MM YYYY"),
                  this.props.consultant ? this.props.consultant : false,
                  this.props.default.userid
                );
              }}
            >
              <TextInput
                validateCallback={this.validateEmail}
                requireSubmit={true}
                ref={this.email}
                placeholder="theninja@emailadress.com"
                maxlength="50"
                autofocus
              />
              <TextInput
                validateCallback={this.validateName}
                requireSubmit={true}
                ref={this.firstname}
                placeholder="Firstname"
                maxlength="30"
                default={
                  this.props.default && this.props.default.firstname
                    ? this.props.default.firstname
                    : ""
                }
              />
              <TextInput
                validateCallback={this.validateName}
                requireSubmit={true}
                ref={this.lastname}
                placeholder="Lastname"
                maxlength="30"
                default={
                  this.props.default && this.props.default.lastname
                    ? this.props.default.lastname
                    : ""
                }
              />
              <button
                className="btn btn-success btn-lg btn-block"
                type="submit"
              >
                INVITE
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default InviteUser;
