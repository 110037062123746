import React from "react";
import Helper from "../utils/helper";

class CommandBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advanced: false,
      repeat: false,
    };

    this.repeatValue = React.createRef();
  }

  shouldComponentUpdate(props, state) {
    return (
      Helper.stateChanged(this.props, props) ||
      Helper.stateChanged(this.state, state)
    );
  }

  getButton(title, icon, bgColor, iconColor, code, type, border) {
    var topPadding = title ? 14 : 0;
    return (
      <div
        style={{
          display: "inline-block",
          marginLeft: 20,
          paddingTop: topPadding,
          cursor: "pointer",
          verticalAlign: "middle",
        }}
        onClick={() => {
          if (code) {
            this.props.submitCb(
              code,
              type,
              this.state.repeat ? this.repeatValue.current.selectedIndex : -1
            );
            this.setState({ advanced: false, repeat: false });
          }
        }}
      >
        <div
          style={{
            width: 38,
            height: 38,
            borderRadius: 5,
            paddingLeft: 1,
            paddingTop: 7,
            backgroundColor: bgColor,
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            border: border ? border : 0,
          }}
        >
          <div
            className={icon}
            style={{ fontSize: "1rem", color: iconColor }}
          />
        </div>
        <center>
          <div style={{ fontSize: "0.6rem", color: "grey", fontWeight: 600 }}>
            {title}
          </div>
        </center>
      </div>
    );
  }

  render() {
    return (
      <div
        id="commandbar"
        className="commandbar"
        style={{
          position: "absolute",
          width: "100%",
          height: this.state.advanced ? 150 : 94,
          top: this.props.show ? 0 : -200,
          left: 0,
          zIndex: 5000,
          backgroundColor: "#fff",
          paddingTop: 7,
          boxShadow: "0px 0px 10px #222",
          overflow: "hidden",
        }}
      >
        <center>
          {this.getButton(null, "fas fa-check", "#5bc0de", "#3c8fa8", null)}{" "}
          <div
            className="fas fa-arrow-right"
            style={{
              color: "grey",
              fontSize: "1.3rem",
              paddingLeft: 15,
              paddingRight: 20,
              verticalAlign: "middle",
            }}
          />
          {this.getButton("WORKING", "", "#32CD32", "", "working", "avail")}
          {this.getButton(
            "SICK",
            "fas fa-procedures",
            "#ff8c8c",
            "#d66464",
            "sick",
            "avail"
          )}
          {this.getButton(
            "VACATION",
            "fas fa-plane",
            "#fdff8c",
            "#e0cc5c",
            "vacation",
            "avail"
          )}
          {this.getButton(
            "VAB",
            "fas fa-child",
            "#ffc18c",
            "#d69964",
            "vab",
            "avail"
          )}
          {this.getButton(
            "PARENTAL",
            "fas fa-child",
            "#8ca4ff",
            "#6d83d1",
            "parental",
            "avail"
          )}
          {this.getButton(
            "LOCAL HOLIDAY",
            "",
            "",
            "",
            "localholiday",
            "avail",
            "4px solid #f1c232"
          )}
          {this.getButton("OTHER WORK", "", "#ccc", "", "otherwork", "avail")}
          {this.getButton(
            "OFF PROJECT",
            "",
            "",
            "",
            "offproject",
            "avail",
            "2px solid #aaa"
          )}
          <div
            className="fas fa-times"
            style={{
              color: "grey",
              fontSize: "1.5rem",
              paddingLeft: 50,
              cursor: "pointer",
              verticalAlign: "middle",
            }}
            onClick={() => {
              if (this.props.cancelCb) {
                this.props.cancelCb();
              }
              this.setState({ advanced: false, repeat: false });
            }}
          />
          <div
            style={{
              position: "absolute",
              right: 20,
              top: 32,
              fontSize: "0.7rem",
              color: "#bbb",
              marginLeft: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({ advanced: !this.state.advanced, repeat: false });
            }}
          >
            advanced
          </div>
          <div style={{ display: "block", marginTop: 25 }}>
            <input
              type="checkbox"
              style={{ display: "inline-block" }}
              onChange={() => {
                this.setState({ repeat: !this.state.repeat });
              }}
              checked={this.state.repeat}
              name="Repeat every week for"
            />
            <div
              style={{ display: "inline-block", marginLeft: 10, color: "#000" }}
              disabled={!this.state.repeat}
            >
              Repeat every week for
            </div>
            <select
              ref={this.repeatValue}
              style={{ display: "inline-block", marginLeft: 10 }}
              disabled={!this.state.repeat}
            >
              <option>rest of project</option>
              <option>1 week</option>
              <option>2 weeks</option>
              <option>3 weeks</option>
              <option>4 weeks</option>
              <option>5 weeks</option>
            </select>
          </div>
        </center>
      </div>
    );
  }
}

export default CommandBar;
