import React, { Component } from "react";
import db from "../utils/db";
import validator from "validator";
import history from "../utils/history";
import TextInput from "../components/TextInput";
// import { GoogleLogin } from "react-google-login";

class Login extends Component {
  constructor(props) {
    super(props);

    var invite = localStorage.getItem("invite_email");
    var last = localStorage.getItem("lastEmail");
    var email = "";
    if (invite) email = invite;
    else if (last) email = last;

    this.state = {
      emailDBError: "",
      prevCheckedEmail: "",
      passwordStatus: "",
      email: email,
      stayIn: false,
    };

    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.gotoResetPass = this.gotoResetPass.bind(this);

    this.email = React.createRef();
    this.password = React.createRef();
    this.stayin = React.createRef();
  }

  componentDidMount() {
    if (this.state.email) this.password.current.select();
    localStorage.removeItem("invite_email");
  }

  validateEmail(value) {
    var msg = "";
    if (!validator.isEmail(value)) msg = "Please give a valid email my way.";
    return msg;
  }

  validatePassword(value) {
    var msg = "";
    if (validator.trim(value) === "") msg = "I need that special password..";
    else if (this.state.passwordStatus !== "") {
      msg = this.state.passwordStatus;
    }
    return msg;
  }

  async doLogin(e) {
    e.preventDefault();
    var pass = this.password.current.value();
    var mail = this.email.current.value();
    var stayLoggedIn = this.stayin.current.checked;
    var state = this.state;
    var loginSuccess = false;

    // Check email
    const mail_res = await db.request("/getuserbyemail", "POST", {
      email: mail,
    });
    state.prevCheckedEmail = mail;
    state.emailDBError = "";
    if (mail_res && mail_res.status === 200) {
      // Email ok, found the account

      // Now check the password
      const resLogin = await db.request("/login", "POST", {
        email: mail,
        password: pass,
        stayin: stayLoggedIn,
      });

      if (resLogin && resLogin.status === 200) {
        // ALL CHECKED - User good to log in

        // First check if reroute to org due to invitation
        const invitedToOrgId = parseInt(
          localStorage.getItem("orgid_redirect"),
          10
        );
        var invitedToOrgIndex = -1;
        var setOrgIndex = resLogin.org;
        var setProjIndex = resLogin.proj;

        if (invitedToOrgId) {
          JSON.parse(resLogin.orgs).forEach((val, i) => {
            if (val.id === invitedToOrgId) invitedToOrgIndex = i;
          });
          if (invitedToOrgIndex > -1) {
            localStorage.removeItem("orgid_redirect");
            setOrgIndex = invitedToOrgIndex;
          }
        }

        loginSuccess = true;
        state.passwordStatus = "";
        localStorage.setItem("lastEmail", resLogin.email);
        this.props.auth.login(
          resLogin.token,
          resLogin.expires,
          false,
          resLogin.duration
        );
        this.props.auth.setUser(
          resLogin.firstname,
          resLogin.lastname,
          resLogin.email,
          resLogin.userid
        );
        this.props.auth.setOrgs(setOrgIndex, resLogin.orgs);
        this.props.auth.setProjs(JSON.parse(resLogin.projs));
        this.props.auth.selectProj(setProjIndex);
        this.props.dispatch.fire("orgchange");
        this.props.dispatch.fire("projrefresh");
        this.props.dispatch.fire("userupdate");
        this.props.dispatch.fire("loggedin");
      } else {
        state.passwordStatus = "Wrong password";
      }
    } else {
      state.emailDBError = "Couldn't find that account";
    }

    if (!loginSuccess) {
      this.email.current.submit(state.emailDBError);
      this.password.current.validate(true);
      this.setState(state);
    } else {
      var referrer = this.props.location.pathname;
      referrer = referrer !== "/login" ? referrer : "/overview-project";
      history.replace(referrer);
    }
  }

  responseGoogle(res) {
    console.log(res);
  }

  gotoResetPass() {
    localStorage.setItem("reset_email", this.email.current.value());
    history.push("/resetpass");
  }

  render() {
    return (
      <div className="form-wrapper">
        <center style={{ paddingTop: 30, paddingBottom: 30 }}>
          <h1>Login</h1>
        </center>
        <form onSubmit={this.doLogin}>
          <TextInput
            placeholder="Email"
            maxlength="50"
            validateCallback={this.validateEmail}
            requireSubmit={true}
            ref={this.email}
            default={this.state.email}
            autofocus
          />
          <TextInput
            placeholder="Password"
            maxlength="25"
            validateCallback={this.validatePassword}
            requireSubmit={true}
            ref={this.password}
            password
          />
          <button
            className="btn btn-primary btn-lg btn-block"
            onClick={this.doLogin}
          >
            <div>Let's go</div>
          </button>
          <div
            className="custom-control custom-checkbox"
            style={{ paddingTop: 5 }}
          >
            <center>
              <input
                type="checkbox"
                className="form-check-input"
                id="stayInCheck"
                ref={this.stayin}
              />
              <label className="form-check-label" htmlFor="stayInCheck">
                Keep me logged in
              </label>
            </center>
          </div>
        </form>
        <br />
        <center>
          <div className="tinytext" style={{ marginTop: 2 }}>
            <button
              className="btn btn-link"
              onClick={this.gotoResetPass}
              style={{ paddingRight: 2, fontSize: 13 }}
            >
              reset password
            </button>
            {"  "}|{"  "}
            <button
              className="btn btn-link"
              onClick={() => history.push("/signup")}
              style={{ fontSize: 13, paddingLeft: 2 }}
            >
              sign up to try
            </button>
          </div>

          <br />
          <br />
        </center>
      </div>
    );
  }
}

export default Login;
