import db from "./db";
import moment from "moment";
import history from "./history";

var loggedIn = null;

export default class Auth {
  constructor(dispatch) {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isAuthorized = this.isAuthorized.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.refreshProjs = this.refreshProjs.bind(this);
    this.getProjs = this.getProjs.bind(this);
    this.refreshOrgs = this.refreshOrgs.bind(this);

    this.dispatch = dispatch;
  }

  isAuthenticated() {
    var token = localStorage.getItem("token");
    var expires = parseInt(localStorage.getItem("expires"), 10);
    var duration = localStorage.getItem("duration");
    var lastRefresh = localStorage.getItem("lastRefresh");

    var authenticated = Boolean(
      token && (expires === -1 || new Date().valueOf() < expires)
    );

    if (!loggedIn) loggedIn = authenticated;

    if (authenticated) {
      // Check if token should refresh on activity (every 5 minutes)
      if (moment().unix() - lastRefresh > 60 * 5) {
        // console.log("Refreshing token!");
        lastRefresh = moment().unix();
        this.refreshToken(token);
        return authenticated;
      }

      if (!loggedIn) this.login(token, expires, false, duration);
    } else {
      if (loggedIn) this.logout();
    }

    return authenticated;
  }

  isAuthorized(roles) {
    if (!roles) roles = [];
    return roles.includes("janitor");
  }

  requiresRole(role) {
    const myRole = this.getRole();
    const matrix = {
      owner: ["owner"],
      janitor: ["owner", "janitor"],
      lead: ["owner", "janitor", "lead"],
      talent: ["owner", "janitor", "lead", "talent"],
    };
    return matrix[role].includes(myRole);
  }

  async refreshToken(token) {
    var duration = localStorage.getItem("duration");
    const res = await db.request("/refreshtoken", "POST", {
      token: token,
      duration: duration,
    });

    if (res && res.status === 200) {
      this.login(res.newToken, res.expires, true, res.duration);
    }
  }

  login(token, expire, refresh = false, dur = "8h") {
    var exp = parseInt(expire, 10);
    if (!loggedIn || refresh === true) {
      if (!expire) expire = -1;
      console.log("AUTH.login() - expires: " + new Date(exp));

      localStorage.setItem("token", token);
      localStorage.setItem("expires", exp);
      localStorage.setItem("duration", dur);
      loggedIn = true;
    }
  }

  logout() {
    if (loggedIn) {
      console.log("AUTH.logout() ");
      // Clear access token and ID token from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
      localStorage.removeItem("duration");
      localStorage.removeItem("last_refresh");
      localStorage.removeItem("org");
      localStorage.removeItem("orgs-all");
      localStorage.removeItem("proj");
      localStorage.removeItem("proj-name");
      localStorage.removeItem("projs-all");
      localStorage.removeItem("firstname");
      localStorage.removeItem("lastname");
      localStorage.removeItem("email");
      localStorage.removeItem("userid");
      localStorage.removeItem("fullcompany");
      loggedIn = false;
      history.push("/login");
    }
  }

  async refreshOrgs(selected, callback) {
    var email = localStorage.getItem("email");
    const res = await db.request("/auth/getorgs", "POST", { email: email });
    this.setOrgs(selected, res.orgs);
    if (callback) callback();
    this.dispatch.fire("orgchange");
  }

  setOrgs(select = 0, orgs, callback) {
    var setSelected = false;
    var currOrgs = localStorage.getItem("orgs-all");
    if (!orgs) orgs = [];

    // If no org array provided, only set selected
    if (orgs.length > 0) {
      currOrgs = orgs;
      localStorage.setItem("orgs-all", orgs);
      setSelected = true;
    } else {
      if (currOrgs && select < currOrgs.length) {
        setSelected = true;
      }
    }

    if (setSelected) {
      // Set recent selected org in db
      localStorage.setItem("org", select);
      this.setSelectOrgInDB(JSON.parse(currOrgs)[select].id, callback);
    }
  }

  selectProj(idx) {
    if (idx === -1) {
      // localStorage.removeItem("proj");
      // localStorage.setItem("fullcompany", true);
      // this.setSelectProjInDB(-1);
    } else {
      var currProjs = JSON.parse(localStorage.getItem("projs-all"));
      localStorage.removeItem("fullcompany");
      if (currProjs && currProjs.length > 0 && idx < currProjs.length) {
        localStorage.setItem("proj", idx);
        localStorage.setItem("proj-name", currProjs[idx].name);
        this.setSelectProjInDB(currProjs[idx].id);
      }
    }
  }

  selectProjById(id) {
    var idInt = parseInt(id, 10);
    if (id) {
      var currProjs = JSON.parse(localStorage.getItem("projs-all"));
      if (currProjs && currProjs.length > 0) {
        for (var i = 0; i < currProjs.length; i++) {
          if (currProjs[i].id === idInt) {
            localStorage.setItem("proj", i);
            localStorage.setItem("proj-name", currProjs[i].name);
            this.setSelectProjInDB(currProjs[i].id);
            this.dispatch.fire("projchange");
            break;
          }
        }
      }
    }
  }

  setProjs(data, selected = -1) {
    if (!data) {
      data = [];
    }
    localStorage.setItem("projs-all", JSON.stringify(data));
    if (selected > -1) this.selectProj(selected);
  }

  async refreshProjs(selected, callback) {
    // Refresh from db
    const res = await db.request("/auth/getprojs", "POST", {
      orgid: this.getOrgId(),
    });
    if (res && res.status === 200) {
      var projs = JSON.parse(res.projs);
      this.setProjs(projs, selected);
      if (callback) callback();
    }
  }

  getProjs() {
    return localStorage.getItem("projs-all")
      ? JSON.parse(localStorage.getItem("projs-all"))
      : [];
  }

  async setSelectOrgInDB(orgid, callback) {
    const email = localStorage.getItem("email");
    await db.request("/auth/setorg", "POST", { orgid: orgid, email: email });
    if (callback) callback();
  }

  async setSelectProjInDB(projid, callback) {
    const email = localStorage.getItem("email");
    await db.request("/auth/setproj", "POST", { projid: projid, email: email });
    if (callback) callback();
  }

  setUser(firstname, lastname, email, userid) {
    if (firstname) localStorage.setItem("firstname", firstname);
    if (lastname) localStorage.setItem("lastname", lastname);
    if (email) localStorage.setItem("email", email);
    if (userid) localStorage.setItem("userid", userid);
  }

  getUserId() {
    return localStorage.getItem("userid")
      ? parseInt(localStorage.getItem("userid"), 10)
      : -1;
  }

  getUserEmail() {
    return localStorage.getItem("email") ? localStorage.getItem("email") : "";
  }

  getUserName() {
    return localStorage.getItem("firstname") && localStorage.getItem("lastname")
      ? localStorage.getItem("firstname") +
          " " +
          localStorage.getItem("lastname")
      : "";
  }

  getProjIndex() {
    return localStorage.getItem("proj");
  }

  getProjId() {
    return localStorage.getItem("projs-all") &&
      JSON.parse(localStorage.getItem("projs-all"))[
        localStorage.getItem("proj")
      ]
      ? JSON.parse(localStorage.getItem("projs-all"))[
          localStorage.getItem("proj")
        ].id
      : -1;
  }

  getProjName() {
    var projs = JSON.parse(localStorage.getItem("projs-all"));
    return projs && projs.length > 0 && localStorage.getItem("proj")
      ? JSON.parse(localStorage.getItem("projs-all"))[
          localStorage.getItem("proj")
        ].name
      : -1;
  }

  getOrgId() {
    return localStorage.getItem("orgs-all")
      ? JSON.parse(localStorage.getItem("orgs-all"))[
          localStorage.getItem("org")
        ].id
      : -1;
  }

  getOrgName() {
    var orgName =
      localStorage.getItem("orgs-all") && localStorage.getItem("org")
        ? JSON.parse(localStorage.getItem("orgs-all"))[
            localStorage.getItem("org")
          ].name
        : "";
    return orgName;
  }

  getOrgCreation() {
    var createDate =
      localStorage.getItem("orgs-all") && localStorage.getItem("org")
        ? JSON.parse(localStorage.getItem("orgs-all"))[
            localStorage.getItem("org")
          ].created
        : "";

    return createDate;
  }

  setOrgCreation(dateStr) {
    var obj = JSON.parse(localStorage.getItem("orgs-all"));
    obj[localStorage.getItem("org")].created = new Date(dateStr);
    localStorage.setItem("orgs-all", JSON.stringify(obj));
  }

  getProjStart() {
    var projs = localStorage.getItem("projs-all");
    var proj = localStorage.getItem("proj");
    var createDate = JSON.parse(projs)[proj]
      ? JSON.parse(projs)[proj].start
      : undefined;
    return createDate;
  }

  getProjEnd() {
    var endDate = JSON.parse(localStorage.getItem("projs-all"))[
      localStorage.getItem("proj")
    ]
      ? JSON.parse(localStorage.getItem("projs-all"))[
          localStorage.getItem("proj")
        ].end
      : undefined;
    return endDate;
  }

  getRole() {
    var role =
      localStorage.getItem("orgs-all") && localStorage.getItem("org")
        ? JSON.parse(localStorage.getItem("orgs-all"))[
            localStorage.getItem("org")
          ].role
        : "";
    return role;
  }

  getDomain() {
    var domain =
      localStorage.getItem("orgs-all") && localStorage.getItem("org")
        ? JSON.parse(localStorage.getItem("orgs-all"))[
            localStorage.getItem("org")
          ].domain
        : "";
    return domain;
  }
}
