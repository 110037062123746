import React, { Fragment } from "react";
import logo from "../imgs/kitty.png";

export default function Loading(props) {
  return (
    props.isLoading && (
      <center>
        <Fragment>
          <img className="logo" src={logo} alt="" style={{ width: 60 }} />
          <h2 style={{ color: "#cccccc", fontStyle: "italic" }}>loading...</h2>
        </Fragment>
      </center>
    )
  );
}
