import { useEffect } from "react";

export default function useMousedown(callback) {
  useEffect(() => {
    function handleKey(e) {
      if (callback) callback(e);
    }

    document.addEventListener("mousedown", handleKey);
    return () => {
      document.removeEventListener("mousedown", handleKey);
    };
  }, []);
  return;
}
