// eslint-disable-next-line no-extend-native
Date.prototype.addMonths = function (months) {
  var date = new Date(this.valueOf());
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

// eslint-disable-next-line no-extend-native
Date.prototype.subtractMonths = function (months) {
  var date = new Date(this.valueOf());
  return new Date(date.getFullYear(), date.getMonth() - months, 1);
};

// eslint-disable-next-line no-extend-native
Date.prototype.subtractDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.isToday = function () {
  var value = new Date(this.valueOf());
  var today = new Date();
  return (
    value.getDate() === today.getDate() &&
    value.getMonth() === today.getMonth() &&
    value.getFullYear() === today.getFullYear()
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.isWeekend = function () {
  var value = new Date(this.valueOf());
  return value.getDay() === 0 || value.getDay() === 6;
};

// eslint-disable-next-line no-extend-native
Date.prototype.isEndOfMonth = function () {
  var value = new Date(this.valueOf());
  var lastDay = new Date(value.getFullYear(), value.getMonth() + 1, 0);
  return value.getDate() === lastDay.getDate();
};

// eslint-disable-next-line no-extend-native
Date.prototype.getStartOfMonth = function () {
  var value = new Date(this.valueOf());
  return new Date(value.getFullYear(), value.getMonth(), 1);
};

// eslint-disable-next-line no-extend-native
Date.prototype.getEndOfMonth = function () {
  var value = new Date(this.valueOf());
  return new Date(value.getFullYear(), value.getMonth() + 1, 0);
};

// eslint-disable-next-line no-extend-native
Date.prototype.countWeekends = function (toDate) {
  var fromDate = new Date(this.valueOf());
  var ndays = 1 + Math.round((toDate - fromDate) / (24 * 3600 * 1000));
  var sum = function (a, b) {
    return a + Math.floor((ndays + ((fromDate.getDay() + 6 - b) % 7)) / 7);
  };
  return [0, 6].reduce(sum, 0);
};

// eslint-disable-next-line no-extend-native
Date.prototype.getMonthName = function (short) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var value = new Date(this.valueOf());
  return short ? shortMonths[value.getMonth()] : months[value.getMonth()];
};

// eslint-disable-next-line no-extend-native
Date.prototype.getDayName = function () {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var value = new Date(this.valueOf());
  return days[value.getDay()];
};

// eslint-disable-next-line no-extend-native
Date.prototype.isSameDay = function (target) {
  var value = new Date(this.valueOf());
  target = new Date(target);
  return (
    value.getDate() === target.getDate() &&
    value.getMonth() === target.getMonth() &&
    value.getFullYear() === target.getFullYear()
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.isBeforeDay = function (target) {
  var value = new Date(this.valueOf());
  target = new Date(target);
  return (
    value.getFullYear() < target.getFullYear() ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() < target.getMonth()) ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() === target.getMonth() &&
      value.getDate() < target.getDate())
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.isAfterDay = function (target) {
  var value = new Date(this.valueOf());
  target = new Date(target);
  return (
    value.getFullYear() > target.getFullYear() ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() > target.getMonth()) ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() === target.getMonth() &&
      value.getDate() > target.getDate())
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.isSameOrAfterDay = function (target) {
  var value = new Date(this.valueOf());
  target = new Date(target);
  return (
    value.getFullYear() > target.getFullYear() ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() > target.getMonth()) ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() === target.getMonth() &&
      value.getDate() >= target.getDate())
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.isSameOrBeforeDay = function (target) {
  var value = new Date(this.valueOf());
  target = new Date(target);
  return (
    value.getFullYear() < target.getFullYear() ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() < target.getMonth()) ||
    (value.getFullYear() === target.getFullYear() &&
      value.getMonth() === target.getMonth() &&
      value.getDate() <= target.getDate())
  );
};

// eslint-disable-next-line no-extend-native
Date.prototype.daysBetween = function (target, floor) {
  var baseInMs = new Date(this.valueOf()).getTime();
  var targetInMs = new Date(target).getTime();

  var diffInMs = Math.abs(targetInMs - baseInMs);
  if (floor) return Math.floor(diffInMs / (1000 * 24 * 3600));
  // div by day in ms
  else return Math.round(diffInMs / (1000 * 24 * 3600)); // div by day in ms
};

// eslint-disable-next-line no-extend-native
Date.prototype.offWeekend = function (before = false) {
  var thisDate = new Date(this.valueOf());
  var day = thisDate.getDay();
  if (before) {
    if (day === 0) thisDate.setDate(thisDate.getDate() - 2);
    else if (day === 6) thisDate.setDate(thisDate.getDate() - 1);
  } else {
    if (day === 0) thisDate.setDate(thisDate.getDate() + 1);
    else if (day === 6) thisDate.setDate(thisDate.getDate() + 2);
  }
  return thisDate;
};

// eslint-disable-next-line no-extend-native
Date.prototype.format = function (invert, time) {
  var value = new Date(this.valueOf());
  var day =
    value.getDate() < 10 ? "0" + value.getDate().toString() : value.getDate();
  var month =
    value.getMonth() + 1 < 10
      ? "0" + (value.getMonth() + 1).toString()
      : value.getMonth() + 1;

  var res = "";
  if (!invert) res = day + " " + month + " " + value.getFullYear().toString();
  else res = value.getFullYear().toString() + "-" + month + "-" + day;

  if (time) {
    var hour = value.getHours();
    var minute = value.getMinutes();
    var hourFormatted = hour % 12 || 12; // hour returned in 24 hour format
    var minuteFormatted = minute < 10 ? "0" + minute : minute;
    var morning = hour < 12 ? "am" : "pm";
    res += " " + hourFormatted + ":" + minuteFormatted + morning;
  }

  return res;
};

// eslint-disable-next-line no-extend-native
Date.prototype.formatFull = function () {
  var value = new Date(this.valueOf());
  var day =
    value.getDate() < 10 ? "0" + value.getDate().toString() : value.getDate();
  var month =
    value.getMonth() + 1 < 10
      ? "0" + (value.getMonth() + 1).toString()
      : value.getMonth() + 1;
  return (
    value.getFullYear().toString() +
    "-" +
    month +
    "-" +
    day +
    " " +
    value.getHours().toString() +
    ":" +
    value.getMinutes().toString() +
    ":" +
    value.getSeconds().toString()
  );
};

// eslint-disable-next-line no-extend-native
Number.prototype.convertMinutes = function () {
  const newMinutes = this.valueOf();

  const MINS_PER_YEAR = 8 * 365 * 60;
  const MINS_PER_MONTH = 8 * 30 * 60;
  const MINS_PER_WEEK = 8 * 7 * 60;
  const MINS_PER_DAY = 8 * 60;
  const MINS_PER_HOUR = 60;

  var minutes = newMinutes;
  const y = Math.floor(minutes / MINS_PER_YEAR);
  minutes = minutes - y * MINS_PER_YEAR;
  const m = Math.floor(minutes / MINS_PER_MONTH);
  minutes = minutes - m * MINS_PER_MONTH;
  const w = Math.floor(minutes / MINS_PER_WEEK);
  minutes = minutes - w * MINS_PER_WEEK;
  const d = Math.floor(minutes / MINS_PER_DAY);
  minutes = minutes - d * MINS_PER_DAY;
  const h = Math.floor(minutes / MINS_PER_HOUR);
  minutes = Math.round(minutes - h * MINS_PER_HOUR);

  var short = "";
  short += y > 0 ? y + " years " : "";
  short += m > 0 ? m + " months " : "";
  short += w > 0 ? w + " weeks " : "";
  short += d > 0 ? d + " days " : "";
  short += h > 0 ? h + " hours " : "";
  short += minutes > 0 ? minutes + " mins " : "";
  return { years: y, months: m, weeks: w, days: d, hours: h, short: short };
};
