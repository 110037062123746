import React from "react";
import db from "../utils/db";
import moment from "moment";
import InviteUser from "../components/InviteUser";
import Popup from "../components/Popup";
import TimeGrid from "../components/TimeGrid";
import { toast } from "react-toastify";
import Helper from "../utils/helper";
require("../utils/extends");

class Peepz extends React.Component {
  constructor(props) {
    super(props);

    const initState = this.getInitState();

    this.state = {
      orgcreated: initState.orgCreated,
      startdate: initState.projStart,
      enddate: initState.projEnd,
      newdata: [],
      days: [],
      weekDays: [],
      ribbons: null,
      movement: null,
      modify: false,
      commandbarShowing: false,
      inviteUser: false,
      pageCols: 10,
      todayShowLeft: false,
      todayShowRight: false,
      projname: initState.projname,
      rangeFrom: initState.rangeFrom,
      rangeTo: initState.rangeTo,
      daysToEnd: initState.daysToEnd,
      myIndex: -1,
      loading: true,
      boxSpaceVert: 2,
      boxWidth: 17,
      nameCol: 0,
      monthHeight: 38,
      newEmployee: false,
      newConsultant: false,
      showDeleteUser: false,
      idxToDelete: -1,
      winHeight: 300,
    };

    this.fetchData = this.fetchData.bind(this);
    this.submitDays = this.submitDays.bind(this);
    this.deleteGoal = this.deleteGoal.bind(this);
    this.clickAdd = this.clickAdd.bind(this);
    this.submitJoinLeaveDay = this.submitJoinLeaveDay.bind(this);
    this.setMovement = this.setMovement.bind(this);
    this.menuSubmit = this.menuSubmit.bind(this);
    this.showDeleteUser = this.showDeleteUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.newGoalName = React.createRef();
    this.newGoalDesc = React.createRef();
    this.timegrid = React.createRef();
  }

  componentDidMount() {
    this.props.dispatch.addListener("orgchange", () => this.fetchData());
    this.props.dispatch.addListener("orgrefresh", () => this.fetchData(true));

    this.fetchData();
  }

  componentWillUnmount() {
    this.props.dispatch.removeListener("orgchange", () => this.fetchData());
    this.props.dispatch.removeListener("orgrefresh", () =>
      this.fetchData(true)
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Helper.stateChanged(this.state, nextState);
  }

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  getInitState() {
    var initState = {};
    var today = new Date();
    var orgCreated = new Date(this.props.auth.getOrgCreation());
    var projStart = new Date(this.props.auth.getOrgCreation());
    var projEnd = new Date().addDays(365);

    initState.newdata = [];
    initState.days = [];
    initState.weekDays = [];
    initState.loading = true;
    initState.modify = false;
    initState.orgCreated = orgCreated;
    initState.projStart = projStart;
    initState.projEnd = projEnd;
    initState.today = today;
    initState.daysToEnd = projStart.daysBetween(projEnd) + 1;
    initState.rangeFrom = orgCreated.daysBetween(projStart);
    initState.rangeTo = orgCreated.daysBetween(projEnd) + 1;
    initState.projname = this.props.auth.getProjName();
    return initState;
  }

  async fetchData(refresh = false) {
    var initState = this.getInitState();

    var years = [];
    var year = initState.projStart.getFullYear();
    while (year <= initState.projEnd.getFullYear()) {
      years.push(year);
      year++;
    }

    // Get public holidays
    const resHolidays = await db.request("/auth/getholidays", "POST", {
      orgid: this.props.auth.getOrgId(),
      years: years,
    });

    const peepzData = await db.request("/auth/getdays", "POST", {
      orgid: this.props.auth.getOrgId(),
      from: initState.rangeFrom - 1,
      to: initState.rangeTo,
    });

    if (peepzData.status === 200) {
      // ORGANIZER LOOP
      var mainArr = [];
      var mainArrIdx = 0;
      var currUserId =
        peepzData.data.length > 0 ? peepzData.data[0].user_id : -1;
      var maxName = 0;
      var joined = false;
      var pointer = 0;
      var lastLeave = -1;
      var inCompany = new Array(initState.daysToEnd).fill(true);
      var joinsLeaves = new Array(initState.daysToEnd).fill(false);
      var status = new Array(initState.daysToEnd).fill(null);
      var dayid = new Array(initState.daysToEnd).fill(-1);
      var date = new Array(initState.daysToEnd).fill(null);
      var ribbons = {};
      var holidays = new Array(initState.daysToEnd).fill(null);
      var ribbonStack = [];
      var myUID = this.props.auth.getUserId();
      var myIdx = -1;
      var idxsToMove = [];

      // Holidays
      if (resHolidays.status === 200) {
        for (var p = 0; p < resHolidays.data.length; p++) {
          var holiDate = new Date(resHolidays.data[p].date);
          if (
            holiDate.isSameOrAfterDay(initState.projStart) &&
            holiDate.isSameOrBeforeDay(initState.projEnd) &&
            resHolidays.data[p].enabled
          ) {
            var daysFromProjStart = holiDate.daysBetween(initState.projStart);
            holidays[daysFromProjStart] = resHolidays.data[p];
          }
        }
      }

      for (var i = 0; i < peepzData.data.length; i++) {
        var row = peepzData.data[i];
        var isLast = i === peepzData.data.length - 1; // Check if last post for user, switch row/user

        // First fill straight fwd arrays
        status[row.dayz_fromstart - initState.rangeFrom] = row.dayz_status;
        dayid[row.dayz_fromstart - initState.rangeFrom] = row.dayz_id;
        date[row.dayz_fromstart - initState.rangeFrom] = row.dayz_date;

        if (!joined && row.dayz_status === "join") {
          joined = true;
          pointer = row.dayz_fromstart;
        } else if (joined && row.dayz_status === "left") {
          joined = false;
          lastLeave = row.dayz_fromstart - initState.rangeFrom;
          joinsLeaves.fill(true, pointer - initState.rangeFrom, lastLeave + 1);
        }

        if (
          (peepzData.data[i + 1] &&
            peepzData.data[i + 1].user_id !== currUserId) ||
          isLast
        ) {
          if (joined) {
            joinsLeaves.fill(
              true,
              pointer - initState.rangeFrom,
              initState.daysToEnd
            );
          }

          var final = {
            role: row.attr_value,
            userid: row.user_id,
            firstname: row.user_firstname,
            lastname: row.user_lastname,
            email: row.user_email,
            joinsLeaves: joinsLeaves,
            inCompany: inCompany,
            status: status,
            dayid: dayid,
            date: date,
            lastLeave: lastLeave,
          };

          if (joined) {
            // User if still in company
            mainArr.push(final);

            if (row.user_id === myUID) myIdx = mainArrIdx;
            mainArrIdx++;
          } else {
            // User is no longer with us, move to bottom
            idxsToMove.push({
              since: row.dayz_fromstart,
              final: final,
            });
          }

          if (row.user_firstname.length > maxName)
            maxName = row.user_firstname.length;

          if (!isLast) {
            joinsLeaves = new Array(initState.daysToEnd).fill(false);
            status = new Array(initState.daysToEnd).fill(null);
            dayid = new Array(initState.daysToEnd).fill(-1);
            date = new Array(initState.daysToEnd).fill(null);

            currUserId = peepzData.data[i + 1].user_id;
            joined = false;
            lastLeave = -1;
          }
        }
      }

      // Move current user to top
      mainArr.splice(0, 0, mainArr.splice(myIdx, 1)[0]);

      // Move quiters to bottom in order AND max since
      idxsToMove.sort((a, b) => {
        return b.since - a.since;
      });
      for (var s = 0; s < idxsToMove.length; s++) {
        mainArr.push(idxsToMove[s].final);
      }

      // Calculate max height of ribbon row
      var rows = 0;
      var ribbonsHeight = 0;
      for (var y = 0; y < ribbonStack.length; y++) {
        if (ribbonStack[y][2] > rows) {
          rows = ribbonStack[y][2];
        }
      }
      ribbonsHeight = rows * (22 + 4 * this.state.boxSpaceVert) + 36;

      // Create days array for reference
      var days = [];
      var weekDays = [];
      for (
        var d = new Date(initState.projStart.getTime());
        d <= initState.projEnd;
        d.setDate(d.getDate() + 1)
      ) {
        var newD = new Date(d);
        days.push(newD);
        weekDays.push(newD.getDay());
      }

      this.setState({
        nameCol: maxName * 9,
        newdata: mainArr,
        days: days,
        weekDays: weekDays,
        holidays: holidays,
        ribbons: ribbons,
        ribbonsHeight: ribbonsHeight,
        myIndex: myIdx,
        loading: false,
        orgcreated: initState.orgCreated,
        startdate: initState.orgCreated,
        enddate: new Date().addDays(365),
        winHeight:
          mainArr.length * (this.state.boxWidth + this.state.boxSpaceVert) +
          250,
      });
    } else {
      this.setState({ newdata: [], loading: false });
    }

    if (!refresh && this.timegrid.current)
      this.timegrid.current.resetViewPosition();
  }

  validateGoalName(val) {
    var res = "";
    if (!val || (val && val.trim() === "")) {
      res = "Can't be empty";
    }
    return res;
  }

  submitGoal(e) {
    var name = this.newGoalName.current.state.value;
    var desc = this.newGoalDesc.current.state.value;
    this.newGoalName.current.submit(name);
    this.newGoalDesc.current.submit(desc);
    if (
      this.validateGoalName(name) === "" &&
      this.validateGoalName(desc) === ""
    ) {
      this.createGoal(name, desc, "goal");
      this.setState({ addGoal: false, movement: null });
    }
  }

  async createGoal(val, desc, type) {
    const res = await db.request("/auth/addgoal", "POST", {
      date: moment(new Date(localStorage.getItem("add-date"))).format(
        "DD MM YYYY"
      ),
      projectid: this.props.auth.getProjId(),
      name: val,
      desc: desc,
      type: type,
    });

    if (res.status === 200) {
      this.fetchData(true);
    } else {
      toast.error("Unknown error, the project milestone could not be created.");
    }

    localStorage.removeItem("invite-date");
  }

  async deleteGoal(id) {
    const res = await db.request("/auth/deletegoal", "POST", {
      goalid: id,
      projectid: this.props.auth.getProjId(),
    });

    if (res.status === 200) {
      toast.success("Removed the milestone!");
      this.fetchData(true);
    } else {
      toast.error("Unknown error, the milestone could not be erased.");
    }
  }

  formatDayData(status, type, selectedDays) {
    var tmpArr = [];
    selectedDays.forEach((val) => {
      var idxs = val.split("-");
      var day = this.state.startdate.addDays(parseInt(idxs[2], 10));
      tmpArr.push({
        date: moment(day).format("DD MM YYYY"),
        userid: parseInt(idxs[1], 10),
        type: type,
        status: status,
      });
    });
    return tmpArr;
  }

  async submitDays(status, type, selectedDays) {
    await db.request("/auth/setdays", "POST", {
      data: this.formatDayData(status, type, selectedDays),
      status: status,
      orgid: this.props.auth.getOrgId(),
      projectid: -1,
    });

    this.fetchData(true);
  }

  clickModify() {
    this.setState({
      modify: !this.state.modify,
      movement: null,
    });
  }

  clickAdd(type, date) {
    switch (type) {
      case "employee":
        this.setState({ newEmployee: true });
        break;
      case "consultant":
        this.setState({ newConsultant: true });
        break;
      default:
    }

    localStorage.setItem("add-date", date);
  }

  async submitJoinLeaveDay(userid, fromDate, toDate, since, status, callback) {
    const res = await db.request("/auth/moveday", "POST", {
      orgid: this.props.auth.getOrgId(),
      userid: userid,
      type: "org",
      status: status,
      fromdate: fromDate.format(),
      todate: toDate.format(),
      since: since,
    });

    callback(res);
  }

  setMovement(obj) {
    this.setState({ movement: obj });
  }

  async menuSubmit(row, idx, type) {
    var day = this.state.orgcreated.addDays(idx + this.state.rangeFrom);

    if (type === "leave") {
      const resLeave = await db.request("/auth/leaveday", "POST", {
        userid: this.state.newdata[row].userid,
        orgid: this.props.auth.getOrgId(),
        todate: moment(day).format("DD MM YYYY"),
        isProject: false,
      });

      if (resLeave.status === 200) {
        this.fetchData(true);
      } else {
        toast.error("Unknown error, day could not be set.");
      }
    }
  }

  showDeleteUser(show, idx) {
    var index = show && idx ? idx : -1;
    this.setState({ showDeleteUser: show, idxToDelete: index });
  }

  async deleteUser(id) {
    const res = await db.request("/auth/deleteuser", "POST", {
      id: id,
      orgid: this.props.auth.getOrgId(),
    });

    if (res.status === 200) {
      toast.success("User ejected! It's done.");
      this.fetchData(true);
    } else if (res.status === 505) {
      toast.warning(
        "The user is the last owner account. There has to be at least one owner."
      );
    } else {
      toast.error("Error deleting user! Please try again later.");
    }

    this.setState({ showDeleteUser: false, idxToDelete: -1 });
  }

  // Render  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  render() {
    return (
      <div style={{ height: this.state.winHeight }}>
        {this.state.newEmployee && (
          <Popup
            title="New employee"
            closeCallback={() => this.setState({ newEmployee: false })}
            height={this.state.winHeight}
          >
            <InviteUser
              auth={this.props.auth}
              dispatch={this.props.dispatch}
              closeCallback={() => this.setState({ newEmployee: false })}
            />
          </Popup>
        )}
        {this.state.newConsultant && (
          <Popup
            title="New consultant"
            closeCallback={() => this.setState({ newConsultant: false })}
            height={this.state.winHeight}
          >
            <InviteUser
              auth={this.props.auth}
              dispatch={this.props.dispatch}
              closeCallback={() => this.setState({ newConsultant: false })}
              consultant={true}
            />
          </Popup>
        )}
        {this.state.showDeleteUser && (
          <Popup
            title="Confirm delete"
            closeCallback={() =>
              this.setState({ showDeleteUser: false, idxToDelete: -1 })
            }
            submitCallback={() => {
              this.deleteUser(
                this.state.newdata[this.state.idxToDelete].userid
              );
            }}
            negativeAction="Nevermind"
            positiveAction="YES, DO IT"
            height={this.state.winHeight}
          >
            Are you sure you want to completely remove{" "}
            <b>
              {this.state.newdata[this.state.idxToDelete]
                ? this.state.newdata[this.state.idxToDelete].firstname +
                  " " +
                  this.state.newdata[this.state.idxToDelete].lastname
                : ""}
            </b>{" "}
            from organization <b>{this.props.auth.getOrgName()}</b>?
          </Popup>
        )}
        <div style={{ marginBottom: 10 }}>
          {this.props.auth.requiresRole("janitor") && (
            <div>
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <button
                  className={
                    this.state.modify
                      ? "btn btn-modify noshadow"
                      : "btn btn-secondary noshadow"
                  }
                  onClick={() => this.clickModify()}
                  style={{
                    padding: 0,
                    paddingRight: 8,
                    width: 23 * 2.3,
                    height: 23 * 2,
                    position: "absolute",
                    left: -15,
                    borderRadius: 5,
                    fontSize: "1.2rem",
                    textAlign: "right",
                    zIndex: 50,
                  }}
                >
                  <div className="fas fa-plus" />
                </button>
              </div>
            </div>
          )}
          <h2
            style={{
              paddingLeft: this.props.auth.requiresRole("janitor") ? 45 : 10,
              paddingTop: 4,
            }}
          >
            <b>People of {this.props.auth.getOrgName()}</b>
          </h2>
        </div>
        <TimeGrid
          ref={this.timegrid}
          auth={this.props.auth}
          boxWidth={this.state.boxWidth}
          boxSpaceHoriz={2}
          boxSpaceVert={this.state.boxSpaceVert}
          headerSpace={14}
          monthHeight={this.state.monthHeight}
          daysHeight={15}
          originDate={this.state.orgcreated}
          startDate={this.state.startdate}
          endDate={this.state.enddate}
          data={this.state.newdata}
          days={this.state.days}
          weekDays={this.state.weekDays}
          holidays={this.state.holidays}
          ribbons={this.state.ribbons}
          ribbonsHeight={this.state.ribbonsHeight}
          modify={this.state.modify}
          movement={this.state.movement}
          setMovement={this.setMovement}
          myIndex={this.state.myIndex}
          loading={this.state.loading}
          nameCol={this.state.nameCol}
          submitDays={this.submitDays}
          menuSubmit={this.menuSubmit}
          clickAdd={this.clickAdd}
          deleteGoal={this.deleteGoal}
          fetchData={this.fetchData}
          submitJoinLeaveDay={this.submitJoinLeaveDay}
          showDeleteUser={this.showDeleteUser}
          addType="addtocompany"
          firstRow={true}
          noScatterJoin={true}
          gridDescriptor="organization"
        />
      </div>
    );
  }
}

export default Peepz;
