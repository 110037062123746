import React from "react";
import TextInput from "./TextInput";
import { toast } from "react-toastify";
import history from "../utils/history";

class AttrCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOver: false,
      isRevealed: false,
      origVal: Object.values(this.props.data.data).join(";"),
    };

    this.submitChanges = this.submitChanges.bind(this);
    this.setEdit = this.setEdit.bind(this);

    this.fields = [];
  }

  submitChanges(e) {
    e.preventDefault();
    if (this.fields.length > 1) {
      const values = this.fields
        .map((val) => {
          return val.value();
        })
        .join(";");

      if (values !== this.state.origVal) {
        this.props.onSubmit(e, this.props.datakey, values);
        this.setState({ origVal: values });
      } else {
        toast("👍🏻 Nothing changed, no update made.", { autoClose: 2500 });
      }
    } else {
      var value = this.props.values
        ? this.props.values[this.fields[0].selectedIndex].value
        : this.fields[0].value();
      if (this.state.origVal !== value) {
        this.props.onSubmit(e, this.props.datakey, value);
        this.setState({ origVal: value });
      } else {
        toast("👍🏻 Nothing changed, no update made.", { autoClose: 2500 });
      }
    }
    this.setEdit(false);
  }

  setEdit(val) {
    this.props.onEdit(this.props.datakey, val);
    this.setState({ isRevealed: val });
  }

  goto(str) {
    history.push("/" + str);
  }

  render() {
    var data = this.props.data ? this.props.data : [];
    var isOver = this.state.isOver;
    var needSettings =
      this.props.values &&
      this.props.values.length === 1 &&
      this.props.values[0] === "";

    // Find the empty cards
    var hasInfo = Object.keys(data.data).some((val) => {
      return data.data[val] !== "";
    });

    var myFields = null;
    if (this.props.editing) {
      myFields = Object.keys(data.data).map((key, i) => {
        if (!this.props.values) {
          return (
            <TextInput
              key={key}
              default={data.data[key]}
              placeholder={key[0].toUpperCase() + key.substr(1)}
              maxlength="30"
              ref={(r) => (this.fields[i] = r)}
              autofocus={i === 0}
            />
          );
        } else {
          return (
            <select
              className="form-control form-control-lg"
              key={key}
              ref={(r) => (this.fields[i] = r)}
              style={{ marginBottom: 10 }}
              defaultValue={data.data[key]}
            >
              {this.props.values.map((val, i) => {
                return <option key={"opt" + i}>{val.label}</option>;
              })}
            </select>
          );
        }
      });
    } else {
      myFields = Object.keys(data.data).map((key, i) => {
        return (
          <span key={key} style={{ display: "block", color: "#000" }}>
            {data.data[key]}
            {hasInfo && this.props.postfix && this.props.postfix}
          </span>
        );
      });
    }

    // Card has no user info put in
    var noInfo = "";
    if (!hasInfo) {
      if (this.props.auth.requiresRole("janitor") || this.props.isMe) {
        if (!this.props.editing) {
          noInfo = (
            <center>
              <div
                style={{
                  fontStyle: "italic",
                  color: "#bb9999",
                  cursor: "pointer",
                }}
                onClick={() => this.setEdit(!this.props.editing)}
              >
                <div style={{ display: "inline-flex", color: "#888" }}>
                  <div
                    className="fas fa-exclamation-triangle"
                    style={{ paddingRight: 8, paddingTop: 5 }}
                  />
                  <h5>add info</h5>
                </div>
              </div>
            </center>
          );
        }
      } else {
        noInfo = (
          <center>
            <div style={{ fontStyle: "italic", color: "#000" }}>
              <h5>no info</h5>
            </div>
          </center>
        );
      }
    }

    return (
      <div
        className="card"
        onClick={() => {
          // TODO: Register in db that user accessed info, under GDPR
          this.setState({ isRevealed: true });
        }}
        onMouseEnter={() => {
          if (!this.props.editing) this.setState({ isOver: true });
        }}
        onMouseLeave={() => {
          this.setState({ isOver: false, isRevealed: false });
        }}
        style={{
          margin: 8,
          minWidth: 220,
          border: 0,
          boxShadow: "0px 0px 6px 0px #222",
          color: "#000",
        }}
      >
        <div className="card-body" style={{ padding: 12 }}>
          {(this.props.auth.requiresRole("janitor") || this.props.isMe) &&
            hasInfo &&
            !needSettings && (
              <div style={{ float: "right" }}>
                <div
                  className="fas fa-pencil-alt"
                  onClick={() => this.setEdit(!this.props.editing)}
                  style={{
                    cursor: "pointer",
                    color: isOver ? "#ccc" : "#000",
                  }}
                />
              </div>
            )}
          <h6 className="card-title" style={{ color: "#000" }}>
            {data ? data.title[0].toUpperCase() + data.title.substr(1) : ""}
          </h6>
          {hasInfo &&
            !this.state.isRevealed &&
            !this.props.editing &&
            !needSettings && (
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#777",
                  position: "absolute",
                  top: 30,
                }}
              >
                <i className="fas fa-mouse-pointer" style={{ color: "#ccc" }} />
                <i style={{ paddingLeft: 5 }}>Click to reveal</i>
              </div>
            )}
          <div
            className={
              !hasInfo || this.state.isRevealed || this.props.editing
                ? "card-text"
                : "card-text noselect"
            }
            style={{
              filter:
                !hasInfo ||
                this.state.isRevealed ||
                this.props.editing ||
                needSettings
                  ? ""
                  : "blur(10px)",
            }}
          >
            <form onSubmit={this.submitChanges}>
              <fieldset disabled={this.props.disabled}>
                {!needSettings && myFields}
                {!needSettings && noInfo}
                {this.props.editing && !needSettings && (
                  <div className="form-row">
                    <div className="col-7">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg btn-block"
                        onClick={this.submitChanges}
                      >
                        SAVE
                      </button>
                    </div>
                    <div className="col-5">
                      <button
                        type="submit"
                        className="btn btn-light btn-lg btn-block"
                        onClick={() => {
                          this.setEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                {needSettings && (
                  <button
                    type="button"
                    className="btn btn-link btn-lg btn-block"
                    style={{ marginBottom: 6 }}
                    onClick={this.goto.bind(this, "admin")}
                  >
                    {data.empty}
                  </button>
                )}
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AttrCard;
