import React from "react";
import { TweenMax, Sine, Elastic } from "gsap";
import iconNeg from "../imgs/critical.png";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
      showError: false,
      value: ["", "", "", "", "", ""],
      fields: [],
      current: 0,
      willUnmount: false
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.setIsAnimating = this.setIsAnimating.bind(this);
    this.validateCode = this.validateCode.bind(this);
  }

  componentDidMount() {
    var state = this.state;
    state.fields = [this.no0, this.no1, this.no2, this.no3, this.no4, this.no5];
    this.setState(state);
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    var state = this.state;
    state.willUnmount = true;
    this.setState(state);
    document.removeEventListener("keydown", this.onKeyDown, false);
    TweenMax.killAll();
  }

  onKeyDown(e) {
    var state = this.state;
    state.showError = false;

    if (e.key === "Backspace") {
      if (state.current > 0) {
        state.current--;
        state.value[state.current] = "";
      }
    } else {
      if (state.current < 6) {
        const regex = new RegExp(/^[0-9\b]+$/);
        if (regex.test(e.key)) {
          // Store value
          state.value[state.current] = e.key;

          if (state.current === 5) {
            var validCode = this.props.validateCallback(state.value.join(""));
            if (typeof validCode.then === "function") {
              // The callback was async and hence is a Promise
              validCode.then(() => {
                // Code is wrong, if component still alive, tell user
                if (!this.state.willUnmount) {
                  this.validateCode(validCode);
                }
              });
            } else if (validCode) {
              this.validateCode(validCode);
            }
          } else {
            this.digitAnim(state.fields[state.current]);
            state.current++;
          }
        }
      }
    }
    this.setState(state);
  }

  validateCode(isValid) {
    if (isValid) {
      var state = this.state;
      state.showError = true;
      state.message = "That's not the code.";
      state.value = ["", "", "", "", "", ""];
      state.current = 0;
      this.shakeAnim();
      this.setState(state);
    }
  }

  setIsAnimating(isAnim) {
    var state = this.state;
    state.isAnimating = isAnim;
    this.setState(state);
  }

  shakeAnim() {
    if (this.state.isAnimating) return;

    this.setIsAnimating(true);

    TweenMax.fromTo(
      this.wrapper,
      0.08,
      { x: -20 },
      {
        x: 20,
        repeat: 3,
        yoyo: true,
        ease: Sine.easeInOut,
        onComplete: function(animCallback) {
          TweenMax.to(this.target, 0.7, {
            x: 0,
            ease: Elastic.easeOut,
            onComplete: function(callback) {
              callback(false);
              this.kill();
            },
            onCompleteParams: [animCallback]
          });
        },
        onCompleteParams: [this.setIsAnimating]
      }
    );
  }

  digitAnim(obj) {
    TweenMax.to(obj, 0.05, {
      scale: 0.9,
      onComplete: function() {
        TweenMax.to(obj, 0.65, {
          scale: 1,
          ease: Elastic.easeOut,
          easeParams: [1.6, 0.25]
        });
      }
    });
  }

  render() {
    var inputStyle = "single-digit";
    var errorClass = "input-error-hidden";

    if (this.state.showError) {
      errorClass = "input-error";
    }

    var message = (
      <div className="error-wrapper bad">
        <img src={iconNeg} alt="img" />
        {this.state.message}
      </div>
    );

    return (
      <center>
        <div ref={b => (this.wrapper = b)}>
          <div ref={c => (this.no0 = c)} className={inputStyle}>
            {this.state.value[0]}
          </div>
          <div ref={c => (this.no1 = c)} className={inputStyle}>
            {this.state.value[1]}
          </div>
          <div ref={c => (this.no2 = c)} className={inputStyle}>
            {this.state.value[2]}
          </div>
          {" - "}
          <div ref={c => (this.no3 = c)} className={inputStyle}>
            {this.state.value[3]}
          </div>
          <div ref={c => (this.no4 = c)} className={inputStyle}>
            {this.state.value[4]}
          </div>
          <div ref={c => (this.no5 = c)} className={inputStyle}>
            {this.state.value[5]}
          </div>
        </div>
        <center>
          <div className={errorClass}>{message}</div>
        </center>
      </center>
    );
  }
}

export default TextInput;
