import React from "react";
import CommandBar from "../components/CommandBar";
import Ribbon from "../components/Ribbon";
import Pin from "./Pin";
import Day from "../components/Day";
import Helper from "../utils/helper";
import history from "../utils/history";
import logo from "../imgs/kitty.png";

class TimeGrid extends React.Component {
  constructor(props) {
    super(props);

    this.goalDefs = {
      goal: { size: "medium", color: "#efa51c", height: 26 },
      event: { size: "small", color: "#888", height: 20 },
      sprint: { size: "pin", color: "", height: 0 },
      major: { size: "large", color: "#efa51c", height: 32 },
      today: { size: "small", color: "#cc0000", height: 20 },
      thematic: { size: "large", color: "#efa51c", height: 32 },
    };

    this.state = {
      selectedDays: [],
      pageCols: 10,
      currentIndex: -1,
      containerExist: false,
      confirmDeleteUser: false,
      daysHeight: this.props.daysHeight ? this.props.daysHeight : 0,
      showOldMembers: false,
      moveRibbon: null,
      moveRibbonX: 0,
      moveRibbonRes: 0,
      draggedRibbon: false,
      draggingDays: null,
      weekendDivider: 5,
      filterAvailRows: this.props.showAllRows,
      modify: false,
      hoveringCol: null,
      hoveringRow: null,
    };

    this.clickJoinLeaveDay = this.clickJoinLeaveDay.bind(this);
    this.cancelJoinLeaveDay = this.cancelJoinLeaveDay.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.navigateLeft = this.navigateLeft.bind(this);
    this.navigateRight = this.navigateRight.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.cancelSelection = this.cancelSelection.bind(this);
    this.showCommandbar = this.showCommandbar.bind(this);
    this.submitJoinLeaveDay = this.submitJoinLeaveDay.bind(this);
    this.navigateLeft = this.navigateLeft.bind(this);
    this.navigateRight = this.navigateRight.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.resetViewPosition = this.resetViewPosition.bind(this);
    this.checkToLeft = this.checkToLeft.bind(this);
    this.checkToRight = this.checkToRight.bind(this);
    this.gotoUrl = this.gotoUrl.bind(this);
    this.submitDaysInternal = this.submitDaysInternal.bind(this);
    this.onMoveRibbon = this.onMoveRibbon.bind(this);
    this.onStopDragRibbon = this.onStopDragRibbon.bind(this);
    this.onStartDragRibbon = this.onStartDragRibbon.bind(this);
    this.ribbonReport = this.ribbonReport.bind(this);
    this.onStopDragDay = this.onStopDragDay.bind(this);
    this.onStartDragDay = this.onStartDragDay.bind(this);
    this.hoverCol = this.hoverCol.bind(this);
    this.hoverRow = this.hoverRow.bind(this);
    this.hover = this.hover.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.handleAddMenuMouse = this.handleAddMenuMouse.bind(this);

    this.container = null;
    this.timegridContainerFunc = (ref) => {
      this.container = ref;
      this.updateDimensions();
      this.setState({ containerExist: true });
    };
    this.todayRibbon = React.createRef();

    this.lefts = [];
    this.leftsNoWeekend = [];
    this.weekday = [];
    this.ribbonsWidth = {};
    this.ribbonsCount = 0;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      Helper.stateChanged(this.state, nextState) ||
      Helper.stateChanged(this.props, nextProps)
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    document.addEventListener("keydown", this.handleKey, false);
    if (!this.props.noMenu)
      document.addEventListener("mousemove", this.mouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey, false);
    window.removeEventListener("resize", this.updateDimensions);
    document.removeEventListener("mousemove", this.mouseMove);

    if (this.state.selectedDays.length > 0) {
      this.showCommandbar(false);
    }
  }

  updateDimensions() {
    if (!this.container) return;
    var width = this.props.boxWidth;
    var space = this.props.boxSpaceHoriz;
    var pageCols = Math.floor(
      (this.container.getBoundingClientRect().width / (width + space)) * 1.13
    );

    var setState = false;
    var newState = {};

    if (this.state.pageCols !== pageCols) {
      newState.pageCols = pageCols;
      setState = true;
    }

    if (setState) {
      this.setState(newState);
      this.ribbonsCount = 0;
    }
  }

  resetViewPosition() {
    // Calculate most proper view start point
    var startAt = 0;
    var today = new Date();
    var todaysIndex = this.props.startDate.daysBetween(new Date(), true);
    var daysToEnd = this.props.startDate.daysBetween(this.props.endDate) + 1;
    if (todaysIndex === 0 || daysToEnd < this.state.pageCols) {
      startAt = 0;
    } else if (
      daysToEnd - todaysIndex < this.state.pageCols ||
      todaysIndex > daysToEnd
    ) {
      startAt = daysToEnd - this.state.pageCols;
    } else if (todaysIndex < 6) {
      startAt = todaysIndex - today.getDay() + 1;
    } else {
      startAt = todaysIndex - 7 - today.getDay();
    }
    this.navigateTo(startAt);
  }

  handleKey(e) {
    if (!this.props.disableInput && !sessionStorage.getItem("popup")) {
      if (e.keyCode === 37) {
        e.preventDefault();
        this.navigateLeft(); // Left arrow
      } else if (e.keyCode === 39) {
        e.preventDefault();
        this.navigateRight(); // Right arrow
      } else if (this.state.commandbarShowing && e.keyCode === 27) {
        this.cancelSelection();
        this.setState({ modify: false });
      } else if (e.key === "e") {
        if (this.props.auth.requiresRole("janitor"))
          this.setState({ modify: !this.state.modify, movement: null });
      }
    }
  }

  mouseMove(e) {
    if (this.props.nameCol && !this.state.addMenuOpen) {
      const rawX = e.pageX - this.props.nameCol - 24;

      var x = 0;
      if (rawX >= 0) {
        for (var i = 0; i < this.leftsNoWeekend.length; i++) {
          if (
            rawX > this.leftsNoWeekend[i] &&
            rawX <= this.leftsNoWeekend[i + 1]
          ) {
            x = i;
            break;
          }
        }

        this.hoverCol(x);
      } else if (this.state.hoveringCol !== null) {
        this.hoverCol(null);
      }
    }
  }

  navigateTo(idx) {
    var to = idx < 0 ? 0 : idx;

    if (this.props.weekDays[to] === 6) to += 2;
    else if (this.props.weekDays[to] === 0) to += 1;

    this.setState(
      {
        currentIndex: to,
      },
      this.updateDimensions
    );
  }

  navigateLeft() {
    var to = Math.max(
      this.state.currentIndex - Math.floor(this.state.pageCols / 2),
      0
    );
    this.navigateTo(to);
  }

  navigateRight() {
    var daysToEnd = this.props.startDate.daysBetween(this.props.endDate) + 1;
    var to = Math.min(
      this.state.currentIndex + Math.floor(this.state.pageCols / 2),
      daysToEnd - this.state.pageCols
    );

    this.navigateTo(to);
  }

  selectDay(gridkey, position, type) {
    var selectedDays = this.state.selectedDays.slice(0);
    const idx = selectedDays.indexOf(gridkey);

    if (idx > -1) {
      selectedDays.splice(idx, 1);
      if (selectedDays.length === 0) {
        this.showCommandbar(false, true);
      }
    } else {
      selectedDays.push(gridkey);
      this.showCommandbar(true, true);
    }

    this.setState({
      selectedDays: selectedDays,
      commandbarShowing: selectedDays.length > 0,
    });
  }

  showCommandbar(val, skipState) {
    if (!skipState) {
      this.setState({ commandbarShowing: val });
    }
  }

  cancelSelection() {
    if (this.state.selectedDays.length > 0) {
      this.setState({
        selectedDays: [],
        commandbarShowing: false,
      });
    }
  }

  checkToLeft(row, idx) {
    var prevJoined = this.props.data[row].joinsLeaves[idx - 1]
      ? this.props.data[row].joinsLeaves[idx - 1]
      : false;
    var isJoined = this.props.data[row].joinsLeaves[idx];
    var prevInCompany = this.props.data[row].inCompany[idx]
      ? this.props.data[row].inCompany[idx - 1]
      : false;
    var isInCompany = this.props.data[row].inCompany[idx + 1];

    var res = {};
    res.isCompanySoonest =
      (!prevInCompany && isInCompany) ||
      (prevInCompany && !isInCompany) ||
      (idx === 0 && isInCompany);
    res.isSoonest =
      (!prevJoined && isJoined) ||
      (prevJoined && !isJoined) ||
      (idx === 0 && isJoined);
    return res;
  }

  checkToRight(row, idx) {
    var nextJoined = this.props.data[row].joinsLeaves[idx + 1]
      ? this.props.data[row].joinsLeaves[idx + 1]
      : false;
    var isJoined = this.props.data[row].joinsLeaves[idx];
    var nextInCompany = this.props.data[row].inCompany[idx + 1]
      ? this.props.data[row].inCompany[idx + 1]
      : false;
    var isInCompany = this.props.data[row].inCompany[idx];

    var res = {};
    res.isCompanyLatest =
      (isInCompany && !nextInCompany) || (!isInCompany && nextInCompany);
    res.isLatest = (isJoined && !nextJoined) || (!isJoined && nextJoined);

    return res;
  }

  clickJoinLeaveDay(row, index, type) {
    if (this.props.movement && this.props.movement.row === row) {
      this.cancelJoinLeaveDay(null, true);
      return;
    }

    document.addEventListener("keydown", this.cancelJoinLeaveDay, false);

    var limit = type === "join" ? index - 1 : index;
    var i = limit;
    var lastDay = this.props.startDate.daysBetween(this.props.endDate) + 1; // Days to end
    var soonest = -1;
    var latest = -1;
    var latestArr = [];
    var soonestCompany = -1;
    var latestCompany = -1;

    // First go backwards to find previous movement day
    var left = null;
    while (i >= 0) {
      left = this.checkToLeft(row, i);
      if (left.isCompanySoonest) {
        soonestCompany = i;
      }
      if (soonest === -1 && left.isSoonest) {
        soonest = i;
      }
      if (soonest > -1 && soonestCompany > -1) break;
      i--;
    }

    // Second, go forward
    i = index;
    var right = null;
    while (i <= lastDay) {
      right = this.checkToRight(row, i);
      if (right.isCompanyLatest) {
        latestCompany = i + 1;
      }
      if (latestArr.length < 2 && right.isLatest) {
        latestArr.push(i);
      }
      if (latestArr.length > 0 && latestCompany > -1) break;
      i++;
    }

    if (latestArr[0] <= latestCompany) {
      latest = latestArr[1];
    } else {
      latest = latestArr[0];
    }

    if (this.props.setMovement) {
      this.props.setMovement({
        row: row,
        soonest: soonest,
        soonestCompany: soonestCompany,
        current: index,
        latest: latest,
        latestCompany: latestCompany,
        type: type,
      });
    }
  }

  cancelJoinLeaveDay(e, force = false) {
    // Escape = 27
    if ((e && e.keyCode === 27) || force) {
      this.props.setMovement(null);
      document.removeEventListener("keydown", this.cancelJoinLeaveDay, false);
    }
  }

  submitJoinLeaveDay(userid, from, to, status, dayid, projid) {
    var fromDate = this.props.startDate.addDays(from);
    var toDate = this.props.startDate.addDays(to);
    var since = this.props.originDate.daysBetween(toDate);

    this.props.submitJoinLeaveDay(
      userid,
      fromDate,
      toDate,
      since,
      this.props.movement.type,
      projid,
      (res) => {
        if (res.status === 200) {
          this.props.fetchData(true);
          this.cancelJoinLeaveDay(null, true);
        }
      }
    );
  }

  gotoUrl(idx) {
    return function () {
      if (!this.props.url) {
        history.push("/user?" + this.props.data[idx].userid);
      } else {
        this.props.auth.selectProjById(this.props.data[idx].projectid);
        history.push(this.props.url);
      }
      window.scrollTo(0, 0);
    }.bind(this);
  }

  submitDaysInternal(code, type, repeat) {
    this.props.submitDays(code, type, this.state.selectedDays, repeat);
    if (this.state.selectedDays.length > 0) {
      this.cancelSelection();
    }
  }

  deleteUser(idx) {
    this.props.showDeleteUser(true, idx);
  }

  onStartDragRibbon(id, coord, idx) {
    this.setState({
      moveRibbon: id,
      moveRibbonX: coord.x,
      moveRibbonToIdx: idx,
    });
  }

  onMoveRibbon(x, y) {
    var newX = x - this.state.moveRibbonX;
    var resX = Math.floor(newX / this.props.boxWidth);
    this.setState({ moveRibbonRes: resX, draggedRibbon: true });
  }

  onStopDragRibbon() {
    if (this.state && this.state.moveRibbonRes !== 0) {
      var day = this.weekday[
        this.state.moveRibbonToIdx -
          this.state.currentIndex +
          this.state.moveRibbonRes
      ];
      var offWeekend = 0;
      if (day === 6) offWeekend = -1;
      else if (day === 0) offWeekend = 1;

      var data = {};
      data.date = new Date(
        this.props.days[
          this.state.moveRibbonToIdx + this.state.moveRibbonRes + offWeekend
        ]
      );
      data.id = this.state.moveRibbon;
      data.onlyDate = true;

      this.props.moveGoal(data, () => {
        this.setState({
          moveRibbonX: 0,
          moveRibbonRes: 0,
          draggedRibbon: false,
        });
      });
    }
  }

  ribbonReport(idx, id, width) {
    this.ribbonsCount++;

    if (!this.ribbonsWidth[idx]) this.ribbonsWidth[idx] = {};
    this.ribbonsWidth[idx][id] = width;

    var keys = Object.keys(this.props.ribbons);
    var count = 0;

    for (var i = 0; i < keys.length; i++) {
      if (
        keys[i] >= this.state.currentIndex &&
        keys[i] < this.state.currentIndex + this.state.pageCols
      ) {
        for (var j = 0; j < this.props.ribbons[keys[i]].length; j++) {
          count += 1;
        }
      }
    }

    if (count === this.ribbonsCount) {
      this.forceUpdate();
    }
  }

  onStartDragDay(gridKey, seed, position, type) {
    this.selectDay(gridKey, position, type);
    this.setState({
      draggingDays: seed,
    });
  }

  onStopDragDay() {
    this.setState({
      draggingDays: null,
    });
  }

  hover(col, row) {
    this.hoverRow(row);
  }

  hoverCol(col) {
    if (col !== this.state.hoveringCol) this.setState({ hoveringCol: col });
  }

  hoverRow(row) {
    if (row !== this.state.hoveringRow) this.setState({ hoveringRow: row });
  }

  handleAddMenuMouse(e) {
    this.setState({ addMenuOpen: false });
    document.removeEventListener("mousedown", this.handleAddMenuMouse);
  }

  render() {
    const st = this.state;
    const emptyOrg = this.props.days.length === 0;
    var groupPad = 0;
    var row = 0;
    var ribbonsHeight = 0;
    var ribbonsCounted = 0;
    var daysCounted = 0;
    var lefts = [];
    var leftsNoWeekend = [];
    var weekday = [];

    if (!this.props.loading && !emptyOrg && this.state.containerExist) {
      var ribbons = [];
      var months = [];
      var days = [];
      var peepz = [];
      var peepzLeft = [];
      var grid = [];
      var goalStack = [];
      var todaysIndex = this.props.startDate.daysBetween(new Date(), true);
      var daysToEnd = this.props.startDate.daysBetween(this.props.endDate) + 1;
      var maxIndex = Math.min(st.currentIndex + st.pageCols, daysToEnd);
      var todayLeft = todaysIndex < st.currentIndex;
      var todayRight =
        st.currentIndex + st.pageCols <= todaysIndex && todaysIndex < daysToEnd;
      var col = 0;
      var left = 0;
      var prevGroup = null;
      var needArrows = this.props.days.length > st.pageCols;

      for (var y = 0; y < this.props.data.length; y++) {
        const data = Object.assign(this.props.data[y]);
        var firstRow = this.props.firstRow && row > 0 ? 16 : 0;
        var showRow =
          (data.joinsLeaves.slice(st.currentIndex, maxIndex).includes(true) &&
            data.inCompany.slice(st.currentIndex, maxIndex).includes(true)) ||
          this.state.filterAvailRows;

        col = 0;
        left = 0;
        var monthName = "";
        var currMonthLeft = 0;
        var nextMonthLeft = 0;
        var recentWeekdayLeft = 0;

        if (showRow && prevGroup && data.discipline !== prevGroup) {
          groupPad += 14;
        }

        for (var x = st.currentIndex; x < maxIndex; x++) {
          var key = y + "-" + data.userid + "-" + x.toString() + "-" + !showRow;
          var isInCompany = this.props.data[y].inCompany[x];
          var isJoined = isInCompany && this.props.data[y].joinsLeaves[x];
          var prevInCompany =
            x > 0 ? this.props.data[y].inCompany[x - 1] : false;
          var prevJoined =
            x > 0
              ? prevInCompany && this.props.data[y].joinsLeaves[x - 1]
              : false;
          var nextInCompany =
            x < maxIndex ? this.props.data[y].inCompany[x + 1] : false;
          var nextJoined =
            x < maxIndex
              ? nextInCompany && this.props.data[y].joinsLeaves[x + 1]
              : false;
          const date = this.props.days[x];
          const holiday = this.props.holidays[x];
          var day = this.props.weekDays[x];
          var isWeekday = day > 0 && day < 6;
          var validDay = isJoined && isWeekday;
          var ribbonData = this.props.ribbons[x]
            ? this.props.ribbons[x].slice()
            : null;
          var type = "avail";
          var status = validDay ? "working" : null;
          var moveJoin = !prevJoined && isJoined;
          var moveLeft = isJoined && !nextJoined;
          var hoveringCol = this.state.hoveringCol === col;
          const colNew = col;

          if (todaysIndex === x) {
            if (!ribbonData) ribbonData = [];
            ribbonData.push({
              name: "TODAY",
              id: "today",
              type: "today",
              custom: true,
              shadecolor: "#550000",
            });
          }

          // Render times, btns & goals only once
          if (y === 0) {
            if (date && date.getDate() === 1) {
              monthName = date.getMonthName();
              currMonthLeft = left;
            }

            // GOALS
            if (ribbonData) {
              var initY = 0;
              var mems = [];
              var monthCoverAdded = false;

              for (var s = 0; s < ribbonData.length; s++) {
                var goal = ribbonData[s];

                // Check heights of previous Ribbons
                for (var j = 0; j < goalStack.length; j++) {
                  if (
                    left < goalStack[j].right &&
                    (goalStack[j].y <= initY ||
                      goalStack[j].y <= initY + this.goalDefs[goal.type].height)
                  ) {
                    initY =
                      goalStack[j].y +
                      this.goalDefs[goalStack[j].type].height +
                      3;

                    if (mems.length > 0) {
                      for (var p = 0; p < mems.length; p++) {
                        if (
                          initY <
                            mems[p].y +
                              this.goalDefs[mems[p].type].height +
                              3 &&
                          initY + this.goalDefs[goal.type].height > mems[p].y
                        ) {
                          initY =
                            mems[p].y + this.goalDefs[mems[p].type].height + 3;
                        }
                      }
                    }
                  } else if (
                    left < goalStack[j].right &&
                    goalStack[j].y > initY
                  ) {
                    mems.push(goalStack[j]);
                  }
                }

                // Keep goal in stack for look-back
                var goalObj = {
                  left: left,
                  right: this.ribbonsWidth[x]
                    ? left + this.ribbonsWidth[x][goal.id]
                    : -1,
                  id: goal.id,
                  name: goal.name,
                  date: goal.date,
                  desc: goal.desc,
                  type: goal.type,
                };

                // Push goals down closer to grid between month titles
                var endOfMonth = date.getEndOfMonth();
                var daysToEndOfMonth = date.daysBetween(endOfMonth);
                var countWeekends = date.countWeekends(endOfMonth);

                nextMonthLeft =
                  (daysToEndOfMonth - 1) *
                    (this.props.boxWidth + this.props.boxSpaceHoriz) -
                  countWeekends *
                    (this.props.boxWidth / this.state.weekendDivider +
                      this.props.boxSpaceHoriz) +
                  left;

                var coversMonth =
                  (left < currMonthLeft + monthName.length * 17 ||
                    goalObj.right < currMonthLeft + monthName.length * 17 ||
                    goalObj.right > nextMonthLeft) &&
                  initY < 15;
                if (coversMonth) {
                  if (!monthCoverAdded) {
                    initY += 15;
                    monthCoverAdded = true;
                  }
                } else {
                  monthCoverAdded = false;
                }

                goalObj.y = initY;
                goalStack.push(goalObj);

                var top = goal.type === "sprint" ? 20 : -initY - 10;

                var leftNew = this.props.nameCol + 42;
                if (goal.type === "today") {
                  if (!isWeekday) {
                    leftNew +=
                      recentWeekdayLeft +
                      this.props.boxWidth / 2 +
                      this.props.boxSpaceHoriz +
                      this.props.boxWidth / this.state.weekendDivider +
                      this.props.boxSpaceHoriz / 2;
                  } else {
                    leftNew += left;
                  }
                } else if (goal.id !== st.moveRibbon) {
                  leftNew += left;
                } else {
                  leftNew += this.lefts[x - st.currentIndex + st.moveRibbonRes];
                }

                if (goal.type === "sprint") leftNew -= 8;

                var z = 400 - initY;
                if (goal.type === "sprint") z = 601 + initY;
                else if (x < todaysIndex && !this.state.modify) z = 200 - initY;

                var color = this.goalDefs[goal.type].color;
                if (x < todaysIndex && !this.state.modify) color = "#aaa";
                else if (goal.color) color = goal.color;

                ribbons.push(
                  goal.type !== "sprint" ? (
                    <Ribbon
                      key={"ribbon" + goal.id + "_" + key}
                      left={leftNew}
                      top={top}
                      zIndex={z}
                      opacity={x < todaysIndex && !this.state.modify ? 0.25 : 1}
                      show={goalObj.right > -1}
                      data={{
                        name: goal.name,
                        id: goal.id,
                        date: goal.date,
                        desc: goal.desc,
                        type: goal.type,
                        color: goal.color,
                      }}
                      size={this.goalDefs[goal.type].size}
                      height={initY + 24}
                      width={8}
                      color={color}
                      pillarcolor={color}
                      shadecolor={goal.shadecolor ? goal.shadecolor : "#555"}
                      clickCallback={(rbData) => {
                        if (!this.state.draggedRibbon && rbData.id !== "today")
                          this.props.ribbonClick(rbData);
                      }}
                      disableDrag={
                        this.props.disableInput || goal.type === "today"
                      }
                      onStartDrag={this.onStartDragRibbon}
                      onMove={this.onMoveRibbon}
                      onStopDrag={this.onStopDragRibbon}
                      idx={x}
                      reportCb={this.ribbonReport}
                      animTiming={ribbonsCounted}
                      movedRibbon={this.state.moveRibbon}
                    />
                  ) : (
                    <Pin
                      key={"pin" + goal.id + "_" + key}
                      left={leftNew}
                      top={top + 4}
                      data={{
                        name: goal.name,
                        id: goal.id,
                        date: goal.date,
                        desc: goal.desc,
                        type: goal.type,
                      }}
                      height={12}
                      zIndex={z}
                      idx={x}
                      isEarlyInMonth={coversMonth}
                      faded={x < todaysIndex && !this.state.modify}
                      reportCb={this.ribbonReport}
                      clickCallback={this.props.ribbonClick}
                      disableDrag={this.props.disableInput}
                      onStartDrag={this.onStartDragRibbon}
                      onMove={this.onMoveRibbon}
                      onStopDrag={this.onStopDragRibbon}
                    />
                  )
                );
                if (initY > ribbonsHeight) ribbonsHeight = initY;
                ribbonsCounted++;
              }
            }

            // DAYS
            if (isWeekday) {
              var dayTop = hoveringCol
                ? this.props.monthHeight - 9
                : this.props.monthHeight;
              var dayLeft = hoveringCol
                ? this.props.nameCol + 25 + left
                : this.props.nameCol + 26 + left;

              days.push(
                <div
                  key={"days_" + key}
                  style={{
                    position: "absolute",
                    top: dayTop,
                    left: dayLeft,
                    fontWeight: "normal",
                    fontSize: "0.6rem",
                    textAlign: "center",
                    color: "#674ea7",
                    width: this.props.boxWidth,
                    transition: "none",
                    cursor: "default",
                  }}
                >
                  <>
                    {this.state.hoveringCol === colNew && !this.props.noMenu ? (
                      <>
                        <div
                          id="dropdownAddButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className={"btn-custom-light"}
                          onClick={() => {
                            this.setState({ addMenuOpen: true });
                            document.addEventListener(
                              "mousedown",
                              this.handleAddMenuMouse
                            );
                          }}
                        >
                          <i
                            className={"fas fa-plus-circle small"}
                            style={{
                              opacity: 0.5,
                              borderRadius: "50%",
                              background: "transparent",
                              boxShadow: "1px 1px 3px #222",
                            }}
                          />
                        </div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          style={{
                            WebkitTransition: "none",
                            msTransition: "none",
                          }}
                          onMouseLeave={() => {
                            this.setState({ addMenuOpen: false });
                          }}
                        >
                          <>
                            <div className="dropdown-header">
                              {date.getDayName()}
                            </div>
                            <>
                              {this.props.auth.requiresRole("janitor") && (
                                <button
                                  id="dropMenuItem"
                                  className="dropdown-item"
                                  onClick={() => {
                                    this.props.clickAdd("person", date);
                                    this.setState({ addMenuOpen: false });
                                  }}
                                >
                                  Person joins
                                </button>
                              )}
                              <button
                                id="dropMenuItem"
                                className="dropdown-item"
                                onClick={() => {
                                  this.props.clickAdd("milestone", date);
                                  this.setState({ addMenuOpen: false });
                                }}
                              >
                                Create Milestone
                              </button>

                              {(!holiday ||
                                (holiday && holiday.type === "offday")) &&
                                this.props.auth.requiresRole("janitor") && (
                                  <>
                                    <div className="dropdown-divider" />
                                    <button
                                      id="dropMenuItem"
                                      className="dropdown-item"
                                      onClick={() => {
                                        this.props.setWorkday(!holiday, date);
                                        this.setState({ addMenuOpen: false });
                                      }}
                                    >
                                      {!holiday
                                        ? "Cancel workday"
                                        : "Reset workday"}
                                    </button>
                                  </>
                                )}
                            </>
                          </>
                        </div>
                      </>
                    ) : (
                      <div style={{ opacity: 0.3 }}>{date.getDate()}</div>
                    )}
                  </>
                </div>
              );
            }

            // MONTHS
            if (date && date.getDate() === 1) {
              months.push(
                <div
                  key={"months_" + key}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: this.props.nameCol + 34 + left,
                    zIndex: 601,
                    textShadow: "1px 1px 3px #222",
                  }}
                >
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "0.8rem",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {date.getFullYear()}
                  </div>
                  <div
                    style={{
                      fontWeight: "600",
                      // fontStyle: "italic",
                      color: "#fff",
                      fontSize: "1.6rem",
                      padding: 0,
                      marginTop: -12,
                    }}
                  >
                    {monthName}
                  </div>
                </div>
              );
            }

            // Remember grid left positions
            if (isWeekday) {
              lefts.push(Math.round(left));
              recentWeekdayLeft = Math.round(left);
            } else {
              lefts.push(recentWeekdayLeft);
            }
            leftsNoWeekend.push(Math.round(left));
            weekday.push(day);
          }

          // Figure out which type of day
          if (holiday) type = "holiday";
          if (!validDay) type = "notjoined";
          if (this.props.data[y].status[x]) {
            status = this.props.data[y].status[x];
          }

          const dayTopItem = !showRow
            ? groupPad +
              row * (this.props.boxWidth + this.props.boxSpaceVert) +
              firstRow
            : this.props.monthHeight +
              this.state.daysHeight +
              groupPad +
              row * (this.props.boxWidth + this.props.boxSpaceVert) +
              firstRow;
          const dayLeftItem = this.props.nameCol + 26 + left;

          var gridItem = (
            <div
              key={"gridItem_" + key}
              style={{
                position: "absolute",
                top: dayTopItem,
                left: dayLeftItem,
                transition: "none",
              }}
            >
              <Day
                gridKey={key}
                idx={this.state.currentIndex + col}
                submitCb={
                  this.state.modify
                    ? this.clickJoinLeaveDay
                    : this.props.submitDays
                }
                clickJoinLeaveDay={this.clickJoinLeaveDay}
                submitJoinLeaveCb={this.submitJoinLeaveDay}
                cancelJoinLeaveCb={this.cancelJoinLeaveDay}
                selectDayCb={this.selectDay}
                menuSubmitCb={this.props.menuSubmit}
                date={date}
                width={this.props.boxWidth}
                height={this.props.boxWidth}
                holiday={holiday}
                status={status}
                userid={data.userid}
                projid={data.projectid}
                type={type}
                auth={this.props.auth}
                selected={this.state.selectedDays.indexOf(key) > -1}
                position={[dayLeftItem, dayTopItem]}
                col={colNew}
                row={y}
                movement={this.props.movement}
                dayid={this.props.data[y].dayid[x]}
                modify={this.state.modify}
                isWeekday={isWeekday}
                day={day}
                isInCompany={isInCompany}
                moveJoin={moveJoin}
                moveJoinCompany={!prevInCompany}
                moveLeft={moveLeft}
                moveLeftCompany={!nextInCompany}
                tooltip={holiday ? holiday.name : ""}
                noScatterJoin={this.props.noScatterJoin}
                gridDescriptor={this.props.gridDescriptor}
                animTiming={daysCounted}
                disableDrag={this.props.disableInput}
                onStartDrag={this.onStartDragDay}
                onStopDrag={this.onStopDragDay}
                isDragging={this.state.draggingDays}
                hoveringCb={this.hover}
                multiSelected={this.state.selectedDays.length > 1}
                cancelSelection={this.cancelSelection}
              />
            </div>
          );

          if (showRow) {
            grid.push(gridItem);
            daysCounted++;
          }

          col++;
          left += isWeekday
            ? this.props.boxWidth + this.props.boxSpaceHoriz
            : this.props.boxWidth / this.state.weekendDivider +
              this.props.boxSpaceHoriz;
        }

        // GROUP
        var group = (
          <div
            key={data.discipline ? data.discipline : "group" + y}
            style={{
              position: "absolute",
              top: !showRow
                ? groupPad +
                  row * (this.props.boxWidth + this.props.boxSpaceVert) -
                  13
                : this.props.monthHeight +
                  this.state.daysHeight +
                  groupPad +
                  row * (this.props.boxWidth + this.props.boxSpaceVert) -
                  13,
              height: 16,
              paddingLeft: 5,
              fontSize: "0.65rem",
              color: "#674ea7",
            }}
          >
            {data.disciplineName}
          </div>
        );

        if (showRow && data.discipline !== prevGroup) {
          peepz.push(group);
        }

        var fullName = this.props.data[y].firstname;
        if (this.props.data[y].lastname) {
          fullName += " " + this.props.data[y].lastname.charAt(0);
        }

        // PEEPZ
        var peep = (
          <div
            key={data.email + "_" + y}
            style={{
              position: "absolute",
              lineHeight: 0,
              top: !showRow
                ? groupPad +
                  row * (this.props.boxWidth + this.props.boxSpaceVert) +
                  firstRow
                : this.props.monthHeight +
                  this.state.daysHeight +
                  groupPad +
                  row * (this.props.boxWidth + this.props.boxSpaceVert) +
                  firstRow,
            }}
          >
            {this.props.gridDescriptor && (
              <>
                <button
                  type="button"
                  id="dropdownAddButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    position: "absolute",
                    width: 24,
                    height: this.props.boxWidth,
                    textAlign: "center",
                    right: 0,
                    top: 0,
                    borderRadius: 4,
                    fontSize: "0.8em",
                    color:
                      this.state.hoveringRow === y ? "#000" : "transparent",
                    cursor: "pointer",
                    paddingTop: 3,
                    paddingRight: 4,
                    border: 0,
                    outline: "none",
                    background: "none",
                  }}
                  onMouseOver={this.hoverRow.bind(this, y)}
                >
                  <i className="fas fa-ellipsis-h" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{
                    WebkitTransition: "none",
                    msTransition: "none",
                  }}
                >
                  <>
                    {data.email === "none" &&
                      this.props.auth.requiresRole("janitor") && (
                        <button
                          id="dropMenuItem"
                          className="dropdown-item"
                          style={{ padding: "14px 14px" }}
                          onClick={() => {
                            if (this.props.inviteUser) {
                              this.props.inviteUser({
                                firstname: data.firstname ? data.firstname : "",
                                lastname: data.lastname ? data.lastname : "",
                                userid: data.userid,
                              });
                            }
                          }}
                        >
                          <i
                            className="fas fa-envelope"
                            style={{
                              width: 16,
                              marginRight: 8,
                              fontSize: "0.8rem",
                              color: "green",
                              textAlign: "center",
                            }}
                          />
                          Send invitation email
                        </button>
                      )}
                    <button
                      id="dropMenuItem"
                      className="dropdown-item"
                      style={{ padding: "12px 12px" }}
                      onClick={this.gotoUrl(y)}
                    >
                      <i
                        className="fas fa-info"
                        style={{
                          width: 16,
                          marginRight: 8,
                          fontSize: "0.8rem",
                          color: "grey",
                          textAlign: "center",
                        }}
                      />
                      Inspect user
                    </button>
                    {this.props.auth.requiresRole("janitor") && (
                      <>
                        <button
                          id="dropMenuItem"
                          className="dropdown-item"
                          style={{ padding: "12px 12px" }}
                          onClick={() => {
                            this.setState({ modify: !this.state.modify });
                          }}
                        >
                          <i
                            className="fas fa-pencil-alt"
                            style={{
                              width: 16,
                              marginRight: 8,
                              fontSize: "0.8rem",
                              color: "grey",
                              textAlign: "center",
                            }}
                          />
                          Edit time on project
                        </button>

                        <button
                          id="dropMenuItem"
                          className="dropdown-item"
                          style={{ padding: "14px 14px" }}
                          onClick={this.deleteUser.bind(this, y)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              width: 16,
                              marginRight: 8,
                              fontSize: "0.8rem",
                              color: "red",
                              textAlign: "center",
                            }}
                          />
                          Remove
                          {this.props.gridDescriptor
                            ? " from " + this.props.gridDescriptor
                            : ""}
                        </button>
                      </>
                    )}
                  </>
                </div>
              </>
            )}

            <button
              onClick={this.gotoUrl(y)}
              style={{
                fontSize: "0.75rem",
                marginLeft: -6,
                lineHeight: "80%",
                fontWeight: "500",
                height: this.props.boxWidth,
                width: this.props.nameCol + 28,
                textAlign: "left",
                verticalAlign: "middle",
                background: this.state.hoveringRow === y ? "#888" : "#aaa",
                borderRadius: 4,
                border: this.props.data[y].consultant ? "2px solid #dfdfdf" : 0,
                zIndex: 101,
                paddingLeft: 14,
                cursor: "pointer",
              }}
              onMouseOver={this.hoverRow.bind(this, y)}
              onMouseOut={this.hoverRow.bind(this, null)}
            >
              <div
                style={{
                  display: "inline-block",
                  color: "#000",
                }}
              >
                {y === this.props.myIndex && (
                  <div
                    className="fas fa-user"
                    style={{ fontSize: "0.6rem", color: "#000" }}
                  />
                )}
                {data.email === "none" && (
                  <div style={{ color: "red", display: "inline" }}>!</div>
                )}
                {"   " + fullName}
              </div>
            </button>
          </div>
        );

        if (showRow) peepz.push(peep);
        else peepzLeft.push(peep);

        // New group or not, for padding
        if (showRow) {
          prevGroup = data.discipline;
          row++;
        }
      }

      peepzLeft.push(
        <div
          key={row + "filler"}
          style={{
            position: "absolute",
            top: row * this.props.boxWidth * 3,
            height: 50,
          }}
        />
      );
    }

    var height =
      ribbonsHeight +
      this.props.monthHeight +
      this.state.daysHeight +
      groupPad +
      row * (this.props.boxWidth + this.props.boxSpaceVert);

    this.lefts = lefts;
    this.leftsNoWeekend = leftsNoWeekend;
    this.weekday = weekday;

    return (
      <>
        <CommandBar
          show={this.state.commandbarShowing}
          submitCb={this.submitDaysInternal}
          cancelCb={this.cancelSelection}
          auth={this.props.auth}
        />

        {needArrows && (
          <>
            <div
              style={{
                position: "absolute",
                top: this.props.arrowsOffset
                  ? 80 + this.props.arrowsOffset
                  : 80,
                left: this.props.nameCol / 2 - 25,
                fontSize: "2.2rem",
                color: "#bbb",
                zIndex: 1000,
                cursor: "pointer",
              }}
              onClick={this.navigateLeft}
            >
              <i
                className="fas fa-arrow-circle-left"
                style={{ color: "#cccccc3c", borderRadius: "50%" }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: this.props.arrowsOffset
                  ? 80 + this.props.arrowsOffset
                  : 80,
                left: this.props.nameCol / 2 + 11,
                fontSize: "2.2rem",
                color: "#bbb",
                zIndex: 1000,
                cursor: "pointer",
              }}
              onClick={this.navigateRight}
            >
              <i
                className="fas fa-arrow-circle-right"
                style={{ color: "#cccccc3c", borderRadius: "50%" }}
              />
            </div>
          </>
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            marginTop: ribbonsHeight + 30,
            height: height + 100,
          }}
          ref={this.timegridContainerFunc}
        >
          {this.props.loading && (
            <>
              <center>
                <br />
                <br />
                <>
                  <img className="logo" src={logo} alt="" />
                  <h2 style={{ color: "#cccccc", fontStyle: "italic" }}>
                    loading...
                  </h2>
                </>
              </center>
            </>
          )}
          {!this.props.loading && emptyOrg && (
            <center>
              <>
                <h2 style={{ color: "#cccccc" }}>Empty team</h2>
                {this.props.auth.requiresRole("janitor") && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.clickAdd("person");
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      width: 40,
                      height: 40,
                      borderRadius: 5,
                      fontSize: 16,
                    }}
                  >
                    <div className="fas fa-plus" />
                  </button>
                )}
              </>
            </center>
          )}
          {!this.props.loading && !emptyOrg && (
            <>
              <>
                <div
                  style={{
                    display: todayLeft ? "initial" : "none",
                  }}
                >
                  <div
                    onClick={this.navigateTo.bind(this, todaysIndex - 7)}
                    style={{
                      left: 90,
                      top: -15,
                      height: "20px",
                      fontSize: "11px",
                      padding: "2px 5px",
                      paddingRight: "10px",

                      color: "#fff",
                      fontWeight: 500,
                      fontStyle: "italic",
                      textShadow: "1px 1px 0px rgba(0, 0, 0, 0.5)",

                      backgroundColor: "#cc0000",
                      position: "absolute",
                      cursor: "pointer",
                      zIndex: 900,
                    }}
                  >
                    TODAY
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: -20,
                        width: 20,
                        height: 0,
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid transparent",
                        borderRight: "10px solid #cc0000",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: todayRight ? "initial" : "none",
                  }}
                >
                  <div
                    onClick={this.navigateTo.bind(this, todaysIndex - 7)}
                    style={{
                      left: "93%",
                      top: -15,
                      height: "20px",
                      fontSize: "11px",
                      padding: "2px 5px",
                      paddingLeft: "10px",

                      color: "#fff",
                      fontWeight: 500,
                      fontStyle: "italic",
                      textShadow: "1px 1px 0px rgba(0, 0, 0, 0.5)",

                      backgroundColor: "#cc0000",
                      position: "absolute",
                      cursor: "pointer",
                      zIndex: 900,
                    }}
                  >
                    TODAY
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 52,
                        width: 20,
                        height: 0,

                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid transparent",
                        borderLeft: "10px solid #cc0000",
                      }}
                    />
                  </div>
                </div>
              </>

              <div
                style={{
                  position: "relative",
                  marginTop: ribbonsHeight > 130 ? ribbonsHeight - 110 : 0,
                }}
              >
                {months}
                {days}
                {ribbons}
                {peepz}
                {grid}
              </div>
              {!this.props.showAllRows && peepz && (
                <div
                  onClick={() => {
                    this.setState({
                      filterAvailRows: !this.state.filterAvailRows,
                    });
                  }}
                  style={{
                    padding: "5px 8px",
                    width: 40,
                    height: 22,
                    position: "absolute",
                    top: peepz.length * (this.props.boxWidth + 0.8) + 80,
                    left: -15,
                    borderRadius: 3,
                    background: "#cccccc3c",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    verticalAlign: "middle",
                    zIndex: 49,
                    cursor: "pointer",
                  }}
                >
                  <div className="fas fa-filter" />
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default TimeGrid;
