import React, { useState, useEffect, useCallback } from "react";
import Popup from "../components/Popup";
import AttrCard from "../components/AttrCard";
import db from "../utils/db";
import { toast } from "react-toastify";
import history from "../utils/history";

export default function UserOverview(props) {
  const [attributes, setAttributes] = useState(null);
  const [editing, setEditing] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const getAttributesCb = useCallback(getAttributes);

  useEffect(() => {
    props.dispatch.addListener("inspectuser", getAttributesCb);

    return () => {
      props.dispatch.removeListener("inspectuser", getAttributesCb);
    };
  }, []);

  useEffect(() => {
    getAttributesCb(props.userid);
  }, [props.userid]);

  // Functions xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  async function getAttributes(userid) {
    const resAttr = await db.request("/auth/getattributes", "POST", {
      userid: userid ? userid : props.userid,
    });

    const resDiscs = await db.request("/auth/getdisciplines", "POST", {
      org: props.auth.getOrgId(),
    });

    var disciplines = [""];
    var discByID = {};
    if (resDiscs && resDiscs.status === 200 && resDiscs.data.length > 0) {
      for (var i = 0; i < resDiscs.data.length; i++) {
        disciplines.push({
          value: resDiscs.data[i].set_id,
          label: resDiscs.data[i].set_name,
        });
        discByID[resDiscs.data[i].set_id] = resDiscs.data[i].set_name;
      }
    }

    // Sort attributes
    var tmpData = {
      basics: {
        title: "Basics",
        data: {},
        access: "talent",
      },
      address: {
        title: "Address",
        data: {
          street: "",
          zip: "",
          city: "",
          country: "",
        },
        access: "janitor",
      },
      position: {
        title: "Position",
        data: {
          position: "",
        },
        access: "talent",
      },
      role: {
        title: "Role",
        values: disciplines,
        data: {
          role: "",
        },
        access: "talent",
        empty: "Add Roles in Settings",
      },
      salary: {
        title: "Salary",
        data: {
          salary: "",
        },
        postfix: "kr",
        access: "owner",
      },
      personum: {
        title: "Personal Number",
        data: {
          number: "",
        },
        access: "owner",
      },
      mobile: {
        title: "Mobile",
        data: {
          number: "",
        },
        access: "talent",
      },
      bank: {
        title: "Bank",
        data: {
          bank: "",
          account: "",
        },
        access: "owner",
      },
      shirtsize: {
        title: "Shirt Size",
        data: {
          shirtsize: "",
        },
        access: "janitor",
      },
    };

    if (resAttr && resAttr.status === 200) {
      tmpData.basics.data.firstname = resAttr.data[0].user_firstname;
      tmpData.basics.data.lastname = resAttr.data[0].user_lastname;
      tmpData.basics.data.email = resAttr.data[0].user_email;

      resAttr.data.forEach((val, i) => {
        var tmp = tmpData[val.attr_type] ? tmpData[val.attr_type].data : "";
        switch (val.attr_type) {
          case "address":
            var address = val.attr_value.split(";");
            tmp.street = address[0];
            tmp.zip = address[1];
            tmp.city = address[2];
            tmp.country = address[3];
            break;
          case "position":
            tmp.position = val.attr_value;
            break;
          case "role":
            tmp.role = discByID[val.attr_value]
              ? discByID[val.attr_value]
              : val.attr_value;
            break;
          case "salary":
            tmp.salary = val.attr_value;
            break;
          case "personum":
            tmp.number = val.attr_value;
            break;
          case "mobile":
            tmp.number = val.attr_value;
            break;
          case "bank":
            var bank = val.attr_value.split(";");
            tmp.bank = bank[0];
            tmp.account = bank[1];
            break;
          case "shirtsize":
            tmp.shirtsize = val.attr_value;
            break;
          default:
        }
      });

      setAttributes(tmpData);
    } else {
      history.push("/user");
    }
  }

  async function submit(e, key, data) {
    e.preventDefault();
    var resChanges = null;
    if (key === "basics") {
      var split = data.split(";");
      resChanges = await db.request("/auth/updateuser", "POST", {
        userid: props.userid,
        firstname: split[0],
        lastname: split[1],
        email: split[2],
      });
      if (props.userid === props.auth.getUserId()) {
        props.auth.setUser(data.split(";")[0], data.split(";")[1]);
      }
      props.dispatch.fire("userupdate");
    } else {
      resChanges = await db.request("/auth/setattr", "POST", {
        userid: props.userid,
        key: key,
        data: data,
      });
    }

    if (resChanges.status === 200) {
      toast.success("User info! It's done.");
    } else {
      toast.error("Error updating user! Please try again later.");
    }

    getAttributesCb();
  }

  function editKey(key) {
    var to = editing === key ? "" : key;
    setEditing(to);
    if (to) document.addEventListener("keydown", handleKey, false);
    else document.removeEventListener("keydown", handleKey, false);
  }

  function handleKey(e) {
    if (e.keyCode === 27) editKey(""); // Escape
  }

  async function deleteUser() {
    const res = await db.request("/auth/deleteuser", "POST", {
      id: props.userid,
      orgid: props.auth.getOrgId(),
    });

    if (res.status === 200) {
      toast.success("User ejected! It's done.");
      history.push("/peepz");
    } else if (res.status === 505) {
      toast.warning(
        "The user is the last owner account. There has to be at least one owner."
      );
    } else {
      toast.error("Error deleting user! Please try again later.");
    }

    setConfirmDelete(false);
  }

  var cards = null;
  if (attributes) {
    cards = Object.entries(attributes).map(([key, value]) => {
      if (props.auth.requiresRole(attributes[key].access) || props.isMe) {
        return (
          <AttrCard
            key={key}
            datakey={key}
            values={attributes[key].values ? attributes[key].values : null}
            data={attributes[key]}
            editing={editing === key}
            onEdit={editKey}
            onSubmit={submit}
            auth={props.auth}
            isMe={props.isMe}
            postfix={attributes[key].postfix}
          />
        );
      } else {
        return "";
      }
    });
  }

  return (
    <>
      {confirmDelete && (
        <Popup
          title="Confirm delete"
          closeCallback={() => {
            setConfirmDelete(false);
          }}
          submitCallback={() => {
            deleteUser(props.userid);
          }}
          negativeAction="Nevermind"
          positiveAction="YES, DO IT"
        >
          Are you sure you want to completely remove <b>{props.name}</b> from
          organization <b>{props.auth.getOrgName()}</b>?
        </Popup>
      )}

      <div
        style={{
          display: "inline-flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          margin: 6,
        }}
      >
        {cards}
      </div>
    </>
  );
}
