import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import Popup from "./Popup";
import Pill from "./Pill";
import InputDropdown from "./InputDropdown";
import ButtonDropdown from "./ButtonDropdown";
import Button from "./Button";
import history from "../utils/history";
import db from "../utils/db";
import cloudinary from "cloudinary-core";
import spinner from "../imgs/kitty.png";
import { createEditor, Editor, Transforms, Node } from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { withHistory } from "slate-history";
import ReactTooltip from "react-tooltip";
import Colors from "../utils/colors";

var cl = new cloudinary.Cloudinary({ cloud_name: "hcnr7jxqk" });

// Temp debug for production bughunting
var interval = null;

export default function EditCard(props) {
  const name = {
    pillar: "Name of pillar",
    epic: "Epic: Larger feature as part of pillar",
    story: "Sprintgoal: What is it you want to achieve?",
    task: "Task: The work that will fulfil the sprintgoal",
  };
  const desc = {
    pillar: "Reason why this is an important pillar for the game",
    epic: "Description of how this Epic will increase quality for its pillar?",
    story: "A good sprint goal to focus on for the team",
    task: "The real work improving a pillar of the Game.",
  };
  const estTypes = [
    { name: "days", short: "d" },
    { name: "hours", short: "h" },
    { name: "weeks", short: "w" },
    { name: "months", short: "m" },
  ];
  const prioTypes = [
    { name: "Low", color: Colors.low },
    { name: "Medium", color: Colors.medium },
    { name: "High", color: Colors.high },
    { name: "Critical", color: Colors.critical },
  ];
  const standardStatus = [
    {
      name: "At Risk",
      desc: "The project is in serious trouble due to this",
      color: "#ff0000",
    },
    {
      name: "Slipping",
      desc: "The project might slip deadlines if issue is not rectified soon",
      color: "#ff9900",
    },
    {
      name: "On Track",
      desc: "Everything is looking good as according to plan",
      color: "#00ff00",
    },
  ];

  const [cardName, setCardName] = useState(props.data.card_name);
  const cardBody = useRef(
    props.data.card_body && isJSON(props.data.card_body)
      ? JSON.parse(props.data.card_body)
      : [
          {
            type: "paragraph",
            children: [
              { text: props.data.card_body ? props.data.card_body : "" },
            ],
          },
        ]
  );

  const [focus, setFocus] = useState(0);
  const [ownerIdx, setOwnerIdx] = useState(-1);
  const [goalIdx, setGoalIdx] = useState(-1);
  const [discIdx, setDiscIdx] = useState(-1);
  const [github, setGithub] = useState(
    props.data.card_github ? JSON.parse(props.data.card_github) : null
  );
  const [status, setStatus] = useState(null);
  const [estimate, setEstimate] = useState(props.data.card_estimate);
  const [prio, setPrio] = useState(props.data.card_priority - 1);
  const [estTypeIdx, setEstTypeIdx] = useState(-1);
  const [images, setImages] = useState(JSON.parse(props.data.card_images));
  const [commits, setCommits] = useState(null);
  const [hoveringImg, setHoveringImg] = useState(null);
  const [viewImg, setViewImg] = useState(null);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [editDods, setEditDods] = useState(false);
  const [myChanges, setMyChanges] = useState(false);
  const [tooltip, setTooltip] = useState("");

  const nameInput = useRef();
  const ownerInput = useRef();
  const goalInput = useRef();
  const commitDropdown = useRef();
  const discInput = useRef();
  const estInput = useRef();
  const prioInput = useRef();
  const bodyRef = useRef();
  const imageAdd = useRef();
  const img = useRef();
  const timer = useRef();

  const cardNameRef = useRef();
  const ownerIdxRef = useRef();
  const goalIdxRef = useRef();
  const discIdxRef = useRef();
  const estimateRef = useRef();
  const prioTaskRef = useRef();
  const estTypeIdxRef = useRef();
  const githubRef = useRef();
  const focusRef = useRef();
  const initialMount = useRef(true);

  const deleteCardCb = useCallback(deleteCard);

  const components = [
    nameInput,
    discInput,
    ownerInput,
    estInput,
    prioInput,
    goalInput,
    "stories",
    "description",
  ];

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    timer.current = null;

    return () => {
      document.removeEventListener("keydown", handleKey);
      if (timer.current) clearTimeout(timer.current);

      submit();
    };
  }, []);

  useEffect(() => {
    if (!props.id) props.closeCallback();
  }, [props.id]);

  useEffect(() => {
    cardNameRef.current = cardName;
  }, [cardName]);

  useEffect(() => {
    ownerIdxRef.current = ownerIdx;
    goalIdxRef.current = goalIdx;
    discIdxRef.current = discIdx;
    estimateRef.current = estimate;
    estTypeIdxRef.current = estTypeIdx;
    prioTaskRef.current = prio;
    githubRef.current = github;
    if (!initialMount.current && myChanges) {
      submit();
      setMyChanges(false);
    }
  }, [discIdx, ownerIdx, estTypeIdx, estimate, goalIdx, github, prio]);

  useEffect(() => {
    var _status = JSON.parse(props.data.card_status);
    if (!_status) {
      _status = {};
      _status.trackWithCards = true;
    }
    setStatus(_status);
  }, [props.data.card_status]);

  useEffect(() => {
    setImages(JSON.parse(props.data.card_images));
  }, [props.data.card_images]);

  useEffect(() => {
    if (!initialMount.current && !timer.current) {
      if (props.data.card_name !== cardNameRef.current) {
        setCardName(props.data.card_name);
      }
      if (props.data.card_body !== cardBody.current && !myChanges) {
        cardBody.current = isJSON(props.data.card_body)
          ? JSON.parse(props.data.card_body)
          : [
              {
                type: "paragraph",
                children: [{ text: props.data.card_body }],
              },
            ];
      }
    }
  }, [props.data.card_name, props.data.card_body]);

  useEffect(() => {
    if (props.users)
      setOwnerIdx(
        props.users.findIndex((val) => val.user_id === props.data.card_owner)
      );
  }, [props.data.card_owner]);

  useEffect(() => {
    if (props.goals)
      setGoalIdx(
        props.goals.findIndex((val) => val.goal_id === props.data.card_goal)
      );
  }, [props.data.card_goal]);

  useEffect(() => {
    setPrio(props.data.card_priority - 1);
  }, [props.data.card_priority]);

  useEffect(() => {
    if (props.disciplines)
      setDiscIdx(
        props.disciplines.findIndex((val) => {
          return val.set_id === props.data.card_discipline;
        })
      );
  }, [props.data.card_discipline]);

  useEffect(() => {
    setEstTypeIdx(
      estTypes.findIndex((val) => val.name === props.data.card_estimate_type)
    );
  }, [props.data.card_estimate_type]);

  useEffect(() => {
    setEstimate(props.data.card_estimate);
  }, [props.data.card_estimate]);

  useEffect(() => {
    focusRef.current = focus;

    if (initialMount.current) {
      initialMount.current = false;
    }
  }, [focus]);

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Functions

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async function getCommits() {
    const resCommits = await db.request("/auth/getrepocommits", "POST", {
      token: props.github.token,
      repo: props.github.repo,
    });
    setCommits(resCommits.status === 200 ? resCommits.data : ["fail"]);
  }

  function handleKey(e) {
    if (e.key === "Enter") {
      // Check possible input field actions before incrementing focus
      if (e.metaKey) {
        submit();
        close();
      } else if (e.shiftKey) {
        if (props.addCard) {
          props.addCard("story", props.id);
        }
      } else {
        for (var i = focusRef.current; i < components.length; i++) {
          if (components[i + 1] && components[i + 1].current) {
            e.preventDefault();
            components[i + 1].current.focus();
            setFocus(i + 1);
            break;
          } else if (components[i + 1] === "stories") {
            if (
              props.data.card_type === "epic" &&
              props.data.children.length > 0
            )
              break;
          } else if (components[i + 1] === "description") {
            e.preventDefault();
            setFocus(i + 1);
            bodyRef.current.focus();
            break;
          }
        }
      }
    } else if (e.key === "Escape") {
      if (viewImg) {
        setViewImg(null);
      } else {
        close();
      }
    }
  }

  function submit() {
    if (props.submitCallback)
      props.submitCallback({
        id: props.id,
        name: cardNameRef.current,
        desc: cardBody.current ? JSON.stringify(cardBody.current) : "",
        owner:
          ownerIdxRef.current > -1
            ? props.users[ownerIdxRef.current].user_id
            : -1,
        goal:
          goalIdxRef.current > -1
            ? props.goals[goalIdxRef.current].goal_id
            : null,
        disc:
          discIdxRef.current !== null &&
          discIdxRef.current !== undefined &&
          discIdxRef.current > -1
            ? props.disciplines[discIdxRef.current].set_id
            : -1,
        estimate: estimateRef.current,
        estType:
          estTypeIdxRef.current !== null &&
          estTypeIdxRef.current !== undefined &&
          estTypeIdxRef.current > -1
            ? estTypes[estTypeIdxRef.current].name
            : null,
        github: githubRef.current ? JSON.stringify(githubRef.current) : null,
        priority: prioTaskRef.current > -1 ? prioTaskRef.current + 1 : -1,
      });
    timer.current = null;
    clearInterval(interval);
    interval = null;
  }

  function setCardStatus(status) {
    if (props.submitCallback)
      props.submitCallback({ id: props.id, status: status });
  }

  function close() {
    if (!cardNameRef.current) {
      deleteCardCb();
    } else {
      props.closeCallback();
    }
  }

  function deleteCard() {
    props.deleteCallback(props.id);
  }

  function setFocusElement(idx) {
    setFocus(idx);
  }

  async function onFileSubmit(files) {
    const url = `https://api.cloudinary.com/v1_1/hcnr7jxqk/upload`;
    var reader = new FileReader();

    for (let file of files) {
      if (props.useLocal) {
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          var product = JSON.parse(localStorage.getItem("product"));
          const idx = product.findIndex((val) => val.card_id === props.id);
          var _images = [];

          if (product[idx].card_images) {
            _images = JSON.parse(product[idx].card_images);
          }

          _images.push(reader.result);
          product[idx].card_images = JSON.stringify(_images);

          localStorage.setItem("product", JSON.stringify(product));

          submit();
          // forceUpdate();
        };
      } else {
        var xhr = new XMLHttpRequest();
        var fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        xhr.onreadystatechange = fileUploaded.bind(this, xhr);

        fd.append("upload_preset", "ircdcrqm");
        fd.append("tags", "card"); // Optional - add tag for image admin in Cloudinary
        fd.append("multiple", true);
        fd.append("file", file);
        xhr.send(fd);
      }
    }
  }

  async function fileUploaded(xhr) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // File uploaded successfully
      var response = JSON.parse(xhr.responseText);
      const resUpload = await db.request("/auth/setcardimg", "POST", {
        cardId: props.id,
        newimg: response.public_id,
      });

      if (resUpload.status === 200) {
        // setImage(response.public_id);
        setUploadingImg(false);
        submit();
      }
    }
  }

  async function deletePic(val, idx) {
    if (props.useLocal) {
      var product = JSON.parse(localStorage.getItem("product"));
      const prodIdx = product.findIndex((val) => val.card_id === props.id);
      var _images = JSON.parse(product[prodIdx].card_images).filter(
        (img, i) => {
          return i !== idx;
        }
      );

      product[prodIdx].card_images = JSON.stringify(_images);
      localStorage.setItem("product", JSON.stringify(product));
      submit();
    } else {
      const resDel = await db.request("/auth/setcardimg", "POST", {
        cardId: props.id,
        removeimg: val,
      });

      if (resDel.status === 200) {
        submit();
      }
    }
  }

  async function sendCommitComment(sha) {
    const cardid = props.data.card_id;
    await db.request("/auth/sendcommitcomment", "POST", {
      token: props.github.token,
      repo: props.github.repo,
      sha: sha,
      url: "https://www.peepz.io/sprint/" + cardid,
    });
  }

  function setTimer() {
    if (timer.current) clearTimeout(timer.current);
    if (interval) {
      clearInterval(interval);
    }
    timer.current = setTimeout(submit, 5000);
  }

  const setBody = useCallback((val) => {
    cardBody.current = val;
    setTimer();
  });

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // Render

  var _est = null;
  if (estimate && estTypeIdx > -1) {
    _est = estimate;
    switch (estTypes[estTypeIdx].name) {
      case "months":
        _est /= 21 * 8;
        break;
      case "weeks":
        _est /= 5 * 8;
        break;
      case "days":
        _est /= 8;
        break;
      default:
    }
  }

  const discColor =
    props.disciplines &&
    props.disciplines[discIdx] &&
    props.disciplines[discIdx].set_value
      ? props.disciplines[discIdx].set_value
      : "green";

  const Story = memo((props) => {
    const storyEditor = useMemo(() => withReact(createEditor()), []);
    const LIST_TYPES = ["numbered-list", "bullet", "bullet-indent"];
    const CustomEditor = {
      isMarkActive(storyEditor, format) {
        const marks = Editor.marks(storyEditor);
        return marks ? marks[format] === true : false;
      },

      isBlockActive(storyEditor, format) {
        const [match] = Editor.nodes(storyEditor, {
          match: (n) => n.type === format,
        });

        return !!match;
      },

      toggleMark(storyEditor, format) {
        const isActive = CustomEditor.isMarkActive(storyEditor, format);

        if (isActive) {
          Editor.removeMark(storyEditor, format);
        } else {
          Editor.addMark(storyEditor, format, true);
        }
        setMyChanges(true);
        submit();
      },

      toggleBlock(storyEditor, format) {
        const isActive = CustomEditor.isBlockActive(storyEditor, format);
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(storyEditor, {
          match: (n) => LIST_TYPES.includes(n.type),
          split: true,
        });

        Transforms.setNodes(storyEditor, {
          type: isActive ? "paragraph" : isList ? "list-item" : format,
        });

        if (!isActive && isList) {
          const block = { type: format, children: [] };
          Transforms.wrapNodes(storyEditor, block);
        }
      },
    };
    const renderElement = useCallback((props) => {
      switch (props.element.type) {
        case "bullet":
          return <ul {...props.attributes}>{props.children}</ul>;
        case "bullet-indent":
          return (
            <ul>
              <ul {...props.attributes}>{props.children}</ul>
            </ul>
          );
        case "list-item":
          return <li {...props.attributes}>{props.children}</li>;
        default:
          return <DefaultElement {...props} />;
      }
    }, []);
    const renderLeaf = useCallback((props) => {
      return <Leaf {...props} />;
    }, []);
    const DefaultElement = (props) => {
      return <div {...props.attributes}>{props.children}</div>;
    };
    const Leaf = ({ attributes, children, leaf }) => {
      if (leaf.link) {
        return (
          <span
            {...attributes}
            className="link"
            onClick={(e) => {
              e.preventDefault();
              var url = leaf.text;
              if (url.substr(0, 3).toLowerCase() === "www") {
                url = "http://" + url;
              }
              window.open(url, "_blank");
            }}
            style={{ cursor: "pointer" }}
          >
            {children}
          </span>
        );
      }
      if (leaf.bold) {
        children = <strong>{children}</strong>;
      }
      if (leaf.italic) {
        children = <em>{children}</em>;
      }
      if (leaf.underlined) {
        children = <u>{children}</u>;
      }

      return <span {...attributes}>{children}</span>;
    };
    const serialize = (nodes) => {
      return nodes.map((n) => Node.string(n)).join("");
    };

    const bgs = ["green", "lightgreen", "yellow", "orange", "red"];
    const colors = ["white", "black", "black", "white", "white"];
    const [storyName, setStoryName] = useState(props.name ? props.name : "");
    const [storyDesc, setStoryDesc] = useState(
      props.desc && isJSON(props.desc)
        ? JSON.parse(props.desc)
        : [
            {
              type: "paragraph",
              children: [{ text: props.desc ? props.desc : "" }],
            },
          ]
    );

    const priorityRef = useRef();
    const sizeRef = useRef();

    function submitStory(name, desc) {
      var data = { id: props.id };
      if (name !== undefined) data.name = name;
      if (desc !== undefined) data.desc = JSON.stringify(desc);

      props.submit(data, true);
    }

    function submitPriority(val) {
      props.submit({ id: props.id, priority: val });
    }

    function submitSize(val) {
      props.submit({ id: props.id, estimate: val });
    }

    return (
      <div
        className="slate"
        style={{
          position: "relative",
          borderRadius: 8,
          padding: "6px 10px",
          background: "#ddd",
          marginBottom: 4,
        }}
      >
        <input
          value={storyName}
          onChange={(e) => {
            setStoryName(e.target.value);
            submitStory(e.target.value);
          }}
          placeholder="Story name"
          style={{
            background: "transparent",
            border: 0,
            outline: "none",
            width: "76%",
          }}
        />
        <div style={{ float: "right", marginTop: -2, marginRight: -4 }}>
          <InputDropdown
            ref={priorityRef}
            data={[
              { name: "Very Low" },
              { name: "Low" },
              { name: "Medium" },
              { name: "High" },
              { name: "Very High" },
            ]}
            selected={props.priority !== null ? props.priority - 1 : -1}
            placeholder={"Priority"}
            submitCb={(idx) => {
              submitPriority(idx + 1);
            }}
            pillStyle={bgs.map((val, m) => {
              return {
                background: val,
                padding: "2px 5px",
                borderRadius: 4,
                color: colors[m],
                border: "1px solid " + val,
              };
            })}
          />
          <InputDropdown
            ref={sizeRef}
            data={[
              { name: "XSmall" },
              { name: "Small" },
              { name: "Medium" },
              { name: "Large" },
              { name: "XLarge" },
            ]}
            selected={props.size !== null ? props.size - 1 : -1}
            placeholder={"Size"}
            submitCb={(idx) => {
              submitSize(idx + 1);
            }}
            pillStyle={bgs.map((val, m) => {
              return {
                background: val,
                padding: "2px 5px",
                borderRadius: 4,
                color: colors[m],
                border: "1px solid " + val,
              };
            })}
          />
        </div>
        {storyDesc &&
        serialize(storyDesc).length === 0 &&
        !(storyDesc.length === 1 && storyDesc[0].type === "bullet") ? (
          <div
            style={{
              position: "absolute",
              fontSize: 14,
              top: 34,
              left: 12,
              color: "#666",
              pointerEvents: "none",
            }}
          >
            Notes here
          </div>
        ) : (
          <></>
        )}
        <Slate
          editor={storyEditor}
          value={
            storyDesc === null
              ? [
                  {
                    type: "paragraph",
                    children: [{ text: props.desc ? props.desc : "" }],
                  },
                ]
              : storyDesc
          }
          onChange={(newValue) => {
            if (newValue !== storyDesc) {
              setStoryDesc(newValue);
              submitStory(undefined, newValue);
            }
          }}
        >
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(e) => {
              if (e.ctrlKey || e.metaKey) {
                if (e.key === "b") {
                  e.preventDefault();
                  CustomEditor.toggleMark(storyEditor, "bold");
                } else if (e.key === "Backspace") {
                  e.preventDefault();
                }
              } else if (e.shiftKey) {
                if (e.key === "*") {
                  e.preventDefault();
                  CustomEditor.toggleBlock(storyEditor, "bullet");
                }
              } else if (
                e.key === "Backspace" ||
                e.key === "Enter" ||
                e.key === " "
              ) {
                if (
                  CustomEditor.isBlockActive(storyEditor, "bullet") &&
                  storyEditor.selection.anchor.offset === 0
                ) {
                  e.preventDefault();
                  CustomEditor.toggleBlock(storyEditor, "bullet");
                  storyEditor.insertText("");
                } else if (
                  (e.key === "Enter" || e.key === " ") &&
                  CustomEditor.isMarkActive(storyEditor, "link")
                ) {
                  Editor.removeMark(storyEditor, "link");
                }
              }
            }}
          />
        </Slate>
      </div>
    );
  });

  const submitWrapped = useCallback((data) => {
    props.submitCallback(data);
  });

  return (
    <>
      {viewImg && (
        <>
          <center>
            <img
              ref={img}
              src={props.useLocal ? viewImg : cl.url(viewImg)}
              alt="Card cap"
              style={{
                position: "absolute",
                top: window.scrollY + 80,
                left: "50%",
                transform: "translate(-50%, 0)",
                maxWidth: "60%",
                zIndex: 7001,
              }}
            />
          </center>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "#000",
              opacity: 0.9,
              zIndex: 7000,
            }}
            onClick={() => {
              setViewImg(null);
            }}
          />
        </>
      )}

      <Popup
        show={props.id !== null}
        cancelOutside={true}
        closeCallback={close}
        deleteCallback={deleteCardCb}
        large={true}
      >
        {props.data.card_type === "pillar" && (
          <>
            <div
              style={{
                position: "absolute",
                background: "transparent",
                width: 0,
                height: 0,
                top: 66,
                left: -36,
                borderTop: "6px solid #115511",
                borderLeft: "36px solid transparent",
              }}
            />
            <div
              style={{
                position: "absolute",
                padding: "4px 14px",
                background: "green",
                borderRadius: 4,
                color: "#87e687",
                top: 36,
                left: -38,
                fontSize: 15,
                boxShadow: "1px 1px 2px #0a2f0a",
              }}
            >
              Pillar
            </div>
          </>
        )}

        {props.data.card_type === "epic" && (
          <>
            <div
              style={{
                position: "absolute",
                background: "transparent",
                width: 0,
                height: 0,
                top: 66,
                left: -36,
                borderTop: "6px solid #523601",
                borderLeft: "36px solid transparent",
              }}
            />
            <div
              style={{
                position: "absolute",
                padding: "4px 14px",
                background: "#ffa10f",
                borderRadius: 4,
                color: "#523601",
                top: 36,
                left: -38,
                fontSize: 15,
                boxShadow: "1px 1px 2px #523601",
              }}
            >
              Epic
            </div>
          </>
        )}

        {props.data.card_type === "story" && (
          <>
            <div
              style={{
                position: "absolute",
                background: "transparent",
                width: 0,
                height: 0,
                top: 66,
                left: -36,
                borderTop: "6px solid #620183",
                borderLeft: "36px solid transparent",
              }}
            />
            <div
              style={{
                position: "absolute",
                padding: "4px 14px",
                background: "#c30fff",
                borderRadius: 4,
                color: "#620183",
                top: 36,
                left: -38,
                fontSize: 15,
                boxShadow: "1px 1px 2px #620183",
              }}
            >
              Story
            </div>
          </>
        )}

        {props.data.card_type === "task" && (
          <>
            <div
              style={{
                position: "absolute",
                background: "transparent",
                width: 0,
                height: 0,
                top: 66,
                left: -36,
                borderTop: "6px solid #444",
                borderLeft: "36px solid transparent",
              }}
            />
            <div
              style={{
                position: "absolute",
                padding: "4px 14px",
                background: "#b3b3b3",
                borderRadius: 4,
                color: "#fff",
                top: 36,
                left: -38,
                fontSize: 15,
                boxShadow: "1px 1px 2px #444",
              }}
            >
              Task
            </div>
          </>
        )}

        <div
          className="tinytext"
          style={{ position: "absolute", color: "#777", left: 30, top: 16 }}
        >
          {props.parent &&
            props.data.card_type !== "pillar" &&
            props.data.card_type !== "standard" &&
            props.id + " - " + props.parent + "/"}
        </div>

        {/* Card title input field */}
        <input
          ref={nameInput}
          className="form-control form-control-lg"
          placeholder={props.data ? name[props.data.card_type] : ""}
          value={cardName === null ? "" : cardName}
          disabled={props.disabled}
          style={{
            color: "#111",
            background: "white",
            fontSize: 40,
            width: "99%",
            border: 0,
            boxShadow: "none",
            padding: "0px 8px",
            fontWeight: 600,
          }}
          autoFocus={true}
          onChange={(e) => {
            setCardName(e.target.value);
            setTimer();
          }}
          onFocus={() => {
            setFocusElement(0);
          }}
        />

        <div style={{ display: "flex", flexFlow: "wrap" }}>
          <div
            style={{
              width: "100%",
              minWidth: 250,
              color: "#000",
              paddingLeft: 12,
            }}
          >
            {
              // Definition of Done
              props.data &&
                props.data.card_type === "epic" &&
                !props.useLocal &&
                props.dods && (
                  <>
                    {props.data.card_type === "epic" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#777",
                          fontWeight: 700,
                        }}
                      >
                        Status
                        <div
                          className={
                            editDods ? "fas fa-pen btn-modify" : "fas fa-pen"
                          }
                          style={{
                            fontSize: 12,
                            color: editDods ? "#fff" : "#ccc",
                            marginLeft: 8,
                            cursor: "pointer",
                            padding: 4,
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            setEditDods((val) => !val);
                          }}
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: 20 }}>
                      {props.data.card_type === "epic" &&
                        props.dods.map((disc, i) => {
                          const hasDods = disc.dods && disc.dods.length > 0;

                          if (disc.set_type !== "discipline") return null;
                          if (!editDods) {
                            if (!hasDods) return null;
                            if (
                              status &&
                              status[disc.set_id] &&
                              status[disc.set_id][1] === -1
                            )
                              return null;
                          }

                          return (
                            <div key={i} style={{ marginLeft: 20 }}>
                              <div
                                style={{
                                  display: "inline-flex",
                                  marginBottom: 0,
                                  fontSize: 11,
                                  color: "#000",
                                }}
                              >
                                <b>{disc.set_name}</b>
                              </div>

                              <div
                                style={{
                                  display: "inline-flex",
                                  marginBottom: editDods ? 6 : 0,
                                  marginLeft: 4,
                                }}
                              >
                                {hasDods ? (
                                  disc.dods.map((dod, j) => {
                                    const isEnabled =
                                      !status ||
                                      (status && !status[disc.set_id]) ||
                                      (status &&
                                        status[disc.set_id] &&
                                        j <= status[disc.set_id][1]) ||
                                      (status[disc.set_id] &&
                                        typeof status[disc.set_id] ===
                                          "number");

                                    var opacity =
                                      (status &&
                                        status[disc.set_id] &&
                                        status[disc.set_id][0] >= j) ||
                                      editDods ||
                                      (status && status[disc.set_id] >= j)
                                        ? 1
                                        : 0.5;

                                    return (
                                      <div
                                        key={"dod" + i + "_" + j}
                                        style={{
                                          opacity: opacity,
                                          display:
                                            isEnabled || editDods
                                              ? "initial"
                                              : "none",
                                        }}
                                        data-tip="React-tooltip"
                                        onMouseEnter={() => {
                                          setTooltip(dod.set_description);
                                        }}
                                        onMouseLeave={() => {
                                          setTooltip("");
                                        }}
                                      >
                                        <Pill
                                          id={dod.set_id}
                                          index={j}
                                          group={i}
                                          name={dod.set_name}
                                          showEnabled={editDods}
                                          enabled={isEnabled}
                                          onClick={
                                            !props.disabled
                                              ? () => {
                                                  props.setDod({
                                                    card: props.data.card_id,
                                                    id: disc.set_id,
                                                    idx: j,
                                                    max: disc.dods.length,
                                                    enableMode: editDods,
                                                  });
                                                }
                                              : null
                                          }
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div
                                    style={{
                                      fontSize: 11,
                                      color: "grey",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      history.push("/admin/workflows");
                                    }}
                                  >
                                    - add in Settings{" "}
                                    <i className="fas fa-arrow-circle-right" />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      {editDods && (
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginLeft: 20,
                            marginBottom: 0,
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={() => {
                              props.setDod({
                                card: props.data.card_id,
                                trackWithCards: !(
                                  !status || status.trackWithCards !== false
                                ),
                              });
                            }}
                            checked={!status || status.trackWithCards !== false}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              fontSize: 11,
                              color: "#000",
                            }}
                          >
                            Include progress of children
                          </span>
                        </div>
                      )}
                      {!editDods &&
                        (!status || status.trackWithCards !== false) && (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: 0,
                                fontSize: 11,
                                color: "#000",
                              }}
                            >
                              Cards
                            </div>
                            <div style={{ position: "relative", width: "90%" }}>
                              <div
                                style={{
                                  position: "absolute",
                                  top: -3,
                                  width: "100%",
                                  height: 7,
                                  background: "#eee",
                                  borderRadius: 4,
                                  margin: "0px 8px",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: -3,
                                  width: props.data.completion.cards + "%",
                                  height: 7,
                                  background: "#ff9900",
                                  borderRadius: 4,
                                  margin: "0px 8px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </>
                )
            }
            {props.data.card_type === "standard" && (
              <>
                <div style={{ display: "flex" }}>
                  {standardStatus.map((std, x) => {
                    return (
                      <StatusPill
                        key={"status" + x}
                        text={std.name}
                        active={parseInt(props.data.card_status, 10) === x}
                        color={std.color}
                        onClick={() => {
                          setCardStatus(x);
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {/* Card discipline input selector */}
            {props.data.card_type === "task" && (
              <InputDropdown
                ref={discInput}
                data={props.disciplines.map((val) => {
                  return {
                    name: val.set_name,
                  };
                })}
                selected={discIdx}
                placeholder={"Discipline"}
                setFocusCb={() => {
                  setFocusElement(1);
                }}
                submitCb={(idx) => {
                  setTimer();
                  setDiscIdx(idx);
                }}
                pillStyle={{
                  background: discColor,
                  padding: "2px 5px",
                  borderRadius: 4,
                  color: "white",
                  border: "1px solid " + discColor,
                }}
              />
            )}
            {/* Card owner input selector */}
            {props.data.card_type === "task" && (
              <InputDropdown
                ref={ownerInput}
                data={props.users.map((val) => {
                  var fullName = val.user_firstname;
                  if (val.user_lastname)
                    fullName += " " + val.user_lastname.charAt(0);

                  return {
                    name: fullName,
                  };
                })}
                selected={ownerIdx}
                placeholder={"Owner"}
                setFocusCb={() => {
                  setFocusElement(2);
                }}
                submitCb={(idx) => {
                  setTimer();
                  setOwnerIdx(idx);
                }}
                pillStyle={{
                  background: "#999",
                  color: "white",
                  border: "1px solid #999",
                  padding: "1px 8px",
                  borderRadius: 10,
                }}
              />
            )}
            {/* Estimate input */}
            {props.data.card_type === "task" && (
              <InputDropdown
                ref={estInput}
                data={estTypes}
                selected={estTypeIdx}
                numPrefix={true}
                prefix={_est}
                placeholder={"Estimate"}
                setFocusCb={() => {
                  setFocusElement(3);
                }}
                pillStyle={{
                  fontSize: 13,
                  background: "white",
                  color: "#666",
                  fontStyle: "italic",
                  fontWeight: 700,
                  marginTop: -2,
                  border: 0,
                }}
                submitCb={(idx, value) => {
                  setTimer();
                  if (idx > -1) {
                    var resHours = parseInt(value, 10);
                    switch (estTypes[idx].name) {
                      case "months":
                        resHours *= 21 * 8;
                        break;
                      case "weeks":
                        resHours *= 5 * 8;
                        break;
                      case "days":
                        resHours *= 8;
                        break;
                      default:
                    }
                    setEstTypeIdx(idx);
                    setEstimate(resHours ? resHours : null);
                  } else {
                    setEstTypeIdx(-1);
                    setEstimate(-1);
                  }
                }}
              />
            )}
            {/* Priority input */}
            {props.data.card_type === "task" && (
              <InputDropdown
                ref={prioInput}
                data={prioTypes}
                selected={prio}
                placeholder={"Priority"}
                setFocusCb={() => {
                  setFocusElement(4);
                }}
                pillStyle={{
                  background:
                    (prio !== null || prio !== undefined) && prioTypes[prio]
                      ? prioTypes[prio].color
                      : "transparent",
                  color: "#fff",
                  border: 0,
                }}
                submitCb={(idx) => {
                  setTimer();
                  setPrio(idx);
                }}
              />
            )}
            {/* Goal selector input */}
            {props.data.card_type === "story" && (
              <InputDropdown
                ref={goalInput}
                data={
                  props.goals && props.goals.length > 0
                    ? props.goals.map((val) => {
                        return {
                          name: val.goal_name,
                          id: val.goal_id,
                          date: val.goal_date,
                        };
                      })
                    : null
                }
                selected={goalIdx}
                placeholder={"When should it be done?"}
                setFocusCb={() => {
                  setFocusElement(5);
                }}
                submitCb={(idx) => {
                  setMyChanges(true);
                  setGoalIdx(idx);
                }}
                pillStyle={{
                  background: "#efa51c",
                  color: "white",
                  textShadow: "1px 1px 2px #555",
                }}
              />
            )}
          </div>

          <div
            style={{ display: "inline-block", width: "70%", paddingRight: 10 }}
          >
            {/* Epic's children Storys */}
            {props.data.card_type === "epic" && (
              <>
                <Button
                  value="Add Story"
                  fontawesome="fas fa-plus"
                  onClick={() => {
                    props.addCard("story", props.id);
                  }}
                  type="green"
                  style={{ width: 100, marginBottom: 4 }}
                />
                {props.data.children.map((story, i) => {
                  return (
                    <Story
                      key={"story" + story.card_id}
                      id={story.card_id}
                      name={story.card_name}
                      desc={story.card_body}
                      priority={story.card_priority}
                      size={story.card_estimate}
                      submit={submitWrapped}
                    />
                  );
                })}
              </>
            )}

            <Body
              ref={bodyRef}
              body={cardBody.current}
              setBody={setBody}
              submit={submit}
              setFocus={setFocus}
            />
          </div>

          <div style={{ width: "28%" }}>
            {/* GitHub commit reference */}
            {props.github && props.data.card_type === "task" && (
              <>
                <ButtonDropdown
                  ref={commitDropdown}
                  value={github ? github.name : "Select commit"}
                  onClick={() => {
                    getCommits();
                  }}
                >
                  {!commits && (
                    <div style={{ width: "100%", padding: 20 }}>
                      <center>
                        <img
                          src={spinner}
                          alt="loading..."
                          style={{ display: "inline-block", width: 30 }}
                        />
                        <div
                          style={{
                            marginLeft: 6,
                            fontSize: 13,
                            color: "#a65ab8",
                            display: "inline-block",
                          }}
                        >
                          loading github...
                        </div>
                      </center>
                    </div>
                  )}

                  {commits &&
                    commits.map((val, i) => {
                      if (val === "fail")
                        return (
                          <div
                            key="commitfail"
                            style={{ color: "red", padding: 20 }}
                          >
                            Failed reaching github
                          </div>
                        );

                      var submittedWhen = "";
                      const date = new Date(val.commit.committer.date);
                      const daysSince = date.daysBetween(new Date(), true);

                      if (daysSince < 1) submittedWhen = "Today";
                      else if (daysSince < 2) submittedWhen = "Yesterday";
                      else submittedWhen = date.format(true);

                      const name =
                        submittedWhen + ": " + val.commit.committer.name;
                      const sha = val.sha;
                      const url = val.url;
                      return (
                        <Button
                          key={"commit" + i}
                          value={name}
                          desc={val.commit.message}
                          onClick={() => {
                            setGithub({ name, date, sha, url });
                            commitDropdown.current.close();
                            sendCommitComment(sha);
                          }}
                          style={{
                            padding: "4px 8px",
                            margin: 4,
                          }}
                        />
                      );
                    })}
                </ButtonDropdown>
                <br />
              </>
            )}

            {/* Image drop */}
            {props.data && (
              <div style={{ marginBottom: 30 }}>
                {images &&
                  images.map((val, i) => {
                    return (
                      <div
                        key={val}
                        style={{
                          display: "flex",
                          width: "100%",
                          height: 100,
                          overflow: "hidden",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 2,
                        }}
                        onMouseEnter={() => {
                          setHoveringImg(i + 1);
                        }}
                        onMouseLeave={() => {
                          setHoveringImg(null);
                        }}
                        onClick={() => {
                          setViewImg(val);
                        }}
                      >
                        <img
                          src={props.useLocal ? val : cl.url(val)}
                          alt="Card cap"
                          style={{
                            display: "block",
                            height: "100%",
                          }}
                        />
                        {hoveringImg === i + 1 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              background: "#ffffffaa",
                              top: 1,
                              right: 1,
                              fontSize: 12,
                              borderRadius: "50%",
                              width: 26,
                              height: 26,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deletePic(val, i);
                            }}
                          >
                            <i
                              className="fas fa-trash-alt"
                              style={{
                                color: "#777",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {uploadingImg && (
                  <div style={{ width: "100%", height: 30 }}>
                    <center>
                      <img
                        src={spinner}
                        alt="loading..."
                        style={{ display: "inline-block", width: 30 }}
                      />
                      <div
                        style={{
                          marginLeft: 6,
                          fontSize: 13,
                          color: "#a65ab8",
                          display: "inline-block",
                        }}
                      >
                        uploading...
                      </div>
                    </center>
                  </div>
                )}
                <button
                  className="btn btn-secondary btn-small btn-block"
                  style={{
                    fontSize: 11,
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    imageAdd.current.click();
                  }}
                >
                  <i className="fas fa-image" /> add image
                </button>

                <input
                  ref={imageAdd}
                  type="file"
                  accept="image/png, image/jpeg, image/gif"
                  style={{ position: "absolute", display: "none" }}
                  onChange={(val) => {
                    setUploadingImg(!props.useLocal);
                    onFileSubmit(val.target.files);
                  }}
                  multiple={true}
                />
              </div>
            )}
          </div>
        </div>
        <ReactTooltip
          place="top"
          effect="solid"
          offset={{ top: -10 }}
          disable={!tooltip}
          delayShow={300}
          backgroundColor={"#fff"}
          className="tooltip"
        >
          {tooltip}
        </ReactTooltip>
      </Popup>
    </>
  );
}

const Body = memo(
  forwardRef((props, ref) => {
    const [cardBody, setCardBody] = useState(props.body);

    const cardBodyRef = useRef();

    useImperativeHandle(ref, () => ({
      focus: () => {
        setTimeout(() => {
          ReactEditor.focus(bodyEditor);
        }, 100);
      },
    }));

    // Slate Editor /////////////////////////
    const bodyEditor = useMemo(
      () => withHistory(withReact(createEditor())),
      []
    );
    const LIST_TYPES = ["numbered-list", "bullet", "bullet-indent"];
    const CustomEditor2 = {
      isMarkActive(editor, format) {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
      },

      isBlockActive(editor, format) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === format,
        });

        return !!match;
      },

      toggleMark(editor, format) {
        const isActive = CustomEditor2.isMarkActive(editor, format);

        if (isActive) {
          Editor.removeMark(editor, format);
        } else {
          Editor.addMark(editor, format, true);
        }
        props.submit();
      },

      toggleBlock(editor, format) {
        const isActive = CustomEditor2.isBlockActive(editor, format);
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
          match: (n) => LIST_TYPES.includes(n.type),
          split: true,
        });

        Transforms.setNodes(editor, {
          type: isActive ? "paragraph" : isList ? "list-item" : format,
        });

        if (!isActive && isList) {
          const block = { type: format, children: [] };
          Transforms.wrapNodes(editor, block);
        }
      },
    };
    const renderElement2 = useCallback((props) => {
      switch (props.element.type) {
        case "bullet":
          return <ul {...props.attributes}>{props.children}</ul>;
        case "bullet-indent":
          return (
            <ul>
              <ul {...props.attributes}>{props.children}</ul>
            </ul>
          );
        case "list-item":
          return <li {...props.attributes}>{props.children}</li>;
        default:
          return <DefaultElement2 {...props} />;
      }
    }, []);
    const renderLeaf2 = useCallback((props) => {
      return <Leaf2 {...props} />;
    }, []);
    const DefaultElement2 = (props) => {
      return <div {...props.attributes}>{props.children}</div>;
    };
    const Leaf2 = ({ attributes, children, leaf }) => {
      if (leaf.link) {
        return (
          <span
            {...attributes}
            className="link"
            onClick={(e) => {
              e.preventDefault();
              var url = leaf.text;
              if (url.substr(0, 3).toLowerCase() === "www") {
                url = "http://" + url;
              }
              window.open(url, "_blank");
            }}
            style={{ cursor: "pointer" }}
          >
            {children}
          </span>
        );
      }
      if (leaf.bold) {
        children = <strong>{children}</strong>;
      }

      if (leaf.italic) {
        children = <em>{children}</em>;
      }

      if (leaf.underlined) {
        children = <u>{children}</u>;
      }

      return <span {...attributes}>{children}</span>;
    };
    const serialize = (nodes) => {
      return nodes.map((n) => Node.string(n)).join("");
    };

    useEffect(() => {
      if (bodyEditor.selection) {
        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const regex = new RegExp(expression);

        const range = bodyEditor.selection.anchor;
        const selected =
          bodyEditor.children[range.path[0]].children[range.path[1]];
        const isLink = selected.text && selected.text.match(regex);

        if (isLink) {
          let offset = selected.text.indexOf(isLink[0]);

          Transforms.setSelection(bodyEditor, {
            anchor: {
              path: range.path,
              offset: offset,
            },
            focus: {
              path: range.path,
              offset: offset + isLink[0].length,
            },
          });

          Editor.addMark(bodyEditor, "link", true);
          Transforms.collapse(bodyEditor, { edge: "end" });
        } else if (selected.link) {
          Transforms.setSelection(bodyEditor, {
            anchor: {
              path: range.path,
              offset: 0,
            },
            focus: {
              path: range.path,
              offset: selected.text.length,
            },
          });
          Editor.removeMark(bodyEditor, "link");
          Transforms.collapse(bodyEditor, { edge: "end" });
        }
      }
      cardBodyRef.current = cardBody;
      props.setBody(cardBody);
    }, [cardBody]);

    return (
      <div
        className="slate"
        style={{
          minWidth: 250,
          position: "relative",
          margin: 8,
        }}
      >
        {serialize(cardBody).length === 0 &&
        !(cardBody.length === 1 && cardBody[0].type === "bullet") ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              color: "#bbb",
              pointerEvents: "none",
            }}
          >
            {props.data ? desc[props.data.card_type] : ""}
          </div>
        ) : (
          <></>
        )}
        <Slate
          editor={bodyEditor}
          value={cardBody === null ? "" : cardBody}
          onChange={(newValue) => {
            if (newValue !== cardBody) {
              setCardBody(newValue);
            }
          }}
        >
          <Editable
            renderElement={renderElement2}
            renderLeaf={renderLeaf2}
            onKeyDown={(e) => {
              if (e.ctrlKey || e.metaKey) {
                if (e.key === "b") {
                  e.preventDefault();
                  CustomEditor2.toggleMark(bodyEditor, "bold");
                } else if (e.key === "Backspace") {
                  e.preventDefault();
                }
              } else if (e.shiftKey) {
                if (e.key === "*") {
                  e.preventDefault();
                  CustomEditor2.toggleBlock(bodyEditor, "bullet");
                  props.submit();
                } else if (e.key === "Tab") {
                  e.preventDefault();
                  if (
                    CustomEditor2.isBlockActive(bodyEditor, "bullet-indent") ||
                    CustomEditor2.isBlockActive(bodyEditor, "bullet")
                  ) {
                    CustomEditor2.toggleBlock(bodyEditor, "bullet");
                    // setMyChanges(true);
                    props.submit();
                  }
                }
              } else if (e.key === "Tab") {
                e.preventDefault();
                if (CustomEditor2.isBlockActive(bodyEditor, "bullet")) {
                  CustomEditor2.toggleBlock(bodyEditor, "bullet-indent");
                  // setMyChanges(true);
                  props.submit();
                } else if (
                  !CustomEditor2.isBlockActive(bodyEditor, "bullet") &&
                  !CustomEditor2.isBlockActive(bodyEditor, "bullet-indent")
                ) {
                  CustomEditor2.toggleBlock(bodyEditor, "bullet");
                  // setMyChanges(true);
                  props.submit();
                }
              } else if (
                e.key === "Backspace" ||
                e.key === "Enter" ||
                e.key === " "
              ) {
                if (
                  (CustomEditor2.isBlockActive(bodyEditor, "bullet") ||
                    CustomEditor2.isBlockActive(bodyEditor, "bullet-indent")) &&
                  bodyEditor.selection.anchor.offset === 0
                ) {
                  e.preventDefault();
                  CustomEditor2.toggleBlock(bodyEditor, "bullet");
                  props.submit();

                  bodyEditor.insertText("");
                } else if (
                  (e.key === "Enter" || e.key === " ") &&
                  CustomEditor2.isMarkActive(bodyEditor, "link")
                ) {
                  Editor.removeMark(bodyEditor, "link");
                }
              }
            }}
            onFocus={() => {
              setTimeout(() => {
                props.setFocus(7);
              }, 100);
            }}
          />
        </Slate>
      </div>
    );
  })
);

const StatusPill = (props) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 20,
        borderRadius: 4,
        background: props.color,
        opacity: hovering || props.active ? 1 : 0.6,
        margin: 4,
        padding: "0px 11px",
        fontSize: 11,
        userSelect: "none",
        cursor: "pointer",
      }}
      onMouseOver={() => {
        setHovering(true);
      }}
      onMouseOut={() => {
        setHovering(false);
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          color: "#000",
          pointerEvents: "none",
        }}
      >
        {props.text}
      </div>
    </div>
  );
};
