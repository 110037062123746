import React, { useState, useEffect } from "react";
import SprintGoal from "../components/SprintGoal";
import useKey from "../components/useKey";
import history from "../utils/history";
import update from "immutability-helper";

export default function Board(props) {
  const [isDragging, setIsDragging] = useState(false);
  const [stories, setStories] = useState(null);
  const [criticalTasks, setCriticalTasks] = useState(null);
  const [dragged, setDragged] = useState(null);

  useKey("a", () => {
    addCard();
  });

  useEffect(() => {
    var followedStories = {};
    var _stories = [];
    var _criticalTasks = [[], [], [], []];

    for (var i = 0; i < props.cards.length; i++) {
      const card = props.cards[i];

      if (card.card_type === "story") {
        const epic = props.pillars[props.idToIdxPillar[card.card_parent]];
        const pillar = epic
          ? props.pillars[props.idToIdxPillar[epic.card_parent]]
          : null;

        if (pillar && pillar.card_goal === props.goal.goal_id) {
          if (card.card_following) {
            // Check if manual ordering has been made, following = card above
            followedStories[card.card_following] = card.card_id;
          } else {
            _stories.push(card.card_id);
          }
        }
      }

      // Find critical tasks and place at the top
      if (
        card.card_type === "task" &&
        card.card_priority === 4 &&
        (!card.card_status || parseInt(card.card_status, 10) < 3)
      ) {
        const epicId = props.cards[props.idToIdx[card.card_parent]].card_parent;
        const pillarId = props.cards[props.idToIdx[epicId]].card_parent;
        const pillar = props.cards[props.idToIdx[pillarId]];

        const status = !card.card_status ? 0 : parseInt(card.card_status, 10);

        if (pillar.card_goal === props.goal.goal_id)
          _criticalTasks[status].push(card);
      }
    }

    var finalStories = [];

    // Push story at top position, id -1
    if (followedStories["-1"]) {
      _stories.unshift(followedStories["-1"]);
    }

    for (var i = 0; i < _stories.length; i++) {
      finalStories.push(_stories[i]);

      if (followedStories[_stories[i]]) {
        var current = _stories[i];
        for (const [key, value] of Object.entries(followedStories)) {
          if (followedStories[current]) {
            finalStories.push(followedStories[current]);
            current = followedStories[current];
          }
        }
      }
    }

    setCriticalTasks(_criticalTasks);
    setStories(finalStories);
  }, [props.showArchived, props.currentGoal, props.cards]);

  ///////////////////////////////////////////////////////////////////////////
  // Functions

  function addCard(type) {
    props.addCard(type);
  }

  function storyShouldShow(val) {
    // Filter only stories with my cards in it
    const arr = val.children.flat();
    const discs = props.showDiscipline
      ? props.showDiscipline
          .filter((di) => di.checked === true)
          .map((di) => di.id)
      : [];

    const hasMyCards = arr.find(
      (story) => story.card_owner === props.auth.getUserId()
    );

    const hasDisc =
      discs.length > 0 &&
      arr.find((story) =>
        props.showDiscipline.find((disc) =>
          discs.includes(story.card_discipline)
        )
      );

    // Filter show archived stories or specific disciplines or not
    if (
      (val.card_status === "-1" && !props.showArchived) ||
      (props.showMyCards && !hasMyCards) ||
      (discs.length > 0 && !hasDisc)
    ) {
      return false;
    } else {
      return true;
    }
  }

  function dragSprintgoal(dragIdx, hoverIdx) {
    setStories((prev) =>
      update(prev, {
        $splice: [
          [dragIdx, 1],
          [hoverIdx, 0, prev[dragIdx]],
        ],
      })
    );
  }

  function submitDragSprintgoal(draggedIdx) {
    // Find the next visible story above
    var idxAbove = -1;
    if (draggedIdx > 0) {
      for (var i = draggedIdx - 1; i >= 0; i--) {
        if (storyShouldShow(props.cards[props.idToIdx[stories[i]]])) {
          idxAbove = i;
          break;
        }
      }
    }

    props.submitDragSprintgoal(
      stories[draggedIdx],
      stories[idxAbove] ? stories[idxAbove] : -1,
      dragged.oldAbove
    );

    setDragged(null);
  }

  function startDrag(obj) {
    // Find the next visible story above
    var idxAbove = -1;
    if (obj.index > 0) {
      for (var i = obj.index - 1; i >= 0; i--) {
        if (storyShouldShow(props.cards[props.idToIdx[stories[i]]])) {
          idxAbove = i;
          break;
        }
      }
    }
    const oldAbove = idxAbove > -1 ? stories[idxAbove] : -1;
    const newObj = Object.assign({ oldAbove: oldAbove }, props);
    setDragged(newObj);
  }

  /////////////////////////
  // Render

  const hasContent = stories && stories.length > 0;
  const inspectedParent =
    props.inspectTask && props.idToIdx[props.inspectTask]
      ? props.cards[props.idToIdx[props.inspectTask]].card_parent
      : null;
  var visibleIdx = -1;

  return (
    <div>
      {criticalTasks &&
        criticalTasks.flat().length > 0 &&
        storyShouldShow({ children: criticalTasks }) &&
        props.showPriority && (
          <SprintGoal
            isCustom={true}
            customColor="#f00"
            key={"criticalTasks"}
            highlight={false}
            highlightCol={props.highlightCol}
            highlightCard={props.highlightCard}
            title="Critical Priority Tasks"
            columns={props.columns}
            data={{ card_status: 1, children: criticalTasks }}
            editSprintGoal={props.editCard}
            editTheCard={props.editSprintGoal}
            deleteCard={props.deleteCard}
            pushCard={props.pushCard}
            addTask={() => {
              props.addCard("task", val.card_id, props.sprint);
            }}
            users={props.users}
            disciplines={props.disciplines}
            idToIdx={props.idToIdx}
            idToIdxUsers={props.idToIdxUsers}
            idToIdxDiscs={props.idToIdxDiscs}
            dropCallback={props.dropCallback}
            ribbonClick={props.ribbonClick}
            cloudinary={props.cloudinary}
            showMyCards={props.showMyCards}
            showDiscipline={props.showDiscipline}
            showPriority={props.showPriority}
            auth={props.auth}
          />
        )}
      {hasContent &&
        stories.map((id, i) => {
          const val = props.cards[props.idToIdx[id]];

          // If inspectStory is set, user is trying to inspect one specific story
          if (inspectedParent && val.card_id !== parseInt(inspectedParent, 10))
            return null;

          if (!storyShouldShow(val)) return null;

          visibleIdx++;

          return (
            <SprintGoal
              key={"sprintgoal" + id}
              id={id}
              index={i}
              highlight={props.highlight === visibleIdx}
              highlightCol={props.highlightCol}
              highlightCard={props.highlightCard}
              title={val.card_name}
              columns={props.columns}
              data={val}
              editSprintGoal={props.editCard}
              editTheCard={props.editSprintGoal}
              deleteCard={props.deleteCard}
              pushCard={props.pushCard}
              addTask={() => {
                props.addCard("task", val.card_id, props.sprint);
              }}
              users={props.users}
              disciplines={props.disciplines}
              idToIdx={props.idToIdx}
              idToIdxUsers={props.idToIdxUsers}
              idToIdxDiscs={props.idToIdxDiscs}
              dropCallback={props.dropCallback}
              ribbonClick={props.ribbonClick}
              cloudinary={props.cloudinary}
              showMyCards={props.showMyCards}
              showDiscipline={props.showDiscipline}
              showPriority={props.showPriority}
              auth={props.auth}
              startDrag={startDrag}
              dragSprintgoal={dragSprintgoal}
              submitDragSprintgoal={submitDragSprintgoal}
            />
          );
        })}

      {!props.modify && !hasContent && (
        <div style={{ fontSize: 30, color: "#bbb" }}>
          <center>
            <br />
            <br />
            <i className="fas fa-gamepad" /> No product goals.
            <br />
            <div style={{ fontSize: 19 }}>
              <br />
              1. Did you create your bigger goals in{" "}
              <button
                className={"btn btn-peepz btn-sm"}
                onClick={() => history.push("/playbook")}
              >
                Playbook
              </button>{" "}
              yet?
              <br style={{ paddingBottom: 8 }} />
              2. Then come back and break it down!
            </div>
          </center>
        </div>
      )}
    </div>
  );
}
