import React from "react";
import db from "../utils/db";
import moment from "moment";
import history from "../utils/history";
import InviteUser from "../components/InviteUser";
import Popup from "../components/Popup";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import TimeGrid from "../components/TimeGrid";
import { toast } from "react-toastify";
import Helper from "../utils/helper";
import InspectBar from "../components/InspectBar";
import socket from "../utils/socket";

require("../utils/extends");

class OverviewProject extends React.Component {
  constructor(props) {
    super(props);

    this.types = {
      major: "Major",
      goal: "Normal",
      event: "Minor",
      sprint: "Pin",
    };
    this.typeColors = {
      goal: { background: "#efa51c", font: "#fff" },
      event: { background: "#af1cef", font: "#fff" },
      sprint: { background: "#aaa", font: "#000" },
      major: { background: "#efa51c", font: "#fff" },
    };
    this.typeKeys = Object.keys(this.types);

    const initState = this.getInitState();

    this.state = {
      orgcreated: initState.orgCreated,
      startdate: initState.projStart,
      enddate: initState.projEnd,
      data: [],
      days: [],
      weekDays: [],
      ribbons: null,
      movement: null,
      commandbarShowing: false,
      inviteUser: false,
      newEmployee: null,
      pageCols: 10,
      todayShowLeft: false,
      todayShowRight: false,
      projname: initState.projname,
      rangeFrom: initState.rangeFrom,
      rangeTo: initState.rangeTo,
      daysToEnd: initState.daysToEnd,
      myIndex: -1,
      loading: true,
      boxWidth: 16,
      boxSpaceVert: 2,
      nameCol: 0,
      winHeight: 300,
      showDeleteUser: false,
      idxToDelete: -1,
      newConsultant: false,
      ribbon: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.submitDays = this.submitDays.bind(this);
    this.clickModify = this.clickModify.bind(this);
    this.deleteGoal = this.deleteGoal.bind(this);
    this.clickAdd = this.clickAdd.bind(this);
    this.setWorkday = this.setWorkday.bind(this);
    this.submitJoinLeaveDay = this.submitJoinLeaveDay.bind(this);
    this.setMovement = this.setMovement.bind(this);
    this.menuSubmit = this.menuSubmit.bind(this);
    this.hasAdjacentMove = this.hasAdjacentMove.bind(this);
    this.showDeleteUser = this.showDeleteUser.bind(this);
    this.updateGoal = this.updateGoal.bind(this);
    this.getGoalCapacity = this.getGoalCapacity.bind(this);
    this.ribbonClick = this.ribbonClick.bind(this);
    this.closeInspect = this.closeInspect.bind(this);
    this.emitUpdate = this.emitUpdate.bind(this);

    this.newGoalName = React.createRef();
    this.newGoalType = React.createRef();
    this.timegrid = React.createRef();

    socket.emit("joinroom", {
      room: props.auth.getOrgId() + "timeline",
      user: props.auth.getUserName(),
    });
    socket.on("timelineupdate", this.fetchData);

    this.fetchData();
  }

  componentDidMount() {
    this.props.dispatch.addListener("orgchange", () => this.fetchData());
    this.props.dispatch.addListener("projchange", () => this.fetchData());
    this.props.dispatch.addListener("projrefresh", () => this.fetchData(true));
    document.addEventListener("keydown", this.handleKey, false);
  }

  componentWillUnmount() {
    this.props.dispatch.removeListener("orgchange", () => this.fetchData());
    this.props.dispatch.removeListener("projchange", () => this.fetchData());
    this.props.dispatch.removeListener("projrefresh", () =>
      this.fetchData(true)
    );
    document.removeEventListener("keydown", this.handleKey, false);

    socket.removeListener("timelineupdate", this.fetchData);
    socket.emit("leaveroom", {
      room: this.props.auth.getOrgId() + "timeline",
      user: this.props.auth.getUserName(),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Helper.stateChanged(this.state, nextState);
  }

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  getInitState() {
    var initState = {};
    var today = new Date();
    var orgCreated = new Date(this.props.auth.getOrgCreation());
    var projStart = new Date(this.props.auth.getProjStart());
    var projEnd = new Date(this.props.auth.getProjEnd());

    initState.data = [];
    initState.days = [];
    initState.weekDays = [];
    initState.loading = true;
    initState.orgCreated = orgCreated;
    initState.projStart = projStart;
    initState.projEnd = projEnd;
    initState.today = today;
    initState.daysToEnd = projStart.daysBetween(projEnd) + 1;
    initState.rangeFrom = orgCreated.daysBetween(projStart);
    initState.rangeTo = orgCreated.daysBetween(projEnd) + 1;
    initState.projname = this.props.auth.getProjName();
    return initState;
  }

  async fetchData(refresh = false, cb) {
    var projid = this.props.auth.getProjId();

    var initState = this.getInitState();

    var years = [];
    var year = initState.projStart.getFullYear();
    while (year <= initState.projEnd.getFullYear()) {
      years.push(year);
      year++;
    }

    // Get public holidays
    const resHolidays = await db.request("/auth/getholidays", "POST", {
      orgid: this.props.auth.getOrgId(),
      proj: projid,
      years: years,
    });

    // Get all velocities
    const resVelocs = await db.request("/auth/getvelocity", "POST", {
      proj: projid,
    });

    // Get all goals
    const resGoals = await db.request("/auth/getgoals", "POST", {
      projid: projid,
    });

    const peepzData = await db.request("/auth/getdays", "POST", {
      projid: projid,
      orgid: this.props.auth.getOrgId(),
      from: initState.rangeFrom - 1,
      to: initState.rangeTo,
    });

    const resDiscs = await db.request("/auth/getdisciplines", "POST", {
      org: this.props.auth.getOrgId(),
    });

    var discByID = {};
    if (resDiscs && resDiscs.status === 200 && resDiscs.data.length > 0) {
      for (var j = 0; j < resDiscs.data.length; j++) {
        discByID[resDiscs.data[j].set_id] = resDiscs.data[j];
      }
    }

    if (peepzData && peepzData.status === 200) {
      // ORGANIZER LOOP
      var mainArr = [];
      var rowIdx = 0;
      var currUserId =
        peepzData.data.length > 0 ? peepzData.data[0].user_id : -1;
      var maxName = 0;
      var joined = false;
      var joinedFrom = 0;
      var lastLeave = -1;
      var joinedCompany = true;
      var lastCompanyJoinLeave = 0;

      var inCompany = new Array(initState.daysToEnd).fill(true);
      var joinsLeaves = new Array(initState.daysToEnd).fill(false);
      var status = new Array(initState.daysToEnd).fill(null);
      var dayid = new Array(initState.daysToEnd).fill(-1);
      var date = new Array(initState.daysToEnd).fill(null);

      var holidays = {};
      var ribbons = {};
      var myUID = this.props.auth.getUserId();
      var myIdx = -1;

      // Holidays
      if (resHolidays)
        for (var p = 0; p < resHolidays.data.length; p++) {
          var holiDate = new Date(resHolidays.data[p].date);
          if (
            holiDate.isSameOrAfterDay(initState.projStart) &&
            holiDate.isSameOrBeforeDay(initState.projEnd) &&
            resHolidays.data[p].enabled
          ) {
            var daysFromProjStart = holiDate.daysBetween(initState.projStart);
            holidays[daysFromProjStart] = resHolidays.data[p];
          }
        }

      for (var i = 0; i < peepzData.data.length; i++) {
        var row = peepzData.data[i];
        var isLast = i === peepzData.data.length - 1; // Check if last post for user, switch row/user
        var isSingleOffProj =
          row.dayz_status !== "offproject" || row.dayz_project === projid;

        inCompany[row.dayz_fromstart - initState.rangeFrom] = joinedCompany;
        dayid[row.dayz_fromstart - initState.rangeFrom] = row.dayz_id;
        date[row.dayz_fromstart - initState.rangeFrom] = row.dayz_date;
        status[row.dayz_fromstart - initState.rangeFrom] = isSingleOffProj
          ? row.dayz_status
          : null;

        if (myIdx === -1 && row.user_id === myUID) myIdx = rowIdx;

        if (row.dayz_type === "org") {
          // In or out of company

          if (row.dayz_status === "join") {
            inCompany.fill(
              false,
              lastCompanyJoinLeave,
              row.dayz_fromstart - initState.rangeFrom
            );
            lastCompanyJoinLeave = row.dayz_fromstart - initState.rangeFrom;
            joinedCompany = true;
          } else if (row.dayz_status === "left") {
            joinedCompany = false;
            lastCompanyJoinLeave = row.dayz_fromstart - initState.rangeFrom;
          }
        } else {
          // Off or on the project

          if (!joined && row.dayz_status === "join") {
            joined = true;
            joinedFrom = row.dayz_fromstart;
          } else if (joined && row.dayz_status === "left") {
            joined = false;
            lastLeave = row.dayz_fromstart - initState.rangeFrom;
            joinsLeaves.fill(
              true,
              joinedFrom - initState.rangeFrom,
              lastLeave + 1
            );
          }
        }

        if (
          (peepzData.data[i + 1] &&
            peepzData.data[i + 1].user_id !== currUserId) ||
          isLast
        ) {
          const disc = parseInt(row.attr_value, 10);
          var final = {
            discipline: disc,
            disciplineName: disc ? discByID[disc].set_name : "",
            userid: row.user_id,
            projectid: projid,
            firstname: row.user_firstname,
            lastname: row.user_lastname,
            email: row.user_email,
            consultant: row.user_consultant,
            joinsLeaves: joinsLeaves,
            inCompany: inCompany,
            status: status,
            dayid: dayid,
            date: date,
            lastLeave: lastLeave,
          };
          mainArr.push(final);

          if (row.user_firstname.length > maxName)
            maxName = row.user_firstname.length;

          // Fill any final company leaves backwards
          if (!joinedCompany) {
            inCompany.fill(
              false,
              lastCompanyJoinLeave + 1,
              initState.daysToEnd
            );
          }

          if (!isLast) {
            joinsLeaves = new Array(initState.daysToEnd).fill(false);
            inCompany = new Array(initState.daysToEnd).fill(true);
            status = new Array(initState.daysToEnd).fill(null);
            dayid = new Array(initState.daysToEnd).fill(-1);
            date = new Array(initState.daysToEnd).fill(null);

            currUserId = peepzData.data[i + 1].user_id;
            joined = false;
            joinedCompany = true;
            lastLeave = -1;
            lastCompanyJoinLeave = 0;
            rowIdx++;
          }
        }
      }

      // Add ribbons data
      if (resGoals && resGoals.status === 200 && resGoals.data.length > 0) {
        for (var k = 0; k < resGoals.data.length; k++) {
          const _date = resGoals.data[k].goal_date;
          var goalDate = new Date(_date);
          var daysToGoal = _date
            ? initState.projStart.daysBetween(goalDate)
            : "thematic";
          if (!ribbons[daysToGoal]) ribbons[daysToGoal] = [];

          ribbons[daysToGoal].push({
            id: resGoals.data[k].goal_id,
            type: resGoals.data[k].goal_type,
            name: resGoals.data[k].goal_name,
            desc: resGoals.data[k].goal_description,
            date: resGoals.data[k].goal_date,
            color: resGoals.data[k].goal_color,
          });
        }
      }

      // Create days array for reference
      var days = [];
      var weekDays = [];
      for (
        var d = new Date(initState.projStart.getTime());
        d <= initState.projEnd;
        d.setDate(d.getDate() + 1)
      ) {
        var newD = new Date(d);
        days.push(newD);
        weekDays.push(newD.getDay());
      }

      this.setState({
        nameCol: maxName * 11,
        data: mainArr,
        days: days,
        weekDays: weekDays,
        holidays: holidays,
        ribbons: ribbons,
        myIndex: myIdx,
        loading: false,
        projname: initState.projname,
        orgcreated: initState.orgCreated,
        startdate: initState.projStart,
        enddate: initState.projEnd,
        today: initState.today,
        daysToEnd: initState.daysToEnd,
        rangeFrom: initState.rangeFrom,
        rangeTo: initState.rangeTo,
        winHeight:
          mainArr.length * (this.state.boxWidth + this.state.boxSpaceVert) +
          250,
        velocity: resVelocs.data,
        disciplinesById: discByID,
      });
    } else {
      this.setState({
        data: [],
        days: [],
        loading: false,
        projname: initState.projname,
      });
    }
    if (!refresh && this.timegrid.current)
      this.timegrid.current.resetViewPosition();

    if (cb) cb();
  }

  handleKey(e) {
    if (
      !this.state.addEvent &&
      !this.state.inviteUser &&
      !this.state.addGoal &&
      !this.state.showDeleteUser &&
      !this.state.ribbon
    ) {
      if (e.key === "1") history.push("/playbook");
      else if (e.key === "2") history.push("/overview-project");
      else if (e.key === "3") history.push("/sprint");
    }
  }

  emitUpdate() {
    socket.emit("timelineupdate", {
      room: this.props.auth.getOrgId() + "timeline",
      name: this.props.auth.getUserName(),
    });
  }

  async setDay(date, userid, status) {
    var tmpDate = moment(date).format("DD MM YYYY");
    var data = [];
    data.push({
      date: tmpDate,
      userid: userid,
      type: "project",
      status: status,
    });
    const res = await db.request("/auth/setdays", "POST", {
      data: data,
      orgid: this.props.auth.getOrgId(),
      projectid: this.props.auth.getProjId(),
    });

    if (res.status === 200) {
      this.fetchData(true);
      this.emitUpdate();
    }
  }

  validateGoalName(val) {
    var res = "";
    if (!val || (val && val.trim() === "")) {
      res = "Can't be empty";
    }
    return res;
  }

  submitGoal(e) {
    var name = this.newGoalName.current.value();
    var type = this.newGoalType.current.value;
    this.newGoalName.current.submit(name);
    if (this.validateGoalName(name) === "") {
      this.createGoal(name, "", type);
      this.setState({ addGoal: false, movement: null });
    }
  }

  async createGoal(val, desc, type, id) {
    const res = await db.request("/auth/addgoal", "POST", {
      date: moment(new Date(localStorage.getItem("add-date"))).format(
        "DD MM YYYY"
      ),
      projectid: this.props.auth.getProjId(),
      name: val,
      desc: desc,
      type: type,
      id: id,
    });

    if (res.status === 200) {
      this.fetchData(true);
      this.emitUpdate();
    } else {
      toast.error("Unknown error, the project milestone could not be created.");
    }

    localStorage.removeItem("invite-date");
  }

  async deleteGoal(id, thematic) {
    const res = await db.request("/auth/deletegoal", "POST", {
      goalid: id,
      projectid: this.props.auth.getProjId(),
      thematic: thematic,
    });

    if (res.status === 200) {
      toast.success("Removed the milestone!");
      this.fetchData(true);
      this.emitUpdate();
    } else {
      toast.error("Unknown error, the milestone could not be erased.");
    }
  }

  formatDayData(status, type, selectedDays, repeat) {
    var tmpArr = [];
    var tmpDates = {};

    selectedDays.forEach((val) => {
      var idxs = val.split("-");
      var day = this.state.startdate.addDays(parseInt(idxs[2], 10));

      if (!tmpDates[idxs[1]]) tmpDates[idxs[1]] = [];

      if (repeat === -1) {
        tmpArr.push({
          date: moment(day).format("DD MM YYYY"),
          userid: idxs[1],
          type: type,
          status: status,
        });
      } else if (repeat > 0) {
        for (var i = 0; i <= repeat; i++) {
          if (
            day.isSameOrBeforeDay(this.state.enddate) &&
            !tmpDates[idxs[1]].includes(day.getTime())
          ) {
            tmpArr.push({
              date: moment(day).format("DD MM YYYY"),
              userid: idxs[1],
              type: type,
              status: status,
            });
            tmpDates[idxs[1]].push(day.getTime());
          }
          day = new Date(day.addDays(7));
        }
      } else if (repeat === 0) {
        while (
          day.isSameOrBeforeDay(this.state.enddate) &&
          !tmpDates[idxs[1]].includes(day.getTime())
        ) {
          tmpArr.push({
            date: moment(day).format("DD MM YYYY"),
            userid: idxs[1],
            type: type,
            status: status,
          });
          tmpDates[idxs[1]].push(day.getTime());
          day = day.addDays(7);
        }
      }
    });

    return tmpArr;
  }

  async submitDays(status, type, selectedDays, repeat) {
    await db.request("/auth/setdays", "POST", {
      data: this.formatDayData(status, type, selectedDays, repeat),
      status: status,
      orgid: this.props.auth.getOrgId(),
      projectid: status === "offproject" ? this.props.auth.getProjId() : -1,
    });

    this.fetchData(true);
    this.emitUpdate();
  }

  clickModify() {
    this.setState({
      movement: null,
    });
  }

  clickAdd(type, date) {
    switch (type) {
      case "person":
        this.setState({ inviteUser: true });
        break;
      case "milestone":
        this.setState({ addGoal: true });
        break;
      case "event":
        this.setState({ addEvent: true });
        break;
      default:
    }

    localStorage.setItem("add-date", date);
  }

  async setWorkday(val, date) {
    var res = null;
    if (val) {
      res = await db.request("/auth/addholiday", "POST", {
        orgid: this.props.auth.getOrgId(),
        proj: this.props.auth.getProjId(),
        name: "Cancelled workday",
        date: date.format(),
      });
    } else {
      res = await db.request("/auth/deleteholiday", "POST", {
        orgid: this.props.auth.getOrgId(),
        proj: this.props.auth.getProjId(),
        date: date.format(),
      });
    }

    if (res.status === 200) {
      this.fetchData(true);
      this.emitUpdate();
    } else {
      toast.error("Unknown error, day could not be set.");
    }
  }

  async submitJoinLeaveDay(
    userid,
    fromDate,
    toDate,
    since,
    status,
    projid,
    callback
  ) {
    var hasAdjacent = this.hasAdjacentMove(
      this.state.movement.row,
      since - this.state.rangeFrom,
      status
    );

    var dayid = null;
    var move = Object.assign({}, this.state.movement);
    if (status === "join") {
      if (move.soonestCompany === -1) {
        if (move.soonest === -1) {
          move.soonest = 0;
        }
      } else {
        if (move.soonest < move.soonestCompany) {
          dayid = this.state.data[move.row].dayid[move.soonest];
        }
      }
    } else {
      if (move.latestCompany === -1) {
        if (move.latest === -1) {
          move.latest = this.state.data[move.row].joinsLeaves.length - 1;
        } else {
          dayid = this.state.data[move.row].dayid[move.latest];
        }
      } else {
        if (move.latest > move.latestCompany) {
          dayid = this.state.data[move.row].dayid[move.latest];
        }
      }
    }

    const res = await db.request("/auth/moveday", "POST", {
      projectid: projid,
      userid: userid,
      type: "project",
      status: status,
      fromdate: fromDate.format(),
      todate: toDate.format(),
      since: since,
      hasAdjacent: hasAdjacent,
      dayid: dayid,
    });

    callback(res);
  }

  setMovement(obj) {
    this.setState({ movement: obj });
  }

  hasAdjacentMove(row, toidx, type) {
    var wOffsets = 0;
    const weekends = [0, 6];
    var isJoined = this.state.data[row].joinsLeaves[toidx];

    if (type === "join") {
      while (weekends.includes(this.state.weekDays[toidx - wOffsets - 1])) {
        wOffsets++;
      }
      var prevJoined =
        toidx > 0
          ? this.state.data[row].joinsLeaves[toidx - wOffsets - 1]
          : false;

      return prevJoined && !isJoined;
    } else if (type === "left") {
      while (weekends.includes(this.state.weekDays[toidx + wOffsets + 1])) {
        wOffsets++;
      }
      var nextJoined =
        toidx < this.state.data[row].joinsLeaves.length
          ? this.state.data[row].joinsLeaves[toidx + wOffsets + 1]
          : false;

      return !isJoined && nextJoined;
    } else {
      return false;
    }
  }

  async menuSubmit(row, idx, type, projid) {
    var day = this.state.orgcreated.addDays(idx + this.state.rangeFrom);

    if (type === "leave") {
      const resLeave = await db.request("/auth/leaveday", "POST", {
        userid: this.state.data[row].userid,
        todate: moment(day).format("DD MM YYYY"),
        projid: projid,
        isProject: true,
      });

      if (resLeave.status === 200) {
        this.fetchData(true);
        this.emitUpdate();
      } else {
        toast.error("Unknown error, day could not be set.");
      }
    } else if (type === "join") {
      const resJoin = await db.request("/auth/joinday", "POST", {
        userid: this.state.data[row].userid,
        date: moment(day).format("DD MM YYYY"),
        projid: projid,
        orgid: this.props.auth.getOrgId(),
        isProject: true,
      });

      if (resJoin.status === 200) {
        this.fetchData(true);
        this.emitUpdate();
      } else {
        toast.error("Unknown error, day could not be set.");
      }
    }
  }

  showDeleteUser(show, idx) {
    var index = show && idx !== null && idx !== undefined ? idx : -1;
    this.setState({ showDeleteUser: show, idxToDelete: index });
  }

  async deleteUser(id) {
    const res = await db.request("/auth/deleteuser", "POST", {
      id: id,
      projid: this.props.auth.getProjId(),
    });

    if (res.status === 200) {
      toast.success("User removed from project.");
      this.fetchData(true);
      this.emitUpdate();
    } else {
      toast.error("Error deleting user! Please try again later.");
    }

    this.setState({ showDeleteUser: false, idxToDelete: -1 });
  }

  async updateGoal(data, cb) {
    const res = await db.request("/auth/updategoal", "POST", {
      date: data.date ? new Date(data.date).format() : null,
      name: data.name ? data.name : null,
      desc: data.desc ? data.desc : null,
      type: data.type ? data.type : null,
      color: data.color ? data.color : null,
      id: data.id,
    });

    if (res && res.status === 200) {
      this.fetchData(true, cb);
      this.emitUpdate();
    } else {
      toast.error("Unknown error, the project milestone could not be created.");
    }
  }

  getGoalCapacity(goalDate, inclVelocity) {
    var todaysIndex = this.state.startdate.daysBetween(new Date(), true);
    var goalIndex = this.state.startdate.daysBetween(goalDate) + 1;
    var disc = null;
    var result = {};

    for (var i = 0; i < this.state.data.length; i++) {
      disc = this.state.data[i].discipline;
      if (!disc) continue;

      for (var j = todaysIndex; j < goalIndex; j++) {
        var day = this.state.weekDays[j];
        var isWeekday = day > 0 && day < 6;

        if (
          this.state.data[i].joinsLeaves[j] &&
          isWeekday &&
          !this.state.holidays[j] &&
          !(
            this.state.data[i].status[j] &&
            (this.state.data[i].status[j] !== "join" ||
              this.state.data[i].status[j] !== "left")
          )
        ) {
          if (!result[disc]) {
            result[disc] = {};
            result[disc].value = 1;
            result[disc].name = this.state.disciplinesById[disc].set_name;
          } else {
            result[disc].value += 1;
          }
        }
      }
    }

    if (inclVelocity) {
      var keys = Object.keys(result);
      for (var v = 0; v < keys.length; v++) {
        if (
          this.state.velocity[keys[v] + "_velocity"] &&
          this.state.velocity[keys[v] + "_velocity"] > 0.05
        ) {
          result[keys[v]].value = Math.round(
            result[keys[v]].value / this.state.velocity[keys[v] + "_velocity"]
          );
        }
      }
    }

    return result;
  }

  ribbonClick(data) {
    this.setState({
      ribbon: data,
    });
  }

  closeInspect() {
    this.setState({ ribbon: null });
    this.fetchData(true);
  }

  // Render  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  render() {
    // var firstname = localStorage.getItem("firstname");
    // var greets = [
    //   firstname + ", sleep is important too..",
    //   "Top of the morning, " + firstname + "!",
    //   "G'day, " + firstname,
    //   "Good evening, " + firstname
    // ];
    //
    // var hour = moment().hour();
    // if (hour >= 18) hour -= 1;
    // if (hour >= 24) hour = 23;
    const hasThematics =
      this.state.ribbons &&
      this.state.ribbons["thematic"] &&
      this.state.ribbons["thematic"].length > 0;

    var fullName = "";
    if (this.state.data[this.state.idxToDelete]) {
      fullName = this.state.data[this.state.idxToDelete].firstname;
      if (this.state.data[this.state.idxToDelete].lastname) {
        fullName += " " + this.state.data[this.state.idxToDelete].lastname;
      }
    }

    return (
      <>
        <div style={{ height: this.state.winHeight }}>
          {this.state.ribbon && (
            <InspectBar
              data={this.state.ribbon}
              capacityCb={this.getGoalCapacity}
              closeCallback={this.closeInspect}
              onChange={this.updateGoal}
              deleteGoal={this.deleteGoal}
              auth={this.props.auth}
            />
          )}
          {this.state.newEmployee && (
            <Popup
              title="New employee"
              closeCallback={() => this.setState({ newEmployee: null })}
            >
              <InviteUser
                auth={this.props.auth}
                dispatch={this.props.dispatch}
                refreshCallback={() => this.fetchData(true)}
                closeCallback={() => this.setState({ newEmployee: null })}
                default={this.state.newEmployee}
              />
            </Popup>
          )}
          {this.state.inviteUser && (
            <Popup
              title="New Members"
              closeCallback={() =>
                this.setState({
                  inviteUser: false,
                  movement: null,
                })
              }
            >
              <InviteUser
                type="project"
                auth={this.props.auth}
                dispatch={this.props.dispatch}
                refreshCallback={() => this.fetchData(true)}
                closeCallback={() =>
                  this.setState({
                    inviteUser: false,
                    movement: null,
                  })
                }
              />
            </Popup>
          )}
          {this.state.addGoal && (
            <Popup
              title="New milestone"
              submitCallback={(e) => {
                this.submitGoal(e);
              }}
              closeCallback={() =>
                this.setState({
                  addGoal: false,
                  movement: null,
                })
              }
              negativeAction="cancel"
              positiveAction="CREATE"
            >
              <TextInput
                validateCallback={this.validateGoalName}
                ref={this.newGoalName}
                placeholder="Milestone name"
                maxlength="50"
                autofocus
                requireSubmit={true}
                keypressCallback={(e) => {
                  if (e.key === "Enter") {
                    this.submitGoal(e);
                  } else if (e.key === "Escape")
                    this.setState({
                      addGoal: false,
                      movement: null,
                    });
                }}
              />
              <select
                ref={this.newGoalType}
                className="form-control form-control-lg"
                style={{ marginTop: 6, marginBottom: 12 }}
                value={this.state.type}
              >
                {this.typeKeys.map((val, i) => {
                  return (
                    <option key={"opt" + i} value={val}>
                      {this.types[val]}
                    </option>
                  );
                })}
              </select>
              {hasThematics && (
                <>
                  <center>
                    <div style={{ color: "#888", marginTop: 30 }}>
                      or add from Playbook
                    </div>
                    {this.state.ribbons["thematic"].map((obj, j) => {
                      return (
                        <div
                          key={"goal" + j}
                          style={{
                            margin: 10,
                          }}
                        >
                          <Button
                            value={obj.name}
                            type="green"
                            onClick={() => {
                              this.createGoal(null, null, "thematic", obj.id);
                              this.setState({ addGoal: false, movement: null });
                            }}
                            large
                          />
                        </div>
                      );
                    })}
                    <br />
                  </center>
                </>
              )}
            </Popup>
          )}
          {this.state.addEvent && (
            <Popup
              title="New event"
              submitCallback={(e) => {
                // this.submitProjName(e);
              }}
              closeCallback={() =>
                this.setState({
                  addEvent: false,
                  movement: null,
                })
              }
              negativeAction="cancel"
              positiveAction="CREATE"
            >
              <TextInput
                validateCallback={this.validateProjName}
                ref={this.newProjName}
                placeholder="Milestone name"
                maxlength="50"
                autofocus
                requireSubmit={true}
                keypressCallback={(e) => {
                  if (e.key === "Enter") {
                    // this.submitProjName(e);
                  } else if (e.key === "Escape")
                    this.setState({
                      addEvent: false,
                      movement: null,
                    });
                }}
              />
              <TextInput
                validateCallback={this.validateProjName}
                ref={this.newProjName}
                placeholder="Description"
                maxlength="50"
                requireSubmit={true}
                keypressCallback={(e) => {
                  if (e.key === "Enter") {
                    // this.submitProjName(e);
                  } else if (e.key === "Escape")
                    this.setState({
                      addEvent: false,
                      movement: null,
                    });
                }}
              />
            </Popup>
          )}
          {this.state.showDeleteUser && (
            <Popup
              title="Confirm delete"
              closeCallback={() => {
                this.setState({ showDeleteUser: false, idxToDelete: -1 });
              }}
              submitCallback={() => {
                this.deleteUser(this.state.data[this.state.idxToDelete].userid);
              }}
              negativeAction="Nevermind"
              positiveAction="YES"
            >
              Are you sure you want to completely remove <b>{fullName}</b> from
              project <b>{this.props.auth.getProjName()}</b>?
            </Popup>
          )}

          <TimeGrid
            ref={this.timegrid}
            auth={this.props.auth}
            boxWidth={this.state.boxWidth}
            boxSpaceHoriz={2}
            boxSpaceVert={this.state.boxSpaceVert}
            daysHeight={18}
            monthHeight={44}
            originDate={this.state.orgcreated}
            startDate={this.state.startdate}
            endDate={this.state.enddate}
            data={this.state.data}
            days={this.state.days}
            weekDays={this.state.weekDays}
            holidays={this.state.holidays}
            ribbons={this.state.ribbons}
            movement={this.state.movement}
            setMovement={this.setMovement}
            myIndex={this.state.myIndex}
            loading={this.state.loading}
            nameCol={this.state.nameCol}
            submitDays={this.submitDays}
            menuSubmit={this.menuSubmit}
            clickAdd={this.clickAdd}
            setWorkday={this.setWorkday}
            deleteGoal={this.deleteGoal}
            fetchData={this.fetchData}
            submitJoinLeaveDay={this.submitJoinLeaveDay}
            showDeleteUser={this.showDeleteUser}
            addType="add"
            gridDescriptor="project"
            disableInput={this.state.ribbon}
            moveGoal={this.updateGoal}
            ribbonClick={this.ribbonClick}
            inviteUser={(obj) => {
              this.setState({ newEmployee: obj });
            }}
          />
        </div>

        {this.props.auth.requiresRole("owner") && (
          <div style={{ margin: 10, width: 150 }}>
            <Button
              value="Export time report"
              type="outline"
              onClick={() => {
                const excludes = ["join", "left", "otherwork"];

                var _data = [];
                for (var x = 0; x < this.state.data.length; x++) {
                  const person = this.state.data[x];

                  if (!_data[x]) {
                    _data[x] = {
                      name: person.firstname + " " + person.lastname,
                    };
                  }

                  for (var j = 0; j < person.status.length; j++) {
                    const day = this.state.days[j];
                    const year = day.getFullYear();
                    const month = day.getMonth() + 1;
                    const type = person.status[j];

                    if (
                      person.status[j] &&
                      !excludes.includes(person.status[j])
                    ) {
                      if (!_data[x][year]) _data[x][year] = {};

                      if (!_data[x][year][month])
                        _data[x][year][month] = { month: day.getMonthName() };

                      if (!_data[x][year][month][type])
                        _data[x][year][month][type] = 1;
                      else
                        _data[x][year][month][type] =
                          _data[x][year][month][type] + 1;
                    }
                  }
                }

                db.request("/auth/sendexportemail", "POST", {
                  data: JSON.stringify(_data),
                });
              }}
              small
            />
          </div>
        )}
      </>
    );
  }
}

export default OverviewProject;
