import React from "react";
import db from "../utils/db";
import moment from "moment";
import history from "../utils/history";
import EditCard from "../components/EditCard";
import Board from "../components/Board";
import InspectBar from "../components/InspectBar";
import Toggle from "../components/Toggle";
import ToggleDrop from "../components/ToggleDrop";
import Button from "../components/Button";
import ButtonDropdown from "../components/ButtonDropdown";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import cloudinary from "cloudinary-core";
import socket from "../utils/socket";
import update from "immutability-helper";
require("../utils/extends");

var cl = new cloudinary.Cloudinary({ cloud_name: "hcnr7jxqk" });
const columns = ["PLANNED", "IN PROGRESS", "REVIEW", "DONE"];

class Sprint extends React.Component {
  constructor(props) {
    super(props);

    const initState = this.getInitState();
    const inspectTask = props.computedMatch.params.task;

    this.state = {
      projname: this.props.auth.getProjName(),
      goals: null,
      pillars: null,
      cards: null,
      storys: null,
      users: null,
      disciplines: null,
      sprints: null,
      dayWidth: 20,
      modify: false,
      addcard: null,
      idToIdxPillar: null,
      idToIdxCard: null,
      idToIdxGoal: null,
      idToIdxUsers: null,
      idToIdxDiscs: null,
      editcard: null,
      cardSelected: -1,
      colSelected: -1,
      storySelected: -1,
      pillarSelected: null,
      epicSelected: null,
      goalSelected: null,
      ribbon: null,
      estimateByDisc: null,
      currentGoal: null,
      currentGoalIdx: null,

      days: null,
      orgcreated: initState.orgCreated,
      startdate: initState.projStart,
      enddate: initState.projEnd,
      today: initState.today,
      daysToEnd: initState.daysToEnd,
      rangeFrom: initState.rangeFrom,
      rangeTo: initState.rangeTo,
      sprintDays: 14,
      sprintHasFocus: false,
      hoverSprint: false,
      inspectTask: inspectTask,
      github: null,
      showArchived:
        localStorage.getItem(
          this.props.auth.getProjId() + "showArchivedStories"
        ) === "true",
      showMyCards:
        localStorage.getItem(this.props.auth.getProjId() + "showMyCards") ===
        "true",
      showDiscipline: JSON.parse(
        localStorage.getItem(this.props.auth.getProjId() + "showDiscipline")
      ),
      showScope: JSON.parse(
        localStorage.getItem(this.props.auth.getProjId() + "showScope") !==
          "off"
      ),
      showPriority:
        localStorage.getItem(this.props.auth.getProjId() + "showPriority") ===
        "true",

      loading: true,
    };

    this.handleKey = this.handleKey.bind(this);
    this.getInitState = this.getInitState.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.clickModify = this.clickModify.bind(this);
    this.getCards = this.getCards.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.addStory = this.addStory.bind(this);
    this.addCard = this.addCard.bind(this);
    this.editCard = this.editCard.bind(this);
    this.orderCard = this.orderCard.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.pushCard = this.pushCard.bind(this);
    this.moveCard = this.moveCard.bind(this);
    this.updateGoal = this.updateGoal.bind(this);
    this.getGoalCapacity = this.getGoalCapacity.bind(this);
    this.sprintUpdatedEvent = this.sprintUpdatedEvent.bind(this);
    this.cardDeletedEvent = this.cardDeletedEvent.bind(this);
    this.updateAnalytics = this.updateAnalytics.bind(this);

    this.timer = null;

    socket.emit("joinroom", {
      room: props.auth.getOrgId() + "sprint",
      user: props.auth.getUserName(),
    });
    socket.on("productupdated", this.sprintUpdatedEvent);
    socket.on("carddelete", this.cardDeletedEvent);

    this.fetchData();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKey, false);
    window.addEventListener("resize", this.updateDimensions);
    this.props.dispatch.addListener("orgchange", () => this.fetchData());
    this.props.dispatch.addListener("projchange", () => this.fetchData());
    this.props.dispatch.addListener("projrefresh", () => this.fetchData());
  }

  componentWillUnmount() {
    socket.emit("leaveroom", {
      room: this.props.auth.getOrgId() + "sprint",
      user: this.props.auth.getUserName(),
    });
    socket.removeListener("productupdated", this.sprintUpdatedEvent);
    socket.removeListener("carddelete", this.cardDeletedEvent);

    if (this.timer) clearTimeout(this.timer);
    document.removeEventListener("keydown", this.handleKey, false);
    window.removeEventListener("resize", this.updateDimensions);
    this.props.dispatch.removeListener("orgchange", () => this.fetchData());
    this.props.dispatch.removeListener("projchange", () => this.fetchData());
    this.props.dispatch.removeListener("projrefresh", () => this.fetchData());
  }

  // Functions  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  handleKey(e) {
    if (!this.state.editcard && !this.state.sprintHasFocus) {
      // Not editing a card
      if (e.key === "e") {
        if (this.state.cards && this.state.cards.length > 0) {
          this.setState({
            modify: !this.state.modify,
            pillarSelected: null,
            epicSelected: null,
            goalSelected: null,
          });
        }
      } else if (e.key === "1") history.push("/playbook");
      else if (e.key === "2") history.push("/overview-project");
      else if (e.key === "3") history.push("/sprint");
      else if (this.state.modify) {
        // Currently in modify mode
        var idx = null;
        var pillars = [];
        var epics = [];
        var pillar = this.state.pillarSelected;
        var epic = this.state.epicSelected;
        var goal = this.state.goalSelected;

        // Enter key
        //
        if (e.key === "Enter") {
          e.preventDefault();
          if (pillar === null) {
            this.setState({ pillarSelected: 0 });
          } else if (epic === null) {
            // Check if epics exist
            epics = this.state.pillars.filter(
              (card) =>
                card.card_type === "epic" &&
                card.card_parent ===
                  this.state.pillars[this.state.pillarSelected].card_id
            );
            if (epics.length > 0) {
              this.setState({ epicSelected: 0 });
            } else {
              this.setState({ epicSelected: -1, goalSelected: 0 });
            }
          } else if (goal === null) {
            this.setState({ goalSelected: 0 });
          } else {
            this.addStory();
          }
        }

        // Arrow right key
        //
        if (e.key === "ArrowRight") {
          if (pillar === null) {
            e.preventDefault();
            // this.setState({ pillarSelected: 0 });
          } else if (epic === null) {
            e.preventDefault();
            // Navigate pillars
            pillars = this.state.pillars.filter(
              (card) => card.card_type === "pillar"
            );
            if (pillar < pillars.length - 1) pillar++;
            else pillar = 0;
            // this.setState({ pillarSelected: pillar });
          } else if (goal === null) {
            e.preventDefault();
            // Navigate epics
            epics = this.state.pillars.filter(
              (card) =>
                card.card_type === "epic" &&
                card.card_parent ===
                  this.state.pillars[this.state.pillarSelected].card_id
            );
            if (epic < epics.length - 1) epic++;
            else epic = 0;
            this.setState({ epicSelected: epic });
          } else {
            e.preventDefault();
            if (goal < this.state.goals.length - 1) goal++;
            else goal = 0;
            this.setState({ goalSelected: goal });
          }
        }

        // Arrow left key
        //
        if (e.key === "ArrowLeft") {
          if (pillar === null) {
            e.preventDefault();
            pillars = this.state.pillars.filter(
              (card) => card.card_type === "pillar"
            );
            // this.setState({ pillarSelected: pillars.length - 1 });
          } else if (epic === null) {
            e.preventDefault();
            // Navigate pillars
            pillars = this.state.pillars.filter(
              (card) => card.card_type === "pillar"
            );
            if (pillar > 0) pillar--;
            else pillar = pillars.length - 1;
            // this.setState({ pillarSelected: pillar });
          } else if (goal === null) {
            e.preventDefault();
            // Navigate epics
            epics = this.state.pillars.filter(
              (card) =>
                card.card_type === "epic" &&
                card.card_parent ===
                  this.state.pillars[this.state.pillarSelected].card_id
            );
            if (epic > 0) epic--;
            else epic = epics.length - 1;
            this.setState({ epicSelected: epic });
          } else {
            e.preventDefault();
            if (goal > 0) goal--;
            else goal = this.state.goals.length - 1;
            this.setState({ goalSelected: goal });
          }
        }

        // Escape key
        //
        if (e.key === "Escape") {
          e.preventDefault();
          if (goal !== null) {
            this.setState({ goalSelected: null });
          } else if (epic !== null) {
            this.setState({ epicSelected: null });
          } else if (pillar !== null) {
            this.setState({ pillarSelected: null });
          }
        }

        //
      } else {
        if (!this.state.ribbon && this.state.cards) {
          var stories = this.state.cards.filter(
            (card) => card.card_type === "story" && card.card_status === "-1"
          );

          if (e.key === "a") {
            if (this.state.storySelected > -1) {
              this.addCard("task", stories[this.state.storySelected].card_id);
            }
          } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault();
            idx = this.state.storySelected;
            if (this.state.cardSelected === -1) {
              // Navigating among Storys/Sprintgoals
              if (e.key === "ArrowDown") {
                if (idx < stories.length - 1) idx++;
                else idx = 0;
              } else {
                if (idx > 0) idx--;
                else idx = stories.length - 1;
              }
              // this.setState({ storySelected: idx });
            } else {
              // Navigating INSIDE a Story/Sprintgoal
              var column = this.state.colSelected;
              var card = this.state.cardSelected;
              var allColumnsArr = stories[this.state.storySelected].children;
              var columnArr = allColumnsArr[column];

              if (e.key === "ArrowDown") {
                if (card < columnArr.length - 1) {
                  card++;
                } else {
                  if (column < allColumnsArr.length - 1) {
                    for (var i = column + 1; i < allColumnsArr.length; i++) {
                      if (allColumnsArr[i].length > 0) {
                        column = i;
                        card = 0;
                        break;
                      }
                    }
                  }
                }
              } else {
                if (card > 0) {
                  card--;
                } else if (column > 0) {
                  for (var j = column - 1; j >= 0; j--) {
                    if (allColumnsArr[j].length > 0) {
                      column = j;
                      card = allColumnsArr[j].length - 1;
                      break;
                    }
                  }
                }
              }
              this.setState({ cardSelected: card, colSelected: column });
            }
          } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            if (e.metaKey || e.ctrlKey) {
              e.preventDefault();
              if (this.state.cardSelected > -1) {
                var movee =
                  stories[this.state.storySelected].children[
                    this.state.colSelected
                  ][this.state.cardSelected];
                var moveToStatus = movee.card_status;
                const moveFromStatus = movee.card_status;

                if (e.key === "ArrowLeft") {
                  if (movee.card_status > 0) {
                    moveToStatus--;
                    this.moveCard(
                      {
                        id: stories[this.state.storySelected].card_id,
                        idx: moveToStatus,
                        prevIdx: moveFromStatus,
                      },
                      { id: movee.card_id }
                    );

                    // Make highlight follow
                    var newPlaceInCol =
                      stories[this.state.storySelected].children[
                        this.state.colSelected - 1
                      ].length;
                    this.setState({
                      colSelected: this.state.colSelected - 1,
                      cardSelected: newPlaceInCol,
                    });
                  }
                } else {
                  if (movee.card_status < 3) {
                    moveToStatus++;
                    this.moveCard(
                      {
                        id: stories[this.state.storySelected].card_id,
                        idx: moveToStatus,
                        prevIdx: moveFromStatus,
                      },
                      { id: movee.card_id }
                    );

                    // Make highlight follow
                    var newPlaceInCol2 =
                      stories[this.state.storySelected].children[
                        this.state.colSelected + 1
                      ].length;
                    this.setState({
                      colSelected: this.state.colSelected + 1,
                      cardSelected: newPlaceInCol2,
                    });
                  }
                }
              }
            } else {
              if (this.state.cardSelected > -1) {
                e.preventDefault();
                // Navigating INSIDE a Story/Sprintgoal
                column = this.state.colSelected;
                card = this.state.cardSelected;
                allColumnsArr = stories[this.state.storySelected].children;
                columnArr = allColumnsArr[column];

                if (e.key === "ArrowRight") {
                  if (card < columnArr.length - 1) {
                    card++;
                  } else {
                    if (column < allColumnsArr.length - 1) {
                      for (var c = column + 1; c < allColumnsArr.length; c++) {
                        if (allColumnsArr[c].length > 0) {
                          column = c;
                          card = 0;
                          break;
                        }
                      }
                    }
                  }
                } else {
                  if (card > 0) {
                    card--;
                  } else if (column > 0) {
                    for (var h = column - 1; h >= 0; h--) {
                      if (allColumnsArr[h].length > 0) {
                        column = h;
                        card = allColumnsArr[h].length - 1;
                        break;
                      }
                    }
                  }
                }
                // this.setState({ cardSelected: card, colSelected: column });
              }
            }
          } else if (e.key === "Enter") {
            e.preventDefault();
            if (e.metaKey || e.ctrlKey) {
              // If Cmd or Ctrl held down, go edit Story card
              // if (this.state.storySelected > -1) {
              //   this.setState({
              //     editcard: stories[this.state.storySelected].card_id,
              //   });
              // }
            } else {
              if (this.state.storySelected > -1) {
                var col2 = stories[this.state.storySelected].children.findIndex(
                  (val) => val.length > 0
                );
                // Enter to go into a Story and navigate Tasks

                if (col2 > -1) {
                  if (this.state.cardSelected === -1) {
                    // this.setState({ colSelected: col2, cardSelected: 0 });
                  } else {
                    // this.setState({
                    //   editcard:
                    //     stories[this.state.storySelected].children[
                    //       this.state.colSelected
                    //     ][this.state.cardSelected].card_id,
                    // });
                  }
                }
              } else {
                // if (stories.length > 0) this.setState({ storySelected: 0 });
              }
            }
          } else if (!e.metaKey && !e.ctrlKey) {
            if (e.key === "Escape" && this.state.cardSelected > -1) {
              this.setState({ cardSelected: -1 });
            } else {
              this.setState({
                storySelected: -1,
                colSelected: -1,
                cardSelected: -1,
              });
            }
          }
        }
      }
    }
  }

  getInitState() {
    var initState = {};
    var today = new Date();
    var orgCreated = new Date(this.props.auth.getOrgCreation());
    var projStart = new Date(this.props.auth.getProjStart());
    var projEnd = new Date(this.props.auth.getProjEnd());

    initState.capacityData = [];
    initState.days = [];
    initState.weekDays = [];
    initState.modify = false;
    initState.orgCreated = orgCreated;
    initState.projStart = projStart;
    initState.projEnd = projEnd;
    initState.today = today;
    initState.daysToEnd = projStart.daysBetween(projEnd) + 1;
    initState.rangeFrom = orgCreated.daysBetween(projStart);
    initState.rangeTo = orgCreated.daysBetween(projEnd) + 1;
    return initState;
  }

  async fetchData(cb) {
    var goals = null;
    var pillars = [];
    var users = [];
    var discs = [];
    var sprints = [];
    var idToIdxPillar = {};
    var idToIdxGoal = {};
    var idToIdxUsers = {};
    var idToIdxDiscs = {};
    var currentGoal = null;
    var currentGoalIdx = -1;

    // Get all org specific playbook info
    const req = await db.request("/auth/getplaybook", "POST", {
      org: this.props.auth.getOrgId(),
    });
    const _goal = req.data.find((val) => {
      return val.set_name === "goal";
    });
    const currentTeamGoal = parseInt(_goal.set_value, 10);
    currentGoal = currentTeamGoal;

    // Get my goal from cookies
    var myGoal = localStorage.getItem("myGoal");
    var prevTeamGoal = localStorage.getItem("prevTeamGoal");
    prevTeamGoal = prevTeamGoal ? parseInt(prevTeamGoal, 10) : null;
    myGoal = myGoal ? parseInt(myGoal, 10) : null;

    // Get all pillars
    const resPillars = await db.request("/auth/getcards", "POST", {
      project: this.props.auth.getProjId(),
      type: "pillar",
    });

    if (resPillars && resPillars.status === 200 && resPillars.data.length > 0) {
      for (var i = 0; i < resPillars.data.length; i++) {
        var card = resPillars.data[i];
        pillars.push(card);
        idToIdxPillar[card.card_id] = i;

        if (card.card_type === "pillar") {
          pillars[pillars.length - 1].children = [];
        } else {
          pillars[idToIdxPillar[card.card_parent]].children.push(card);
        }
      }
    }

    // Get all goals
    const resGoals = await db.request("/auth/getgoals", "POST", {
      projid: this.props.auth.getProjId(),
      type: "thematic",
    });

    if (resGoals && resGoals.status === 200 && resGoals.data.length > 0) {
      goals = resGoals.data;
      for (var j = 0; j < goals.length; j++) {
        var goal = goals[j];
        idToIdxGoal[goal.goal_id] = goal;

        if (myGoal && myGoal === goal.goal_id) {
          currentGoal = myGoal;
          currentGoalIdx = j;
        } else if (currentGoal === goal.goal_id) {
          currentGoalIdx = j;
        }
      }

      // If there's a new goal set as current by team, switch to it
      // if (prevTeamGoal !== null && prevTeamGoal !== currentTeamGoal) {
      //   currentGoal = currentTeamGoal;
      //   currentGoalIdx = goals.findIndex(
      //     (val) => val.goal_id === currentTeamGoal
      //   );
      //   localStorage.removeItem("myGoal");
      //   localStorage.removeItem("prevTeamGoal");
      // }
    }

    // Get all users
    const resUsers = await db.request("/auth/getusers", "POST", {
      group: "project",
      org: this.props.auth.getOrgId(),
      project: this.props.auth.getProjId(),
    });

    if (resUsers && resUsers.rowCount > 0) {
      users = resUsers.rows;
      for (var k = 0; k < users.length; k++) {
        var user = users[k];
        idToIdxUsers[user.user_id] = k;
      }
    }

    // Get all disciplines
    const resDiscs = await db.request("/auth/getdisciplines", "POST", {
      org: this.props.auth.getOrgId(),
    });

    if (resDiscs && resDiscs.status === 200 && resDiscs.data.length > 0) {
      discs = resDiscs.data;
      for (var m = 0; m < discs.length; m++) {
        var disc = discs[m];
        idToIdxDiscs[disc.set_id] = m;
      }
    }

    // Get any github connection
    const github = await db.request("/auth/getsetting", "POST", {
      orgid: this.props.auth.getOrgId(),
      type: "github",
    });

    const theGoal = idToIdxGoal[currentGoal];
    const goalDate =
      theGoal && theGoal.goal_date ? new Date(theGoal.goal_date) : -1;
    const allDaysBetween =
      goalDate < new Date()
        ? -new Date().daysBetween(goalDate)
        : new Date().daysBetween(goalDate);

    const daysTo =
      allDaysBetween +
      1 -
      Math.abs(new Date().offWeekend().countWeekends(goalDate));

    this.setState(
      {
        goals: goals,
        pillars: pillars,
        users: users,
        disciplines: discs,
        sprints: sprints,
        idToIdxPillar: idToIdxPillar,
        idToIdxGoal: idToIdxGoal,
        idToIdxUsers: idToIdxUsers,
        idToIdxDiscs: idToIdxDiscs,
        currentGoal: _goal ? currentGoal : -1,
        currentGoalIdx: _goal ? currentGoalIdx : -1,
        daysToNextGoal: theGoal && theGoal.goal_date ? daysTo : -1,
        goalDate: theGoal && theGoal.goal_date ? theGoal.goal_date : -1,
        github:
          github && github.status === 200 ? JSON.parse(github.value) : null,
      },
      () => {
        this.getCards(cb);
      }
    );
  }

  async getCards(cb) {
    var cards = [];
    var idToIdxCard = {};
    var estimateByDisc = {};

    // Get all cards for current sprint
    var resCards = await db.request("/auth/getcards", "POST", {
      type: "cards",
      project: this.props.auth.getProjId(),
      goal: this.state.currentGoal,
    });

    // Get historical average velocities for all card categories
    const resVelocs = await db.request("/auth/getvelocity", "POST", {
      proj: this.props.auth.getProjId(),
    });

    if (resCards && resCards.status === 200) {
      for (var i = 0; i < resCards.data.length; i++) {
        var card = resCards.data[i];

        cards.push(card);
        idToIdxCard[card.card_id] = i;

        if (card.card_type === "story") {
          cards[cards.length - 1].children = [];
          for (var x = 0; x < columns.length; x++) {
            cards[cards.length - 1].children.push([]);
          }
        } else if (card.card_type === "task") {
          var status =
            card.card_status === null || card.card_status === undefined
              ? 0
              : parseInt(card.card_status, 10);
          const parentStory = cards[idToIdxCard[card.card_parent]];
          if (!parentStory) continue;
          const parentEpic = cards[idToIdxCard[parentStory.card_parent]];
          const parentPillar = cards[idToIdxCard[parentEpic.card_parent]];

          // Increment time projection for discipline if...
          //  - a discipline is assigned
          //  - a historical median of actual time spent exists
          //  - the task is in Planned column
          //  - It's parent is NOT archived, hence active
          if (
            card.card_discipline &&
            resVelocs.data[card.card_discipline + "_median"] &&
            status === 0 &&
            parentStory.card_status !== "-1" &&
            parentPillar.card_goal === this.state.currentGoal
          ) {
            if (!estimateByDisc[card.card_discipline])
              estimateByDisc[card.card_discipline] = 0;

            if (card.card_estimate) {
              // Task has estimate, add that instead of median
              var estimate = card.card_estimate * 60 * 60 * 1000; // convert from hours
              estimateByDisc[card.card_discipline] +=
                estimate * resVelocs.data[card.card_discipline + "_velocity"]; // apply estimation velocity for discipline
            } else {
              // Task has NO estimate, use median of actual historical time spent in progress
              estimateByDisc[card.card_discipline] +=
                resVelocs.data[card.card_discipline + "_median"];
            }
          }

          cards[idToIdxCard[card.card_parent]].children[status].push(card);
        }
      }
    }

    // FETCH CAPACITY
    // On e.g. org change to org without projs, switch to /peepz

    var initState = this.getInitState();

    var years = [];
    var year = initState.projStart.getFullYear();
    while (year <= initState.projEnd.getFullYear()) {
      years.push(year);
      year++;
    }

    // Get public holidays
    const resHolidays = await db.request("/auth/getholidays", "POST", {
      orgid: this.props.auth.getOrgId(),
      proj: this.props.auth.getProjId(),
      years: years,
    });

    const peepzData = await db.request("/auth/getdays", "POST", {
      email: localStorage.getItem("email"),
      projid: this.props.auth.getProjId(),
      orgid: this.props.auth.getOrgId(),
      from: initState.rangeFrom - 1,
      to: initState.rangeTo,
    });

    // ORGANIZER LOOP
    var mainArr = [];
    var rowIdx = 0;
    var currUserId = peepzData.data.length > 0 ? peepzData.data[0].user_id : -1;
    var maxName = 0;
    var joined = false;
    var pointer = 0;
    var joinsLeaves = new Array(initState.daysToEnd).fill(false);
    var status = new Array(initState.daysToEnd).fill(null);
    var dayid = new Array(initState.daysToEnd).fill(-1);
    var date = new Array(initState.daysToEnd).fill(null);
    var holidays = new Array(initState.daysToEnd).fill(null);
    var myUID = this.props.auth.getUserId();
    var myIdx = -1;

    // Holidays
    for (var p = 0; p < resHolidays.data.length; p++) {
      var holiDate = new Date(resHolidays.data[p].date);
      if (
        holiDate.isSameOrAfterDay(initState.projStart) &&
        holiDate.isSameOrBeforeDay(initState.projEnd) &&
        resHolidays.data[p].enabled
      ) {
        var daysFromProjStart = holiDate.daysBetween(initState.projStart);
        holidays[daysFromProjStart] = resHolidays.data[p];
      }
    }

    for (var i = 0; i < peepzData.data.length; i++) {
      var row = peepzData.data[i];
      var isLast = i === peepzData.data.length - 1; // Check if last post for user, switch row/user

      // First fill straight fwd arrays
      status[row.dayz_fromstart - initState.rangeFrom] = row.dayz_status;
      dayid[row.dayz_fromstart - initState.rangeFrom] = row.dayz_id;
      date[row.dayz_fromstart - initState.rangeFrom] = row.dayz_date;
      if (myIdx === -1 && row.user_id === myUID) myIdx = rowIdx;
      if (!joined && row.dayz_status === "join") {
        joined = true;
        pointer = row.dayz_fromstart;
      } else if (joined && row.dayz_status === "left") {
        joined = false;
        joinsLeaves.fill(
          true,
          pointer - initState.rangeFrom,
          row.dayz_fromstart - initState.rangeFrom + 1
        );
      }

      if (
        (peepzData.data[i + 1] &&
          peepzData.data[i + 1].user_id !== currUserId) ||
        isLast
      ) {
        var final = {
          role: row.attr_value,
          userid: row.user_id,
          firstname: row.user_firstname,
          lastname: row.user_lastname,
          email: row.user_email,
          consultant: row.user_consultant,
          joinsLeaves: joinsLeaves,
          status: status,
          dayid: dayid,
          date: date,
        };
        mainArr.push(final);

        if (row.user_firstname.length > maxName)
          maxName = row.user_firstname.length;

        if (!isLast) {
          joinsLeaves = new Array(initState.daysToEnd).fill(false);
          status = new Array(initState.daysToEnd).fill(null);
          dayid = new Array(initState.daysToEnd).fill(-1);
          date = new Array(initState.daysToEnd).fill(null);

          currUserId = peepzData.data[i + 1].user_id;
          joined = false;
          rowIdx++;
        }
      }
    }

    // Create days array for reference
    var days = [];
    var weekDays = [];
    for (
      var d = new Date(initState.projStart.getTime());
      d <= initState.projEnd;
      d.setDate(d.getDate() + 1)
    ) {
      var newD = new Date(d);
      days.push(newD);
      weekDays.push(newD.getDay());
    }

    // Get goal capacity
    const goalCapacity = this.getGoalCapacity(
      this.state.goalDate,
      mainArr,
      weekDays,
      holidays
    );

    var stories = cards.filter(
      (card) => card.card_type === "story" && card.card_status !== "-1"
    );

    // Mark outOfScope cards
    var currEstPerDisc = {};
    for (var j = 0; j < stories.length; j++) {
      const story = stories[j];
      const parentEpic = cards[idToIdxCard[story.card_parent]];
      const parentPillar = cards[idToIdxCard[parentEpic.card_parent]];

      if (
        story.card_status === "-1" ||
        (parentPillar && parentPillar.card_goal !== this.state.currentGoal)
      )
        continue;

      // Calculate in priority top to bottom, only non-archived tasks
      for (var k = 0; k < story.children[0].length; k++) {
        const task = story.children[0][k];
        if (!task.card_discipline) continue;

        if (!currEstPerDisc[task.card_discipline])
          currEstPerDisc[task.card_discipline] = 0;

        const _estimate = task.card_estimate
          ? task.card_estimate *
            60 *
            60 *
            1000 *
            resVelocs.data[task.card_discipline + "_velocity"]
          : resVelocs.data[task.card_discipline + "_median"];

        currEstPerDisc[task.card_discipline] += _estimate;
        task.outOfScope =
          currEstPerDisc[task.card_discipline] /
            (1000 * 60 * 60 * 8) /
            goalCapacity[task.card_discipline] >
            1 && this.state.showScope;
      }
    }
    console.log(cards);
    this.setState(
      {
        cards: cards,
        idToIdxCard: idToIdxCard,
        storys: stories,
        estimateByDisc: estimateByDisc,

        goalCapacity: goalCapacity,
        days: days,
        orgcreated: initState.orgCreated,
        startdate: initState.projStart,
        enddate: initState.projEnd,
        today: initState.today,
        daysToEnd: initState.daysToEnd,
        rangeFrom: initState.rangeFrom,
        rangeTo: initState.rangeTo,
        loading: false,
      },
      () => {
        if (cb) cb();

        if (this.state.inspectTask) {
          this.setState({
            editcard: this.state.idToIdxCard[this.state.inspectTask]
              ? this.state.inspectTask
              : null,
          });
        }
      }
    );
  }

  clickModify() {
    this.setState({
      modify: !this.state.modify,
      pillarSelected: null,
      epicSelected: null,
      goalSelected: null,
    });
  }

  addStory(epicId) {
    this.addCard("story", epicId);
    this.setState({
      pillarSelected: null,
      epicSelected: null,
      goalSelected: null,
      modify: null,
    });
    this.emitSprintUpdate();
  }

  async addCard(type, parentId) {
    const resAdd = await db.request("/auth/addcard", "POST", {
      type: type,
      parent: parentId ? parentId : null,
      project: this.props.auth.getProjId(),
      goal:
        this.state.goalSelected !== null
          ? this.state.goals[this.state.goalSelected].goal_id
          : null,
    });

    if (resAdd.status === 200) {
      this.fetchData(() => {
        this.emitSprintUpdate();
        this.setState({
          editcard: resAdd.data[0].card_id,
        });
      });
    }
  }

  async editCard({
    id,
    name,
    desc,
    owner,
    goal,
    disc,
    estimate,
    estType,
    github,
    status,
    priority,
  }) {
    const resEdit = await db.request("/auth/editcard", "POST", {
      id: id,
      name: name ? name : null,
      desc: desc ? desc : null,
      owner: owner ? owner : null,
      goal: goal ? goal : null,
      disc: disc ? disc : null,
      estimate: estimate ? estimate : null,
      estType: estType ? estType : null,
      github: github,
      status: status ? status : null,
      priority: priority ? priority : null,
    });

    if (resEdit.status === 200) {
      this.emitSprintUpdate();
      this.fetchData();
    }
  }

  async orderCard(id, following, oldAbove) {
    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    // TODO: I can't get manual ordering to work properly, so giving up
    // const resEdit = await db.request("/auth/ordercard", "POST", {
    //   id: id,
    //   following: following,
    //   oldAbove: oldAbove,
    // });
    //
    // if (resEdit.status === 200) {
    //   this.emitSprintUpdate();
    //   this.fetchData();
    // }
  }

  async deleteCard(id) {
    const card = this.state.cards[this.state.idToIdxCard[id]];

    if (
      !card.card_name ||
      window.confirm(
        "Are you sure you want to delete " +
          card.card_name.toUpperCase() +
          " and ALL of its CHILDREN?"
      )
    ) {
      const resDel = await db.request("/auth/deletecard", "POST", {
        id: id,
      });

      if (resDel.status === 200) {
        this.emitSprintUpdate(id);
        this.fetchData();
        this.setState({ editcard: null });
      }
    }
  }

  async pushCard(id) {
    var nextGoalId = -1;
    const card = this.state.cards[this.state.idToIdxCard[id]];

    if (
      window.confirm(
        "Are you sure you want to push out the Story: " +
          card.card_name.toUpperCase() +
          ", to the next goal in Playbook?"
      )
    ) {
      if (this.state.currentGoalIdx + 1 === this.state.goals.length) {
        // There is no next goal, create a placeholder goal to move card into
        const resGoal = await db.request("/auth/addgoal", "POST", {
          projectid: this.props.auth.getProjId(),
          type: "thematic",
          name: "Placeholder",
          desc: "",
        });
        if (resGoal && resGoal.status === 200) {
          nextGoalId = resGoal.data[0].goal_id;
        }
      } else {
        nextGoalId = this.state.goals[this.state.currentGoalIdx + 1].goal_id;
      }

      if (card.card_type === "story") {
        // Is there a pillar in next goal derived from selected story's pillar
        const epic = this.state.cards[this.state.idToIdxCard[card.card_parent]];
        const pillar = this.state.cards[
          this.state.idToIdxCard[epic.card_parent]
        ];

        const resPush = await db.request("/auth/pushcard", "POST", {
          story: card.card_id,
          epic: epic.card_id,
          pillar: pillar.card_id,
          toGoal: nextGoalId,
        });

        if (resPush && resPush.status === 200) {
          this.emitSprintUpdate(id);
          this.fetchData();
        }
      }
    }
  }

  async moveCard(props, item, priority) {
    var prevStatus = null;
    if (props.prevIdx) prevStatus = parseInt(props.prevIdx, 10);
    else if (item.prevStatus) prevStatus = parseInt(item.prevStatus, 10);

    const resDel = await db.request("/auth/movecard", "POST", {
      task: item.id,
      story: props.id,
      status: props.idx,
      prevStatus: prevStatus,
      priority,
    });

    if (resDel && resDel.status === 200) {
      this.emitSprintUpdate();
      this.fetchData();

      // If card moved from In Progress, update analytics/velocity data
      if (prevStatus === 1) this.updateAnalytics();
    }
  }

  async updateAnalytics() {
    // ---------------
    // 1. Get velocities per discipline

    const resVelo = await db.request("/auth/getanalytics", "POST", {
      project: this.props.auth.getProjId(),
    });
    var resArr = {};
    var totalArr = [];
    var totalEst = [];

    Object.keys(resVelo.data).forEach((disc, i) => {
      resArr[disc] = {};
      resArr[disc].id = resVelo.data[disc].id;

      Object.keys(resVelo.data[disc]).forEach((stat, j) => {
        if (stat === "id") return;

        resArr[disc][stat] = {};
        const arr = resVelo.data[disc][stat];
        const arrSort = arr.slice();
        arrSort.sort((a, b) => a - b);
        const mid = Math.ceil(arrSort.length / 2);

        resArr[disc][stat].average =
          Math.round((arr.reduce((a, b) => a + b) / arr.length) * 100) / 100;

        resArr[disc][stat].medianRaw =
          Math.round(
            (arrSort.length % 2 === 0
              ? (arrSort[mid] + arrSort[mid - 1]) / 2
              : arrSort[mid - 1]) * 100
          ) / 100;

        resArr[disc][stat].deviation =
          Math.round(
            Math.sqrt(
              arrSort
                .map((x) => Math.pow(x - resArr[disc][stat].average, 2))
                .reduce((a, b) => a + b) / arrSort.length
            ) * 100
          ) / 100;

        if (!["estimated", "id"].includes(stat)) {
          resArr[disc][stat].average = (
            resArr[disc][stat].average /
            (1000 * 60)
          ).convertMinutes();
          resArr[disc][stat].median = (
            resArr[disc][stat].medianRaw /
            (1000 * 60)
          ).convertMinutes();
          resArr[disc][stat].deviation = (
            resArr[disc][stat].deviation /
            (1000 * 60)
          ).convertMinutes();
        }
      });

      if (resVelo.data[disc]["1"] && resVelo.data[disc]["estimated"]) {
        resArr[disc].amountEstimated = resVelo.data[disc]["estimated"].length;
        resArr[disc].amountInProgress = resVelo.data[disc]["1"].length;
        totalArr = totalArr.concat(resVelo.data[disc]["1"]);
      }

      if (resVelo.data[disc]["estimated"]) {
        totalEst = totalEst.concat(resVelo.data[disc]["estimated"]);
      }
    });

    // One more time for the all disciplines combined
    resArr.total = {};
    const totalSort = totalArr.slice();
    totalSort.sort((a, b) => a - b);
    const totalMid = Math.ceil(totalSort.length / 2);

    if (totalArr.length > 0) {
      resArr.total.average =
        Math.round((totalArr.reduce((a, b) => a + b) / totalArr.length) * 100) /
        100;
      resArr.total.median =
        Math.round(
          (totalSort.length % 2 === 0
            ? (totalSort[totalMid] + totalSort[totalMid - 1]) / 2
            : totalSort[totalMid - 1]) * 100
        ) / 100;
      resArr.total.deviation =
        Math.round(
          Math.sqrt(
            totalSort
              .map((x) => Math.pow(x - resArr.total.average, 2))
              .reduce((a, b) => a + b) / totalSort.length
          ) * 100
        ) / 100;
    }

    // Last time for the all disciplines estimations combined
    const estSort = totalEst.slice();
    estSort.sort((a, b) => a - b);
    const estMid = Math.ceil(estSort.length / 2);
    resArr.total.velocity =
      Math.round(
        (estSort.length % 2 === 0
          ? (estSort[estMid] + estSort[estMid - 1]) / 2
          : estSort[estMid - 1]) * 100
      ) / 100;
    resArr.total.amountEst = estSort.length;

    const veloFormat = {};
    Object.keys(resArr).forEach((disc, k) => {
      if (disc === "total" || !resArr[disc].estimated) return;
      veloFormat[resArr[disc].id + "_velocity"] =
        resArr[disc].estimated.medianRaw;
      if (resArr[disc]["1"])
        veloFormat[resArr[disc].id + "_median"] = resArr[disc]["1"].medianRaw;
    });

    //----------------------
    // 2. Get general averages for cards and pillars etc.

    var _cards = [];
    var _pillars = [];
    var _epics = [];
    var _stories = [];
    var idToIdx = {};
    var _tshirtSizes = {};

    const resCards = await db.request("/auth/getcards", "POST", {
      project: this.props.auth.getProjId(),
      type: "all",
    });

    if (
      resCards &&
      resCards.status === 200 &&
      resCards.data &&
      resCards.data.length > 0
    ) {
      for (var i = 0; i < resCards.data.length; i++) {
        var card = resCards.data[i];
        card.children = [];
        idToIdx[card.card_id] = i;

        if (card.card_parent && idToIdx[card.card_parent] !== undefined) {
          _cards[idToIdx[card.card_parent]].children.push(card);
          card.parent = _cards[idToIdx[card.card_parent]].card_name;
        }

        _cards.push(card);

        if (card.card_type === "pillar") _pillars.push(card);
        else if (card.card_type === "epic") _epics.push(card);
        else if (card.card_type === "story") _stories.push(card);
      }
    }

    // Calculate average amount of Epics per Pillar
    var amountEpics = [];
    _pillars.forEach((pillar) => {
      amountEpics.push(pillar.children.length);
    });
    const avgEpics = amountEpics.reduce((a, b) => a + b) / amountEpics.length;

    // Calculate average amount of Stories per Epic
    var amountStories = [];
    _epics.forEach((epic) => {
      amountStories.push(epic.children.length);
    });
    const avgStories =
      amountStories.reduce((a, b) => a + b) / amountStories.length;

    // Calculate average amount of Tasks per Story
    var amountTasks = [];
    _stories.forEach((story) => {
      amountTasks.push(story.children.length);

      // ALSO: get averages for Stories tshirt estimates
      if (story.card_estimate && story.card_estimate > -1) {
        if (!_tshirtSizes[story.card_estimate])
          _tshirtSizes[story.card_estimate] = {};
        _tshirtSizes[story.card_estimate].amountTasks = story.children.length;
      }
    });
    const avgTasks = amountTasks.reduce((a, b) => a + b) / amountTasks.length;

    veloFormat.avgEpics = Math.round(avgEpics * 10) / 10;
    veloFormat.avgStories = Math.max(1, Math.round(avgStories * 10) / 10);
    veloFormat.avgTasks = Math.round(avgTasks * 10) / 10;
    veloFormat.avgCardTime = resArr.total.median;

    //---------------------
    // 3. Store both in settings > velocity

    // Save velocity to DB
    await db.request("/auth/setvelocity", "POST", {
      proj: this.props.auth.getProjId(),
      org: this.props.auth.getOrgId(),
      velocity: veloFormat,
    });
  }

  updateDimensions() {
    this.setState({ winWidth: window.innerWidth });
  }

  async updateGoal(data) {
    const res = await db.request("/auth/updategoal", "POST", {
      date: data.date ? moment(data.date).format("DD MM YYYY") : null,
      name: data.name,
      desc: data.desc,
      type: data.type,
      id: data.id,
    });

    if (res.status === 200) {
      this.emitSprintUpdate();
      this.fetchData();
    } else {
      toast.error("Unknown error, the project milestone could not be created.");
    }
  }

  getGoalCapacity(goalDate, capacityData, weekDays, holidays) {
    var todaysIndex = this.state.startdate.daysBetween(new Date(), true);
    var goalIndex = this.state.startdate.daysBetween(goalDate) + 1;
    var currRole = "";
    var result = {};

    for (var i = 0; i < capacityData.length; i++) {
      currRole = capacityData[i].role;

      for (var j = todaysIndex; j < goalIndex; j++) {
        var day = weekDays[j];
        var isWeekday = day > 0 && day < 6;

        if (
          capacityData[i].joinsLeaves[j] &&
          isWeekday &&
          !holidays[j] &&
          !(
            capacityData[i].status[j] &&
            (capacityData[i].status[j] !== "join" ||
              capacityData[i].status[j] !== "left")
          )
        ) {
          if (!result[currRole]) {
            result[currRole] = 1;
          } else {
            result[currRole]++;
          }
        }
      }
    }

    return result;
  }

  setTimer() {
    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.props.onChange) this.props.onChange(this.state);
    }, 2000);
  }

  emitSprintUpdate(deleteId) {
    socket.emit("productupdated", {
      room: this.props.auth.getOrgId() + "sprint",
      name: this.props.auth.getUserName(),
      deleteId: deleteId,
    });
  }

  sprintUpdatedEvent(author) {
    console.log("Sprint update by " + author);
    this.setState({
      storySelected: -1,
      colSelected: -1,
      cardSelected: -1,
    });
    this.fetchData();
    this.forceUpdate();
  }

  cardDeletedEvent(deleteId) {
    if (this.state.editcard === deleteId) {
      this.setState({ editcard: null });
      this.fetchData();
    }
  }

  // Render  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  render() {
    var epicList = [];
    if (this.state.pillarSelected !== null) {
      epicList = this.state.pillars[this.state.pillarSelected].children.map(
        (val, i) => {
          var cls = "btn btn-sm";
          var prefix = "";
          if (this.state.epicSelected === i) {
            cls += " btn-success";
            prefix = <i className="fas fa-check" />;
          } else {
            cls += " btn-light";
          }
          return (
            <button
              key={"epic" + i}
              className={cls}
              style={{
                fontSize: 11,
                marginRight: 4,
                marginTop: 4,
              }}
              onClick={() => {
                this.setState({
                  epicSelected: i,
                });
              }}
            >
              {prefix}{" "}
              {this.state.pillars[this.state.idToIdxPillar[val]].card_name}
            </button>
          );
        }
      );
      if (
        !this.state.pillars[this.state.pillarSelected].children ||
        this.state.pillars[this.state.pillarSelected].children.length === 0
      ) {
        epicList.push(
          <div
            key="noEpics"
            style={{
              display: "inline-block",
              fontSize: 12,
              verticalAlign: "middle",
              color: "#888",
            }}
          >
            <i>No EPICs yet added</i>
          </div>
        );
      }
    }

    const nextGoal =
      this.state.goals &&
      this.state.goals.length > 1 &&
      this.state.currentGoalIdx < this.state.goals.length - 1;
    const prevGoal = this.state.currentGoalIdx > 0;

    var editData = null;
    if (this.state.editcard)
      editData = this.state.cards[this.state.idToIdxCard[this.state.editcard]];

    return this.state.loading ? (
      <div style={{ marginTop: 100 }}>
        <Loading isLoading={true} />
      </div>
    ) : (
      <div>
        {this.state.ribbon && (
          <InspectBar
            data={this.state.ribbon}
            capacityCb={this.getGoalCapacity}
            closeCallback={() => {
              this.setState({ ribbon: null });
              this.fetchData();
            }}
            onChange={this.updateGoal}
            auth={this.props.auth}
          />
        )}
        {this.state.editcard && this.state.cards && (
          <EditCard
            id={this.state.editcard}
            data={editData}
            parent={
              this.state.cards[this.state.idToIdxCard[editData.card_parent]]
                .card_name
            }
            submitCallback={this.editCard}
            closeCallback={() => {
              const param = this.props.computedMatch.params.task;
              this.setState({ editcard: null, inspectTask: null }, () => {
                if (param) history.push("/sprint");
              });
            }}
            deleteCallback={this.deleteCard}
            users={this.state.users}
            goals={this.state.goals}
            disciplines={this.state.disciplines}
            github={this.state.github}
          />
        )}

        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <div
            style={{
              display: "inline-block",
            }}
          >
            {/*<button
              className={
                this.state.modify
                  ? "btn btn-modify noshadow"
                  : "btn btn-secondary noshadow"
              }
              onClick={() => this.clickModify()}
              style={{
                padding: 0,
                width: 30,
                height: 30,
                borderRadius: "50%",
                fontSize: "1rem",
                zIndex: 50,
                transform: this.state.modify ? "rotate(45deg)" : "rotate(0deg)",
              }}
            >
              <div className="fas fa-plus" />
            </button>*/}
            {this.state.goals && this.state.goals.length > 1 && (
              <div style={{ marginTop: -2 }}>
                <ButtonDropdown type="ellipsis" value="..." nocaret left>
                  <div style={{ display: "inline-block", width: 140 }}>
                    <div style={{ color: "#777", height: 20, fontWeight: 300 }}>
                      SWITCH GOAL
                    </div>
                    {prevGoal && (
                      <div style={{ float: "left", width: "45%" }}>
                        <Button
                          key="menubtn2"
                          fontawesome="fas fa-arrow-left"
                          onClick={() => {
                            const newestGoal = this.state.goals[
                              this.state.goals.length - 1
                            ].goal_id;
                            const toGoal = this.state.goals[
                              this.state.currentGoalIdx - 1
                            ].goal_id;
                            localStorage.setItem("myGoal", toGoal);
                            localStorage.setItem("prevTeamGoal", newestGoal);

                            this.setState(
                              {
                                currentGoal: toGoal,
                                currentGoalIdx: this.state.currentGoalIdx - 1,
                              },
                              this.fetchData
                            );
                          }}
                          style={{
                            padding: "12px",
                          }}
                        />
                      </div>
                    )}
                    {nextGoal && (
                      <div style={{ float: "right", width: "45%" }}>
                        <Button
                          key="menubtn3"
                          fontawesome="fas fa-arrow-right"
                          onClick={() => {
                            const newestGoal = this.state.goals[
                              this.state.goals.length - 1
                            ].goal_id;
                            const toGoal = this.state.goals[
                              this.state.currentGoalIdx + 1
                            ].goal_id;

                            localStorage.setItem("myGoal", toGoal);
                            localStorage.setItem("prevTeamGoal", newestGoal);

                            this.setState(
                              {
                                currentGoal: toGoal,
                                currentGoalIdx: this.state.currentGoalIdx + 1,
                              },
                              this.fetchData
                            );
                          }}
                          style={{
                            padding: "12px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </ButtonDropdown>
              </div>
            )}
          </div>

          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                fontWeight: 700,
                fontSize: 20,
                color: this.state.daysToNextGoal < -1 ? "limegreen" : "#efa51c",
                padding: "6px 13px",
                borderRadius: 10,
              }}
            >
              {this.state.idToIdxGoal[this.state.currentGoal].goal_name}
              {this.state.daysToNextGoal === -1 &&
                this.state.storys.length > 0 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#efa51c",
                      fontWeight: 200,
                      marginLeft: 14,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/overview-project");
                    }}
                  >
                    (no date assigned)
                  </div>
                )}
              {this.state.daysToNextGoal < -1 && this.state.storys.length > 0 && (
                <div
                  style={{
                    fontSize: 12,
                    color: "limegreen",
                    fontWeight: 200,
                    marginLeft: 14,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/overview-project");
                  }}
                >
                  Goal is passed{" "}
                  <span role="img" aria-label="thumbs up">
                    👍🏻
                  </span>
                </div>
              )}
            </div>
          </div>
          {this.state.daysToNextGoal > -1 && (
            <>
              {this.state.showScope &&
                Object.keys(this.state.estimateByDisc).map((d) => {
                  const estimate = Math.round(
                    this.state.estimateByDisc[d] / (1000 * 60 * 60 * 8)
                  );
                  const _disc = this.state.disciplines[
                    this.state.idToIdxDiscs[d]
                  ];
                  const percentage = Math.round(
                    (estimate / this.state.goalCapacity[d]) * 100
                  );

                  return (
                    <div
                      key={"disc" + d}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        borderRadius: 12,
                      }}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          position: "relative",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 9,
                          padding: "1px 6px",
                          marginRight: 8,
                          borderRadius: 4,
                          color: "white",
                          border: _disc.set_value
                            ? "1px solid " + _disc.set_value
                            : "1px solid green",
                          textShadow: "1px 1px 0px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {_disc.set_name}
                        <div
                          style={{
                            color:
                              estimate > this.state.goalCapacity[d]
                                ? "#f00"
                                : "#fff",
                            fontSize: 12,
                            padding: "0px 3px",
                            borderRadius: 4,
                            marginLeft: 8,
                            fontWeight:
                              estimate > this.state.goalCapacity[d] ? 700 : 400,
                            textShadow: "1px 1px 0px rgba(0, 0, 0, 0.9)",
                          }}
                        >
                          {percentage}
                          <div
                            style={{
                              display: "inline",
                              fontSize: 9,
                              color:
                                estimate > this.state.goalCapacity[d]
                                  ? "#f00"
                                  : "#fff",
                            }}
                          >
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div
                style={{
                  fontSize: 12,
                  color: "#efa51c",
                  marginLeft: 8,
                }}
              >
                {this.state.daysToNextGoal} days remaining
              </div>
            </>
          )}
        </div>

        {this.state.storys && this.state.storys.length > 0 && (
          <div
            style={{
              display: "inline-flex",
              float: "right",
              padding: "0px 10px",
              marginTop: 9,
            }}
          >
            <ToggleDrop
              text="Roles"
              data={this.state.disciplines.map((disc, x) => {
                const _showDisc = JSON.parse(
                  localStorage.getItem(
                    this.props.auth.getProjId() + "showDiscipline"
                  )
                );

                const _disc = _showDisc
                  ? _showDisc.find((val) => val.text === disc.set_name)
                  : {};

                return {
                  text: disc.set_name,
                  checked: _disc === undefined ? false : _disc.checked,
                  id: disc.set_id,
                };
              })}
              onClick={(newData) => {
                localStorage.setItem(
                  this.props.auth.getProjId() + "showDiscipline",
                  JSON.stringify(newData)
                );
                this.setState({ showDiscipline: newData });
              }}
            />
            <Toggle
              text="My work"
              state={this.state.showMyCards}
              onClick={(val) => {
                localStorage.setItem(
                  this.props.auth.getProjId() + "showMyCards",
                  !this.state.showMyCards
                );
                this.setState({ showMyCards: !this.state.showMyCards });
              }}
            />
            {this.state.daysToNextGoal > -1 && (
              <Toggle
                text="Scope"
                state={this.state.showScope}
                onClick={(val) => {
                  const newState =
                    this.state.showScope === false ? "on" : "off";
                  localStorage.setItem(
                    this.props.auth.getProjId() + "showScope",
                    newState
                  );
                  this.setState(
                    { showScope: this.state.showScope === false },
                    this.getCards
                  );
                }}
              />
            )}
            <Toggle
              text="Archived"
              state={this.state.showArchived}
              onClick={(val) => {
                localStorage.setItem(
                  this.props.auth.getProjId() + "showArchivedStories",
                  !this.state.showArchived
                );
                this.setState({ showArchived: !this.state.showArchived });
              }}
            />
            <Toggle
              text="Priority"
              state={this.state.showPriority}
              onClick={(val) => {
                localStorage.setItem(
                  this.props.auth.getProjId() + "showPriority",
                  !this.state.showPriority
                );
                this.setState({ showPriority: !this.state.showPriority });
              }}
            />
          </div>
        )}

        <div
          style={{
            display: this.state.modify ? "block" : "none",
            overflow: "hidden",
          }}
        >
          <center>
            <div
              style={{
                display: "block",
                paddingTop: 5,
                marginRight: 6,
                whiteSpace: "nowrap",
              }}
            >
              Select the Epic you are gonna work on
            </div>
            <div
              style={{
                display: "inline-block",
                margin: 40,
                marginTop: 10,
                background: "#ffffff22",
                padding: "18px 22px",
                borderRadius: 10,
              }}
            >
              <div style={{ display: "flex" }}>
                {this.state.pillars &&
                  this.state.pillars.map((val, i) => {
                    if (
                      val.card_type !== "pillar" ||
                      val.card_goal !== this.state.currentGoal
                    )
                      return null;

                    return (
                      <div
                        className="column"
                        key={"pillar" + i}
                        style={{ margin: "0px 6px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              position: "relative",
                              borderRadius: 4,
                              marginBottom: 3,
                              padding: "3px 8px",
                              fontSize: 12,
                              textAlign: "center",
                              flexWrap: "nowrap",
                            }}
                          >
                            {val.card_name}
                          </div>
                        </div>
                        {val.children &&
                          val.children.map((epic, j) => {
                            return (
                              <div
                                key={"epic" + j}
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  background: "#fff",
                                  borderRadius: 3,
                                  padding: "3px 10px",
                                  fontSize: 11,
                                  cursor: "pointer",
                                  flexGrow: 1,
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  boxShadow: "0px 3px 4px #00000099",
                                  color: "#000",
                                  marginBottom: 4,
                                }}
                                onClick={() => {
                                  this.addStory(epic.card_id);
                                }}
                              >
                                {epic.card_name}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}

                {!this.state.pillars ||
                  (this.state.pillars.length === 0 && (
                    <div>No pillars added. Goto Goals to add some.</div>
                  ))}
              </div>
            </div>
          </center>
        </div>

        {this.state.cards && (
          <Board
            pillars={this.state.pillars}
            cards={this.state.cards}
            goal={this.state.goals[this.state.currentGoalIdx]}
            currentGoal={this.state.currentGoal}
            users={this.state.users}
            columns={columns}
            disciplines={this.state.disciplines}
            idToIdx={this.state.idToIdxCard}
            idToIdxPillar={this.state.idToIdxPillar}
            idToIdxGoal={this.state.idToIdxGoal}
            idToIdxUsers={this.state.idToIdxUsers}
            idToIdxDiscs={this.state.idToIdxDiscs}
            highlight={this.state.storySelected}
            highlightCol={this.state.colSelected}
            highlightCard={this.state.cardSelected}
            editCard={(id) => {
              this.setState({ editcard: id });
            }}
            deleteCard={this.deleteCard}
            pushCard={this.pushCard}
            editSprintGoal={this.editCard}
            addCard={this.addCard}
            sprint={this.state.sprint}
            dropCallback={this.moveCard}
            ribbonClick={(data) => {
              this.setState({ ribbon: data });
            }}
            modify={this.state.modify}
            cloudinary={cl}
            inspectTask={this.state.inspectTask}
            showArchived={this.state.showArchived}
            showMyCards={this.state.showMyCards}
            showDiscipline={this.state.showDiscipline}
            showPriority={this.state.showPriority}
            submitDragSprintgoal={this.orderCard}
            auth={this.props.auth}
          />
        )}
      </div>
    );
  }
}

export default Sprint;
