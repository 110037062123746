const TYPES = {
  number: 1,
  string: 2,
  object: 3,
  array: 4,
  date: 5,
  boolean: 6,
  function: 7
};

module.exports = {
  stateChanged: (from, to, debug) => {
    var stateChanged = false;
    var childChanged = false;
    var type = null;
    if (!from || !to) return false;

    var newKeys = Object.keys(to);

    for (var i = 0; i < newKeys.length; i++) {
      if (
        (!from[newKeys[i]] && to[newKeys[i]]) ||
        (from[newKeys[i]] && !to[newKeys[i]])
      ) {
        // Simple change
        childChanged = true;
        type = null;
      } else {
        // Complex change, find type
        if (to[newKeys[i]] && typeof to[newKeys[i]] === "object") {
          if (Array.isArray(to[newKeys[i]])) type = "array";
          else if (to[newKeys[i]].getDate) type = "date";
          else type = "object";
        } else {
          type = typeof to[newKeys[i]];
        }

        switch (TYPES[type]) {
          case TYPES.number:
          case TYPES.string:
          case TYPES.boolean:
          case TYPES.function:
            childChanged = from[newKeys[i]] !== to[newKeys[i]];
            break;
          case TYPES.date:
            childChanged =
              from[newKeys[i]].getTime() !== to[newKeys[i]].getTime();
            break;
          case TYPES.object:
            childChanged = from[newKeys[i]] !== to[newKeys[i]];
            break;
          case TYPES.array:
            childChanged = from[newKeys[i]] !== to[newKeys[i]];
            break;
          default:
            childChanged = false;
        }
      }

      if (childChanged && debug) {
        console.groupCollapsed(
          "%cSTATE CHANGE: %c" +
            newKeys[i] +
            " %c(" +
            debug.constructor.name +
            ")",
          "",
          "color: #f00",
          "font-weight: normal; color: #bbb"
        );
        if (type) console.log("%cType: %c" + type, "font-weight: bold", "");
        console.log(from[newKeys[i]]);
        console.log(to[newKeys[i]]);
        console.groupEnd("STATE CHANGE: " + newKeys[i]);
      }

      if (!stateChanged && childChanged) stateChanged = true;
      if (stateChanged && !debug) break;
      type = null;
    }

    return stateChanged;
  }
};
