import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../utils/history";
import db from "../utils/db";
import { toast } from "react-toastify";

export default function VoteRecruit(props) {
  let { email, score, voter } = useParams();

  useEffect(() => {
    async function fetchData() {
      await db.request("/voterecruit", "POST", {
        email: email,
        score: score,
        voter: voter,
      });

      if (props.auth.isAuthenticated() && props.auth.getUserEmail() === voter) {
        toast.success("Your vote has been added or uppdated, merci!");
        history.push("/recruit");
      }
    }
    fetchData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <center
        style={{
          marginTop: 100,
          fontSize: 26,
          color: "green",
          alignItems: "center",
          fontWeight: 600,
        }}
      >
        <i className="fas fa-check" style={{ marginRight: 20 }} />
        Roger that, vote collected or updated!
      </center>
    </div>
  );
}
