import React, { useState, useRef, useEffect } from "react";
import Popup from "../components/Popup";
import Button from "../components/Button";
import ButtonAdd from "../components/ButtonAdd";
import ButtonDropdown from "../components/ButtonDropdown";
import ButtonFlap from "../components/ButtonFlap";
import InputDropdown from "../components/InputDropdown";
import Textfield from "../components/Textfield";
import db from "../utils/db";
import validator from "validator";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";
import Chance from "chance";

export default function Recruit(props) {
  const chance = new Chance();

  const [loading, setLoading] = useState(true);
  const [recruits, setRecruits] = useState(null);
  const [recruiters, setRecruiters] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersById, setUsersById] = useState({});
  const [showInvite, setShowInvite] = useState(false);
  const [showDetails, setShowDetails] = useState(-1);
  const [showEditMyVote, setShowEditMyVote] = useState(-1);
  const [confirmDeleteObj, setConfirmDeleteObj] = useState(null);
  const [hoverRecruiter, setHoverRecruiter] = useState(-1);
  const [addRecruit, setAddRecruit] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [formEnabled, setFormEnabled] = useState(true);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [editComment, setEditComment] = useState(-1);
  const [showArchived, setShowArchived] = useState(
    localStorage.getItem("showArchivedRecruits") === "true"
  );
  const codename = chance.word({ length: 5 });

  const votes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const statuses = {
    awaitresponse: "Awaiting Response",
    awaitsubmi: "Awaiting Submission",
    followup: "Follow Up",
    hired: "Hired",
    rejected: "Rejected",
  };

  const inviteText = useRef(null);
  const messageRefs = useRef([]);
  const statusButtonRefs = useRef([]);
  const codenameRef = useRef();
  const positionRef = useRef();
  const emailRef = useRef();
  const message = useRef();
  const timer = useRef();

  useEffect(() => {
    fetchData(true);
  }, []);

  useEffect(() => {
    if (showInvite) inviteText.current.focus();
  }, [showInvite]);

  useEffect(() => {
    if (showDetails > -1 && recruits && messageRefs.current) {
      const element =
        messageRefs.current[recruits.findIndex((v) => v.id === showDetails)];

      replaceLinksInText(
        element,
        // eslint-disable-next-line no-useless-escape
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
        (node, match) => {
          node.splitText(match.index + match[0].length);
          var a = document.createElement("a");
          const hasHttp = match[0].substr(0, 4).toLowerCase() === "http";
          a.href = hasHttp ? match[0] : "http://" + match[0];
          a.target = "_blank";
          a.appendChild(node.splitText(match.index));
          node.parentNode.insertBefore(a, node.nextSibling);
        }
      );
    }
  }, [showDetails]);

  useEffect(() => {
    if (recruiters)
      setIsRecruiter(
        recruiters.find(
          (v) =>
            v.set_name !== "disabled" &&
            parseInt(v.set_value, 10) === props.auth.getUserId()
        )
      );
  }, [recruiters]);

  // Functions ------------------------------------------------------

  async function fetchData(initial = false) {
    // First get recruiters
    const _recruiters = await db.request("/auth/getrecruiters", "POST", {
      orgid: props.auth.getOrgId(),
    });
    setRecruiters(_recruiters.status === 200 ? _recruiters.data : []);

    // Some might be disabled but previously active
    const _activeRecruiters = _recruiters.data.filter(
      (v) => v.set_name !== "disabled"
    );

    // Get current recruits
    const _recruits = await db.request("/auth/getrecruits", "POST", {
      orgid: props.auth.getOrgId(),
    });

    // Get current recruits
    const _commentsRes = await db.request("/auth/getcomments", "POST", {
      project: props.auth.getProjId(),
      type: "recruit",
    });

    if (_recruits.status === 200) {
      var arr = _recruits.data.slice();

      for (var i = 0; i < arr.length; i++) {
        // Filter out old recruiters votes'
        var log = arr[i].log ? JSON.parse(arr[i].log) : [];
        log = log.filter(
          (val, k) =>
            _recruiters.data &&
            _recruiters.data.findIndex((v) => val.voter === v.user_email) > -1
        );
        arr[i].log = log.slice();

        // Calculate average score per recruit
        arr[i].score =
          log.length > 0
            ? log.reduce(
                (acc, curr) => parseInt(acc, 10) + parseInt(curr.score, 10),
                0
              ) / log.length
            : 0;

        // Add comments for this recruit
        const recruitId = arr[i].id;
        arr[i].comments = _commentsRes.data
          ? _commentsRes.data.filter((val) => val.com_owner === recruitId)
          : [];
      }

      arr = arr.sort((a, b) => {
        if (a.position.toUpperCase() > b.position.toUpperCase()) return -1;
        else if (a.position.toUpperCase() < b.position.toUpperCase()) return 1;
        else if (a.score > b.score) return -1;
        else if (a.score < b.score) return 1;
        else return 0;
      });

      setRecruits(arr);
    }

    // Get potential recruiters to add from org
    const _users = await db.request("/auth/getusers", "POST", {
      org: props.auth.getOrgId(),
      group: "active",
    });

    var usrArr =
      _activeRecruiters && _activeRecruiters.length > 0
        ? _users.rows.filter(
            (val) => !_activeRecruiters.find((v) => val.user_id === v.user_id)
          )
        : _users.rows;

    // Only lead-roles and up allowed
    usrArr = usrArr.filter((v) =>
      ["owner", "janitor", "lead"].includes(v.mem_role)
    );

    setUsers(
      usrArr.map((obj, j) => {
        return {
          name: obj.user_firstname + " " + obj.user_lastname,
          value: obj.user_id,
          email: obj.user_email,
        };
      })
    );

    var _usersById = {};
    for (var k = 0; k < _users.rows.length; k++) {
      _usersById[_users.rows[k].user_id] = _users.rows[k];
    }
    setUsersById(_usersById);

    if (initial) setLoading(false);
  }

  async function submitInvite(idx) {
    const res = await db.request("/auth/addrecruiter", "POST", {
      orgid: props.auth.getOrgId(),
      userid: users[idx].value,
    });

    if (res.status === 200) {
      setShowInvite(false);
      fetchData();
    }
  }

  async function deleteRecruiter(idx) {
    const res = await db.request("/auth/deleterecruiter", "POST", {
      orgid: props.auth.getOrgId(),
      userid: getActiveRecruiters()[idx].user_id,
    });

    if (res.status === 200) {
      fetchData();
    }
  }

  async function voteOnRecruit(email, score, voter) {
    const res = await db.request("/voterecruit", "POST", {
      email: email,
      score: score,
      voter: voter,
    });

    if (res.status === 200) {
      fetchData();
    }
  }

  function replaceLinksInText(element, pattern, callback) {
    for (var childi = element.childNodes.length; childi-- > 0; ) {
      var child = element.childNodes[childi];
      if (child.nodeType === 1) {
        replaceLinksInText(child, pattern, callback);
      } else if (child.nodeType === 3) {
        var matches = [];
        var match;
        while ((match = pattern.exec(child.data))) {
          matches.push(match);
        }
        for (var i = matches.length; i-- > 0; )
          callback.call(window, child, matches[i]);
      }
    }
  }

  function emailChanged(val) {
    setEmailValid([null, undefined, ""].includes(validateEmail(val)));
  }

  function codenameChanged(val) {
    setEmailValid([null, undefined, ""].includes(validateEmail(val)));
  }

  function validateCodename(value) {
    var msg = "";
    if (!value || (value && !value.trim()))
      msg = "Application needs a codename";
    return msg;
  }

  function validatePosition(value) {
    var msg = "";
    if (!value || (value && !value.trim()))
      msg = "Please specify position as stated on website";
    return msg;
  }

  function validateEmail(value) {
    var msg = "";
    if (!validator.isEmail(value)) msg = "Please give a valid email my way.";
    return msg;
  }

  function validateMessage(value) {
    var msg = "";
    if (!value || (value && !value.trim())) msg = "You gotto say something..";
    return msg;
  }

  function messageChanged(val) {
    setMessageValid([null, undefined, ""].includes(validateMessage(val)));
  }

  function submitAddRecruit() {
    if (
      positionRef.current.submit() &&
      codenameRef.current.submit() &&
      emailRef.current.submit() &&
      message.current.submit()
    ) {
      const positionVal = positionRef.current.value();
      const codenameVal = codenameRef.current.value();
      const emailVal = emailRef.current.value();
      const messageVal = message.current.value();
      setFormEnabled(false);

      db.request("/recruitment", "POST", {
        token: Base64.encode(emailVal),
        message: messageVal,
        position: positionVal,
        codename: codenameVal,
        domain: "nagstudios",
      })
        .then(() => {
          codenameRef.current.reset();
          positionRef.current.reset();
          emailRef.current.reset();
          message.current.reset();
          setFormEnabled(true);
          setEmailValid(false);
          setMessageValid(false);

          fetchData();
          setAddRecruit(false);
        })
        .catch((error) => alert(error));
    }
  }

  async function deleteRecruit(id) {
    const res = await db.request("/auth/deleterecruit", "POST", {
      id: id,
    });

    if (res.status === 200) {
      fetchData();
    } else {
      toast.error("Couldn't delete the recruit..");
    }
    setConfirmDeleteObj(null);
  }

  function getActiveRecruiters() {
    return recruiters
      ? recruiters.filter((v) => v.set_name !== "disabled")
      : [];
  }

  async function claimOwnership(position, codename, email, unclaim) {
    const res = await db.request("/recruitment", "POST", {
      claim: true,
      recruiter: unclaim
        ? -1
        : getActiveRecruiters().find(
            (v) => v.user_id === props.auth.getUserId()
          ).user_email,
      position: position,
      codename: codename,
      applicant: email,
      domain: "nagstudios",
    });

    if (res.status === 200) {
      fetchData();
    } else {
      toast.error("Couldn't claim the recruit..");
    }
  }

  async function setRecruitStatus(id, status, idx) {
    const res = await db.request("/auth/setrecruitstatus", "POST", {
      id: id,
      status: status,
    });

    if (res.status === 200) {
      fetchData();
    } else {
      toast.error("Couldn't set the status for the recruit..");
    }

    if (statusButtonRefs.current) statusButtonRefs.current[idx].close();
  }

  async function addComment() {
    const res = await db.request("/auth/addcomment", "POST", {
      owner: showDetails,
      type: "recruit",
      creator: props.auth.getUserId(),
      project: props.auth.getProjId(),
    });

    if (res.status === 200) {
      setEditComment(res.data[0].com_id);
      fetchData();
      await db.request("/auth/notifyrecruiters", "POST", {
        type: "recruit",
        creator: props.auth.getUserId(),
        org: props.auth.getOrgId(),
        applicant: recruits.find((val) => val.id === showDetails).codename,
      });
    } else {
      toast.error("Error adding comment");
    }
  }

  async function submitComment(_id, _body) {
    const res = await db.request("/auth/editcomment", "POST", {
      id: _id,
      body: _body,
    });

    if (res.status !== 200) {
      toast.error("Error adding comment");
    }
  }

  async function deleteComment(_id) {
    const res = await db.request("/auth/deletecomment", "POST", {
      id: _id,
    });

    if (res.status === 200) {
      toast.success("Deleted comment!");
      fetchData();
    } else {
      toast.error("Error deleting comment");
    }
  }

  function setTimer(cb) {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(cb, 1000);
  }

  // Render ------------------------------------------------------

  let currPosition = "";
  let allArchived =
    recruits &&
    recruits.find((el) => {
      return !["hired", "rejected"].includes(el.status);
    });

  return (
    <>
      {confirmDeleteObj && (
        <Popup
          title="Confirm delete"
          cancelOutside={true}
          closeCallback={() => setConfirmDeleteObj(null)}
          submitCallback={() =>
            deleteRecruit(confirmDeleteObj ? confirmDeleteObj.id : null)
          }
          negativeAction="Nevermind"
          positiveAction="YES"
        >
          Are you certain you want to remove job candidate{" "}
          <b>{confirmDeleteObj ? confirmDeleteObj.codename : ""}</b>?
        </Popup>
      )}
      <ButtonFlap
        value={showArchived ? "Hide archived" : "Show archived"}
        state={showArchived}
        fontawesome={
          showArchived ? "fas fa-sort-amount-up" : "fas fa-sort-amount-down"
        }
        onClick={(val) => {
          localStorage.setItem("showArchivedRecruits", val);
          setShowArchived(val);
        }}
      />
      <div style={{ padding: 20 }}>
        <div style={{ display: "inline-block", width: "82%" }}>
          {(!recruits || !allArchived) && !loading && !showArchived && (
            <center style={{ marginTop: 50, marginBottom: 50, fontSize: 26 }}>
              No active applicants
            </center>
          )}
          {recruits &&
            recruits.map((obj, i) => {
              // Filter outh archived or force show
              var isArchived = ["hired", "rejected"].includes(obj.status);
              if (isArchived && !showArchived) return null;

              let newPos =
                currPosition.toUpperCase() !== obj.position.toUpperCase();
              currPosition = newPos ? obj.position : currPosition;

              // Get amount of recruiters that has voted
              const logJson = obj.log;

              // Show nice date when applying
              var submittedWhen = "";
              const daysSince = new Date(obj.submitted).daysBetween(
                new Date(),
                true
              );

              if (daysSince < 1) submittedWhen = "today";
              else if (daysSince < 2) submittedWhen = "yesterday";
              else submittedWhen = daysSince + " days ago";

              const owner = recruiters.find((v) => v.user_id === obj.ownership);
              const isMe = obj.ownership === props.auth.getUserId();
              const z = recruits.length - i;

              var progressColor = "";
              if (obj.status === "hired") progressColor = "#0b0";
              else if (obj.status === "rejected") progressColor = "#b00";
              else progressColor = "#ff9900";

              return (
                <div key={obj.id} style={{ position: "relative", padding: 3 }}>
                  {newPos && (
                    <div
                      style={{
                        marginTop: i > 0 ? 10 : 0,
                        fontSize: 13,
                        color: "#7f65c2",
                      }}
                    >
                      {obj.position.toUpperCase()}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 10px",
                      borderRadius: 12,
                      height: "100%",
                      background: "#473673",
                      cursor: "pointer",
                      zIndex: 2,
                      boxShadow: "1px 1px 3px #00000044",
                    }}
                    onClick={() =>
                      setShowDetails((prev) =>
                        prev === -1 || prev !== obj.id ? obj.id : -1
                      )
                    }
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 3,
                        height: 40,
                        width: recruiters
                          ? (obj.score * 10).toString() + "%"
                          : 0,
                        background: progressColor,
                        borderRadius: obj.score > 9.8 ? 12 : "12px 0 0 12px",
                        zIndex: 2,
                      }}
                    />

                    <div
                      style={{
                        fontSize: 22,
                        marginLeft: 12,
                        marginRight: 20,
                        zIndex: 2,
                        fontWeight: "bold",
                        userSelect: "none",
                      }}
                    >
                      {obj.codename}
                    </div>

                    {!isArchived && (
                      <div
                        style={{
                          fontSize: 13,
                          opacity: 0.7,
                          color: daysSince >= 7 ? "red" : "#fff",
                          fontWeight: daysSince >= 7 ? "bold" : 300,
                          zIndex: 2,
                        }}
                      >
                        {submittedWhen}
                      </div>
                    )}

                    {obj.ownership && owner && (
                      <>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: 12,
                            textAlign: "right",
                            lineHeight: 1.1,
                            zIndex: 2,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: 300,
                              color: "#876ec4",
                            }}
                          >
                            {owner.user_firstname}
                          </div>
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: 300,
                              color: "#876ec4",
                            }}
                          >
                            {owner.user_lastname}
                          </div>
                        </div>
                        {isMe && isRecruiter ? (
                          <div
                            style={{
                              position: "relative",
                              zIndex: 3 + z,
                              textAlign: "right",
                              lineHeight: 1.1,
                            }}
                          >
                            <ButtonDropdown
                              ref={(el) => (statusButtonRefs.current[i] = el)}
                              value={
                                obj.status ? statuses[obj.status] : "Status"
                              }
                              type={obj.status ? "outline" : ""}
                            >
                              {obj.status && (
                                <Button
                                  value="Remove status"
                                  fontawesome="fas fa-times"
                                  onClick={() => {
                                    setRecruitStatus(obj.id, null, i);
                                  }}
                                  type="red"
                                  style={{
                                    padding: "12px",
                                    borderRadius: "8px 8px 0px 0px",
                                  }}
                                />
                              )}
                              <Button
                                value="Send email"
                                fontawesome="fas fa-envelope"
                                onClick={() => {
                                  if (statusButtonRefs.current)
                                    statusButtonRefs.current[i].close();

                                  window.open(
                                    "http://www.nagstudios.com/respondapplicant/" +
                                      Base64.encode(obj.email) +
                                      "/" +
                                      obj.codename,
                                    "_blank"
                                  );
                                }}
                                style={{
                                  padding: "12px",
                                  borderRadius: obj.status
                                    ? 0
                                    : "8px 8px 0px 0px",
                                }}
                              />
                              <Button
                                value="Awaiting response"
                                fontawesome="fas fa-clock"
                                onClick={() => {
                                  setRecruitStatus(obj.id, "awaitresponse", i);
                                }}
                                style={{
                                  padding: "12px",
                                  borderRadius: 0,
                                }}
                              />
                              <Button
                                value="Awaiting submission"
                                fontawesome="fas fa-clock"
                                onClick={() => {
                                  setRecruitStatus(obj.id, "awaitsubmi", i);
                                }}
                                style={{ padding: "12px", borderRadius: 0 }}
                              />
                              <Button
                                value="Follow up"
                                fontawesome="fas fa-bell"
                                onClick={() => {
                                  setRecruitStatus(obj.id, "followup", i);
                                }}
                                style={{ padding: "12px", borderRadius: 0 }}
                              />
                              <Button
                                value="Hired"
                                fontawesome="fas fa-archive"
                                onClick={() => {
                                  setRecruitStatus(obj.id, "hired", i);
                                }}
                                type="green"
                                style={{ padding: "12px", borderRadius: 0 }}
                              />
                              <Button
                                value="Reject"
                                fontawesome="fas fa-archive"
                                onClick={() => {
                                  setRecruitStatus(obj.id, "rejected", i);
                                }}
                                type="red"
                                style={{
                                  padding: "12px",
                                  borderRadius: "0px 0px 8px 8px",
                                }}
                              />

                              {obj.ownership &&
                                (isMe ||
                                  props.auth.requiresRole("janitor")) && (
                                  <Button
                                    value="Remove Ownership"
                                    fontawesome=""
                                    onClick={() =>
                                      claimOwnership(
                                        obj.position,
                                        obj.codename,
                                        obj.email,
                                        true
                                      )
                                    }
                                    style={{
                                      marginTop: 10,
                                      padding: "12px",
                                      borderRadius: 8,
                                    }}
                                  />
                                )}
                            </ButtonDropdown>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 500,
                              marginRight: 4,
                              zIndex: 2,
                            }}
                          >
                            {obj.status ? statuses[obj.status] : "Status"}
                          </div>
                        )}
                      </>
                    )}
                    {(!obj.ownership ||
                      (props.auth.requiresRole("owner") && !isMe)) &&
                      recruiters.findIndex(
                        (v) => props.auth.getUserId() === v.user_id
                      ) > -1 && (
                        <div
                          style={{
                            marginLeft: obj.ownership ? 4 : "auto",
                            zIndex: 2,
                          }}
                        >
                          <Button
                            value="Take ownership"
                            small
                            onClick={() =>
                              claimOwnership(
                                obj.position,
                                obj.codename,
                                obj.email
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                  <div
                    style={{
                      display:
                        showDetails === obj.id ? "inline-block" : "inherit",
                      height: showDetails === obj.id ? "auto" : 0,
                      width: "98%",
                      marginLeft: 20,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {isMe && (
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          color: "#c00",
                          cursor: "pointer",
                        }}
                        onClick={() => setConfirmDeleteObj(obj)}
                      >
                        <i className="fas fa-trash-alt" />
                      </div>
                    )}
                    <div
                      ref={(el) => (messageRefs.current[i] = el)}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        padding:
                          showDetails === obj.id ? "30px 20px" : "0px 20px",
                        height: "100%",
                        width: "68%",
                        minWidth: "68%",
                        border:
                          showDetails === obj.id
                            ? "2px solid #ffffff22"
                            : "0px solid #ffffff22",
                        borderRadius: 13,
                        background: "#47367344",
                        fontWeight: 400,
                        fontSize: 13,
                        overflowY: "hidden",
                        marginTop: -18,
                        hyphens: "auto",
                      }}
                      dangerouslySetInnerHTML={{ __html: obj.message }}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        height: "100%",
                        padding:
                          showDetails === obj.id ? "6px 20px" : "0px 20px",
                        overflow: "hidden",
                        width: "32%",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          lineHeight: "initial",
                          color: "#674ea7",
                          marginBottom: 4,
                        }}
                      >
                        {props.auth.requiresRole("owner")
                          ? "VOTES"
                          : "YOUR VOTE"}
                        {props.auth.requiresRole("owner") && (
                          <div
                            style={{
                              fontSize: 11,
                              color: "#ff9900",
                              fontWeight: 200,
                            }}
                          >
                            You as Owner can see all votes.
                          </div>
                        )}
                      </div>
                      {recruiters &&
                        getActiveRecruiters().map((val, j) => {
                          const _voter =
                            logJson &&
                            logJson.find((v) => v.voter === val.user_email);

                          return props.auth.requiresRole("owner") ||
                            val.user_id === props.auth.getUserId() ? (
                            <div key={"voter" + j}>
                              <div
                                style={{
                                  display: "flex",
                                  height: 28,
                                  alignItems: "center",
                                  lineHeight: 1,
                                }}
                              >
                                <div>
                                  {val.user_firstname + " " + val.user_lastname}
                                </div>
                                {_voter && (
                                  <div
                                    style={{
                                      color: "#ff9900",
                                      marginLeft: 8,
                                      fontSize: 26,
                                      marginTop: -2,
                                    }}
                                  >
                                    {_voter.score}
                                  </div>
                                )}
                                <div
                                  style={{
                                    color: "#674ea7",
                                    marginLeft: 6,
                                    fontSize: 12,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {_voter ? "" : "-"} of 10
                                </div>

                                {val.user_id === props.auth.getUserId() && (
                                  <div
                                    style={{
                                      marginLeft: 6,
                                      padding: "2px 8px",
                                      background: "#674ea7",
                                      borderRadius: 4,
                                      fontSize: 11,
                                      color: "#230c5e",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      userSelect: "none",
                                    }}
                                    onClick={() =>
                                      setShowEditMyVote((prev) =>
                                        prev > -1 ? -1 : j
                                      )
                                    }
                                  >
                                    CHANGE
                                  </div>
                                )}
                              </div>
                              {showEditMyVote === j && (
                                <div>
                                  {votes.map((v, s) => {
                                    return (
                                      <div
                                        key={"votes" + s}
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          height: 26,
                                          width: 26,
                                          background: "#ff9900",
                                          borderRadius: 6,
                                          margin: 4,
                                          color: "#fff",
                                          textDecoration: "none",
                                          fontSize: 13,
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          voteOnRecruit(
                                            obj.email,
                                            v,
                                            getActiveRecruiters().find(
                                              (o) =>
                                                o.user_id ===
                                                props.auth.getUserId()
                                            ).user_email
                                          );
                                          setShowEditMyVote(-1);
                                        }}
                                      >
                                        {v}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          ) : null;
                        })}
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          lineHeight: "initial",
                          color: "#674ea7",
                          marginTop: 20,
                          marginBottom: 4,
                        }}
                      >
                        COMMENTS
                      </div>

                      <Button value="Add comment" onClick={addComment} />

                      {obj.comments &&
                        obj.comments.map((comment, k) => {
                          const user = usersById[comment.com_creator];
                          const myComment =
                            comment.com_creator === props.auth.getUserId();
                          return (
                            <div
                              key={"recruit" + i + "comment" + comment.com_id}
                              style={{
                                display: "inline-block",
                                position: "relative",
                                background: "#47367344",
                                width: "100%",
                                padding: "2px 6px",
                                border: "2px solid #ffffff22",
                                borderRadius: "8px",
                                marginTop: 6,
                              }}
                            >
                              <div className="tinytext">
                                {user &&
                                  !myComment &&
                                  user.user_firstname +
                                    " " +
                                    user.user_lastname}
                                {myComment && "You"}
                              </div>
                              <div
                                className="tinytext"
                                style={{
                                  position: "absolute",
                                  top: 2,
                                  right: 7,
                                }}
                              >
                                {new Date(comment.com_created).format(
                                  true,
                                  true
                                )}
                              </div>
                              {(myComment ||
                                props.auth.requiresRole("owner")) && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: 1,
                                    right: 6,
                                    fontSize: 11,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to delete your comment regarding " +
                                          obj.codename +
                                          "?"
                                      )
                                    ) {
                                      const _id = comment.com_id;
                                      deleteComment(_id);
                                    }
                                  }}
                                >
                                  <i className="fas fa-trash-alt" />
                                </div>
                              )}

                              <Comment
                                defaultValue={
                                  comment.com_body
                                    ? unescape(comment.com_body)
                                    : ""
                                }
                                onChange={(e) => {
                                  const text = escape(e.target.value);
                                  setTimer(() =>
                                    submitComment(comment.com_id, text)
                                  );
                                }}
                                onFocus={() => {
                                  setEditComment(comment.com_id);
                                }}
                                onBlur={(e) => {
                                  setEditComment(-1);
                                }}
                                readOnly={!myComment}
                                isCurrent={editComment === comment.com_id}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            display: "inline-block",
            maxWidth: "10%",
            verticalAlign: "top",
            textAlign: "right",
            float: "right",
          }}
        >
          {!showInvite && (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <div
                style={{
                  paddingRight: 8,
                  fontWeight: "bold",
                  fontSize: 12,
                  lineHeight: "initial",
                  color: "#674ea7",
                }}
              >
                RECRUITERS
              </div>
              {props.auth.requiresRole("janitor") && (
                <ButtonAdd onClick={() => setShowInvite(true)} />
              )}
            </div>
          )}
          {showInvite && (
            <InputDropdown
              ref={inviteText}
              data={users}
              placeholder="Type name..."
              alignedRight
              autoFocus
              submitCb={submitInvite}
              closeCb={() => setShowInvite(false)}
              large
            />
          )}
          {getActiveRecruiters() &&
            getActiveRecruiters().map((obj, i) => {
              return (
                <div key={"recruiter" + i} style={{ padding: 0, margin: 0 }}>
                  <div style={{ display: "inline-block" }}>
                    <div
                      style={{
                        position: "relative",
                        fontSize: 16,
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      onMouseOver={() => setHoverRecruiter(i)}
                      onMouseOut={() => setHoverRecruiter(-1)}
                    >
                      {obj.user_firstname + " " + obj.user_lastname.charAt(0)}

                      {props.auth.requiresRole("janitor") && (
                        <i
                          className="fas fa-times"
                          style={{
                            visibility:
                              hoverRecruiter === i ? "visible" : "hidden",
                            marginLeft: 8,
                            fontSize: 13,
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteRecruiter(i)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ButtonAdd onClick={() => setAddRecruit((prev) => !prev)} />
          <div
            style={{
              paddingLeft: 6,
              fontWeight: "600",
              fontSize: 10,
              color: "#674ea7",
            }}
          >
            ADD MANUALLY
          </div>
        </div>
        <div
          style={{
            height: addRecruit ? 600 : 0,
            overflow: addRecruit ? "visible" : "hidden",
            width: 600,
            textAlign: "center",
          }}
        >
          <Textfield
            prefix="Codename"
            default={codename}
            maxlength="50"
            validateCb={validateCodename}
            onChange={codenameChanged}
            ref={codenameRef}
            disabled={!formEnabled}
          />
          <Textfield
            placeholder="Position"
            maxlength="50"
            validateCb={validatePosition}
            ref={positionRef}
            disabled={!formEnabled}
          />
          <Textfield
            placeholder="Email"
            maxlength="50"
            validateCb={validateEmail}
            onChange={emailChanged}
            ref={emailRef}
            disabled={!formEnabled}
            autofocus
          />
          <Textfield
            placeholder="Portfolio link and/or quick hello.."
            validateCb={validateMessage}
            onChange={messageChanged}
            ref={message}
            disabled={!emailValid || !formEnabled}
            multiline
          />
          <Button
            value={formEnabled ? "SEND" : "Sending..."}
            onClick={submitAddRecruit}
            disabled={!emailValid || !messageValid || !formEnabled}
          />
        </div>
      </div>
    </>
  );
}

const Comment = (props) => {
  const textarea = useRef();

  useEffect(() => {
    adjustHeight();
    if (props.isCurrent) textarea.current.focus();
  }, [props.isCurrent]);

  function adjustHeight() {
    textarea.current.style.height = "auto";
    textarea.current.style.height = "5px";
    textarea.current.style.height = textarea.current.scrollHeight + 5 + "px";
  }

  return (
    <textarea
      ref={textarea}
      style={{
        width: "100%",
        background: "transparent",
        outline: "none",
        border: 0,
        fontSize: 12,
        color: "#fff",
        margin: "0px 3px",
        resize: "none",
        cursor: props.readOnly ? "default" : "auto",
      }}
      placeholder="Comment here..."
      defaultValue={props.defaultValue ? props.defaultValue : ""}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        adjustHeight();
      }}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      readOnly={props.readOnly}
    />
  );
};
